import { Avatar, Button, Input, List, Modal, Select, Space, Spin, Table, Tag, Tooltip, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { accountAction } from "../state/actions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  SearchOutlined,
  CloseOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Loading } from "../../Common";
const { confirm } = Modal;

export default function ManageUser() {
  const {
    createdPersonsLoading,
    createdPersonsData,
    totalRecords,
    delegateListData,
    delegateListLoading,
  } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const [searchText, setsearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentQuery, setCurrentQuery] = useState({});
  const [roles, setRoles] = useState([]);
  const [searchedColumn, setsearchedColumn] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disabledBtn, setdisabledBtn] = useState(true);
  const Text = Typography;

  const showModal = (Id) => {
    dispatch(accountAction.getDelegateList(Id));
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const searchInput = useRef();
  useEffect(() => {
    let AllRoles = [];
    createdPersonsData.forEach((element) => {
      AllRoles.push({
        adminOfficeRole: element.adminOfficeRole,
        userRole: element.userRole,
        agentRole: element.agentRole,
        realtorRole: element.realtorRole,
        transactionCoordinatorRole: element.transactionCoordinatorRole.role,
        delegates: element.delegates,
      });
    });
    setRoles(AllRoles);
  }, [createdPersonsData]);

  useEffect(() => {
    let query = {
      ...currentQuery,
      page: currentPage,
      limit: 30,
    };
    dispatch(accountAction.getAllPersonsByProfileCreated(query));
  }, [currentPage]);

  const handleRoles = (idx, key, role, fullName, id) => {
    confirm({
      title: `Are you sure you want to ${
        !roles[idx][key] ? "assign" : "revoke"
      } ${role} role to ${fullName}`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk() {
        if (key === "agentRole") {
          roles[idx]["userRole"] = false;
          roles[idx]["realtorRole"] = false;
        } else if (key === "userRole") {
          roles[idx]["agentRole"] = false;
          roles[idx]["realtorRole"] = false;
        } else if (key === "realtorRole") {
          roles[idx]["agentRole"] = false;
          roles[idx]["userRole"] = false;
        }
        roles[idx][key] = !roles[idx][key];
        setRoles([...roles]);
        const allRoles = { ...roles[idx] };
        delete allRoles.delegates;
        dispatch(
          accountAction.updatePersonCreated({
            allRoles: allRoles,
            personId: id,
          })
        );
      },
      onCancel() {},
    });
  };


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchText(selectedKeys[0]);
    let query = {
      search: selectedKeys[0],
      page: 1,
      limit: 30,
    };
    dispatch(accountAction.getAllPersonsByProfileCreated(query));
    setsearchedColumn(dataIndex);
    setCurrentQuery(query);
    setCurrentPage(1);
  };
  const handleReset = (clearFilters, setSelectedKeys) => {
    clearFilters();
    setSelectedKeys("");
    let query = {
      page: 1,
      limit: 30,
    };
    dispatch(accountAction.getAllPersonsByProfileCreated(query));
    setsearchText("");
  };

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search in fullName and email`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {handleSearch(selectedKeys, confirm, dataIndex);setdisabledBtn(false)}}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {handleSearch(selectedKeys, confirm, dataIndex);setdisabledBtn(false)}}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisabledBtn(true)}}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    render: (text) => {
      const fullName = text.fullName ? text.fullName.toString() : "";
      const email = text.email ? text.email.toString() : "";
      return searchedColumn === dataIndex ? (
        <>
          <div>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={fullName}
            />
          </div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={email}
            />
          </div>
        </>
      ) : (
        <>
          <div>{text.fullName ? text.fullName : "-"}</div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            {text.email ? `${text.email}` : ""}
          </div>
        </>
      );
    },
  });

const handleResetfilters=()=>{
    let query = {
      page: 1,
      limit: 30,
    };
    dispatch(accountAction.getAllPersonsByProfileCreated(query));
    setsearchText("");
}

const handleRemoveDelegate=(allData,data,Id,currentName)=>{
    let selectedUser;
    let isRemoving = true;
      selectedUser = data?.fullName
    confirm({
      title: `Are you sure you want to ${isRemoving ? "revoke" : "delegate"} ${
        (selectedUser ? selectedUser + " to" : "") || "user from"
      } ${currentName}'s account`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk() {
        let obj = {delegateRemoved:data._id};
        dispatch(
                accountAction.updatePersonCreated({
                ...obj,
                personId: Id,
                })
              );
         handleCancel();
      },
      onCancel() {},
    });
}

  const handleDelegateChange = (allData,data,Id,currentName) => {
    let selectedUser;
    let isRemoving = data.isDelegate;
    if (!isRemoving) {
      selectedUser = data?.fullName
    }
    confirm({
      title: `Are you sure you want to ${isRemoving ? "revoke" : "delegate"} ${
        (selectedUser ? selectedUser + " to" : "") || "user from"
      } ${currentName}'s account`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk() {
        let obj = {};
        if (isRemoving) {
          const previous = [data];
          previous.forEach((element) => {
            if (allData?.allDelegates?.includes(element)) {
              obj.delegateRemoved = element?._id;
            }
          });
        }
        if(data.isDelegate){
            dispatch(
                accountAction.updatePersonCreated({
                  ...obj,
                  personId: Id,
                })
              );
         }else{
            dispatch(
                accountAction.updatePersonCreated({
                  delegatesAssigned:data?._id,
                  personId: Id,
                })
              );
        }

         handleCancel();
      },
      onCancel() {},
    });
  };

  return createdPersonsLoading ? (
    <Loading />
  ) : (<>
    <Modal title={<span style={{display:"flex",justifyContent:"center",color:"grey"}}>ASSIGN DELIGATES</span>}
     visible={isModalOpen}  onCancel={handleCancel} footer={false}>
        {delegateListLoading ? <div style={{width:"100%",minHeight:"300px"}}>
            <Spin style={{alignItems:"center",margin:"auto",width:"100%",paddingTop:"25%"}} size="large" />
        </div> :
        <List
        itemLayout="horizontal"
        dataSource={delegateListData}
        renderItem={(item,idx) => (<>
        {item?.allDelegates?.map(el=>(
        <List.Item
        style={{background:el.isDelegate ? "#93b4d2" : "",cursor:"pointer"}}
        onClick={(e) =>
            handleDelegateChange(item, el, item?._id, item?.fullName)
        }
        >
            <List.Item.Meta
            avatar={ el?.profileImg?.original ?
            <Avatar style={{border:"1px solid grey"}} size={50} src={el?.profileImg?.original} /> :
            <Avatar size={50} style={{background:"#085191"}} >
                {el?.fullName
                    ?.split(' ')
                    .shift()
                    ?.charAt(0)
                    ?.toUpperCase() +
                    el?.fullName
                    ?.split(' ')
                    .pop()
                    ?.charAt(0)
                    ?.toUpperCase()}
                </Avatar>
            }
            title={<span>{el?.fullName}</span>}
            description={el?.email}
            />
        </List.Item>
        ))}
        </>
        )}
    />
    }
    </Modal>
    <div style={{position:"absolute",top:"89px",zIndex:"111",left :'216px'}}>
             <Button
             disabled={disabledBtn} onClick={()=>{handleResetfilters();setdisabledBtn(true)}} size="" style={{marginLeft:"60px"}}
            >Reset Filters</Button>
    </div>
    <Table
       scroll={{y:"62vh"}}
      columns={[
        {
          title: "USERS",
          dataIndex: "userDetails",
          key: "userDetails",
          ...getColumnSearchProps("userDetails"),
          width: "15%",
        },
        {
          title: (
            <div>
              <div>USER</div>
              <p>(Auto Assigned)</p>
            </div>
          ),
          dataIndex: "userRole",
          key: "userRole",
          width: "10%",
          align: "center",
          render: (text, allData, idx) => (
            <div>
              <input
                type="radio"
                onClick={() =>
                  handleRoles(
                    idx,
                    "userRole",
                    "User Role",
                    allData.userDetails.fullName,
                    allData._id
                  )
                }
                checked={roles?.[idx]?.userRole}
                style={{ pointerEvents: "none" }}
              />
            </div>
          ),
        },
        {
          title: (
            <div>
              <div>AGENT</div>
              <p>(Auto Assigned)</p>
            </div>
          ),
          dataIndex: "agentRole",
          key: "agentRole",
          width: "10%",
          align: "center",
          render: (text, allData, idx) => (
            <div>
              <input
                type="radio"
                onClick={() =>
                  handleRoles(
                    idx,
                    "agentRole",
                    "Agent Role",
                    allData.userDetails.fullName,
                    allData._id
                  )
                }
                checked={roles?.[idx]?.agentRole}
                style={{ pointerEvents: "none" }}
              />
            </div>
          ),
        },
        {
          title: (
            <div>
              <div>REALTOR</div>
              <p>(Auto Assigned)</p>
            </div>
          ),
          dataIndex: "realtorRole",
          key: "realtorRole",
          width: "10%",
          align: "center",
          render: (text, allData, idx) => (
            <div>
              <input
                type="radio"
                onClick={() =>
                  handleRoles(
                    idx,
                    "realtorRole",
                    "Realtor Role",
                    allData.userDetails.fullName,
                    allData._id
                  )
                }
                checked={roles?.[idx]?.realtorRole}
                style={{ pointerEvents: "none" }}
              />
            </div>
          ),
        },
        {
          title: "TRANSACTION CO-ORDINATOR",
          dataIndex: "transactionCoordinatorRole",
          key: "transactionCoordinatorRole",
          width: "10%",
          align: "center",
          render: (text, allData, idx) => (
            <div>
              <input
                type="radio"
                onClick={() =>
                  handleRoles(
                    idx,
                    "transactionCoordinatorRole",
                    "Transaction Co-ordinator Role",
                    allData.userDetails.fullName,
                    allData._id
                  )
                }
                checked={roles?.[idx]?.transactionCoordinatorRole}
                style={{ cursor: "pointer" }}
              />
            </div>
          ),
        },
        {
          title: "ASSIGN DELEGATE",
          dataIndex: "assignCoordinator",
          key: "assignCoordinator",
          width: "10%",
          align: "center",
          render: (text, allData, idx) => (
            <div style={{display:"flex", justifyContent:"space-between"}}>
              <div style={{width:"100%",padding:"5px",}}>
                {allData?.delegates?.map((el,index)=>(
                <div  style={{padding:"3px",margin:"5px auto",alignItems:"flex-start", width:"100%",border:"1px solid grey",display:"flex",justifyContent:"space-around" }}
                onClose={()=>handleRemoveDelegate(allData, el, allData?._id, allData?.userDetails?.fullName)}>
                   <Text style={{fontSize:"16px", width:"90%",textAlign:"left"}} elipsis>{el?.fullName}</Text>
                      <span style={{textAlign:"center",padding:"2px",cursor:"pointer",margin:"auto", width:"20%"}} onClick={()=>handleRemoveDelegate(allData, el, allData?._id, allData?.userDetails?.fullName)}>
                        <CloseOutlined/>
                      </span>
                </div>
                ))}
              </div>
              <div>
              <Tooltip title='Assign Deligates' trigger={['click','hover']}>
              <PlusCircleFilled
                  style={{
                    fontSize: "20px",
                    fill: "#085191",
                    color: "#085191",
                    marginLeft:"5px",
                  }}
                  onClick={()=>showModal(text)}
                />
                </Tooltip>
              </div>
            </div>
          ),
        },
      ]}
      dataSource={createdPersonsData}
      bordered
      pagination={{
        position: ["bottomCenter"],
        showSizeChanger: false,
        pageSize: 30,
        current: currentPage,
        total: totalRecords,
        onChange: (page) => {
          setCurrentPage(page);
        },
      }}
    />
    </>
  );
}
