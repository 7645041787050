import { Modal } from 'antd'
import React from 'react'
import styles from '../styles/CustomCalendar.module.css';

function AllDayEventsModal({setModalStates, modalStates}) {
    return (
        <>
            {/* Modal to show all the day event details */}
            <Modal
                title="Day Events"
                visible={true}
                closable
                onCancel={() => setModalStates(prev => ({ ...prev, openAllEventsModal: false }))}
                footer={null}
            >
                {modalStates?.dayEvents?.map((event, index) => (
                    <div key={index} className={styles.eventTitle}>
                        {event.title}
                    </div>
                ))}
            </Modal>
        </>
    )
}

export default AllDayEventsModal