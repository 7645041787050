import { useDispatch, useSelector } from "react-redux";
import { subscriptionAction } from "../state/action";
import { Menu } from "antd";
import { GrView } from "react-icons/gr";
import { MdManageHistory } from "react-icons/md";
export const MenuItem = ({ setPlanDetailsModalVisible }) => {
  const dispatch = useDispatch();
  const { getUserDetailsData } = useSelector((state) => state.account);
  let currentPlan = getUserDetailsData?.subscriptionData?.activePlan;
  const handleViewTransactionHistory = () => {
    dispatch(
      subscriptionAction.viewTransHistoryFun({
        email: getUserDetailsData?.email,
        redirectTo: "ADMIN_APP",
      })
    );
  };
  return (
    <Menu size="large">
      {currentPlan && (
        <Menu.Item
          key="trans"
          onClick={() => setPlanDetailsModalVisible(true)}
          style={{ display: "flex", alignItems: "center" }}
        >
          <span
            style={{ display: "flex", alignItems: "center", marginTop: "1px" }}
          >
            <GrView />
          </span>
          <span style={{ marginLeft: "5px" }}>View Subscription Details</span>
        </Menu.Item>
      )}
      <Menu.Item
        key="manage"
        onClick={() => handleViewTransactionHistory()}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span
          style={{ display: "flex", alignItems: "center", marginTop: "1px" }}
        >
          <MdManageHistory />
        </span>
        <span style={{ marginLeft: "5px" }}>Manage Plan</span>
      </Menu.Item>
    </Menu>
  );
};
