import { accountCreationConstants } from "./action-types";

const initialState = {
  createdPersonsLoading: true,
  createdPersonsError: false,
  createdPersonsData: [],
  delegateListLoading: true,
  delegateListError: false,
  delegateListData: [],
  totalRecords:null,
  getUserDetailsData: null,
  userID: "",
  adminName: "",
  adminRole: "",
  roleAccessed: "",
  getUserDetailsLoading: false,
  getUserProfileDataLoading: false,
  getUserDetailsError: null,
};

const account = (state = initialState, action) => {
  switch (action.type) {
    // Get Profile Details
    case accountCreationConstants.GET_USER_DETAILS_LOADING:
      return {
        ...state,
        getUserProfileDataLoading: true,
        getUserDetailsError: null,
      };
    case accountCreationConstants.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        getUserDetailsData: action.getUserDetailsData,
        userID: action.userID,
        adminName: action.adminName,
        adminRole: action.adminRole,
        roleAccessed: action.roleAccessed,
        getUserDetailsLoading: false,
        getUserProfileDataLoading: false,
        getUserDetailsError: null,
      };
    case accountCreationConstants.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        getUserDetailsData: [],
        getUserDetailsLoading: false,
        getUserProfileDataLoading: false,
        getUserDetailsError: action.getUserDetailsError,
      };
    case accountCreationConstants.CREATED_PERSON_LOADING:
      return {
        ...state,
        createdPersonsLoading: true,
        createdPersonsError: false,
      };
    case accountCreationConstants.CREATED_PERSON_ERROR:
      return {
        ...state,
        createdPersonsLoading: false,
        createdPersonsError:true,
      };
    case accountCreationConstants.CREATED_PERSON_DATA:
      const data = [];

      action.createdPersonsData.forEach((element) => {
        let adminOfficeRole = false;
        // if (element.adminRole === "ADMIN_OFFICE") {
        //   adminOfficeRole = true;
        // }

        let userRole = false,
          agentRole = false,
          realtorRole = false,
          transactionCoordinatorRole = false;
        element.roles.forEach((role) => {
          if (role === "USER") {
            userRole = true;
          }
          if (role === "AGENT") {
            agentRole = true;
          }
          if (role === "REALTOR") {
            realtorRole = true;
          }
          if (role === "TRANSACTION_COORDINATOR") {
            transactionCoordinatorRole = true;
          }
        });
        data.push({
          adminOfficeRole,
          userRole,
          agentRole,
          realtorRole,
          transactionCoordinatorRole: {
            role: transactionCoordinatorRole,
            _id: element._id,
          },
          userDetails: {
            fullName: element.fullName,
            email: element.email,
          },
          roles: element.roles,
          adminRole: element.adminRole,
          assignCoordinator: element._id,
          delegates: element.delegatesAssigned || [],
          _id: element._id,
        });
      });
      return {
        ...state,
        createdPersonsLoading: false,
        createdPersonsError: false,
        createdPersonsData: data,
        totalRecords: action.totalRecords
      };

      case accountCreationConstants.GET_DELEGATE_LIST_LOADING:
        return {
          ...state,
          delegateListLoading: true,
          delegateListError: null,
        };
      case accountCreationConstants.GET_DELEGATE_LIST_SUCCESS:
        return {
          ...state,
          delegateListData: action.getDelegateListData,
          delegateListLoading: false,
          delegateListError: null,
        };
      case accountCreationConstants.GET_DELEGATE_LIST_ERROR:
        return {
          ...state,
          delegateListData: [],
          delegateListLoading: false,
          delegateListError: null,
        };

    default:
      return state;
  }
};
export { account };
