export const subcriptionMainConstants = {
    GET_SPECIALPLANS_LOADING : "GET_SPECIALPLANS_LOADING",
    GET_SPECIALPLANS_SUCCESS : "GET_SPECIALPLANS_SUCCESS",
    GET_SPECIALPANS_ERROR : "GET_SPECIALPANS_ERROR",

    EDIT_SPECIALPLANS_ERROR : "EDIT_SPECIALPLANS_ERROR",
    EDIT_SPECIALPLANS_LOADING : "EDIT_SPECIALPLANS_LOADING",
    EDIT_SPECIALPLANS_SUCCESS : "EDIT_SPECIALPLANS_SUCCESS",

}
