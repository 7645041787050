import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { listingEditAction } from "../state/actions";
import { Form, Input, Button, Select } from "antd";

const { Option } = Select;

function AddCategory({ categoryType }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { getCategoryData, getSubCategoryData } = useSelector(
    (state) => state.listingEdit
  );
  // const [category, setCategory] = useState("");
  // const [subCategory, setSubCategory] = useState("");
  const onFinish = (values) => {
    form.resetFields();
    dispatch(listingEditAction.createCategory(values, categoryType));
  };

  // Category change Event
  let handleChange = (categoryID) => {
    // setCategory(categoryID);
    dispatch(listingEditAction.getSubCategory(categoryID));
  };
  // Sub Category change Event
  let handleSubCategory = (e) => {
    // setSubCategory(e);
  };
  return (
    <Form
      name="customized_form_controls"
      form={form}
      layout="inline"
      onFinish={onFinish}
    >
      {categoryType === "PRIMARY_CATEGORY" ? (
        <Form.Item
          name="name"
          label="PRIMARY CATEGORY Name"
          rules={[
            { required: true, message: "Please Enter PRIMARY CATEGORY Name!" },
          ]}
        >
          <Input />
        </Form.Item>
      ) : categoryType === "SUB_CATEGORY" ? (
        <>
          <Form.Item
            name="categoryId"
            label="Select Category"
            rules={[{ required: true, message: "Please Select CATEGORY!" }]}
          >
            <Select style={{ width: 150 }} onChange={handleChange}>
              {getCategoryData &&
                getCategoryData.map((ele, index) => {
                  if (ele.categoryType === "PRIMARY_CATEGORY") {
                    return <Option value={ele._id}>{ele.name}</Option>;
                  }
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label="Sub Category Name"
            rules={[
              { required: true, message: "Please Enter SUB CATEGORY Name!" },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      ) : categoryType === "DOC_TYPE" ? (
        <>
          <Form.Item
            name="categoryId"
            label="Category"
            rules={[{ required: true, message: "Please Select CATEGORY!" }]}
          >
            <Select style={{ width: 120 }} onChange={handleChange}>
              {getCategoryData &&
                getCategoryData.map((ele, index) => {
                  if (ele.categoryType === "PRIMARY_CATEGORY") {
                    return <Option value={ele._id}>{ele.name}</Option>;
                  }
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="subCategoryId"
            label="Sub Category"
            rules={[{ required: true, message: "Please Select SUB CATEGORY!" }]}
          >
            <Select style={{ width: 120 }} onChange={handleSubCategory}>
              {getSubCategoryData &&
                getSubCategoryData.map((ele, index) => {
                  if (ele.categoryType === "SUB_CATEGORY") {
                    return <Option value={ele._id}>{ele.name}</Option>;
                  }
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label="Dock Type Name"
            rules={[{ required: true, message: "Please Enter DOC TYPE Name!" }]}
          >
            <Input />
          </Form.Item>
        </>
      ) : (
        <></>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add
        </Button>
      </Form.Item>
    </Form>
  );
}
export { AddCategory };
