export const suggestionConstants = {
  CREATE_SUGGESSION_FAILURE: "CREATE_SUGGESSION_FAILURE",
  CREATE_SUGGESSION_SUCCESS: "CREATE_SUGGESSION_SUCCESS",

  GET_SUGGESION_FAILURE: "GET_SUGGESION_FAILURE",
  GET_SUGGESION_SUCCESS: "GET_SUGGESION_SUCCESS",

  // GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  // GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",

  // EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
  // EDIT_CATEGORY_FAILURE: "EDIT_CATEGORY_FAILURE",

  // DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",
  // DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
};
