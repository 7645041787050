import React, { useState } from "react";
import { Typography } from "antd";
import { truncateStr } from "../../Common/utils/universalFuntions";
import { EditOutlined } from "@ant-design/icons";
import { CustomInputField } from "./CustomInputField";
import { useSelector } from "react-redux";
const { Text } = Typography;

const Expandable = ({ content, handleCancel, save, docId, keyName, ele, source,markedAsHidden }) => {

  const [showMore, setShowMore] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const { URLofferTableJSON, checkForDisable } = useSelector((state) => state?.offerTable);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  
  return (
    <div style={{ display: "flex", justifyContent: "center",}}  >
      {showInput ? (
        <CustomInputField handleCancel={handleCancel} formatCol="text" placeholder="Enter Now" docId={docId} save={save} keyName={keyName} 
        ele={ele} source={"Editable"} markedAsHidden={markedAsHidden} />
      ) : showMore ? (
        <Text style={{color:markedAsHidden?"gray":"black"}}>
          {content}
          {content?.length > 80 ? (
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              <span onClick={toggleShowMore} style={{ cursor: "pointer", marginLeft: "20px", color: "blue" }}>
                See less
              </span>{" "}
            </span>
          ) : null}
        </Text>
      ) : (
        <Text
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            color:markedAsHidden?"gray":"black"
          }}
        >
          {truncateStr(content, 80)}{" "}
          {content?.length > 80 ? (
            <span style={{ whiteSpace: "nowrap" }}>
              {" "}
              <span onClick={toggleShowMore} style={{ cursor: "pointer", marginLeft: "20px", color: "blue" }}>
                See more
              </span>{" "}
            </span>
          ) : null}
        </Text>
      )}
      {source === "Editable" ? (
        <span style={{ marginLeft: "20px",color:markedAsHidden?"gray":"black" }}>
          <EditOutlined
            style={{
              color: "hsl(211deg 77% 48%)",
              pointerEvents:  (URLofferTableJSON?.urlData?.sellerOpenOfferTable || checkForDisable?.disabled) ? "none" : "auto",
            }}
            onClick={(e) => {
              e.stopPropagation()
              setShowInput(!showInput)
            }}
          />
        </span>
      ) : null}
    </div>
  );
};

export { Expandable };
