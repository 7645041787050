import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Row, Col, Layout } from "antd";
import { clausesHandle } from "./state/actions";
import { EditableTableForClauses } from "./components/EditableTableForClauses";
import { AddCategoryForClauses } from "./components/AddCategoryForClauses";
import { Redirect } from "react-router-dom";
import { roleAccessFunc } from "../Common/commondata/commonData";
const { Content } = Layout;

function Clauses(props) {
  // useEffect(() => {
  //   if (props.networkConnection === false) {
  //     window.location.reload();
  //   }
  // }, [props]);
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  const dispatch = useDispatch();
  let { clausesData } = useSelector((state) => state.clauses);
  useEffect(() => {
    if(roleAccessFunc(roleAccessed, "CLAUSES")){
      dispatch(clausesHandle.getClausesData());
    }
  }, [dispatch]);

  if (!roleAccessFunc(roleAccessed, "CLAUSES")) {
    return <Redirect to="/error" />;
  }

  return (
    <Layout style={{ height: "100%", padding: "0px" }}>
      <Content
        style={{
          overflow: "auto",
          background: "rgb(202 202 202)",
          padding: "1rem",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <AddCategoryForClauses list={clausesData} />
          </Col>
          <Col span={24}>
            <EditableTableForClauses list={clausesData} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export { Clauses };
