import { clientAuthConstants } from "./action-types";

const initialState = {
  setLoading: false,
  setError: "",
  setData: [],
};

const clientAuth = (state = initialState, action) => {
  switch (action.type) {
    case clientAuthConstants.VERIFY_OTP_LOADING:
      return {
        ...state,
        setLoading:true,
        setError: "",
      };
    case clientAuthConstants.VERIFY_OTP_ERROR:
      return {
        ...state,
        setLoading: false,
        setError: action.setError,
      };
    case clientAuthConstants.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        setLoading: false,
        setError: false,
      };

    default:
      return state;
  }
};
export { clientAuth };
