/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { SendEmailModal } from "./SendEmailModal";
import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { useDispatch, useSelector } from "react-redux";
import { commonDataHandle } from "../state/actions";
import styles from "../style/SendEmailModal.module.css"
import { contractDataHandle } from "../../CreateOffer/state/actions";
import axios from "axios";

const SignableAndReadOnly = ({ isEmailModalVisible, rootDocData, role, offerId, selectedData, documentId, setIsEmailModalVisible, ispdfClone, source, setisMenuopen, nestedModalOpen, client, userAuthKey, contractType, documentBuyerIds, documentSellerIds, RTDInitiater, allBuyerAgentData, propertyAddress }) => {
  const initialMountRef = useRef(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 550 });
  const isMediumScreen = useMediaQuery({ minWidth: 551, maxWidth: 850 });
  const [newState, setNewState] = useState({ email: "", isOpen: false });
  const [showTextArea, setShowTextArea] = useState(false);
  const [callPropertyDocsApi, setCallPropertyDocsApi] = useState(false);
  const {getTransactionLaneData, getTransactionLaneLoading} = useSelector((state) => state.rtdTransactionLane);
  const dispatch = useDispatch();

  const handleEmailCancel = () => {
    setIsEmailModalVisible(false);
    if (source === "SHARE_OMD" || source === "SEND_EMAIL_BUYER_AGENT") {
      setisMenuopen(true);
    } else if (source === "CONTACT_NAV" || source === "SELLING_RTD") {
      setIsEmailModalVisible({ ...isEmailModalVisible, isEmailModalOpen: false, sendMultipleDoc: false });
      setNewState({ ...newState, email: "", fullName: "" });
      setShowTextArea(false);
      initialMountRef.current = false;
    }
  };

  useEffect(() => {
    if (userAuthKey && rootDocData?.offerId && ["CONTACT_NAV", "TRANSACTION_CALENDAR"].includes(source)) {
      let openFor;
      if (source === 'TRANSACTION_CALENDAR') {
        openFor = (rootDocData?.openFor === 'BUYER_AGENT') ? 'BUYER' : (rootDocData?.openFor === 'SELLER_AGENT') ? 'SELLER' : rootDocData?.openFor;
      }
      dispatch(transactionLaneDataHandle.getTransactionLaneMethod(rootDocData?.offerId, (rootDocData?.RTDInitiator || openFor), userAuthKey));
    }
    if (["CONTACT_NAV", "TRANSACTION_CALENDAR"].includes(source)) {
      dispatch(transactionLaneDataHandle.getEmail_Template(userAuthKey));
      dispatch(commonDataHandle.getEmailSignData(userAuthKey));
    }
  }, [userAuthKey]);

  let unmounted = {
    value: false,
  };
  
  let unmountSource = axios.CancelToken.source();

  useEffect(() => {
    if (source === 'TRANSACTION_CALENDAR' && userAuthKey && getTransactionLaneData?.[0]) {
      let params = {
        openFor: rootDocData?.openFor,
        offerId: (offerId || rootDocData?.offerId),
        personId: (rootDocData?.openFor === 'BUYER_AGENT') ? getTransactionLaneData?.[0]?.buyerAgentId : (rootDocData?.openFor === 'SELLER_AGENT') ? getTransactionLaneData?.[0]?.sellerAgentId : ""
      }
      dispatch(contractDataHandle.getPropertyDocsFun({ params, unmounted, source: unmountSource, contractAuthKey: userAuthKey }));
    }
    setCallPropertyDocsApi(false);
    return () => {
      unmounted.value = true;
      unmountSource.cancel("Cancelling in cleanup");
    };
  }, [userAuthKey, getTransactionLaneData?.[0], callPropertyDocsApi])
  
  return (
    (source === "CONTACT_NAV" || (source === "SELLING_RTD" && !nestedModalOpen?.isAgent) ? isEmailModalVisible?.isEmailModalOpen : isEmailModalVisible) && (
      <Modal
        visible={source === "CONTACT_NAV" || (source === "SELLING_RTD" && !nestedModalOpen?.isAgent) ? isEmailModalVisible?.isEmailModalOpen : isEmailModalVisible}
        onCancel={handleEmailCancel}
        title={
          <div className={styles.modalTitle}>
            {source === "SHARE_OMD" ? "SEND OFFER MANAGEMENT DASHBOARD TO" : source === "SEND_EMAIL_BUYER_AGENT" ? "Send Email To All Buyer Agents" : source === "SHARE_INDIVIDUAL_OFFER" ? "SEND OFFER TO" : source === "CONTACT_NAV" || source === "SELLING_RTD" ? "SEND EMAIL" : source === "TRANSACTION_CALENDAR" ? "SEND CALENDAR TO CLIENTS" : "SEND DOCUMENT TO"}
            {source !== "SHARE_OMD" && source !== "SEND_EMAIL_BUYER_AGENT" && source !== "SHARE_INDIVIDUAL_OFFER" && (
              <Tooltip title="Add Read Only Recipient!" trigger={["hover", "click"]}>
                <Button
                  type="primary"
                  style={
                    isSmallScreen
                      ? {
                          padding: "2px 6px", // Custom padding for small size
                          fontSize: "12px", // Smaller font for small
                          height: "28px", // Small height
                          position: "absolute",
                          right: "35px",
                          color: "white",
                          marginRight: "5px",
                        }
                      : {
                          padding: "4px 12px", // Custom padding for medium size
                          fontSize: "14px", // Larger font for medium
                          height: "32px", // Medium height
                          position: "absolute",
                          right: "35px",
                          color: "white",
                          marginRight: "10px",
                        }
                  }
                  onClick={() => setShowTextArea(true)}
                >
                  Add Recipient
                </Button>
              </Tooltip>
            )}
          </div>
        }
        width={800}
        footer={false}
      >
        <SendEmailModal
          role={role}
          initialMountRef={initialMountRef}
          selectedData={["CONTACT_NAV", "TRANSACTION_CALENDAR"].includes(source) ? getTransactionLaneData?.[0] : selectedData}
          documentId={documentId}
          setIsEmailModalVisible={setIsEmailModalVisible}
          isEmailModalVisible={isEmailModalVisible}
          ispdfClone={ispdfClone}
          source={source === "CONTACT_NAV" ? (rootDocData?.openFor === "SELLER_AGENT" ? "CONTACT_SELLING_RTD" : "CONTACT_BUYING_RTD") : source}
          handleEmailCancel={handleEmailCancel}
          isSmallScreen={isSmallScreen}
          isMediumScreen={isMediumScreen}
          nestedModalOpen={nestedModalOpen}
          client={client}
          userAuthKey={userAuthKey}
          RTDInitiater={source === "CONTACT_NAV" ? rootDocData?.RTDInitiator : RTDInitiater}
          contractType={contractType}
          BuyerIds={documentBuyerIds}
          SellerIds={documentSellerIds}
          offerId={offerId}
          rootDocData={rootDocData}
          newState={newState}
          setNewState={setNewState}
          showTextArea={showTextArea}
          setShowTextArea={setShowTextArea}
          getTransactionLaneLoading={getTransactionLaneLoading}
          allBuyerAgentData={allBuyerAgentData}
          setCallPropertyDocsApi={setCallPropertyDocsApi}
          propertyAddress={propertyAddress}
        />
      </Modal>
    )
  );
};

export { SignableAndReadOnly };
