import React, { useState, useEffect } from "react";
import {
  Upload,
  message,
  Layout,
  Button,
  Select,
  Form,
  Input,
  DatePicker,
  Typography,
  Card,
} from "antd";
import moment from "moment";

import { suggessionAction } from "../Suggestion/state/actions";
import { Loading } from "../Common/components/Loading";
import { InboxOutlined } from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { postPdfHandle } from "./state/actions";
import { listingEditAction } from "../ListingEdit/state/actions";
import { Redirect } from "react-router-dom";
import styles from "./styles/PdfUpload.module.css";
import { roleAccessFunc } from "../Common/commondata/commonData";
// import { NotAutharised } from "../Common/components/NotAutharised";
const { Text } = Typography;
const { Option } = Select;

const { Content } = Layout;
const { Dragger } = Upload;

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};

function beforeUpload(file) {
  const isJpgOrPng = file.type === "application/pdf";

  if (!isJpgOrPng) {
    message.error("You can only upload PDF file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error("PDF must smaller than 5MB!");
  }
  return isJpgOrPng && isLt2M;
}

function PdfUpload(props) {
  // useEffect(() => {
  //   if (props.networkConnection === false) {
  //     window.location.reload();
  //   }
  // }, [props]);

  const [categoryData, setcategoryData] = useState({
    category: "",
    subCategory: "",
    docType: "",
  });
  const dispatch = useDispatch();
  const [fileText, setFileText] = useState({ name: null, size: null });

  const [lodingpdfSubmit, setLodingpdfSubmit] = useState(false);

  // Fetches the loggedIn state from redux store
  const { loading, id, documentryListData } = useSelector(
    (state) => state.pdfUpload
  );
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );

  //maintaing temp state
  // const [loading, setTemp] = useState(true);
  const { getSuggesionData, getSuggesionLoading } = useSelector(
    (state) => state.suggestion
  );
  const { getCategoryData, getSubCategoryData, getDockTypeData } = useSelector(
    (state) => state.listingEdit
  );

  useEffect(() => {
    if(roleAccessFunc(roleAccessed, "UPLOAD")){
      dispatch(postPdfHandle.getDocumentryList());
    }
  }, [dispatch]);

  
  
  useEffect(() => {
    if (loading === false) {
      setLodingpdfSubmit(false);
      message.success(`file uploaded successfully.`);
    } else if (loading === true) {
      setLodingpdfSubmit(false);
      message.error(`file upload failed.`);
      // setTimeout(() => {
      //   // return <Redirect to="/failed" />;
      //   history.push("/failed");
      // }, 1000);
    }
    return () => {
      // dispatch(postPdfHandle.resetPdfUpload());
    };
  }, [loading]);

  if (!roleAccessFunc(roleAccessed, "UPLOAD")) {
    return <Redirect to="/error" />;
  }

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  let handleCustome = (event) => {
    setFileText({ name: event.file.name, size: bytesToSize(event.file.size) });
  };

  let onFinish = (event) => {
    setLodingpdfSubmit(true);
    dispatch(postPdfHandle.postPdfFile(event));
  };

  if (loading === false && getSuggesionLoading === false) {
    return (
      <Redirect
        to={{
          pathname: `/preview/${id}`,
          state: { getSuggesionData: JSON.stringify(getSuggesionData) },
        }}
      />
    );
  }

  // Category Event handle
  let handleCategory = async (event) => {
    dispatch(listingEditAction.getSubCategory(event));
    setcategoryData({
      category: event,
      subCategory: "",
      docType: "",
    });
  };

  // SubCategory Event handle
  let handleSubCategory = (event) => {
    setcategoryData({
      category: categoryData.category,
      subCategory: event,
      docType: "",
    });
    dispatch(listingEditAction.getDockType(event));
  };

  // Dock Type Event handle
  // let handleDockType = (event) => {
  //   setcategoryData({
  //     category: categoryData.category,
  //     subCategory: categoryData.subCategory,
  //     docType: event,
  //   });
  // };

  // Dock Type Event handle
  let handleDocumentType = (event) => {};
  // handleYear Event handle
  let handleYear = (event) => {};

  return (
    <Layout className={styles.layoutAlignment}>
      
      <Content>
        {/* {props.roleDisplay && props.roleDisplay === "Admin" ? ( */}
        {/* <> */}
        {lodingpdfSubmit ? (
          <Loading />
        ) : (
          // <Card className={styles.cardAlignment}>
          <Card>
            {/* Category */}
            <Form
              name="validate_other"
              {...formItemLayout}
              onFinish={onFinish}
            >
              {/* <Form.Item
              name="selectCategory"
              label="Select Category"
              rules={[{ required: true, message: "Please select Category!" }]}
            >
              <Select
                // mode="multiple"
                showSearch
                placeholder="Select a Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleCategory}
              >
                {getCategoryData &&
                  getCategoryData.map((ele) => {
                    if (ele.categoryType === "PRIMARY_CATEGORY") {
                      return (
                        <Option key={ele._id} value={ele._id}>
                          {ele.name}
                        </Option>
                      );
                    }
                    return "";
                  })}
              </Select>
            </Form.Item> */}
              {/* Sub category */}
              {/* <Form.Item
              name="subCategory"
              label="Select Sub Cat"
              rules={[
                { required: true, message: "Please select Sub Category!" },
              ]}
            >
              <Select
                // mode="multiple"
                showSearch
                placeholder="Select Sub Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleSubCategory}
              >
                {getSubCategoryData &&
                  getSubCategoryData.map((ele) => {
                    if (ele.categoryType === "SUB_CATEGORY") {
                      return (
                        <Option key={ele._id} value={ele._id}>
                          {ele.name}
                        </Option>
                      );
                    }
                    return "";
                  })}
              </Select>
            </Form.Item> */}
              {/* Dock Type */}
              {/* <Form.Item
              name="docType"
              label="Select Doc Type"
              rules={[
                { required: true, message: "Please select document Type!" },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="Select Doc Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleDockType}
              >
                {getDockTypeData &&
                  getDockTypeData.map((ele) => (
                    <Option key={ele._id} value={ele._id}>
                      {ele.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item> */}
              {/* Select documentries */}
              {/* <Form.Item
              name="documentries"
              label="Select Doc Type"
              rules={[
                { required: true, message: "Please select document Type!" },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="Select Doc Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleDockType}
              >
                {getDockTypeData &&
                  getDockTypeData.map((ele) => (
                    <Option key={ele._id} value={ele._id}>
                      {ele.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item> */}
              {/* select documentry id */}
              <Form.Item
                name="documentries"
                label="Select Doc Type"
                rules={[
                  { required: true, message: "Please select document Type!" },
                ]}
              >
                <Select
                  // mode="multiple"
                  showSearch
                  placeholder="Select Doc Type"
                  optionFilterProp="children"
                  // filterOption={(input, option) =>
                  //   option.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  // onChange={handleDocumentType}
                >
                  {documentryListData &&
                    documentryListData.map((ele) => (
                      <Option key={ele._id} value={ele._id}>
                        {ele.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {/* select year */}
              <Form.Item
                name="year"
                label="Select Year"
                rules={[{ required: true, message: "Please select Year!" }]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    let customDate = moment().format("YYYY-MM-DD");
                    return (
                      current && current < moment(customDate, "YYYY-MM-DD")
                    );
                  }}
                  picker="year"
                />
              </Form.Item>

              {/* Enter dynamic API key  */}
              {/* <Form.Item
              name="api_key"
              label="Enter API Key"
              // rules={[
              //   { required: true, message: "Please provide API Key" },
              // ]}
            >
              <Input placeholder="API Key" />
            </Form.Item> */}
              {/* File upload  */}
              <Form.Item
                name="upload"
                label="Upload File"
                rules={[{ required: true, message: "Please select file!" }]}
              >
                <Dragger
                  accept="application/pdf"
                  showUploadList={false}
                  customRequest={handleCustome}
                  beforeUpload={beforeUpload}
                  disabled={loading === null ? false : true}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag only PDF file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single PDF upload. Strictly prohibit from
                    uploading company data or other band files
                  </p>
                </Dragger>
              </Form.Item>
              {fileText.name && (
                <Form.Item label="File Name ">
                  <Text type="secondary">{fileText.name}</Text>
                  <br />
                  <Text type="secondary">Size: {fileText.size}</Text>
                </Form.Item>
              )}
              <Form.Item className={styles.buttonAlignment}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
        {/* </> */}
        {/* // ) : (
      //   <NotAutharised />
      // )} */}
      </Content>
    </Layout>
  );
}

export { PdfUpload };
