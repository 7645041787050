import React, { useState } from "react";
import { EmptyData } from "./EmptyData";
import { Modal,  } from "antd";
import { Loading } from "./Loading";
import { shallowEqual, useSelector } from "react-redux";
import styles from "../../OfferTable/styles/OfferTable.module.css";
const EmailTemplateModal = ({ Email_templatevisible, setEmail_templatevisible, handleselectemail, handleEmail_templateCancel }) => {
  const { getEmail_TemplateData, getEmail_TemplateLoading } = useSelector((state) => state.rtdTransactionLane, shallowEqual);
  const [Email_templateCount, setEmail_templateCount] = useState(8);
  let handleLoadMore = () => {
    setEmail_templateCount(Email_templateCount + 6);
  };

  return (
    <>
      <Modal title={"Email Template"} style={{ top: 20, height:"500px" }} className="emailModal" visible={Email_templatevisible} onCancel={handleEmail_templateCancel} footer={false}>
        {getEmail_TemplateLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginTop: "70px",
            }}
          >
            <Loading />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              maxHeight: "100%",
              overflowY: "auto",
              overflowX: "auto",
              backgroundColor: "#fff",
              position: "relative",
              zIndex: "30",
              // margin: "20px",
            }}
            onScroll={(e) => {
              if (Math.round(e.target.scrollHeight - Math.round(e.target.scrollTop)) < 800) {
                handleLoadMore();
              }
            }}
          >
            {getEmail_TemplateData?.length > 0 ? (
              <div>
                <table
                  className={styles.licenseTable}
                  style={{
                    marginTop: "-5px",
                  }}
                >
                  <thead
                    style={{
                      position: "sticky",
                      top: -2,
                      left: "0px",
                      // zIndex: 100,
                      // height: "50px",
                      // width:"100px"
                    }}
                  >
                    <tr>
                      <th style={{ border: "1px solid white", width: "20%" }}>Title</th>
                      <th style={{ border: "1px solid white", maxWidth: "100px" }}>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getEmail_TemplateData &&
                      getEmail_TemplateData.length &&
                      getEmail_TemplateData?.map((el, i) => (
                        <tr key={el._id} style={{ marginTop: "20px", cursor: "pointer" }} onClick={() => handleselectemail(el.name)}>
                          <td
                            style={{
                              fontWeight: "bolder",
                              width: "20%",
                              textAlign: "center",
                            }}
                          >
                            {el.title}
                          </td>
                          <td
                            style={{
                              maxWidth: "100px",
                              maxHeight: "60px",
                              overflow: "auto",
                              // border:"1px solid gray",
                            }}
                            // className={style.customscrollbar}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: el.name }}
                              style={{
                                textOverflow: "ellipsis",
                                overflowY: "auto",
                                maxHeight: "80px",
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                  textAlign: "center",
                }}
              >
                <EmptyData />
                {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span> </span>} /> */}
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default EmailTemplateModal;
