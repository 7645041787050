import React, { useState } from "react";
import { Row, Col, Button, Upload, Image, Tabs, message } from "antd";
import {
  FontSizeOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { GetSignaturePad } from "./GetSignaturePad";
import TextPad from "./TextPad";
import { useMediaQuery } from "react-responsive";
const { TabPane } = Tabs;

function ModalChild({ saveFunc, evnt, currentUrlId, rootDocData }) {
  const [signaturePng, setSignaturePng] = useState(true);
  const isMobile = useMediaQuery({maxWidth:500});
  let changeStatus = (info) => {
    let reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsDataURL(info.file);
  };

  let onLoadCallback = (e) => {
    setSignaturePng(e.target.result);
  };

  let callback = (tab) => {
  };
  let handleClear = () => {
    setSignaturePng(false);
  };

  let handleSubmit = () => {
    saveFunc(signaturePng, "text", undefined);
  };

  let beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <div>
      <Tabs onChange={callback}>
        <TabPane
          tab={
            <span style={{ fontSize: isMobile ? "10px" : "15px" }}>
              <FontSizeOutlined />
              TEXT
            </span>
          }
          key="1"
        >
          <TextPad
            evnt={evnt}
            saveFunc={saveFunc}
            currentUrlId={currentUrlId}
          rootDocData ={rootDocData }
          />
        </TabPane>
        <TabPane
          tab={
            <span style={{fontSize: isMobile ? "10px" : "15px" }}>
              <EditOutlined />
              DRAWER
            </span>
          }
          key="2"
        >
          <GetSignaturePad saveFunc={saveFunc} />
        </TabPane>
        <TabPane
          tab={
            <span style={{ fontSize: isMobile ? "10px" : "15px" }}>
              <UploadOutlined />
              UPLOAD
            </span>
          }
          key="3"
        >
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "35px",
              }}
            >
              <Upload
                showUploadList={false}
                // onChange={(e) => changeStatus(e)}
                // data={changeStatus}
                beforeUpload={beforeUpload}
                customRequest={changeStatus}
              >
                <Button icon={<UploadOutlined />} size="large">
                  Upload Signature
                </Button>
              </Upload>
            </Col>
            {signaturePng && (
              <Row style={{ width: "100%", marginTop: "20px" }}>
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Image
                    preview={false}
                    width={"250px"}
                    height={"auto"}
                    src={signaturePng}
                  />
                </Col>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "45px",
                  }}
                >
                  <Button
                    size={"middle"}
                    onClick={handleClear}
                    style={{ width: "100px", marginRight: "10px" }}
                  >
                    Clear
                  </Button>
                  <Button
                    size={"middle"}
                    type="primary"
                    onClick={handleSubmit}
                    style={{ width: "100px" }}
                  >
                    Sign
                  </Button>
                </Col>
              </Row>
            )}
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
}
export { ModalChild };
