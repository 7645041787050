import React from "react";
import { Result, Button } from "antd";
import { useSelector } from "react-redux";
import dotEnv from "dotenv";
import { detectWebView } from "../utils/universalFuntions";
dotEnv.config();
const apiURL = process.env.REACT_APP_WEB_BASE_URL;
function SuccessForm({ title, subTitle, flag }) {
  const { currentUrlId } = useSelector((state) => state.createOffer);
  let localData = JSON.parse(localStorage.getItem(currentUrlId));
  let redirectTo = localData && (localData["redirectTo"] || "webApp");

  let redirectHome = async () => {
    await window?.ReactNativeWebView?.postMessage("DOCUMENT_SAVED");
  };
  let homeHandle = () => {
    if (redirectTo === "webApp") {
      window.location = apiURL;
    } else {
      let isInWebView = detectWebView();
      if (isInWebView) {
        redirectHome();
      } else {
        window.location = apiURL;
      }
    }
  };
  return (
    <Result
      status="success"
      title={title}
      subTitle={subTitle}
      extra={
        flag && (
          <Button type="primary" onClick={homeHandle} key="console">
            Home
          </Button>
        )
      }
    />
  );
}

export { SuccessForm };
