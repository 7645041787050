import { pdfUploadConstants } from "./action-types";
import { pdfUploadApi } from "../utils/api";
import { message } from "antd";
// import axios from "axios";

// const apiURL = process.env.REACT_APP_BASEURL;

/**
 * All Actions for App
 */

/**
 * To update Navbar title action
 * @param {string} navbar_title
 */

// Get documentry listing
const documentryError = (error) => {
  return {
    type: pdfUploadConstants.DOCUMENTRY_LIST_FAILURE,
    documentryListError: error,
    documentryListLoading: false,
  };
};

const documentrySuccess = (response) => {
  return {
    type: pdfUploadConstants.DOCUMENTRY_LIST_SUCCESS,
    documentryListData: response.data.info,
    documentryListLoading: false,
  };
};

const getDocumentryList = () => {
  return (dispatch, getState) => {
    pdfUploadApi
      .documentryListingRequest()
      .then((response) => {
        dispatch(documentrySuccess(response));
      })
      .catch((error) => {
        dispatch(documentryError(error));
      });
  };
};

// pdf upload
const uploadError = (error) => {
  return {
    type: pdfUploadConstants.UPLOAD_PDF_FAILURE,
    uploadError: error,
    loading: true,
  };
};

const uploadSuccess = (response) => {
  return {
    type: pdfUploadConstants.UPLOAD_PDF_SUCCESS,
    uploadData: response.data.info.finalReactComp,
    id: response.data.info._id,
    loading: false,
  };
};

const postPdfFile = (file) => {
  return (dispatch, getState) => {
    // const token = getState().authentication.token;
    const token = true;
    pdfUploadApi
      .pdfUploadRequest(token, file)
      .then((response) => {
        dispatch(uploadSuccess(response));
      })
      .catch((error) => {
        dispatch(uploadError(error));
      });
  };
};

const sendPreviewError = (error) => {
  return {
    type: pdfUploadConstants.SEND_PREVIEW_FAILURE,
    sendPreviewError: error,
    previewLoading: true,
  };

};

const sendPreviewSuccess = (response) => {
  return {
    type: pdfUploadConstants.SEND_PREVIEW_SUCCESS,
    sendPreviewData: response.data.info._id,
    previewLoading: false,
  };
};

const sendPreview = (
  id,
  password,
  htmlString,
  offerTableData,
  boldElements,
  originalJSX
) => {
  return (dispatch, getState) => {
     
    pdfUploadApi
      .sendPreviewSubmitRequest(
        id,
        password,
        htmlString,
        offerTableData,
        boldElements,
        originalJSX
      )
      .then((response) => {
        dispatch(sendPreviewSuccess(response));
        message.success("Submitted Sucessfully")
      })
      .catch((error) => {
        dispatch(sendPreviewError(error));
        message.error("Invalid password")
      });
  };
};

const resetPdfUpload = () => ({
  type: pdfUploadConstants.RESET_PDF_UPLOAD,
});

export const postPdfHandle = {
  postPdfFile,
  sendPreview,
  resetPdfUpload,
  getDocumentryList,
};
