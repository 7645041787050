import React, { useState } from "react";
import CreateFirmForm from "./CreateFirmsForm";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { firmDetailsHandle } from "../state/action";
import { roleAccessFunc } from "../../Common/commondata/commonData";

function CreateFirms({ setShowCreateModal, form , isBrokerageLogoRequired, setIsBrokerageLogoRequired,brokrageAddress,setBrokrageAddress,officeAddress,setOfficeAddress}) {
  const [activeTab, setActiveTab] = useState("BROKERAGE");
 
  const dispatch = useDispatch();
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  // const isAdminOffice = adminRole?.includes("ADMIN_OFFICE");
 const { getFranchiseData } = useSelector((state) => state.firmDetails);

 
  useEffect(() => {
    if (activeTab === "FRANCHISE") {
    } else if (activeTab === "BROKERAGE") {
      let data = { type: "FRANCHISE" };
      dispatch(firmDetailsHandle.getAllFranchise(data));
    } else if (activeTab=== "OFFICE") {
      if (getFranchiseData.length === 0) {
        const payload = { type: "FRANCHISE" };
        dispatch(firmDetailsHandle.getAllFranchise(payload));
      }
      let data = { type: "BROKERAGE" };
      dispatch(firmDetailsHandle.getBrokrage(data));
    }
  }, [activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setIsBrokerageLogoRequired(true);
    form.resetFields();
    setBrokrageAddress("")
    setOfficeAddress("")
   dispatch(firmDetailsHandle.resetImageUrl());
    dispatch(firmDetailsHandle.getAdminSearchData([]));
  };

  const renderTabContent = (tab) => {
    return (
      <CreateFirmForm
        tag={tab}
        form={form}
        setShowCreateModal={setShowCreateModal}
        isBrokerageLogoRequired={isBrokerageLogoRequired}
        setIsBrokerageLogoRequired={setIsBrokerageLogoRequired}
        brokrageAddress={brokrageAddress} 
        setBrokrageAddress={setBrokrageAddress}
        officeAddress={officeAddress}
        setOfficeAddress={setOfficeAddress}
       />
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          borderRadius: "4px",
          overflow: "hidden",
          
        }}
      >
       {roleAccessFunc(roleAccessed, "FRANCHISE_CREATE") ? (
        <div
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            backgroundColor: activeTab === "FRANCHISE" ? "#1890ff" : "#f0f0f0",
            color: activeTab === "FRANCHISE" ? "#fff" : "#333",
            borderRight: "1px solid #ccc",
          }}
          onClick={() => handleTabChange("FRANCHISE")}
        >
          Franchise
        </div>):""}
        {roleAccessFunc(roleAccessed, "BROKERAGE_CREATE") ? (
        <div
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            backgroundColor: activeTab === "BROKERAGE" ? "#1890ff" : "#f0f0f0",
            color: activeTab === "BROKERAGE" ? "#fff" : "#333",
            borderRight: "1px solid #ccc",
          }}
          onClick={() => handleTabChange("BROKERAGE")}
        >
          Brokerage
        </div>):""}
        {/* {roleAccessFunc(roleAccessed, "OFFICE_CREATE") ? (
        <div
          style={{
            padding: "10px 20px",
            cursor: "pointer",
            backgroundColor: activeTab === "OFFICE" ? "#1890ff" : "#f0f0f0",
            color: activeTab === "OFFICE" ? "#fff" : "#333",
          }}
          onClick={() => handleTabChange("OFFICE")}
        >
          Office
        </div>):""} */}
      </div>
      <div style={{ padding: "20px" }}>{renderTabContent(activeTab)}</div>
    </div>
  );
}

export { CreateFirms };
