import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Row, Typography } from "antd";
import newlogo from "../../Assets/Icons/newLogo.png";
import { useSelector } from "react-redux";
import styles from "../styles/OfferTable.module.css";
import { detectWebView, generateFullNameFromObj, generateInitialsFromObj } from "../../Common/utils/universalFuntions";
import dotEnv from "dotenv";
dotEnv.config();
const { Text } = Typography;
const apiURL = process.env.REACT_APP_WEB_BASE_URL;
const Header = ({ urlId }) => {
  const {currentSellerAgentData, URLofferTableJSON } = useSelector((state) => state.offerTable);
 
    const handleHomeImageClick = async () => {
     localStorage.removeItem(urlId);
    let redirectHome = async () => {
      await window?.ReactNativeWebView?.postMessage("REDIRECT_HOME");
    };
    const isInWebView = detectWebView();
      if (isInWebView) {
        redirectHome();
      } else {
        window.location.href = `${apiURL}`;
      }
    return;
    };
    const agentInitials = generateInitialsFromObj(currentSellerAgentData);
  return (
    <div className={styles.header}>
      <Row>
        <Col span={8} style={{ display: "flex", alignItems: "center", color: "white" }}>
          <div style={{ display: "flex", gap: "10px", alignItems: "center", cursor: URLofferTableJSON?.urlData?.sellerOpenOfferTable ? "context-menu" : "pointer", pointerEvents: URLofferTableJSON?.urlData?.sellerOpenOfferTable ? "none" : "auto" }} onClick={handleHomeImageClick}>
            <img src={newlogo} className={styles.headerlogo} preview={false} alt="" />
          </div>
        </Col>
        <Col span={8} className={styles.omdheading}>
          {window.screen.width > 1080 ? <span style={{ fontWeight: "500" }}>Offer Management Dashboard</span> : <span style={{ visibility: "hidden" }}>Normal</span>}
        </Col>
        <Col span={8} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "#fff",
            }}
          >
            <span style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Text ellipsis>
                <div className={styles.headername}>{generateFullNameFromObj(currentSellerAgentData)}</div>
              </Text>
              <Avatar key="5" src={currentSellerAgentData?.profileImg?.original} size={window.screen.width > 1080 ? "large" : "small"} style={{ margin: "auto", color: "#085190", border: "1px solid #085190", fontSize: "18px" }}>
                {!currentSellerAgentData?.profileImg?.original && agentInitials ? agentInitials : <UserOutlined style={{ fontSize: "24px" }} />}
              </Avatar>
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Header;
