import React, { useEffect, useState } from "react";
import { Layout, Menu, Tree, message } from "antd";
import { useLocation } from "react-router-dom";
import {
  // DownOutlined,
  FrownFilled,
  FrownOutlined,
  MehOutlined,
  SmileOutlined,
  PicCenterOutlined,
  UnorderedListOutlined,
  EditOutlined,
  DashboardOutlined,
  LeftCircleFilled,
  RightCircleFilled,
  // GoldOutlined,
  CloudUploadOutlined,
  CopyOutlined,
  BugOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  UserOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  DollarCircleOutlined,
  FormOutlined,
  KeyOutlined,
  ForkOutlined ,
} from "@ant-design/icons";
import { FaUsers } from "react-icons/fa";
import styles from "../styles/PrivateRoute.module.css";
import { Link } from "react-router-dom";
import { MdDomainVerification, MdHistory } from "react-icons/md";
import { shallowEqual, useSelector } from "react-redux";
import { roleAccessFunc } from "../../Common/commondata/commonData";
const { Sider } = Layout;

function SideBar({
  toggle,
  collapsed,
  selectedMenu,
  setselectedMenu
}) {
  const [ScreenWidth, setScreenWidth] = useState();
  const [selectedKey, setSelectedKey] = useState("0");
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setSelectedKey("0");
        break;
      case "/listing":
        setSelectedKey("1");
        break;
      case "/listEdit":
        setSelectedKey("2");
        break;
      case "/upload":
        setSelectedKey("4");
        break;
      case "/suggestion":
        setSelectedKey("3");
        break;
      case "/user":
        setSelectedKey("5");
        break;
      case "/nda":
        setSelectedKey("6");
        break;
      case "/clauses":
        setSelectedKey("7");
        break;
      case "/bugs":
        setSelectedKey("8");
        break;
      case "/officeType":
        setSelectedKey("9");
        break;
      case "/help":
            setSelectedKey("10");
            break;
      case "/accountCreation":
        setSelectedKey("12");
        break;
      case "/subscriptionVerification":
        setSelectedKey("13");
        break;
      case "/fjggaccess":
        setSelectedKey("18");
        break;
      case "/propertyCreatedLogs":
        setSelectedKey("19")
      default:
        break;
    }
    return () => {};
  }, [location]);

  const handleset=(key)=>{
    setSelectedKey(key)
  }
  let handleResize = (e) => {
    if (window.innerWidth < 700) {
      setScreenWidth(true);
    } else {
      setScreenWidth(false);
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    window.addEventListener("load", handleResize);
  });

  let handleClick = (e) => {
    setSelectedKey(e.key);
  };

  const { roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );

  const { TreeNode } = Tree;

 const treeData = [
    {
      title: "parent 1",
      key: "0-0",
      icon: <SmileOutlined />,
      children: [
        {
          title: "leaf",
          key: "0-0-0",
          icon: <MehOutlined />,
        },
        {
          title: "leaf",
          key: "0-0-1",
          icon: ({ selected }) =>
            selected ? <FrownFilled /> : <FrownOutlined />,
        },
      ],
    },
  ];


  const handleChevron =()=>{
    toggle();
     setselectedMenu({
        showVisible:true,
        showVisible2:true,
        showVisible3:true,
        showVisible4:true,
     })
   }

  const handleAccordianToggle = () => {
    setselectedMenu({
        ...selectedMenu,
        showaccordian:!selectedMenu?.showaccordian,
        showaccordian2:false,
        showaccordian3:false,
        showAccordion4:false,
     })
  };

  const handleAccordianToggle2 = () => {
    setselectedMenu({
        ...selectedMenu,
        showaccordian:false,
        showaccordian2:!selectedMenu?.showaccordian2,
        showaccordian3:false,
        showAccordion4:false,
     })
  };

  const handleAccordianToggle3 = () => {
    setselectedMenu({
        ...selectedMenu,
        showaccordian:false,
        showaccordian2:false,
        showaccordian3:!selectedMenu?.showaccordian3,
     })
  };

  const handleAccordionToggle4 = () => {
    setselectedMenu({
        ...selectedMenu,
        showaccordian:false,
        showaccordian2:false,
        showaccordian3:false,
        showAccordion4:!selectedMenu?.showaccordian4,
     })
  };

  return (
    <>
      {roleAccessed && (
        <Sider
          trigger={null}
          collapsible
          collapsed={ScreenWidth ? true : collapsed}
          // breakpoint="lg"
          collapsedWidth={ScreenWidth ? (collapsed ? "0px" : "80px") : "80px"}
          onBreakpoint={(broken) => {}}
          style={{
            backgroundColor: "#4e73df",
            backgroundImage: `linear-gradient(
          180deg
          ,#4e73df 10%,#224abe 100%)`,
            maxWidth: "100% !important",
            minWidth: "100% !important",
            width: "100% !important",
            display: "flex",
            justifyContent: "center",
            height:"inherit"
          }}
          onCollapse={(collapsed, type) => {}}
        >
          {/* <div className={styles.help} style={{position: "fixed", bottom: 20, right: 30, zIndex: "999", width: "350px"}}>
           {paramName==='/help' ? null : <Help paramName={paramName}/>}
          </div> */}
          <Link to="/">
            <div style={{ textAlign: "center" }}>
              <img
                className={styles.logo}
                style={{ width: "52px", height: "auto" }}
                src={"https://resureprojectbucket.s3.amazonaws.com/dev/649be3538168791298e1a3f0/RESURE_RESERVED/18resure-logo-short-white.png"}
                alt="Logo"
              />
            </div>
            {/* /* <div className={styles.logo} /> */}
          </Link>
          <div className={styles.menuScrollBox} style={{ height: "calc(100vh - 90px)", overflowY: "auto" }}>
          <Menu
            style={{
              background: "transparent",
            }}
            theme="dark"
            // style={{ background: "#0077D8" }}
            mode="inline"
            // onSelect={onMenuSelect}
            defaultSelectedKeys={["0"]}
            selectedKeys={[selectedKey]}
            onClick={handleClick}
          >
            <Menu.Item
              key={"0"}
              icon={
                <DashboardOutlined
                  style={
                    collapsed
                      ? { fontSize: "25px" }
                      : { fontSize: "18px", color: "white" }
                  }
                />
              }
            >
              <Link to="/">
                <span style={{ color: "#fff" }}>Dashboard</span>
              </Link>
            </Menu.Item>
            {roleAccessFunc(roleAccessed,"LISTING") ? (
              <Menu.Item
                key={"1"}
                icon={
                  <UnorderedListOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/listing">
                  {" "}
                  <span style={{ color: "#fff" }}>Listing Documents</span>
                </Link>
              </Menu.Item>
            ) : (
              ""
            )}
            {roleAccessFunc(roleAccessed,"LIST_EDIT") ? (
              <Menu.Item
                key={"2"}
                icon={
                  <EditOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/listEdit">
                  <span style={{ color: "#fff" }}>Category</span>
                </Link>
              </Menu.Item>
            ) : (
              ""
            )}
            {/* {adminAccess["SUGGESTION"] ? (
              <Menu.Item
                key={"3"}
                icon={
                  <GoldOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/suggestion">
                  <span style={{ color: "#fff" }}>Suggestion</span>
                </Link>
              </Menu.Item>
            ) : (
              ""
            )} */}
            {roleAccessFunc(roleAccessed,"UPLOAD") ? (
              <Menu.Item
                key={"4"}
                icon={
                  <CloudUploadOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/upload">
                  <span style={{ color: "#fff" }}> Upload Documents</span>
                </Link>
              </Menu.Item>
            ) : (
              ""
            )}
            {roleAccessFunc(roleAccessed,"USER") ? (
              <Menu.Item
                key={"5"}
                icon={
                  <FaUsers
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/user">
                  <span style={{ color: "#fff" }}>Users</span>
                </Link>
              </Menu.Item>
            ) : (
              ""
            )}
            {roleAccessFunc(roleAccessed,"NDA") ? (
              <Menu.Item
                key={"6"}
                icon={
                  <CopyOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/nda">
                  <span style={{ color: "#fff" }}>NDA</span>
                </Link>
              </Menu.Item>
            ) : (
              ""
            )}
            {/* {adminAccess["CLAUSES"] ? (
              <Menu.Item
                key={"7"}
                icon={
                  <PicRightOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/clauses">
                  <span style={{ color: "#fff" }}>Clauses</span>
                </Link>
              </Menu.Item>
            ) : (
              ""
            )} */}
            {roleAccessFunc(roleAccessed,"BUGS") ? (
              <Menu.Item
                key={"8"}
                icon={
                  <BugOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/bugs">
                  <span style={{ color: "#fff" }}>Bugs</span>
                </Link>

              </Menu.Item>
            ) : (
              ""
            )}

            {roleAccessFunc(roleAccessed,"BUGS") ? (
              <Menu.Item
                key={"10"}
                icon={
                  <FileUnknownOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/help">
                  <span style={{ color: "#fff" }}>Help Topics</span>
                </Link>

              </Menu.Item>
            ) : (
              ""
            )}


            {roleAccessFunc(roleAccessed,"BUGS") ? (
              <Menu.Item
                key={"16"}
                icon={
                  <FormOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/news">
                  <span style={{ color: "#fff" }}>News Letter</span>
                </Link>

              </Menu.Item>
            ) : (
              ""
            )}

            {roleAccessFunc(roleAccessed,"BUGS") ? (
                <Menu.Item
                    key={"18"}
                    icon={
                    <KeyOutlined
                    style={
                    collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                        }
                        />
                        }
                    >
                <Link to="/fjggaccess">
                <span style={{ color: "#fff" }}>FJGG Access</span>
                </Link>

                </Menu.Item>
                ) : (
                ""
            )}

            {/* {roleAccessFunc(roleAccessed,"SUBSCRIPTION_USERS") ? (
              <Menu.Item
                key={"13"}
                icon={
                  <DollarCircleOutlined
                    style={
                      collapsed
                        ? { fontSize: "25px" }
                        : { fontSize: "18px", color: "white" }
                    }
                  />
                }
              >
                <Link to="/subscriptionVerification">
                  <span style={{ color: "#fff" }}>Subscription</span>
                </Link>
              </Menu.Item>
            ) : (
              ""
            )} */}

            {
              roleAccessFunc(roleAccessed, "FIRM") ? <Menu.Item key={"11"}>
                <div
                  style={
                    collapsed
                      ? { fontSize: "25px" }
                      : { fontSize: "18px", color: "white" }
                  }
                >
                  {!selectedMenu?.showVisible ? (
                    <>

                      <CaretRightOutlined
                        style={{ fontSize: "25px" ,color:'#D3D3D3'}}
                      onClick={()=>handleChevron('firm')}
                      />

                      <div style={{ fontSize: "16px", color: "#fff" }}>Firm</div>
                    </>
                  ) : (
                    ""
                  )}
                  {selectedMenu?.showaccordian && selectedMenu?.showVisible ? (
                    <CaretDownOutlined onClick={()=>handleAccordianToggle("firm")} />
                  ) : selectedMenu?.showVisible ? (
                    <CaretRightOutlined onClick={()=>handleAccordianToggle("firm")} />
                  ) : (
                    ""
                  )}
                  {selectedMenu?.showVisible ? (
                    <>

                      <text
                        style={{
                          color: "white",
                          paddingBottom: "5px",
                          cursor: "pointer",
                        }}
                        onClick={()=>handleAccordianToggle("firm")}
                      >
                      <Link to="/firmCreation">
                      <span style={{ color: "#fff",fontSize:"16px" }}> Firm</span>

                        </Link>
                      </text>

                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Menu.Item> : ""
            }
            <div
              style={
                collapsed
                  ? { fontSize: "30px", marginLeft: "30px" }
                  : { fontSize: "18px", color: "white", marginLeft: "30px" }
              }
            >
              {selectedMenu?.showaccordian ? (
                <div
                  style={{
                    marginLeft: "15px",
                    fontSize: "18px",

                  }}
                >
                 {roleAccessFunc(roleAccessed,"FIRM_CREATION") ? (
                    <div style={{ color: "white", backgroundColor:location.pathname=="/firmCreation"?"#1890ff":"",display:"flex",alignItems:"center",padding:"5px" }} onClick={()=>handleset("11")}>
                      <Link to="/firmCreation">
                        <ProfileOutlined
                          style={{
                            color: "white",
                            fontSize: "15px",

                          }}
                        />
                        <text
                          style={{
                            color: "white",
                            fontSize: "12px",
                            paddingLeft: "7px",

                            paddingBottom: "10px",
                          }}
                        >
                          Create
                        </text>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {roleAccessFunc(roleAccessed,"FIRM_VERIFICATION") ? (
                    <div style={{ color: "white", fontSize: "18px" ,
                    backgroundColor:location.pathname=="/firmVerification"?"#1890ff":"",display:"flex",alignItems:"center",padding:"5px"}} onClick={()=>handleset("11")}>
                      <Link to="/firmVerification">
                        <MdDomainVerification
                          style={{ color: "white", fontSize: "18px" }}
                        />
                        <text
                          style={{
                            color: "white",
                            fontSize: "12px",
                            paddingLeft: "5px",
                            paddingBottom: "10px",
                          }}
                        >
                          Verify
                        </text>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )} */}



                  <div style={{ color: "white" ,backgroundColor:location.pathname=="/firmaccess"?"#1890ff":"",display:"flex",alignItems:"center",padding:"5px"}} onClick={()=>handleset("11")}>
                    <Link to="/firmaccess">
                      <PicCenterOutlined
                        style={{
                          color: "white",
                          fontSize: "15px",

                        }}
                      />
                      <text
                        style={{
                          color: "white",
                          fontSize: "12px",
                          paddingLeft: "7px",
                          paddingBottom: "10px",
                        }}
                      >
                        Revoke Access
                      </text>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>


            {
              roleAccessFunc(roleAccessed,"TITLE") ? <Menu.Item key={"14"}>
                <div
                  style={
                    collapsed
                      ? { fontSize: "25px" }
                      : { fontSize: "18px", color: "white" }
                  }
                >
                  {!selectedMenu?.showVisible2 ? (
                    <>

                      <CaretRightOutlined
                        style={{ fontSize: "25px" , color:'#D3D3D3'}}
                        onClick={() => {
                        handleChevron('title');
                        }}
                      />

                      <div style={{ fontSize: "16px", color: "#fff" }}>TitleCompany</div>
                    </>
                  ) : (
                    ""
                  )}
                  {selectedMenu?.showaccordian2 && selectedMenu?.showVisible2 ? (
                    <CaretDownOutlined onClick={()=>handleAccordianToggle2("title")} />
                  ) : selectedMenu?.showVisible2 ? (
                    <CaretRightOutlined onClick={()=>handleAccordianToggle2("title")} />
                  ) : (
                    ""
                  )}
                  {selectedMenu?.showVisible2 ? (
                    <>
                      <text
                        style={{
                          color: "white",
                          paddingBottom: "5px",
                          cursor: "pointer",
                        }}
                        onClick={()=>handleAccordianToggle2("title")}
                      >
                      <Link to="/titleCompanyVerification">
                      <span style={{ color: "#fff",fontSize:"16px" }}>TitleCompany</span>
                      </Link>
                      </text>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Menu.Item> : ""
            }
            <div
              style={
                collapsed
                  ? { fontSize: "30px", marginLeft: "30px" }
                  : { fontSize: "18px", color: "white", marginLeft: "20px" }
              }
            >
              {selectedMenu?.showaccordian2 ? (
                <div
                  style={{
                    marginLeft: "18px",
                    fontSize: "18px",

                  }}
                >
                  {roleAccessFunc(roleAccessed,"TITLE_VERIFICATION") ? (
                    <div style={{ color: "white", fontSize: "18px",backgroundColor:location.pathname==="/titleCompanyVerification"?"#1890ff":"",display:"flex",alignItems:"center",padding:"5px" }}
                     onClick={()=>handleset("14")}>
                      <Link to="/titleCompanyVerification">
                        <MdDomainVerification
                          style={{ color: "white", fontSize: "18px" }}
                        />
                        <text
                          style={{
                            color: "white",
                            fontSize: "12px",
                            paddingLeft: "5px",
                            paddingBottom: "10px",
                          }}
                        >
                          Verify
                        </text>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}

                  {roleAccessFunc(roleAccessed,"TITLE_CREATION") ? (
                    <div style={{ color: "white",fontSize: "18px",backgroundColor:location.pathname==="/titleCompanyCreation"?"#1890ff":"",display:"flex",alignItems:"center",padding:"5px"  }} onClick={()=>handleset("14")}>
                      <Link to="/titleCompanyCreation">
                        <ProfileOutlined
                          style={{
                            color: "white",
                            fontSize: "15px",

                          }}
                        />
                        <text
                          style={{
                            color: "white",
                            fontSize: "12px",
                            paddingLeft: "7px",
                            paddingBottom: "10px",
                          }}
                        >
                          Create
                        </text>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}


                </div>
              ) : (
                ""
              )}
            </div>
          {/* subscription toggle code  */}
            {/* {
              roleAccessFunc(roleAccessed,"SUBSCRIPTION_MAIN") ? <Menu.Item key={"20"}>
                <div
                  style={
                    collapsed
                      ? { fontSize: "25px" }
                      : { fontSize: "18px", color: "white" }
                  }
                >
                  {!selectedMenu?.showVisible3 ? (
                    <>

                      <CaretRightOutlined
                        style={{ fontSize: "25px" , color:'#D3D3D3'}}
                        onClick={() => {
                            handleChevron();
                        }}
                      />
                      <div style={{ fontSize: "16px", color: "#fff" }}>Subscription</div>
                    </>
                  ) : (
                    ""
                  )}
                  {selectedMenu?.showaccordian3 && selectedMenu?.showVisible3 ? (
                    <CaretDownOutlined onClick={handleAccordianToggle3} />
                  ) : selectedMenu?.showVisible3 ? (
                    <CaretRightOutlined onClick={handleAccordianToggle3} />
                  ) : (
                    ""
                  )}
                  {selectedMenu?.showVisible3 ? (
                    <>
                      <text
                        style={{
                          color: "white",
                          paddingBottom: "5px",
                          cursor: "pointer",
                        }}
                        onClick={handleAccordianToggle3}
                      >
                      <Link to="/subscription">
                      <span style={{ color: "#fff",fontSize:"16px" }}>Suscription</span>
                      </Link>
                      </text>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Menu.Item> : ""
            } */}

            {/* <div
              style={
                collapsed
                  ? { fontSize: "30px", marginLeft: "30px" }
                  : { fontSize: "18px", color: "white", marginLeft: "20px" }
              }
            >
              {selectedMenu?.showaccordian3 ? (
                <div
                  style={{
                    marginLeft: "18px",
                    fontSize: "18px",

                  }}
                >
                  {roleAccessFunc(roleAccessed,"SUBSCRIPTION_MAIN") ? (
                    <div style={{ color: "white", fontSize: "18px",backgroundColor:location.pathname==="/subscription"?"#1890ff":"",display:"flex",alignItems:"center",padding:"5px" }}
                     onClick={()=>handleset("20")}>
                      <Link to="/subscription">
                        <ForkOutlined
                          style={{ color: "white", fontSize: "18px" }}
                        />
                        <text
                          style={{
                            color: "white",
                            fontSize: "12px",
                            paddingLeft: "5px",
                            paddingBottom: "10px",
                          }}
                        >
                          Special Plans
                        </text>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div> */}
            {/* ---------------------------------------------------------- */}

            {/* Created Logs */}
            {
                roleAccessFunc(roleAccessed, "CREATED_LOGS") ?
                  <Menu.Item key={"19"}>
                    <div style={{ fontSize: collapsed ? "25px" : "18px", color: "white" }}>
                      {
                        !selectedMenu?.showVisible4 ? (
                          <>
                            <CaretRightOutlined style={{ fontSize: "25px", color: '#D3D3D3' }} onClick={()=>{
                              // toggle();
                              // handleToggle2();
                              // history.push("/propertyCreatedLogs");
                              handleChevron();
                            }} />
                            <div style={{ fontSize: "16px", color: "#fff" }}>Logs</div>
                          </>
                        ) : ""
                      }
                      {
                        (selectedMenu?.showAccordion4 && selectedMenu?.showVisible4) ? (<CaretDownOutlined onClick={()=>handleAccordionToggle4} />) :
                        selectedMenu?.showVisible4 ? (<CaretRightOutlined onClick={handleAccordionToggle4} />) :
                            ""
                      }
                      {
                        selectedMenu?.showVisible4 ? (
                          <>
                            <text style={{ color: "white", paddingBottom: "5px", cursor: "pointer" }} onClick={handleAccordionToggle4}>
                              <Link to="/propertyCreatedLogs"><span style={{ color: "#fff", fontSize: "16px" }}> Logs</span></Link>
                            </text>
                          </>
                        ) : ""
                      }
                    </div>
                  </Menu.Item>
                : ""
              }
              <div style={{ fontSize: collapsed ? "30px" : "18px", marginLeft: collapsed ? "30px" : "20px" }}>
                {
                  selectedMenu?.showAccordion4 ? (
                    <div style={{ marginLeft: "18px", fontSize: "18px" }}>
                      {roleAccessFunc(roleAccessed, "CREATED_LOGS") ? (
                        <div style={{ color: "white", fontSize: "18px", backgroundColor: location.pathname == "/propertyCreatedLogs" ? "#1890ff" : "", display: "flex", alignItems: "center", padding: "5px" }}
                          onClick={() => handleset("19")}>
                          <Link to="/propertyCreatedLogs">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <MdHistory style={{ color: "white", fontSize: "18px" }} />
                              <text style={{ color: "white", fontSize: "12px", paddingLeft: "5px" }}>Manual Property</text>
                            </div>
                          </Link>
                        </div>
                      ) : ""
                      }
                    </div>
                  ) : ""
                }
              </div>
              <div style={{ fontSize: collapsed ? "30px" : "18px", marginLeft: collapsed ? "30px" : "20px" }}>
                {
                  selectedMenu?.showAccordion4 ? (
                    <div style={{ marginLeft: "18px", fontSize: "18px" }}>
                      {roleAccessFunc(roleAccessed, "CREATED_LOGS") ? (
                        <div style={{ color: "white", fontSize: "18px", backgroundColor: location.pathname === "/mlsPropertyCreatedLogs" ? "#1890ff" : "", display: "flex", alignItems: "center", padding: "5px" }}
                          onClick={() => handleset("19")}>
                          <Link to="/mlsPropertyCreatedLogs">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <MdHistory style={{ color: "white", fontSize: "18px" }} />
                              <text style={{ color: "white", fontSize: "12px", paddingLeft: "5px" }}>MLS Property</text>
                            </div>
                          </Link>
                        </div>
                      ) : ""
                      }
                    </div>
                  ) : ""
                }
              </div>
              <div style={{ fontSize: collapsed ? "30px" : "18px", marginLeft: collapsed ? "30px" : "20px" }}>
                {
                  selectedMenu?.showAccordion4 ? (
                    <div style={{ marginLeft: "18px", fontSize: "18px" }}>
                      {roleAccessFunc(roleAccessed, "CREATED_LOGS") ? (
                        <div style={{ color: "white", fontSize: "18px", backgroundColor: location.pathname == "/sellerAgentCreatedLogs" ? "#1890ff" : "", display: "flex", alignItems: "center", padding: "5px" }}
                          onClick={() => handleset("19")}>
                          <Link to="/sellerAgentCreatedLogs">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <MdHistory style={{ color: "white", fontSize: "18px" }} />
                              <text style={{ color: "white", fontSize: "12px", paddingLeft: "5px" }}>Seller Agent</text>
                            </div>
                          </Link>
                        </div>
                      ) : ""
                      }
                    </div>
                  ) : ""
                }
              </div>
              <div style={{ fontSize: collapsed ? "30px" : "18px", marginLeft: collapsed ? "30px" : "20px" }}>
                {
                  selectedMenu?.showAccordion4 ? (
                    <div style={{ marginLeft: "18px", fontSize: "18px" }}>
                      {roleAccessFunc(roleAccessed, "CREATED_LOGS") ? (
                        <div style={{ color: "white", fontSize: "18px", backgroundColor: location.pathname == "/buyerAgentCreatedLogs" ? "#1890ff" : "", display: "flex", alignItems: "center", padding: "5px" }}
                          onClick={() => handleset("19")}>
                          <Link to="/buyerAgentCreatedLogs">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <MdHistory style={{ color: "white", fontSize: "18px" }} />
                              <text style={{ color: "white", fontSize: "12px", paddingLeft: "5px" }}>Buyer Agent</text>
                            </div>
                          </Link>
                        </div>
                      ) : ""
                      }
                    </div>
                  ) : ""
                }
              </div>
              <div style={{ fontSize: collapsed ? "30px" : "18px", marginLeft: collapsed ? "30px" : "20px" }}>
                {
                  selectedMenu?.showAccordion4 ? (
                    <div style={{ marginLeft: "18px", fontSize: "18px" }}>
                      {roleAccessFunc(roleAccessed, "CREATED_LOGS") ? (
                        <div style={{ color: "white", fontSize: "18px", backgroundColor: location.pathname == "/agentCreatedLogs" ? "#1890ff" : "", display: "flex", alignItems: "center", padding: "5px" }}
                          onClick={() => handleset("19")}>
                          <Link to="/agentCreatedLogs">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <MdHistory style={{ color: "white", fontSize: "18px" }} />
                              <text style={{ color: "white", fontSize: "12px", paddingLeft: "5px" }}>Agent/User</text>
                            </div>
                          </Link>
                        </div>
                      ) : ""
                      }
                    </div>
                  ) : ""
                }
              </div>
              <div style={{ fontSize: collapsed ? "30px" : "18px", marginLeft: collapsed ? "30px" : "20px" }}>
                {
                  selectedMenu?.showAccordion4 ? (
                    <div style={{ marginLeft: "18px", fontSize: "18px" }}>
                      {roleAccessFunc(roleAccessed, "CREATED_LOGS") ? (
                        <div style={{ color: "white", fontSize: "18px", backgroundColor: location.pathname == "/subscriptionLogs" ? "#1890ff" : "", display: "flex", alignItems: "center", padding: "5px" }}
                          onClick={() => handleset("19")}>
                          <Link to="/subscriptionLogs">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <MdHistory style={{ color: "white", fontSize: "18px" }} />
                              <text style={{ color: "white", fontSize: "12px", paddingLeft: "5px" }}>Subscription</text>
                            </div>
                          </Link>
                        </div>
                      ) : ""
                      }
                    </div>
                  ) : ""
                }
              </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  margin: "1.5rem 0",
                  border: "1px solid",
                  borderBottom: "#fff",
                  width: "72%",
                }}
              ></div>
            </div>
            <Menu.Item key={"17"}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {collapsed ? (
                  <>
                    <RightCircleFilled
                      onClick={() => {
                        toggle();
                        handleChevron();
                      }}
                      style={{ fontSize: "25px" }}
                    />{" "}
                  </>
                ) : (
                  <>
                    <LeftCircleFilled
                      onClick={() => {
                        toggle();
                        setselectedMenu({});
                      }}
                      style={{ fontSize: "25px" }}
                    />
                  </>
                )}
              </div>
            </Menu.Item>
          </Menu>
          </div>
        </Sider>
      )}
    </>
  );
}
export { SideBar };
