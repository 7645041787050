
/**
 * To update Navbar title action
 * @param {string} navbar_title
 */

import { commonApi } from "../utils/api";
import { commonConstants } from "./action-types";

const getEmasilSignSuccess = (data) => {
    return {
      type: commonConstants.GET_EMAIL_SIGN_DATA_SUCCESS,
      emailSignData:data
    };
  };

  const getEmasilSignLoading = () => {
    return {
      type: commonConstants.GET_EMAIL_SIGN_DATA_LOADING,
    };
  };
  const getEmasilSignError = (error) => {
    return {
      type: commonConstants.GET_EMAIL_SIGN_DATA_ERROR,
    };
  };

  const getEmailSignData=(accessTokan)=>{
    return (dispatch)=> {
     dispatch(getEmasilSignLoading());
     commonApi.getEmailSignRequest(accessTokan)
     .then((response)=>{
        dispatch(getEmasilSignSuccess(response?.data?.info))
     })
     .catch((error)=>{
        dispatch(getEmasilSignError())
     });
    }
  }

export const commonDataHandle = {
  getEmailSignData,
};
