import React, { useEffect } from "react";
import text2PNG from "text2png";
import WebFont from "webfontloader";
import { Row, Typography, Button } from "antd";
import { googleFonts } from "../../Common/commondata/commonData";
const { Text } = Typography;

export default function TextPad({ saveFunc, evnt }) {
  useEffect(() => {
    WebFont.load({
      google: {
        families: googleFonts,
      },
    });
  }, []);

  let localstoredData = JSON.parse(localStorage.getItem("NDA"));
  let buyer = localstoredData["nameofPersonReceivingParty"] || "";

  setTimeout(() => {
    let init = buyer && buyer.trim().split(" ");
    const intial = init ? init[0][0]?.toUpperCase() + init[init.length - 1][0]?.toUpperCase() : "";
    const image = text2PNG(buyer, {
      font: `40px ${"Calligraffitti"}`,
      textAlign: "center",
      padding: 20,
      output: "dataURL",
      bgColor: "transparent",
    });
    document.getElementsByName("signature")[0].value = buyer;
    document.getElementsByName("initial")[0].innerHTML = intial;
    document.getElementsByName("font")[0].value = "Calligraffitti";
    document.getElementsByName("imgAdd")[0].src = image;
  }, 500);

  let handleChange = (e, name) => {
    const value = e.target.value;
    let intial = "";
    if (name === "signature") {
      let init = value && value.trim().split(" ");
      if (init && init.length > 1) {
        intial = init ? init[0][0]?.toUpperCase() + init[init?.length - 1][0]?.toUpperCase() : "";
      } else {
        intial = init ? init[0][0]?.toUpperCase() : "";
      }
      document.getElementsByName("initial")[0].innerHTML = intial;
    }
    document.getElementsByName("imgAdd")[0].src = text2PNG(document.getElementsByName("signature")[0].value, {
      font: `40px ${value}`,
      textAlign: "center",
      padding: 20,
      output: "dataURL",
      bgColor: "transparent",
    });
    document.getElementsByName(name)[0].value = value;
  };

  let clear = () => {
    document.getElementsByName("signature")[0].value = "";
    document.getElementsByName("initial")[0].innerHTML = "";
    document.getElementsByName("font")[0].value = "Calligraffitti";
    document.getElementsByName("imgAdd")[0].src = "";
  };

  let handleSubmit = () => {
    const initial = document.getElementsByName("initial")[0].innerHTML;
    if (document.getElementsByName("signature")[0].value !== "" || initial !== "") saveFunc(document.getElementsByName("imgAdd")[0].src, "text", initial);
    else {
      let error = document.getElementById("errorSig");
      error.style.display = "block";
      setTimeout(() => {
        if (error) {
          error.style.display = "none";
        }
      }, 1500);
    }
  };
  return (
    <>
      <Row style={{ marginTop: "10px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <input
            style={{
              width: "70%",
              border: "1px solid #D9D9D9",
              padding: "10px",
            }}
            name="signature"
            onChange={(e) => handleChange(e, "signature")}
            placeholder="Your Signature"
          />
          <div
            style={{
              width: "25%",
              border: "1px solid #D9D9D9",
              padding: "10px",
            }}
          >
            initial: <span name="initial"></span>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <select
            onChange={(e) => handleChange(e, "font")}
            name="font"
            style={{
              border: "1px solid #D9D9D9",
              padding: "10px",
              marginTop: "20px",
              width: "100%",
            }}
          >
            {googleFonts.map((ele, idx) => {
              return (
                <option key={idx} style={{ padding: "15px" }} value={ele}>
                  {ele}
                </option>
              );
            })}
          </select>
        </div>
      </Row>

      <Row justify="center" style={{ marginTop: "15px" }}>
        <img name="imgAdd" style={{ height: "80px" }} alt="" />
      </Row>
      <Row justify="center">
        <Text type="danger" id="errorSig" style={{ paddingBottom: "5px", display: "none" }}>
          Please enter your signature
        </Text>
      </Row>
      <Row justify="end" align="bottom" style={{ marginLeft: "10rem", marginTop: "15px" }}>
        <Button size={"middle"} onClick={clear} style={{ width: "100px", marginRight: "10px" }}>
          Clear
        </Button>
        <Button size={"middle"} type="primary" onClick={handleSubmit} style={{ width: "100px" }}>
          Sign
        </Button>
      </Row>
    </>
  );
}
