import { fjggConstants } from "./action-types";
import { fjggApis } from "../utils/api";

// get access for FJGG Doc & clauses
const getFJGGAccessLoading = () => {
    return {
      type: fjggConstants.GET_ACCESS_DATA_LOADING,
    };
  };
  const getFJGGAccessError = (error) => {
    return {
      type: fjggConstants.GET_ACCESS_DATA_FAILURE,
    };
  };

  const getFJGGAccessSuccess = (response) => {
    return {
      type: fjggConstants.GET_ACCESS_DATA_SUCCESS,
      FJGGAccessData: response?.data?.info,
    };
  };

  const getFJGGAccess = (query) => {
    return (dispatch, getState) => {
      dispatch(getFJGGAccessLoading());
        fjggApis
        .getFJGGAccessRequest(query)
        .then((response) => {
          dispatch(getFJGGAccessSuccess(response));
        })
        .catch((error) => {
          dispatch(getFJGGAccessError(error));
        });
    };
  };

  // grant access for FJGG Doc & clauses
const grantFJGGAccessLoading = () => {
    return {
      type: fjggConstants.GRANT_ACCESS_TO_FJGG_LOADING,
    };
  };
  const grantFJGGAccessError = (error) => {
    return {
      type: fjggConstants.GRANT_ACCESS_TO_FJGG_FAILURE,
    };
  };

  const grantFJGGAccessSuccess = (response,dispatch) => {
    dispatch(getFJGGAccess())
    return {
      type: fjggConstants.GRANT_ACCESS_TO_FJGG_SUCCESS,
      FJGGAccessData: response?.data?.info,
    };
  };

  const grantFJGGAccess = (data) => {
    return (dispatch, getState) => {
      dispatch(grantFJGGAccessLoading());
        fjggApis
        .grantFJGGAccessRequest(data)
        .then((response) => {
          dispatch(grantFJGGAccessSuccess(response,dispatch));
        })
        .catch((error) => {
          dispatch(grantFJGGAccessError(error));
        });
    };
  };


export const fjggDataApis = {
    getFJGGAccess,
    grantFJGGAccess
};
