import React, { useState } from "react";
import { Row, Col, Button, Upload, Image, Tabs } from "antd";
import {
  FontSizeOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { GetSignaturePad } from "./GetSignaturePad";
import TextPad from "./TextPad";
const { TabPane } = Tabs;

function ModalChild({ saveFunc, evnt, handleCustome }) {
  const [signaturePng, setSignaturePng] = useState(false);

  let onLoadCallback = (e) => {
    setSignaturePng(e.target.result);
  };
  let changeStatus = (info) => {
    let reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsDataURL(info.file);
  };
  let callback = (tab) => {
  };

  let testHandle = (tab) => {
  };

  let handleSubmit = () => {
    saveFunc(signaturePng, "text", undefined);
  };

  let handleClear = () => {
    setSignaturePng(false);
  };

  return (
    <div>
      <Tabs onChange={callback} type="card">
        <TabPane
          tab={
            <span>
              <FontSizeOutlined />
              TEXT
            </span>
          }
          key="1"
        >
          <TextPad evnt={evnt} saveFunc={saveFunc} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <EditOutlined />
              DRAWER
            </span>
          }
          key="2"
        >
          <GetSignaturePad saveFunc={saveFunc} />
        </TabPane>
        <TabPane tab="Upload Signature" key="3">
          <Row gutter={[24, 24]}>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Upload
                showUploadList={false}
                // onChange={(e) => changeStatus(e)}
                // data={changeStatus}
                customRequest={changeStatus}
              >
                <Button icon={<UploadOutlined />} size={"Default"}>
                  Upload Signature
                </Button>
              </Upload>
            </Col>
            {signaturePng && (
              <Row gutter={[24, 24]} style={{ width: "100%" }}>
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Image
                    preview={false}
                    width={"150px"}
                    height={"auto"}
                    src={signaturePng}
                  />
                </Col>
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button size={"middle"} onClick={handleClear}>
                    Clear
                  </Button>
                  <Button size={"middle"} type="primary" onClick={handleSubmit}>
                    Sign
                  </Button>
                </Col>
              </Row>
            )}
          </Row>
        </TabPane>
      </Tabs>
      {/* <Row gutter={[24, 24]}>
        <Col>
          <Button
            onClick={() => changeStatus(true)}
            icon={<FontSizeOutlined />}
            size={"Default"}
          >
            TEXT
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => changeStatus(false)}
            icon={<EditOutlined />}
            size={"Default"}
          >
            DRAWER
          </Button>
        </Col>
        <Col>
          <Upload showUploadList={false} customRequest={handleCustome}>
            <Button
              // onClick={() => changeStatus(false)}
              icon={<UploadOutlined />}
              size={"Default"}
            >
              Upload Signature
            </Button>
          </Upload>
        </Col>
      </Row>
      <Row
        justify="center"
        style={{ padding: "3rem 0 1rem 0" }}
        gutter={[24, 24]}
      >
        {state ? (
          <TextPad evnt={evnt} saveFunc={saveFunc} />
        ) : (
          <GetSignaturePad saveFunc={saveFunc} />
        )}
      </Row> */}
    </div>
  );
}
export { ModalChild };
