import { clausesConstants } from "./action-types";
import { clausesApi } from "../utils/api";
/**
 * To post NDA actions
 * @param {string} NDA
 */

const createClausesError = (error) => {
  return {
    type: clausesConstants.CREATE_CLAUSES_FAILURE,
    createClausesError: error,
    createClausesLoading: false,
  };
};

const createClausesSuccess = (response) => {
  return {
    type: clausesConstants.CREATE_CLAUSES_SUCCESS,
    createClausesData: response.data.info,
    createClausesLoading: false,
  };
};

const createClauses = (data) => {
  return (dispatch, getState) => {
    clausesApi
      .createClausesRequest(data)
      .then((response) => {
        dispatch(createClausesSuccess(response));
        dispatch(getClausesData());
      })
      .catch((error) => {
        dispatch(createClausesError(error));
      });
  };
};

// Get Clauses
const ClausesError = (error) => {
  return {
    type: clausesConstants.GET_CLAUSES_FAILURE,
    clausesError: error,
    clausesLoading: false,
  };
};

const ClausesSuccess = (response) => {
  return {
    type: clausesConstants.GET_CLAUSES_SUCCESS,
    clausesData: response.data.info.clausesArray,
    clausesLoading: false,
  };
};

const getClausesData = () => {
  return (dispatch, getState) => {
    clausesApi
      .getClausesDataRequest()
      .then((response) => {
        dispatch(ClausesSuccess(response));
      })
      .catch((error) => {
        dispatch(ClausesError(error));
      });
  };
};

export const clausesHandle = {
  createClauses,
  getClausesData,
};
