import { helpConstants } from "./action-types";

const initialState = {
  helpTopicData: [],
  helpTopicDataLoading: false,
  helpTopicDataError: null,

  uploadImageData:[],
  uploadImageLoading:false,
  uploadImageError: null,

 sectionsData: [],
 sectionsDataLoading: false,
 sectionsDataError: null,

 rolesData: [],
 rolesDataLoading: false,
 rolesDataError: null,
};

const helpTopics = (state = initialState, action) => {
  switch (action.type) {
    case helpConstants.UPDATE_APP_TITLE:
      return {
        ...state,
        appTitle: action.appTitle,
      };
      case helpConstants.HELP_TOPIC_DATA_LOADING:
        return {
          ...state,
          helpTopicData: [],
          helpTopicDataLoading: true,
          helpTopicDataError: null,
        };
      case helpConstants.HELP_TOPIC_DATA_SUCCESS:
        return {
          ...state,
          helpTopicData: action?.helpData?.data,
          totalRecords: action?.helpData?.totalRecords,
          helpTopicDataLoading: false,
          helpTopicDataError: null,
        };
      case helpConstants.HELP_TOPIC_DATA_ERROR:
        return {
          ...state,
          helpTopicData: [],
          helpTopicDataLoading: true,
          helpTopicDataError: action.helpDataError,
        };
        case helpConstants.HELP_TOPIC_GLOBAL_SEARCH_LOADING:
            return {
              ...state,
              helpTopicData: [],
              helpTopicDataLoading: true,
              helpTopicDataError: null,
            };
          case helpConstants.HELP_TOPIC_GLOBAL_SEARCH_SUCCESS:
            return {
              ...state,
              helpTopicData: action?.helpData?.data,
              totalRecords: action?.helpData?.totalRecords,
              helpTopicDataLoading: false,
              helpTopicDataError: null,
            };
          case helpConstants.HELP_TOPIC_GLOBAL_SEARCH_ERROR:
            return {
              ...state,
              helpTopicData: [],
              helpTopicDataLoading: true,
              helpTopicDataError: action.helpDataError,
            };
            case helpConstants.UPLOAD_IMAGE_LOADING:
                return {
                  ...state,
                  uploadImageData:[],
                  uploadImageLoading:true,
                  uploadImageError: null,
                };
              case helpConstants.UPLOAD_IMAGE_SUCCESS:
                return {
                  ...state,
                  uploadImageData: action?.uploadImageData,
                  uploadImageLoading: false,
                  uploadImageError: null,
                };
              case helpConstants.UPLOAD_IMAGE_ERROR:
                return {
                  ...state,
                  uploadImageData:[],
                  uploadImageLoading:false,
                  uploadImageError: null,
                };
                case helpConstants.RESET_IMAGE_LOADING:
                return {
                  ...state,
                  uploadImageData:[],
                  uploadImageLoading:true,
                  uploadImageError: null,
                };
              case helpConstants.RESET_IMAGE_SUCCESS:
                return {
                  ...state,
                  uploadImageData: [],
                  uploadImageLoading: false,
                  uploadImageError: null,
                };
              case helpConstants.RESET_IMAGE_ERROR:
                return {
                  ...state,
                  uploadImageData:[],
                  uploadImageLoading:false,
                  uploadImageError: null,
                };
                case helpConstants.GET_ROLES_LOADING:
                    return {
                      ...state,
                      rolesData: [],
                      rolesDataLoading: true,
                      rolesDataError: null,
                    };
                  case helpConstants.GET_ROLES_SUCCESS:
                    return {
                      ...state,
                      rolesData: action?.rolesData,
                      rolesDataLoading: false,
                      rolesDataError: null,
                    };
                  case helpConstants.GET_ROLES_ERROR:
                    return {
                      ...state,
                      rolesData: [],
                      rolesDataLoading: true,
                      rolesDataError: action.helpDataError,
                    };
                    case helpConstants.GET_SECTION_LOADING:
                        return {
                          ...state,
                          sectionsData: [],
                          sectionsDataLoading: true,
                          sectionsDataError: null,
                        };
                      case helpConstants.GET_SECTION_SUCCESS:
                        return {
                          ...state,
                          sectionsData: action?.sectionsData,
                          sectionsDataLoading: false,
                          sectionsDataError: null,
                        };
                      case helpConstants.GET_SECTION_ERROR:
                        return {
                          ...state,
                          sectionsData: [],
                          sectionsDataLoading: true,
                          sectionsDataError: action.helpDataError,
                        };
    default:
      return state;
  }
};
export { helpTopics };
