import React from "react";
import { useDispatch } from "react-redux";
import { suggessionAction } from "../state/actions";
import { Input, Form, Button } from "antd";
import { Loading } from "../../Common";

const { TextArea } = Input;

function AddCategoryForSuggession({ list }) {
  const dispatch = useDispatch();
  // const [item, setItem] = useState({ suggestionsArray: list });

  // useEffect(() => {
  //   setItem({ suggestionsArray: list });
  // }, [list]);

  const onFinish = (values) => {
    let valueArray = values.suggestionsArray
      .split(",")
      .map((ele) => ele.replace(/(\r\n|\n|\r)/gm, "").trim());

    dispatch(suggessionAction.createSuggession(valueArray));
  };

  // let index = 0;

  // const [NameChange, setNameChange] = useState({
  //   name: "",
  // });

  // let onNameChange = (event) => {
  //   setNameChange({
  //     name: event.target.value,
  //   });
  // };

  // let addItem = () => {
  //   const { name } = NameChange;
  //   const { suggestionsArray } = item;

  //   setNameChange({
  //     name: "",
  //   });
  //   setItem({
  //     suggestionsArray: [...suggestionsArray, name || `New item ${index++}`],
  //   });
  // };

  return (
    <>
      {list && list.length > 0 ? (
        <Form
          name="customized_form_controls"
          layout="inline"
          onFinish={onFinish}
          initialValues={{
            suggestionsArray: list && list.join(),
          }}
        >
          <Form.Item
            name="suggestionsArray"
            label="Suggession"
            style={{ width: "400px" }}
          >
            {/* <Select
          style={{ width: 240 }}
          placeholder="custom dropdown render"
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Input
                  style={{ flex: "auto" }}
                  value={NameChange.name}
                  onChange={onNameChange}
                />
                eslint-disable-next-line jsx-a11y/anchor-is-valid 
                <a
                  style={{
                    flex: "none",
                    padding: "8px",
                    display: "block",
                    cursor: "pointer",
                  }}
                  onClick={addItem}
                >
                  <PlusOutlined /> Add item
                </a>
              </div>
            </div>
          )}
        >
          {item.suggestionsArray.map((it, index) => {
            return <Option key={index}>{it}</Option>;
          })}
        </Select> */}
            <TextArea id="detectTextArea" rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Suggession
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Loading />
      )}
    </>
  );
}
export { AddCategoryForSuggession };
