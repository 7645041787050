export const bugListingPageConstants = {
  BUG_LISTING_PAGE_SUCCESS: "BUG_LISTING_PAGE_SUCCESS",
  BUG_LISTING_PAGE_FAILURE: "BUG_LISTING_PAGE_FAILURE",
  BUG_LISTING_PAGE_LOADING: "BUG_LISTING_PAGE_LOADING",

  POST_BUG_DELETE_SUCCESS: "POST_BUG_DELETE_SUCCESS",
  POST_BUG_DELETE_FAILURE: "POST_BUG_DELETE_FAILURE",

  BUG_STATUS__SUCCESS: "BUG_STATUS__SUCCESS",
  BUG_STATUS_FAILURE: "BUG_STATUS_FAILURE",

  RESET_LISTING_PAGE: "RESET_LISTING_PAGE",
  RESET_IMAGE_UPLOAD_PAGE: "RESET_IMAGE_UPLOAD_PAGE",
  RESET_BUG: "RESET_BUG",

  LOGIN_UPDATE_PAGE: "LOGIN_UPDATE_PAGE",
  LOGOUT_PAGE: "LOGOUT_PAGE",

  DELETE_CONTRACT_SUCCESS: "DELETE_CONTRACT_SUCCESS",
  DELETE_CONTRACT_FAILURE: "DELETE_CONTRACT_FAILURE",

  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

  BUG_REPORT_COMMENT_LOADING:"BUG_REPORT_COMMENT_LOADING",
  BUG_REPORT_COMMENT_SUCCESS:"BUG_REPORT_COMMENT_SUCCESS",
  BUG_REPORT_COMMENT_FAILURE:"BUG_REPORT_COMMENT_FAILURE",

  BUG_COMMENT__SUCCESS: "BUG_COMMENT__SUCCESS",
  BUG_COMMENT_FAILURE: "BUG_COMMENT_FAILURE",

};
