export const documentConstants = {
  URL_IMPORT_SUCCESS: "URL_IMPORT_SUCCESS",
  URL_IMPORT_ERROR: "URL_IMPORT_ERROR",
  URL_IMPORT_CLEAR_SUCCESS: "URL_IMPORT_CLEAR_SUCCESS",
  URL_IMPORT_ERROR_CLEAR: "URL_IMPORT_ERROR_CLEAR",
  PDF_IMPORT_ERROR: "PDF_IMPORT_ERROR",
  SET_PDF_IMPORT_STATE:"SET_PDF_IMPORT_STATE",
  CBSR_CONTRACT_ID: "CBSR_CONTRACT_ID",

  BUILD_OFFER_SHORTHAND_URL_SUCCESS: "BUILD_OFFER_SHORTHAND_URL_SUCCESS",
  BUILD_OFFER_SHORTHAND_URL_FAILURE: "BUILD_OFFER_SHORTHAND_URL_FAILURE",

  PREIVIEW_BUILD_CONTRACT_SUCCESS: "PREIVIEW_BUILD_CONTRACT_SUCCESS",
  PREIVIEW_BUILD_CONTRACT_FAILURE: "PREIVIEW_BUILD_CONTRACT_FAILURE",


  CLEAR_FORM_PROPERTY: "CLEAR_FORM_PROPERTY",

};
