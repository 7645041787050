// data created for showing the Legends
export const LegendData = [
  {
    id: 1,
    status: "INPROGRESS",
    color: "#bab710",
     border: "2px solid #bab710",
    
    info: "Seller Agent has signed the document",
  },
  {
    id: 2,
    status: "HOLD",
    color: "#000000",
   border: "2px solid #000000",
    info: "Can be applied to only INPROGRESS offers",
  },
  {
    id: 3,
    status: "ACCEPTED",
    color: "#00DE24",
    backgroundColor:"#4FFF6B0D",
    
    border: "2px solid #00DE24",
    info: "The offer, which has signed by all the parties from the seller side",
  },
  {
    id: 4,
    status: "TERMINATED",
    color: "#FF0000",
   border: "2px solid #FF0000",
    backgroundColor:"#f6dfdf",
    info: "Only ACCEPTED offers can be TERMINATED",
  },
];

export const getAllOffersSellersInfoFun = (offerTableData=[]) => {
  const emailArr = [];
  const sellers = offerTableData
    .filter((el) => el.data?.offerStatus)
    .flatMap((el) =>
      el.sellerIds
        ? el.sellerIds.map((seller) => ({
            sellerId: seller?._id,
            sellerName: seller?.fullName,
            sellerEmail: seller?.email || "",
          }))
        : []
    );

  sellers.forEach((seller) => {
    if (!emailArr.some((el) => el.key === seller.sellerId) && seller.sellerEmail) {
      emailArr.push({
        openFor: "SELLER",
        key: seller.sellerId,
        name: seller.sellerName,
        email: seller.sellerEmail,
      });
    }
  });
  return emailArr;
}
