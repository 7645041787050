import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;

const urlImport = (data = {}, accessTokan) => {
  if (!data?.clonefromPdf) {
    return axios.post(
      `${apiURL}/urlImport`,
      {
        ...data,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  } else {
    var formData = new FormData();
    formData.append("file", data.file);
    formData.append("clonefromPdf", data.clonefromPdf);
    formData.append("createOffer", data.createOffer);
    formData.append("propertyId", data.propertyId);
    if (data.transactionData) {
      const transactionDataString = JSON.stringify(data.transactionData);
      formData.append("transactionData", transactionDataString);
    }
    const config = {
      method: "POST",
      url: `${apiURL}/offerImport`,
      headers: {
        accept: "application/json",
        "Content-Type": "multipart/form-data",
        authorization: `bearer ${accessTokan}`,
      },
      data: formData,
    };
    return axios(config);
  }
};

// preview Contract Offer Api Url
const previewContractApi = (data) => {
  // let accessTokan = localStorage.getItem("userAuth");
  return axios.get(
    `${adminURL}/createOffer/${data._id}`,
    // {
    //   ...data.buyerId
    // },
    {
      headers: {
        accept: "application/json",
        // authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// drafts api

const draftDocumentsApi = (data) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/offers?status=${data}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

export const documentDataApi = {
  previewContractApi,
  draftDocumentsApi,
  urlImport,
 
};
