import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;



const getAllPersonsApi = (query) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/getAllPersons?onlySubscription=${true}`,
  {
    params: {
      ...query,
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
const transationViewRequest = (data) => {
  let accessToken = localStorage.getItem("auth");
  return axios.post(`${apiURL}/customer-portal`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

export const subscriptionApi = {
  getAllPersonsApi,
  transationViewRequest
};
