import { message } from "antd";
import { newsConstants } from "./action-types";
import { newsDataApis } from "../utils/api";

const postNewsDataLoading = (response) => {
    return {
        type: newsConstants.POST_NEWS_LOADING,
        newsDataLoading: true,
    };
  }

  const postNewsDataSuccess = (response) => {
    return {
      type: newsConstants.POST_NEWS_SUCCESS,
      newsData: response,
      newsDataLoading: false,
    };
  }
  const postNewsDataError = (error) => {
    return {
      type: newsConstants.POST_NEWS_ERROR,
      newsDataError: error,
      newsDataLoading: false,
    };
  }
  const postNewsData = (data,fileType,heading) => {
    return (dispatch, updateState) => {
      dispatch(postNewsDataLoading());
      newsDataApis.postNewsDataApi(data,fileType,heading).then((response) =>{
        message.success(`NewsLetter ${response.data.info.message} !`);
      }).then((response) =>{dispatch(getNewsData())})
        .then((response) => {
          dispatch(postNewsDataSuccess(response?.data?.info));
        })
        .catch((error) => {
          dispatch(postNewsDataError(error));
        });
    };
  }

  //Get News Data
  const getNewsLoading = (response) => {
    return {
        type: newsConstants.GET_NEWS_LOADING,
        helpData: response,
        newsDataLoading: true,
    };
  }

  const getNewsSuccess = (response) => {
    return {
      type: newsConstants.GET_NEWS_SUCCESS,
      newsData: response,
      newsDataLoading: false,
    };
  }
  const getNewsError = (error) => {
    return {
      type: newsConstants.GET_NEWS_ERROR,
      newsDataError: error,
      newsDataLoading: false,
    };
  }
  const getNewsData = () => {
    return (dispatch, updateState) => {
      dispatch(getNewsLoading());
      newsDataApis.getNewsDataApi()
        .then((response) => {
          dispatch(getNewsSuccess(response?.data?.info));
        })
        .catch((error) => {
          dispatch(getNewsError(error));
        });
    };
  }

   //Delete Help Data
   const deleteNewsDataLoading = (response) => {
    return {
        type: newsConstants.DELETE_NEWS_LOADING,
        newsData: response,
        newsDataLoading: true,
    };
  }

  const deleteNewsDataSuccess = (response) => {
    message.success('News Letter deleted successfully!')
    return {
      type: newsConstants.DELETE_NEWS_SUCCESS,
      newsData: response,
      newsDataLoading: false,
    };
  }
  const deleteNewsDataError = (error) => {
    return {
      type: newsConstants.DELETE_NEWS_ERROR,
      newsDataError: error,
      newsDataLoading: false,
    };
  }
  const deleteNewsData = (id) => {
    return (dispatch, updateState) => {
      dispatch(deleteNewsDataLoading());
      newsDataApis.deleteNewsDataApi(id)
      .then((data) =>dispatch(getNewsData()))
        .then((response) => {
          dispatch(deleteNewsDataSuccess(response?.data?.info));
        })
        .catch((error) => {
          dispatch(deleteNewsDataError(error));
        });
    };
  }

  // Upload PDF
//   const uploadPDFLoading = () => {
//     return {
//       type: newsConstants.UPLOAD_PDF_LOADING,
//       uploadPdfLoading: false,
//     };
//   };

// const uploadPDFError = (error) => {
//   return {
//     type: newsConstants.UPLOAD_IMAGE_FAILURE,
//     uploadPdfError: error,
//     uploadPdfLoading: false,
//   };
// };

// const uploadPDFSuccess = (response) => {
//   return {
//     type: newsConstants.UPLOAD_IMAGE_SUCCESS,
//     uploadPdfData: response.data.info.docUrl
//       ? response.data.info.docUrl
//       : response.data.info.fileData.fileUrl,
//       uploadPdfLoading: false,
//   };
// };

// const uploadPDF = (file, origin) => {
//   return (dispatch, getState) => {
//     dispatch(uploadPDFLoading());
//     newsDataApis.uploadPdfRequestApi(file, origin)
//       .then((response) => {
//         dispatch(uploadPDFSuccess(response));
//       })
//       .catch((error) => {
//         dispatch(uploadPDFError(error));
//       });
//   };
// };

export const newsApis={
    postNewsData,
    getNewsData,
    deleteNewsData,
    // uploadPDF,
}
