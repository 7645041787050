
import { subcriptionMainConstants } from "./action-types";

const initialState = {
  plansData: [],
  plansDataLoading: false,
  plansDataError: false,

};

const subscriptionMainReducer = (state = initialState, action) => {

  switch (action.type) {

    // get specials plans for subscription
    case subcriptionMainConstants.GET_SPECIALPLANS_LOADING:
      return {
        ...state,
        plansData:[],
        plansDataLoading: true,
        plansDataError: false,
      };
    case subcriptionMainConstants.GET_SPECIALPLANS_SUCCESS:
      return {
        ...state,
        plansData:action?.plansData,
        plansDataLoading: false,
        plansDataError: false,

      };
    case subcriptionMainConstants.GET_SPECIALPANS_ERROR:
      return {
        ...state,
        plansData:[],
        plansDataLoading: false,
        plansDataError: true,
      };


      default:
      return state;
  }
};
export { subscriptionMainReducer };
