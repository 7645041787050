export const accountCreationConstants = {
  CREATED_PERSON_LOADING: "CREATED_PERSON_LOADING",
  CREATED_PERSON_ERROR: "CREATED_PERSON_ERROR",
  CREATED_PERSON_DATA: "CREATED_PERSON_DATA",

  GET_USER_DETAILS_LOADING: "GET_USER_DETAILS_LOADING",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",

  GET_DELEGATE_LIST_LOADING:"GET_DELEGATE_LIST_LOADING",
  GET_DELEGATE_LIST_SUCCESS:"GET_DELEGATE_LIST_SUCCESS",
  GET_DELEGATE_LIST_ERROR:"GET_DELEGATE_LIST_ERROR",
};
