import { suggestionConstants } from "./action-types";

const initialState = {
  createSuggessionData: null,
  createSuggessionLoading: true,
  createSuggessionError: null,
  getSuggesionData: [],
  getSuggesionLoading: true,
  getSuggesionError: null,
};

const suggestion = (state = initialState, action) => {
  switch (action.type) {
    case suggestionConstants.CREATE_SUGGESSION_SUCCESS:
      return {
        ...state,
        createSuggessionData: action.createSuggessionData,
        createSuggessionLoading: action.createSuggessionLoading,
      };
    case suggestionConstants.CREATE_SUGGESSION_FAILURE:
      return {
        ...state,
        createSuggessionError: action.createSuggessionError,
        createSuggessionLoading: action.createSuggessionLoading,
      };

    case suggestionConstants.GET_SUGGESION_SUCCESS:
      return {
        ...state,
        getSuggesionData: action.getSuggesionData,
        getSuggesionLoading: action.getSuggesionLoading,
      };
    case suggestionConstants.GET_SUGGESION_FAILURE:
      return {
        ...state,
        getSuggesionError: action.getSuggesionError,
        getSuggesionLoading: action.getSuggesionLoading,
      };

    // case listingEditConstants.EDIT_CATEGORY_SUCCESS:
    //   return {
    //     ...state,
    //     editCategoryData: action.editCategoryData,
    //     editCategoryLoading: action.editCategoryLoading,
    //   };
    // case listingEditConstants.EDIT_CATEGORY_FAILURE:
    //   return {
    //     ...state,
    //     editCategoryError: action.editCategoryError,
    //     editCategoryLoading: action.editCategoryLoading,
    //   };

    // case listingEditConstants.DELETE_CATEGORY_SUCCESS:
    //   return {
    //     ...state,
    //     deleteCategoryData: action.deleteCategoryData,
    //     deleteCategoryLoading: action.deleteCategoryLoading,
    //   };
    // case listingEditConstants.DELETE_CATEGORY_FAILURE:
    //   return {
    //     ...state,
    //     deleteCategoryError: action.deleteCategoryError,
    //     deleteCategoryLoading: action.deleteCategoryLoading,
    //   };
    default:
      return state;
  }
};
export { suggestion };
