import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const transactionemail = (offerId, accessToken) => { 
  return axios.get(`${apiURL}/needToKnowEmailIdLogs/${offerId}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const gettitlecompany=(accessTokan)=>{
  // const accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/titleOffice?titleType=TITLE_COMPANY&includeLimitedData=true`,{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
  },
  })
}
const getTitleoffice=(id,accessTokan)=>{
 
  return axios.get(`${apiURL}/titleOffice?titleType=TITLE_OFFICE&parentId=${id}`,{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
  },
  })
}

const searchTitleoffice=(id,val,accessTokan)=>{

  if(val.length>0)
  {
  return axios.get(`${apiURL}/titleOffice?titleType=TITLE_OFFICE&parentId=${id}&search=${val}`,{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
  },
  })
}else{
  return axios.get(`${apiURL}/titleOffice?titleType=TITLE_OFFICE&parentId=${id}`,{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
  },
  })
}
}

const addTitleoffice = (data,accessTokan, rootDocData) => {
  return axios.post(`${apiURL}/updateTitleOrderForm?offerId=${data?.offerId}&titleOfficeId=${data?.titleOfficeId}`, 
  {
offerId:data?.offerId,
imageUrl: data?.imageUrl,
titleOfficeId:data?.titleOfficeId,
salesReps:data?.salesReps,
 salesRepsId :data?.titleRepId,
 titleClosuresId:data?.titleCloserId,
titleClosures:data?.titleClosures,
selectedBy: rootDocData?.openFor
  },
   {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const editOfficeImage = (data, contractAuthKey) => {
  const formData = new FormData();
  formData.append('fileBase64', data?.file?.originFileObj);
  formData.append("folderOf", "CLIENT_DOCUMENTS_PDF");
  return axios.post(`${apiURL}/common/uploadFile`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
      authorization: `bearer ${contractAuthKey}`,
    },
  });
}

const getSharedDocsRequest = (offerId, accessToken) => {
  return axios.get(`${apiURL}/getSharedDocs?offerId=${offerId}`,{
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    }
  })
}

export const closingApi = {
  transactionemail,
  addTitleoffice,
  searchTitleoffice,
  getTitleoffice,
  gettitlecompany,
  editOfficeImage,
  getSharedDocsRequest,
};
