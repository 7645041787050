import { Select, Image, List, Input, Spin } from 'antd';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closingActions } from '../state/actions';
import { Loading } from '../../Common/components/Loading';
import { UserOutlined, HomeOutlined } from "@ant-design/icons";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import Avatar from 'antd/lib/avatar/avatar';
import { formatAddress } from '../../Common/utils/ExtraFunctions';

const TitleOffice = ({isMobile}) => {
  const { Option } = Select;
  const dispatch = useDispatch()
  const { getTitleOfficeData, getTitleOfficeLoading } = useSelector(
    (state) => state.transactionReport
  )
  const { openTitleCompanyModal, selectOffice, companyModalStep, titleOffice, titleCloser, titleRep } = useSelector((state) => state.transactionReport);
  const [ searchTerm, setSearchTerm ] = useState("")
  const { contractAuthKey } = useSelector((state) => state.createOffer);

  const handleSelectedValues = (value) => {
    dispatch(closingActions.titleCompanyModalState({
      titleOffice: value?._id,
      titleOfficeAddress: value?.address,
      titleOfficePhoneNumber: value?.phoneNumber,
      titleOfficeFaxNumber: value?.faxNumber,
      titleOfficeName: value?.name
    }));

    //   dispatch(closingActions.getTitleOffice(value))
  }

  const handleSearchChange = (val) => {
    setSearchTerm(val)
    dispatch(closingActions.searchTitleOffice(selectOffice?._id, val, contractAuthKey))

  }
  return (

    <div style={{ width: "100%", margin: "auto" }}>
      {(getTitleOfficeData.length > 5 || searchTerm.length > 0) && <Input
        id="search-input"
        value={searchTerm}
        onChange={(e) => handleSearchChange(e.target.value)}
        placeholder="Search "
      />}
      {getTitleOfficeLoading ? <Spin style={{width:"100%",textAlign:"center"}}/> :
        <div style={{ maxHeight:isMobile?"200px": "400px", overflowY: "auto" }}>
          <List
            style={{
              borderRadius: "8px",

            }}
            // className={styles.demoloadlisting}
            bordered={true}
            itemLayout="horizontal"
            dataSource={getTitleOfficeData}
            renderItem={(item) => {
              return (
                <List.Item
                  style={{
                    background: item._id === titleOffice ? "rgb(132, 187, 238)" : "",
                    borderRadius: "8px",
                    cursor: "pointer"
                  }}
                  key={item._id}
                  onClick={() =>
                    handleSelectedValues(item)}
                >
                  <List.Item.Meta

                    title={<><UserOutlined style={{ color: "black", marginRight: "10px", fontWeight: "bold", fontSize: "20px" }} /><text style={{ color: "#096dd9", }}>{item?.name}</text></>}
                    description={
                      <div style={{ display: "flex", alignItems: "center" }}><HiOutlineOfficeBuilding style={{ color: "black", marginRight: "10px", fontWeight: "bold", fontSize: "20px" }} />
                        <span style={{ color: "black", fontSize: "16px" }}>
                          {formatAddress(item?.address)}
                        </span>
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </div>
      }
    </div>
  )
}

export { TitleOffice }