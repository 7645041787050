import React from "react";
import { Result, Button } from "antd";
function DeleteComponent() {
  window.history.pushState(null, document.title, window.location.href);
  window.addEventListener("popstate", function (event) {
    window.history.pushState(null, document.title, window.location.href);
  });

  let redirectHome = async () => {
    await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
  };
  return (
    <div>
      {" "}
      <Result
        title="Your operation has been executed"
        subTitle="Offer deleted."
        extra={[
          <Button type="primary" onClick={redirectHome} key="console">
            Home
          </Button>,
        ]}
      />
    </div>
  );
}

export { DeleteComponent };
