import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const signupRealtorRequest = (data) => {
  // console.log("registering from admin side account API");
  let accessToken = localStorage.getItem("auth");
  return axios.post(
    `${apiURL}/person/registerUserByAdmin`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${accessToken}`,
  
      }
    }
  );
};

const getAllPersonsApi = (query) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/getAllPersons?onlyProfileLinked=${true}`,
  {
    params: {
      ...query,
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Get persons profile details
const getUserDetailsRequest = ({token}) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/realtorDetailedData`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${token||accessTokan}`,
    },
  });
};

// Get delegate list
const getDelegateListRequest = (Id) => {
    let accessTokan = localStorage.getItem("auth");
    return axios.get(`${apiURL}/getDelegateList?personId=${Id}&isFromAdmin=${true}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

export const accountApi = {
  signupRealtorRequest,
  getAllPersonsApi,
  getUserDetailsRequest,
  getDelegateListRequest,
};
