import { clausesConstants } from "./action-types";

const initialState = {
  createClausesData: null,
  createClausesLoading: true,
  createClausesError: null,

  clausesData: null,
  clausesLoading: true,
  clausesError: null,
};

const clauses = (state = initialState, action) => {
  switch (action.type) {
    // POST NDA data from the webview LINK
    case clausesConstants.CREATE_CLAUSES_SUCCESS:
      return {
        ...state,
        createClausesData: action.createClausesData,
        createClausesLoading: action.createClausesLoading,
      };
    case clausesConstants.CREATE_CLAUSES_FAILURE:
      return {
        ...state,
        createClausesError: action.createClausesError,
        createClausesLoading: action.createClausesLoading,
      };
    // Get NDA Data
    case clausesConstants.GET_CLAUSES_SUCCESS:
      return {
        ...state,
        clausesData: action.clausesData,
        clausesLoading: action.clausesLoading,
      };
    case clausesConstants.GET_CLAUSES_FAILURE:
      return {
        ...state,
        clausesData: [],
        clausesLoading: action.clausesLoading,
        clausesError: action.clausesError,
      };
    default:
      return state;
  }
};
export { clauses };
