import React from "react";
import { Timeline, Modal, Typography, Spin, message } from "antd";
import { CloseSquareOutlined, SmileOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { contractStoreHandle } from "../../ContractStore/state/actions";

const FormValidationModal = ({ modalState, setModalState }) => {
  const { validatesuccess, validateLoading, validateError } = useSelector((state) => state.contractStore);
  const dispatch = useDispatch();

  const validationModalCancel = () => {
    setModalState((prevState) => ({ ...prevState, validateModal: false }));
    dispatch(contractStoreHandle.reSetvalidateForm());
  };

  let handleError = (ele) => {
    setModalState((prevState) => ({ ...prevState, validateModal: false }));
    let extractElement = document.getElementById(ele.id);
    if (extractElement) {
      extractElement.style.background = "#FFFF00";
      extractElement.scrollIntoView({ block: "center", inline: "nearest" });
      setTimeout(() => {
        extractElement.style.background = "rgba(210, 230, 250, 0.29)";
      }, 3000);
    }
  };

  return (
    <>
      <Modal
        style={{
          borderRadius: "7px",
          backgroundColor: "#ffffff",
          border: "1px solid black",
          padding: 0,
        }}
        width="550px"
        visible={modalState?.validateModal}
        okText={"Submit"}
        onCancel={validationModalCancel}
        footer={false}
        bodyStyle={{padding: 10}}
      >
        <div style={{ height: "70vh", overflowY: "auto", margin: "40px 10px 0 10px", padding: 5 }}>
          {validateError ? (
            message.error(validateError?.response?.data?.message || "We encountered an issue while validating the fields in this document. Please try again after some time.") && validationModalCancel()
          ) :
          validateLoading ? (
            <div style={{display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "80%"}}><Spin size="large"/> </div>
          ) : validatesuccess?.length <= 0 ? (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: "center", height: "80%"}}>
              <Typography.Text style={{fontSize: '22px', textAlign: "center"}}>This document has passed the basic validations.</Typography.Text>
            </div>
          ) : (
            <Timeline pending={validatesuccess?.length > 0 ? false : "Validation..."}>
              {validatesuccess &&
                validatesuccess.map((ele, index) => {
                  return (
                    <Timeline.Item key={index} dot={<CloseSquareOutlined className="timeline-clock-icon" />} tempname={ele.id} color={"red"}>
                      <div style={{ cursor: "pointer" }} onClick={() => handleError(ele)}>
                        {ele.message}
                      </div>
                    </Timeline.Item>
                  );
                })}
            </Timeline>
          )}
        </div>
      </Modal>
    </>
  );
};
export default FormValidationModal;
