import React from "react";
import { Spin } from "antd";
import { SomeThingWentWrong } from "./SomeThingWentWrong";
import styles from "../style/Loading.module.css";
import { useState } from "react";

function Loading() {
  // const [changeStatus, setChangeStatus] = useState(true);

  // setTimeout(() => {
  //   setChangeStatus(false);
  // }, 20000);
  return (
    <div className={styles.spin}>
       <Spin size="large" />
    </div>
  );
}
export { Loading };
