import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Row, Col, Layout } from "antd";
// import { Loading } from "../Common/components/Loading";
import { suggessionAction } from "./state/actions";
import { EditableTableForSuggession } from "./components/EditableTableForSuggession";
import { AddCategoryForSuggession } from "./components/AddCategoryForSuggession";
import { Redirect } from "react-router-dom";
import { roleAccessFunc } from "../Common/commondata/commonData";
const { Content } = Layout;

function Suggestion(props) {
  // useEffect(() => {
  //   if (props.networkConnection === false) {
  //     window.location.reload();
  //   }
  // }, [props]);
  const dispatch = useDispatch();
  const { getSuggesionData } = useSelector((state) => state.suggestion);
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  useEffect(() => {
    if(roleAccessFunc(roleAccessed, "SUGGESTION")){
      dispatch(suggessionAction.getSuggesionList());
    }
  }, [dispatch]);

  if (!roleAccessFunc(roleAccessed, "SUGGESTION")) {
    return <Redirect to="/error" />;
  }

  return (
    <Layout style={{ height: "100%", padding: "0px" }}>
      <Content
        style={{
          overflow: "auto",
          background: "rgb(202 202 202)",
          padding: "1rem",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <AddCategoryForSuggession list={getSuggesionData} />
          </Col>
          <Col span={24}>
            <EditableTableForSuggession list={getSuggesionData} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export { Suggestion };
