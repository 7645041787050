import { message } from "antd";
import { contactSheetDataApi } from "../utils/api";

const { contactSheetActionTypes } = require("./action-types");


const getContactSheetLoading = () => {
    return {
        type: contactSheetActionTypes.GET_CONTACT_SHEET_LOADING,
        contactSheetDataLoading: true,
        contactSheetDataError: false,
    }
}

const getContactSheetDataSuccess = (response) => {
    return {
        type: contactSheetActionTypes.GET_CONTACT_SHEET_SUCCESS,
        contactSheetDataLoading: false,
        contactSheetDataError: false,
        contactSheetData: response?.data?.info
    }
}

const getContactSheetDataError = (error) => {
    return {
        type: contactSheetActionTypes.GET_CONTACT_SHEET_ERROR,
        contactSheetDataLoading: false,
        contactSheetDataError: error,
    }
}

const getContactSheetData = ({ offerId, accessToken, openFor }) => {
    return (dispatch, getState) => {
        dispatch(getContactSheetLoading());
        contactSheetDataApi.getContactSheetRequest({ offerId, accessToken, openFor })
            .then((response) => {
                dispatch(getContactSheetDataSuccess(response));
            })
            .catch((error) => {
                dispatch(getContactSheetDataError(error?.response?.data?.message));
            })
    }
}

const addOrUpdateContact = ({ offerId, queryData, payload, accessToken, openFor }) => {
    return (dispatch, getState) => {
        contactSheetDataApi.addOrUpdateContactRequest({ offerId, queryData, payload, accessToken })
            .then((response) => {
                dispatch(getContactSheetData({ offerId, accessToken, openFor }));
                message.success(response?.data?.info?.message);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message || "Couldn't update contact")
            })
    }
}

const sendContactSheetLink = ({ paramData, payload, accessToken }) => {
    return (dispatch, getState) => {
        contactSheetDataApi.sendContactSheetLinkRequest({ paramData, payload, accessToken })
            .then((response) => {
                message.success(response?.data?.info?.message);
            })
            .catch((error) => {
                message.error(error?.response?.data?.message || "Contact Sheet sending failed");
            })
    }
}


export const contactSheetActions = {
    getContactSheetData,
    addOrUpdateContact,
    sendContactSheetLink,
}