function updateElements(type, selector, effect) {
    const elements = document.querySelectorAll(selector);

    elements?.forEach((el) => {
        switch (type) {
            case "checkbox":
                if (el.type === "checkbox") {
                    el.checked = effect;
                }
                break;
            case "input":
                if (el.tagName === "INPUT" && el.type !== "checkbox") {
                    el.value = effect||"";
                }
                break;
            case "textarea":
                if (el.tagName === "TEXTAREA") {
                    el.value = effect||"";
                }
                break;
          

            default:
                console.error("Unsupported element type");
                break;
        }
    });
}

const adjustDate = (dateString = new Date().toISOString().split('T')[0], days = 0) => {
    if (dateString && isNaN(Date.parse(dateString))) {
      return null; 
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return null; 
    }
    if (isNaN(days)) {
      return null; 
    }
    date.setDate(date.getDate() + days);
    if (isNaN(date.getTime())) {
      return null; 
    }
    return date.toISOString().split('T')[0];
  };
  
  const getClientIds = (clients = []) => {
    let clientIds = [];
    clients.forEach(element => {
      if (element.isCorp) {
        clientIds.push(...(element.signers || []).map(ele => ele.personId).filter(Boolean));
      } else if (element.personId) {
        clientIds.push(element.personId);
      }
    });
    return clientIds;
  };
  
export const smallHelpersFun = {
    updateElements,
    adjustDate,
    getClientIds
};
