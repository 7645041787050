import { bugListingPageConstants } from "./action-types";

const initialState = {
  loggedIn: false,

  bugLlistingData: null,
  bugLlistLoading: true,
  bugLlistingError: null,
  totalRecords:0,

  uploadImageData: null,
  uploadImageLoading: false,
  uploadImageError: null,

  deleteContractData: null,
  deleteContractLoading: false,
  deleteContractError: null,

  bugStatusData: null,
  bugStatusLoading: true,
  bugStatusError: null,

  bugPostDeleteData: null,
  bugPostDeleteLoading: true,
  bugPostDeleteError: null,

  bugReportCommentData: null,
  bugReportCommentLoading: true,
  bugReportCommentError: null,
};

const bugReport = (state = initialState, action) => {
  switch (action.type) {
    //JIRA delete functionality
    case bugListingPageConstants.POST_BUG_DELETE_SUCCESS: {
      return {
        ...state,
        bugPostDeleteData: action.bugPostDeleteData,
        bugPostDeleteLoading: action.bugPostDeleteLoading,
      };
    }
    case bugListingPageConstants.POST_BUG_DELETE_FAILURE:
      return {
        ...state,
        bugPostDeleteData: null,
        bugPostDeleteLoading: action.bugPostDeleteLoading,
        bugPostDeleteError: action.bugPostDeleteError,
      };

    // with access token
    case bugListingPageConstants.BUGS_LOGIN_UPDATE_PAGE: {
      localStorage.setItem("userAuth", action.logInData.accessToken);
      localStorage.setItem("username", action.logInData.firstName);
      return {
        ...state,
        logInData: action.logInData,
        logInLoading: action.logInLoading,
      };
    }
    case bugListingPageConstants.BUGS_LOGIN_UPDATE_PAGE_FAIL:
      return {
        ...state,
        logInData: null,
        logInLoading: action.logInLoading,
        logInError: action.logInError,
      };

    // CHANGE STATUS
    case bugListingPageConstants.BUG_STATUS__SUCCESS:
      return {
        ...state,
        bugStatusData: action.bugStatusData,
        bugStatusLoading: action.bugStatusLoading,
        bugStatusError: null,
      };
    case bugListingPageConstants.BUG_STATUS_FAILURE:
      return {
        ...state,
        bugStatusData: [],
        bugStatusLoading: action.bugStatusLoading,
        bugStatusError: action.bugStatusError,
      };

    // with access token
    case bugListingPageConstants.BUG_LISTING_PAGE_LOADING:
      return {
        ...state,
       bugLlistLoading:true,
       bugLlistingData:null,
      };
    case bugListingPageConstants.BUG_LISTING_PAGE_SUCCESS:
      const dataInfo = [];
    //   action.bugLlistingData.forEach((element) => {
    //   dataInfo.push({
    //     nameEmailSearch: {
    //       fullName: element.createdBy.fullName,
    //       email: element.createdBy.email,
    //     },
    //     idTitleSearch:{
    //       id:element._id,
    //       title:element.title
    //     },
    //     contentSearch:{content:element.content,id:element._id},
    //     createdAt:element.createdAt,
    //     imageUrls:element.imageUrls,
    //     status:element.status,
    //     delete:element.canDelete
    //   });
    // });
    action.bugLlistingData.forEach((element) => {
        const dataInfoItem = {
          nameEmailSearch: {
            fullName: element.createdBy && element.createdBy.fullName,
            email: element.createdBy && element.createdBy.email,
          },
          idTitleSearch: {
            id: element._id,
            title: element.title,
          },
          contentSearch: {
            content: element.content,
            id: element._id,
          },
          createdAt: element.createdAt,
          imageUrls: element.imageUrls,
          status: element.status,
          delete: element.canDelete,
        };
        dataInfo.push(dataInfoItem);
    });
      return {
        ...state,
        bugLlistingData: dataInfo,
        totalRecords:action.totalRecords,
        bugLlistLoading: false,
        bugLlistingError: null,
      };
    case bugListingPageConstants.BUG_LISTING_PAGE_FAILURE:
      return {
        ...state,
        bugLlistingData:null,
       bugLlistLoading: false,
        bugLlistingError: true,
      };

    case bugListingPageConstants.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImageData: action.uploadImageData,
        uploadImageLoading: action.uploadImageLoading,
        uploadImageError: null,
      };
    case bugListingPageConstants.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        uploadImageData: [],
        uploadImageLoading: action.uploadImageLoading,
        uploadImageError: action.uploadImageError,
      };

    case bugListingPageConstants.LOGIN_UPDATE_PAGE:
      return {
        ...state,
        loggedIn: true,
      };

    case bugListingPageConstants.RESET_LISTING_PAGE:
      return {
        ...initialState,
      };
    case bugListingPageConstants.RESET_IMAGE_UPLOAD_PAGE: {
      return {
        ...state,
        //       uploadImageData: null,
        // uploadImageLoading: false,
        // uploadImageError: null,
      };
    }
    case bugListingPageConstants.RESET_BUG: {
      return {
        ...state,
        bugPostData: null,
      };
    }
    case bugListingPageConstants.BUG_REPORT_COMMENT_LOADING: {
      return {
        ...state,
      bugReportCommentLoading:action.bugReportCommentLoading,
      };
    }
    case bugListingPageConstants.BUG_REPORT_COMMENT_SUCCESS: {
      return {
        ...state,
        bugReportCommentData:action.bugReportCommentData,
        bugReportCommentLoading:action.bugReportCommentLoading,
        bugReportCommentError:action.bugReportCommentError
      };
    }
    case bugListingPageConstants.BUG_REPORT_COMMENT_FAILURE: {
      return {
        ...state,
        bugReportCommentData:[],
        bugReportCommentLoading:action.bugReportCommentLoading,
        bugReportCommentError:action.bugReportCommentError
      };
    }

    default:
      return state;
  }
};
export { bugReport };
