import { Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TitleUpdated } from './TitleUpdated';
import { TitleCreated } from './TitleCreated';

const TitleCompanyVerfication = () => {
  const [toggle, setToggle] = useState('create');
  const dispatch = useDispatch();
 
 const handleToggle = (value) => {
    setToggle(value);
  };

  return (
    <div style={{ padding: "15px" }}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "38%",
        marginBottom: "15px",
      }}
    >
      <Select style={{ width: 120 }} value={toggle} onChange={(value)=>handleToggle(value)}>
        <Option value="create">Created</Option>
        <Option value="update">Updated</Option>
      </Select>
      <div style={{ fontSize: "20px", fontWeight: 600 }}>
        {(toggle == "create") ? "Verify Created Title Company" : "Verify Updated Title Office"}
      </div>
    </div>
    {toggle == "update" ? 
      <TitleUpdated />: <TitleCreated />}
      </div>
)
  
}

export {TitleCompanyVerfication}