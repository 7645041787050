import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const AvatarGroup = ({ allAgents, source, handleShowAgentDetail }) => (
  <Avatar.Group
    maxCount={2}
    maxPopoverTrigger="click"
    size="large"
    maxStyle={{
      color: '#f56a00',
      backgroundColor: '#fde3cf',
      cursor: 'pointer',
    }}
  >
    {allAgents?.map((agent) => (
      <Tooltip title={agent.fullName} placement="top" key={agent._id}>
        <Avatar
          src={agent.profileImg}
          onClick={() => handleShowAgentDetail(allAgents, source)}
          style={{ cursor: 'pointer', margin: 'auto', background: '#085191' }}
          size={64}
        >
          {!agent.profileImg && agent.fullName ? (
            agent.fullName
              .split(' ')
              .map((namePart) => namePart.charAt(0).toUpperCase())
              .join('')
              .slice(0, 2)
          ) : (
            <UserOutlined style={{ fontSize: '34px' }} />
          )}
        </Avatar>
      </Tooltip>
    ))}
  </Avatar.Group>
);

export default AvatarGroup;
