import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// Get all Special plans for subscription.
const getSpecialPlansRequest = (source) => {
  let accessTokan = localStorage.getItem("auth");
    return axios.get(
    `${apiURL}/subscriptionTempelates?isSpecialPlan=${true}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );

};

const editSpecialPlansRequest = (id, data, addCode,codeId) => {
    let accessTokan = localStorage.getItem("auth");
    let payload = {
        ...(data?.startDate ? { startDate: Date.parse(data.startDate) / 1000 } : {}),
        ...(data?.endDate ? { endDate: Date.parse(data.endDate) / 1000 } : {}),
        ...(data?.code ? { code: data.code } : {}),
        ...(codeId ? { codeDetailId: codeId } : {}),
        addCode: addCode,
    };

    return axios.put(
        `${apiURL}/addOrUpdateSpecialCodesIntoPlans/${id}`, payload,
        {
            headers: {
                "Content-Type": "application/json",
                accept: "application/json",
                authorization: `bearer ${accessTokan}`,
            },
        }
    );
};



export const subscriptionMainApi = {
    getSpecialPlansRequest,
    editSpecialPlansRequest,
  };
