import { subscriptionApi } from "../utils/api";
import { subscriptionConstants } from "./actionTypes";


const getAllPersonsLoading = () => {
  return {
    type: subscriptionConstants.GET_USERS_LOADING,
  };
};

const getAllPersonsError = () => {
  return {
    type: subscriptionConstants.GET_USERS_FAILURE,
  };
};

const getAllPersonsSuccess = (response) => {
  return {
    type: subscriptionConstants.GET_USERS_SUCCESS,
    payload: response.data.info.data,
    totalRecords: response.data.info.totalRecords,
  };
};

const getAllPersonsforSubscription = (query) => {
  return (dispatch, getState) => {
    dispatch(getAllPersonsLoading())
    subscriptionApi.getAllPersonsApi(query).then((response) => {
        dispatch(getAllPersonsSuccess(response));
      })
      .catch((error) => {
        dispatch(getAllPersonsError());
      });
  };
};
const viewTransHistorySuccess = (response) => {
  return {
    type: subscriptionConstants.VIEW_CUSTOMER_PORTAL_SUCCESS,
    payload: response,
  };
};

const viewTransHistoryError = (error) => {
  return {
    type: subscriptionConstants.VIEW_CUSTOMER_PORTAL_FAILURE,
  };
};

const viewTransHistoryFun = (data) => {
  return (dispatch, getState) => {
    dispatch(getAllPersonsLoading())
    subscriptionApi
      .transationViewRequest(data)
      .then((response) => {
        window.location.href = response?.data?.info.sessionUrl;
        dispatch(viewTransHistorySuccess(response?.data?.info));
      })
      .catch((error) => {
        dispatch(viewTransHistoryError(error));
      });
  };
};

export const subscriptionAction = {
  getAllPersonsforSubscription,
  viewTransHistoryFun
};
