import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { app } from "./App";
import { listingPage } from "./ListingPage";
import { pdfUpload } from "./PdfUpload";
import { contractStore } from "./ContractStore/state/reducer";
import { authenticate } from "./Authenticate";
import { dashboard } from "./Dashboard";
import { listingEdit } from "./ListingEdit";
import { cms } from "./CMS";
import { verification } from "./Verification";
import { offerTable } from "./OfferTable";
import { suggestion } from "./Suggestion";
import { nda } from "./NDA";
import { clauses } from "./Clauses";
import { bugReport } from "./BugReport";
import { createOffer } from "./CreateOffer";
import { calendarData } from "./CalenderComponent";
import { clientAuth } from "./ClientAuth";
import { officeType } from "./OfficeType";
import { firmDetails } from "./FirmCreation";
import {titleCompanyDetails} from "./TitleCompany"
import { account } from "./Accounts";
import { transactionReport } from "./TransactionReport";
import { subscription } from "./SubscriptionVerication";
import { helpTopics } from "./Help";
import {news} from "./Newsletter"
import { rtdTransactionLane } from "./Rtd/state/reducer";
import {fjggReducer} from "./FJGG/state/reducer";
import { getLogsData } from "./Logs";
import { subscriptionMainReducer } from "./SubscriptionMain/state/reducer"
import { documentSection } from "./DocumentSection";
import { contactSheetReducer } from "./ContactSheet";
import { commonReducer } from "./Common";

const reducers = {
  app,
  calendarData,
  transactionReport,
  createOffer,
  documentSection,
  helpTopics,
  news,
  listingPage,
  pdfUpload,
  contractStore,
  authenticate,
  dashboard,
  listingEdit,
  cms,
  verification,
  offerTable,
  suggestion,
  nda,
  clauses,
  bugReport,
  clientAuth,
  officeType,
  titleCompanyDetails,
  firmDetails,
  account,
  subscription,
  rtdTransactionLane,
  fjggReducer,
  getLogsData,
  subscriptionMainReducer,
  contactSheetReducer,
  commonReducer,
};

const rootReducers = combineReducers(reducers);

const store = createStore(rootReducers, applyMiddleware(thunkMiddleware));

export { store, reducers };
