import { verificationConstants } from "./action-types";

const initialState = {
  verificationError: null,
  verificationData: null,
  statusCode: null,
  loading: true,

    userDataError: false,
    userDataLoading: false,
    userData: {},
};

const verification = (state = initialState, action) => {
  switch (action.type) {
    case verificationConstants.VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationData: action.verificationData,
        statusCode: action.statusCode,
        loading: action.loading,
      };
    case verificationConstants.VERIFICATION_FAILURE:
      return {
        ...state,
        verificationError: action.verificationError,
        statusCode: action.statusCode,
        loading: action.loading,
      };

    case verificationConstants.RESET_VERIFICATION:
      return {
        ...initialState,
      };
    case verificationConstants.GET_USER_DATA_ERROR:
      return {
        ...state,
        userDataError: true,
        userDataLoading: false,
        userData: {}
      }
    case verificationConstants.GET_USER_DATA_LOADING:
      return {
        userDataError: false,
        userDataLoading: true,
        userData: {}
      }
    case verificationConstants.GET_USER_DATA_SUCCESS:
      return {
        userDataError: false,
        userDataLoading: false,
        userData: action.userData,
      }
    default:
      return state;
  }
};
export { verification };
