export const cmsConstants = {
  GET_PERSONS_LOADING: "GET_PERSONS_LOADING",
  GET_PERSONS_FAILURE: "GET_PERSONS_FAILURE",
  GET_PERSONS_SUCCESS: "GET_PERSONS_SUCCESS",

  USER_CHANGE_STATUS_FAILURE: "USER_CHANGE_STATUS_FAILURE",
  USER_CHANGE_STATUS_SUCCESS: "USER_CHANGE_STATUS_SUCCESS",

  USER_EMAIL_VERIFY_FAILURE: "USER_EMAIL_VERIFY_FAILURE",
  USER_EMAIL_VERIFY_SUCCESS: "USER_EMAIL_VERIFY_SUCCESS",

  GET_FILTER_ROLE_LOADING : "GET_FILTER_ROLE_LOADING",
  GET_FILTER_ROLE_SUCCESS: "GET_FILTER_ROLE_SUCCESS",
  GET_FILTER_ROLE_ERROR: "GET_FILTER_ROLE_ERROR",

  GET_FILTER_ADMINROLE_LOADING : "GET_FILTER_ADMINROLE_LOADING",
  GET_FILTER_ADMINROLE_SUCCESS: "GET_FILTER_ADMINROLE_SUCCESS",
  GET_FILTER_ADMINROLE_ERROR: "GET_FILTER_ADMINROLE_ERROR",

  GET_FILTER_ISACTIVE_LOADING : "GET_FILTER_ISACTIVE_LOADING",
  GET_FILTER_ISACTIVE_SUCCESS: "GET_FILTER_ISACTIVE_SUCCESS",
  GET_FILTER_ISACTIVE_ERROR: "GET_FILTER_ISACTIVE_ERROR",

  GET_SORT_CREATEDAT_LOADING : "GET_SORT_CREATEDAT_LOADING",
  GET_SORT_CREATEDAT_SUCCESS: "GET_SORT_CREATEDAT_SUCCESS",
  GET_SORT_CREATEDAT_ERROR: "GET_SORT_CREATEDAT_ERROR",

  HANDLE_SUBCRIPTION_LOADING: "HANDLE_SUBCRIPTION_LOADING",
  HANDLE_SUBSCRIPTION_SUCCESS: "HANDLE_SUBSCRIPTION_SUCCESS",
  HANDLE_SUBSCRIPTION_ERROR: "HANDLE_SUBSCRIPTION_ERROR",

  DELETE_SUBCRIPTION_LOADING: "DELETE_SUBCRIPTION_LOADING",
  DELETE_SUBSCRIPTION_SUCCESS: "DELETE_SUBSCRIPTION_SUCCESS",
  DELETE_SUBSCRIPTION_ERROR: "DELETE_SUBSCRIPTION_ERROR",

  EDIT_SUBCRIPTION_LOADING: "EDIT_SUBCRIPTION_LOADING",
  EDIT_SUBSCRIPTION_SUCCESS: "EDIT_SUBSCRIPTION_SUCCESS",
  EDIT_SUBSCRIPTION_ERROR: "EDIT_SUBSCRIPTION_ERROR",

  ASSOCIATION_LOADING : "ASSOCIATION_LOADING",
  ASSOCIATION_ERROR : "ASSOCIATION_ERROR",
  ASSOCIATION_SUCCESS : "ASSOCIATION_SUCCESS",

  ADD_ASSOCIATION_LOADING : "ADD_ASSOCIATION_LOADING",
  ADD_ASSOCIATION_ERROR : "ADD_ASSOCIATION_ERROR",
  ADD_ASSOCIATION_SUCCESS : "ADD_ASSOCIATION_SUCCESS",

  VIEW_ASSOCIATION_LOADING: "VIEW_ASSOCIATION_LOADING",
  VIEW_ASSOCIATION_ERROR: "VIEW_ASSOCIATION_ERROR",
  VIEW_ASSOCIATION_SUCCESS: "VIEW_ASSOCIATION_SUCCESS",

  TOGGLE_LOADING: "TOGGLE_LOADING",
};
