import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;
// Creating a Franchise or  Id
const createFranchiseRequest = (payload) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.post(`${apiURL}/office`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
// edit a franchise or ID
const editFranchiseRequest = (payload,officeid) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.put(`${apiURL}/office/${officeid}?updateFirm=${true}`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const editBrokerageRequest = (payload) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.put(`${apiURL}/brokerageInfo`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};


// Get all Franchise created by a person
const getFranchiseRequest = (data) => {
  let accessTokan = localStorage.getItem("auth");
  if (data && data.officeTypes) {
    return axios.get(`${apiURL}/office`,
    {
      params: {...data},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  } else {
    return axios.get(`${apiURL}/office `, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }
};
const postImageRequest = (data) => {
  let accessTokan = localStorage.getItem("auth");
  var params = new FormData();
  params.append("fileBase64", data);
  params.append("folderOf", "OFFICE_LOGO");

  return axios.post(`${apiURL}/common/uploadFile`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Get all seacrched admins
const getAdminSearchRequest = (data) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/allAdmins/${data.tag}?fullName=${data.fullName} `, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const sendMailForClausesRequest = (Id) => {
    let accessTokan = localStorage.getItem("auth");
    return axios.post(`${apiURL}/sendRequestForDocsAndClauses`, {userId:Id}, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }

  const getFJGGAccessRequest = (query) => {
    let accessTokan = localStorage.getItem("auth");
    let url = `${apiURL}/FJGGAccess?source=ADMIN`;

    if (query) {
        url += `&id=${query}`;
    }

    return axios.get(url, {
        headers: {
            accept: "application/json",
            authorization: `bearer ${accessTokan}`,
        }

    })
    }
    
const getCompanyRequest = (query) => {
  let accessTokan = localStorage.getItem("auth");
    return axios.get(`${apiURL}/titleOffice`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
      params: query
    });
};

const getAllOfficeRequest = (query) => {
  let accessTokan = localStorage.getItem("auth");
    return axios.get(`${apiURL}/titleOffice`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
      params: query
    });
};

export const firmDetailsApi = {
  getFranchiseRequest,
  createFranchiseRequest,
  postImageRequest,
  editFranchiseRequest,
  getAdminSearchRequest,
  sendMailForClausesRequest,
  getFJGGAccessRequest,
  getCompanyRequest,
  getAllOfficeRequest,
  editBrokerageRequest
};
