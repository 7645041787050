import { pdfUploadConstants } from "./action-types";

const initialState = {
  id: null,
  uploadData: null,
  uploadError: null,
  loading: null,

  sendPreviewError: null,
  sendPreviewData: null,
  previewLoading: false,

  documentryListError: null,
  documentryListData: null,
  documentryListLoading: true,
};

const pdfUpload = (state = initialState, action) => {
  switch (action.type) {
    // get documentry request
    case pdfUploadConstants.DOCUMENTRY_LIST_SUCCESS:
      return {
        ...state,
        documentryListData: action.documentryListData,
        documentryListLoading: action.documentryListLoading,
      };
    case pdfUploadConstants.DOCUMENTRY_LIST_FAILURE:
      return {
        ...state,
        documentryListError: action.documentryListError,
        documentryListLoading: action.documentryListLoading,
      };

    case pdfUploadConstants.UPLOAD_PDF_SUCCESS:
      return {
        ...state,
        uploadData: action.uploadData,
        id: action.id,
        loading: action.loading,
      };
    case pdfUploadConstants.UPLOAD_PDF_FAILURE:
      return {
        ...state,
        uploadError: action.uploadError,
        loading: action.loading,
      };
    case pdfUploadConstants.SEND_PREVIEW_SUCCESS:
      return {
        ...state,
        sendPreviewData: action.sendPreviewData,
        previewLoading: action.previewLoading,
      };
    case pdfUploadConstants.SEND_PREVIEW_FAILURE:
      return {
        ...state,
        sendPreviewError: action.sendPreviewError,
        previewLoading: action.previewLoading,
      };

    case pdfUploadConstants.RESET_PDF_UPLOAD:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export { pdfUpload };
