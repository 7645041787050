import axios from "axios";
import dotEnv from "dotenv";
import moment from "moment";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const personDataRequest = (query) => {
    let accessTokan = localStorage.getItem("auth");

    // Initialize URLSearchParams
    const params = new URLSearchParams();

    // Check if query and query.role exist and add roles to the params
    if (query?.role) {
      query.role.forEach((value) => {
        params.append('role', value);
      });
    }

    if (query?.adminRole) {
        query.adminRole.forEach((value) => {
          params.append('adminRole', value);
        });
      }

    // Add other query parameters dynamically
    if (query) {
      Object.keys(query).forEach(key => {
        if (key !== 'role'&& key !== "adminRole") { // Roles are already added separately
          params.append(key, query[key]);
        }
      });
    }



    // Set default page and limit if not present in query
    if (!query?.page) {
      params.append('page', 1);
    }
    if (!query?.limit) {
      params.append('limit', 30);
    }

    return axios.get(`${apiURL}/person`, {
      params,
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

const changeStatusRequest = (id, status) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.put(
    `${apiURL}/person/${id}`,
    {
      isActive: !status ? true : false,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const sendOrVerifyEmailRequest = ({id, payload}) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.put(
    `${apiURL}/person/${id}`,
    payload,
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const handleSubcriptionRequest = (data, personId) => {
    let accessToken = localStorage.getItem("auth");

    // Convert data to URL-encoded format
    const urlEncodedData = new URLSearchParams();

    if (data.activePlan) {
        urlEncodedData.append('activePlan', data.activePlan);
    }
    if (data.startDate) {
        urlEncodedData.append('startDate', moment(data.startDate).valueOf().toString());
    }
    if (data.endDate) {
        urlEncodedData.append('endDate', moment(data.endDate).valueOf().toString());
    }
    if (data.manualNotes) {
        urlEncodedData.append('notes', data.manualNotes);
    }
    if (data.removePlan) {
        urlEncodedData.append('removePlan', data.removePlan);
    }
    urlEncodedData.append('personId', personId);

    return axios.post(
        `${apiURL}/plans`,
        urlEncodedData.toString(),
        {
            headers: {
                accept: "application/json",
                authorization: `bearer ${accessToken}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }
    );
};



const handleToggleRequest=(data)=>{
  let accessTokan = localStorage.getItem("auth");
  return axios.put(
    `${apiURL}/updateAssociation`,
    {
     ...data
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const loginToDifferentAccount = (payload) => {
  let accessTokan = localStorage.getItem("auth");

  return axios.post(`${apiURL}/loginDiffAccount`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getAssociationData = (data) => {
  return axios.get(`${apiURL}/getAssociationNoAuth`, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });
};


const addAssociationRequest = (data, planCode) => {
  let accessTokan = localStorage.getItem("auth");
  let payload = {
    ...data,
    planCode: planCode,
  };
  return axios.post(
    `${apiURL}/addLinkParameter`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};


const viewSignupLinkDataRequest = () => {
  const accessToken = localStorage.getItem("auth");
  return axios.get(`${apiURL}/getAssociationLink`, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const deleteSubcriptionRequest =(data)=>{
    const accessToken = localStorage.getItem("auth");
    return axios.delete(`${apiURL}/plans/${data?.planId}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      data: new URLSearchParams({ notes: data?.manualNotes })
    })
}

const updateSubscriptionRequest = (data,planId) => {

    const accessToken = localStorage.getItem("auth");
    let payload = {...data};
    payload.notes = payload?.manualNotes;
    delete payload.manualNotes;

    return axios.put(`${apiURL}/plans/${planId}`, new URLSearchParams(payload), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      }
    })
  };

export const personDataApi = {
  personDataRequest,
  changeStatusRequest,
  sendOrVerifyEmailRequest,
  handleSubcriptionRequest,
  getAssociationData,
  addAssociationRequest,
  viewSignupLinkDataRequest,
  handleToggleRequest,
  deleteSubcriptionRequest,
  updateSubscriptionRequest,
  loginToDifferentAccount
};
