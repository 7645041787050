import React from "react";
import { Result, Button } from "antd";

function NotAutharised(flag) {
  let redirectHome = async () => {
    await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
  };
  return (
    <div>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          flag && (
            <Button type="primary" onClick={redirectHome}>
              Back Home
            </Button>
          )
        }
      />
    </div>
  );
}

export { NotAutharised };
