import { commonConstants } from "./action-types";

const initialState = {
  emailSignLoading:false,
  emailSignData:[],
  emailsignError:false
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {

     case commonConstants.GET_EMAIL_SIGN_DATA_LOADING:
         return {
           ...state,
           emailSignLoading: true,
           emailsignError: false,
         };
     case commonConstants.GET_EMAIL_SIGN_DATA_SUCCESS:
         return {
           ...state,
           emailSignData: action.emailSignData,
           emailSignLoading: false,
           emailsignError: false,
         };
     case commonConstants.GET_EMAIL_SIGN_DATA_ERROR:
           return {
             ...state,
             emailSignLoading:false,
             emailsignError:true
           };

    default:
      return state;
  }
};
export { commonReducer };
