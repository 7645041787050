import { offerTableConstants } from "./action-types";
import { offerTableApi } from "../utils/api";
import { decrypt } from "../../Common/commondata/commonData";
import dotEnv from "dotenv";
import { message } from "antd";
import { contractStoreHandle } from "../../ContractStore/state/actions";
dotEnv.config();

const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;

/**
 * To update Navbar title action
 * @param {string} navbar_title
 */

const offerTableError = (error) => {
  return {
    type: offerTableConstants.OFFER_TABLE_FAILURE,
    offerTableErrorData: error.message,
  };
};


const offerTableSuccess = (res) => {
  return {
    type: offerTableConstants.OFFER_TABLE_SUCCESS,
    offerTableData: res.data.info,
  };
};

const getOfferTable = (accessToken, id, sorter, sortWay, selectedCriteria) => {
  return (dispatch, getState) => {
    dispatch(offerTableCustomLoadingFun())
    offerTableApi
      .offerTableRequest(accessToken, id, sorter, sortWay, selectedCriteria)
      .then((response) => {
        dispatch(offerTableSuccess(response));
      })
      .catch((error) => {
        dispatch(offerTableError(error));
      });
  };
};
// get emailtemplate List
const get_Email_Template_Loading = () => {
  return {
    type: offerTableConstants.GET_EMAIL_TEMPLATE_LOADING,
  };
};
const get_Email_Template_Error = (error) => {
  return {
    type: offerTableConstants.GET_EMAIL_TEMPLATE_FAILURE,
  };
};
const get_Email_Template_Success = (response) => {
  return {
    type: offerTableConstants.GET_EMAIL_TEMPLATE_SUCCESS,
    getEmail_Templatedata: response.data.info,
  };
};

const getEmail_Template = (offerTableAuthKey) => {
  return (dispatch) => {
    dispatch(get_Email_Template_Loading());
    offerTableApi
      .getemailtemplateRequest(offerTableAuthKey)
      .then((response) => {
        dispatch(get_Email_Template_Success(response));
      })
      .catch((error) => {
        dispatch(get_Email_Template_Error(error));
      });
  };
};
const makeOfferStatusChange = ({offerTableAuthKey, offerTablePropertyId, offerId, documentId, isTrue, sort, order, status,}) => {
  return (dispatch) => {
    dispatch(offerTableCustomLoadingFun());
    offerTableApi
      .offerStatusChange(offerTableAuthKey, {propertyId:offerTablePropertyId, offerId, documentId, isTrue, status,})
      .then((response) => {
        dispatch(getOfferTable(offerTableAuthKey, offerTablePropertyId, sort, order));
        message.success(response?.data?.info?.message || "Offer Status Updated Succesfully!");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue while changing status.Please try again later!");
        dispatch(offerTableCustomLoadingFun(false));
      });
  };
};

const postStarsError = (error) => {
  return {
    type: offerTableConstants.POST_STARS_FAILURE,
    postStarsErrorData: error.message,
    postStarsloading: false,
  };
};

const postStarsSuccess = (res) => {
  return {
    type: offerTableConstants.POST_STARS_SUCCESS,
    postStarsData: res.data.info,
    postStarsloading: false,
  };
};

const postStars = (accessToken, propertyId, id, stars, sorter, sortWay) => {
  return (dispatch, getState) => {
    offerTableApi
      .postStarsRequest(accessToken, id, stars)
      .then((response) => {
        dispatch(postStarsSuccess(response));
        dispatch(getOfferTable(accessToken, propertyId, sorter, sortWay));
      })
      .catch((error) => {
        dispatch(postStarsError(error));
      });
  };
};

const checkedOfferError = (error) => {
  return {
    type: offerTableConstants.CHECKED_OFFER_FAILURE,
    checkedOfferErrorData: error.message,
    checkedOfferloading: false,
  };
};

const checkedOfferSuccess = (res) => {
  return {
    type: offerTableConstants.CHECKED_OFFER_SUCCESS,
    checkedOfferData: res.data.info,
    checkedOfferloading: false,
  };
};

const checkedOffer = (
  accessToken,
  propertyId,
  id,
  checked,
  sorter,
  sortWay,
  allData
) => {
  return (dispatch, getState) => {
    offerTableApi
      .checkedOfferRequest(accessToken, id, checked, allData)
      .then((response) => {
        dispatch(checkedOfferSuccess(response));
        dispatch(getOfferTable(accessToken, propertyId, sorter, sortWay));
      })
      .catch((error) => {
        dispatch(checkedOfferError(error));
      });
  };
};

const inputFillError = (error) => {
  return {
    type: offerTableConstants.INPUT_FILL_FAILURE,
    inputFillErrorData: error.message,
    inputFillloading: false,
  };
};

const inputFillSuccess = (res) => {
  return {
    type: offerTableConstants.INPUT_FILL_SUCCESS,
    inputFillData: res.data.info,
    inputFillloading: false,
  };
};

const inputFill = (
  accessToken,
  propertyId,
  id,
  value,
  sorter,
  sortWay,
  allData
) => {
  return (dispatch, getState) => {
    dispatch(offerTableCustomLoadingFun());
    offerTableApi
      .inputFillRequest(accessToken, id, value, allData)
      .then((response) => {
        dispatch(inputFillSuccess(response));
        dispatch(getOfferTable(accessToken, propertyId, sorter, sortWay));
        message.success(response?.data?.info?.message|| "Updated Succesfully!");
      })
      .catch((error) => {
        dispatch(inputFillError(error));
        message.error(error?.response?.data?.message || "We encountered an issue.Please try again later!");
      });
  };
};

// select offer button
const selectOfferError = (error) => {
  return {
    type: offerTableConstants.SELECT_OFFER_FAILURE,
    selectOfferErrorData: error.message,
    selectOfferLoading: false,
  };
};

const selectOfferSuccess = (res) => {
  return {
    type: offerTableConstants.SELECT_OFFER_SUCCESS,
    selectOfferData: res.data.info,
    selectOfferLoading: false,
  };
};
const offerTableCustomLoadingFun = (payload) => {
  return {
    type: offerTableConstants.SET_OFFER_TABLE_LOADING,
    offerTableCustomLoading: payload===false?false:true,
  };
};
// not using this function anyWhere in the app
const selectOffer = (accessToken, propertyId, data, e, sorter, sortWay) => {
  return (dispatch, getState) => {
    offerTableApi
      .selectOfferRequest(accessToken, propertyId, data, e)
      .then((response) => {
        dispatch(selectOfferSuccess(response));
        dispatch(getOfferTable(accessToken, propertyId, sorter, sortWay));
      })
      .catch((error) => {
        dispatch(selectOfferError(error));
      });
  };
};

const hiddenOffer = (accessToken, propertyId, data,markedAsHidden, sorter, sortWay) => {
  return (dispatch, getState) => {
    dispatch(offerTableCustomLoadingFun(true))
    offerTableApi
      .hiddenOfferRequest(accessToken, propertyId, data,markedAsHidden)
      .then((response) => {
        dispatch(getOfferTable(accessToken, propertyId, sorter, sortWay));
        message.success(response?.data?.info?.message|| "Updated Succesfully!");
      })
      .catch((error) => {
        dispatch(offerTableCustomLoadingFun(false));
        message.error(error?.response?.data?.message || "We encountered an issue.Please try again later!");
      });
  };
};
// urlShortening conversion for offer table
const URLShortenError = (error) => {
  return {
    type: offerTableConstants.OFFERTABLE_DADA_FAILURE,
    URLofferTableErrorData: error.message,
    URLofferTableLoading: false,
  };
};

const URLShortenSuccess = (buildData) => {
  return {
    type: offerTableConstants.OFFERTABLE_DADA_SUCCESS,
    URLofferTableJSON: buildData,
    offerTableAuthKey: buildData?.token,
    offerTablePropertyId: buildData?.propertyId,
    URLofferTableLoading: false,
  };
};
// Setting error if empty originalText
const setError = (data) => {
  return {
    type: offerTableConstants.SET_ERROR_IN_OFFER,
    error: data,
  };
};
const URLShorten = (urlId) => {
  return (dispatch, getState) => {
    offerTableApi
      .shortenConversionRequest(urlId)
      .then((response) => {
        let originalText = decrypt(response.data.info);
        console.log("originalText", originalText);
        if (!originalText) {
          return dispatch(
            setError({
              message:
                "Offer Management Dashboard has been closed, Please reinitiate Offer Management Dashboard",
              statusCode: 400,
            })
          );
        }
        dispatch(
          URLShortenSuccess(originalText, originalText.propertyId)
        );
      })
      .catch((error) => {
        dispatch(URLShortenError(error));
      });
  };
};

// select offer URLShortSelectOffer
const URLShortSelectOfferError = (error) => {
  return {
    type: offerTableConstants.URL_SHORT_SELECT_OFFER_FAILURE,
    URLShortErrorData: error.message,
    URLShortLoading: false,
  };
};

const URLShortSelectOfferSuccess = (res) => {
  return {
    type: offerTableConstants.URL_SHORT_SELECT_OFFER_SUCCESS,
    URLShortData: res.data.info,
    URLShortLoading: false,
  };
};

const buildContractOfferCheckSuccess = (response) => {

  let redirectURL = `${adminURL}/contractDocument/${response.data.info._id}`;
  window.location.assign(encodeURI(redirectURL));
};

const URLShortSelectOffer = (accessToken, data) => {
  return (dispatch, getState) => {
    offerTableApi
      .URLShortSelectOfferRequest(accessToken, data)
      .then((response) => {
        dispatch(buildContractOfferCheckSuccess(response));
        // dispatch(URLShortSelectOfferSuccess(response));
        // dispatch(getOfferTable(accessToken, propertyId, sorter, sortWay));
      })
      .catch((error) => {
        dispatch(URLShortSelectOfferError(error));
      });
  };
};

// Email actions
const sendEmailLoading = () => {
  return {
    type: offerTableConstants.SEND_EMAIL_LOADING,
  };
};
const sendEmailError = (error) => {
  return {
    type: offerTableConstants.SEND_EMAIL_FAILURE,
    sendEmailError: error,
  };
};

const sendEmailSuccess = (response) => {
  return {
    type: offerTableConstants.SEND_EMAIL_SUCCESS,
    sendEmailData: response.data.info,
  };
};

const sendEmailToClients = (accessToken, data) => {
  return (dispatch, getState) => {
    dispatch(sendEmailLoading());
    offerTableApi
      .sendEmailToClientRequest(accessToken, data)
      .then((response) => {
        dispatch(sendEmailSuccess(response));
        message.success("Document Sent Succesfully!");
      })
      .catch((error) => {
        dispatch(sendEmailError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while sending email.Please try again later!");
      });
  };
};
// Password actions
const sendPasswordLoading = () => {
  return {
    type: offerTableConstants.SEND_PASSWORD_LOADING,
  };
};
const sendPasswordError = (error) => {
  return {
    type: offerTableConstants.SEND_PASSWORD_FAILURE,
    sendPasswordError: error,
  };
};

const sendPasswordSuccess = (response) => {
  return {
    type: offerTableConstants.SEND_PASSWORD_SUCCESS,
    sendPasswordData: response.data.info,
  };
};

const sendPasswordToClients = (accessToken, data) => {
  return (dispatch, getState) => {
    dispatch(sendPasswordLoading());
    offerTableApi
      .sendPasswordToClientRequest(accessToken, data)
      .then((response) => {
        dispatch(sendPasswordSuccess(response));
        message.success("Password Sent Succesfully!");
      })
      .catch((error) => {
        dispatch(sendPasswordError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while sending password.Please try again later!");
      });
  };
};
// Password actions
const shareOfferTableLoading = () => {
  return {
    type: offerTableConstants.SHARE_OFFER_TABLE_LOADING,
  };
};
const shareOfferTableError = (error) => {
  return {
    type: offerTableConstants.SHARE_OFFER_TABLE_FAILURE,
  };
};

const shareOfferTableSuccess = (response) => {
  return {
    type: offerTableConstants.SHARE_OFFER_TABLE_SUCCESS,
    shareOfferTableData: response.data.info,
  };
};

const shareOfferTableToClients = (accessToken, data) => {
  return (dispatch, getState) => {
    dispatch(shareOfferTableLoading());
    offerTableApi
      .shareOfferTableToClientRequest(accessToken, data)
      .then((response) => {
        dispatch(shareOfferTableSuccess(response));
        dispatch(getRestrictedClientsOfferTableToClients(accessToken, data.propertyId));
        message.success("Mail Sent Succesfully!");
      })
      .catch((error) => {
        dispatch(shareOfferTableError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while sharing offer table.Please try again later!");
      });
  };
};

const shareMessageToBuyerAgents = ({offerTableAuthKey, payloadData}) => {
  return (dispatch, getState) => {
    dispatch(shareOfferTableLoading());
    offerTableApi
      .shareMessageToBuyerAgentsRequest(offerTableAuthKey, payloadData)
      .then((response) => {
        dispatch(shareOfferTableSuccess(response));
        message.success(response?.data?.info?.status || "Mail Sent Succesfully!");
      })
      .catch((error) => {
        dispatch(shareOfferTableError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while sharing message to Buyer Agent.Please try again later!");
      });
  };
};

// make Client restricted in Offer table
const restrictOfferTableLoading = () => {
  return {
    type: offerTableConstants.RESTRICT_OFFER_TABLE_LOADING,
  };
};
const restrictOfferTableError = (error) => {
  return {
    type: offerTableConstants.RESTRICT_OFFER_TABLE_FAILURE,
  };
};

const restrictOfferTableSuccess = (response) => {
  return {
    type: offerTableConstants.RESTRICT_OFFER_TABLE_SUCCESS,
    restrictOfferTableData: response.data.info,
  };
};

const restrictOfferTableToClients = (accessToken, data, offerTablePropertyId) => {
  return (dispatch, getState) => {
    dispatch(restrictOfferTableLoading());
    offerTableApi
      .restrictOfferTableToClientRequest(accessToken, data)
      .then((response) => {
        dispatch(restrictOfferTableSuccess(response));
        dispatch(getRestrictedClientsOfferTableToClients(accessToken, offerTablePropertyId));
        message.success(response?.data?.info.message || "Clent Access Updated");
      })
      .catch((error) => {
        dispatch(restrictOfferTableError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while restricting offer table to client.Please try again later!");
      });
  };
};
const getRestrictedClientsOfferTableLoading = () => {
  return {
    type: offerTableConstants.GET_RESTRICTED_CLIENT_OFFER_TABLE_LOADING,
  };
};
const getRestrictedClientsOfferTableError = () => {
  return {
    type: offerTableConstants.GET_RESTRICTED_CLIENT_OFFER_TABLE_FAILURE
  };
};

const getRestrictedClientsOfferTableSuccess = (response) => {
  return {
    type: offerTableConstants.GET_RESTRICTED_CLIENT_OFFER_TABLE_SUCCESS,
    restrictedClientData: response.data.info?.restrictedSellersIds,
  };
};

const getRestrictedClientsOfferTableToClients = (accessToken, offerTablePropertyId) => {
  return (dispatch, getState) => {
    dispatch(getRestrictedClientsOfferTableLoading());
    offerTableApi
      .getRestrictedClientOffetTableRequest(accessToken, offerTablePropertyId)
      .then((response) => {
        dispatch(getRestrictedClientsOfferTableSuccess(response));
      })
      .catch((error) => {
        dispatch(getRestrictedClientsOfferTableError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while fetching data.Please try again later!");
      });
  };
};
const getConstantsLoading = () => {
  return {
    type: offerTableConstants.GET_CONSTANTS_LOADING,
  };
};
const getConstantsError = () => {
  return {
    type: offerTableConstants.GET_CONSTANTS_FAILURE
  };
};

const getConstantsSuccess = (response) => {
  return {
    type: offerTableConstants.GET_CONSTANTS_SUCCESS,
    OTAddCriteriaKeysArray: response.data.info?.OTAddCriteriaKeysArray,
  };
};

const getConstantsFun = (accessToken, query) => {
  return (dispatch, getState) => {
    dispatch(getConstantsLoading());
    offerTableApi
      .getConstantsRequest(accessToken, query)
      .then((response) => {
        dispatch(getConstantsSuccess(response));
      })
      .catch((error) => {
        dispatch(getConstantsError(error));
        message.error(error?.response?.data?.message || "We encountered an issue while fetching data.Please try again later!");
      });
  };
};

const acceptOfferViaResureSignSuccess = (response) => {
  return {
    type: offerTableConstants.ACCEPT_OFFER_VIA_RESURE_SIGN_SUCCESS,

  };
};

const acceptOfferViaResureSignFun = ({offerTableAuthKey, offerTablePropertyId, documentId, onlySellerAgentSignRequired}) => {
  return (dispatch, getState) => {
    dispatch(contractStoreHandle.setTransparentDivLoading({
      loadingStatus: true,
    }));
    offerTableApi
      .acceptOfferViaResureSignRequest(offerTableAuthKey,{propertyId:offerTablePropertyId,documentId, onlySellerAgentSignRequired})
      .then((response) => {
        if (response.data.info.redirectUrl) {
          // window.location.href = response.data.info.redirectUrl;
          window.open(response.data.info.redirectUrl);
        }
          dispatch(acceptOfferViaResureSignSuccess(response));
          dispatch(contractStoreHandle.setTransparentDivLoading({
            loadingStatus: false,
          }));

      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue in redirection to the RE-Sure sign page. Please try after some time.");
        dispatch(contractStoreHandle.setTransparentDivLoading({
          loadingStatus: false,
        }));
      });
  };
};

export const offerTableHandle = {
  URLShorten,
  selectOffer,
  getOfferTable,
  makeOfferStatusChange,
  postStars,
  checkedOffer,
  inputFill,
  URLShortSelectOffer,
  sendPasswordToClients,
  sendEmailToClients,
  getEmail_Template,
  hiddenOffer,
  shareOfferTableToClients,
  restrictOfferTableToClients,
  getRestrictedClientsOfferTableToClients,
  getConstantsFun,
  acceptOfferViaResureSignFun,
  shareMessageToBuyerAgents
};
