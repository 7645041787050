/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button } from "antd";

function PDFview({ jsx, navjsx }) {
  // when we click on the sign button
  const [sign, setSign] = useState(false);
  let handleSignButtonClick = () => {
    let activeSignatureBlock = document.querySelector(".active-signer");
    if (activeSignatureBlock) {
      activeSignatureBlock.scrollIntoView({
        // behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  useEffect(()=>{
    setTimeout(()=>{
      setSign(document.querySelector(".active-signer"));
    }, 100)
  }, []);

  return (
    <>
      {
        sign ? <Button onClick={handleSignButtonClick} className={navjsx ? "withNavSignbutton no-print" : "withoutNavSignbutton no-print"}>
        Sign
      </Button> : ""
      }
      <div className="zoom_button_1 no-print">
        <div className="zoom-plus-button_1">
          <svg viewBox="0 -85 384 512" className="zoom-icons" height="100" width="100">
            <path d="M48 208H160v111.1c0 17.69 14.31 31.1 32 31.1s32-14.31 32-31.1V208h112c17.69 0 32-14.32 32-32.01s-14.31-31.99-32-31.99H224v-112c0-17.69-14.31-32.01-32-32.01S160 14.33 160 32.01v112H48c-17.69 0-32 14.31-32 31.99S30.31 208 48 208z" id="zoom-plus-color" fill="#84c1fa"></path>
          </svg>
        </div>
        <div className="zoom-minus-button_1">
          <svg viewBox="0 215 384 512" className="zoom-icons" height="50" width="50">
            <path d="M352 448H32c-17.69 0-32 14.31-32 32s14.31 31.1 32 31.1h320c17.69 0 32-14.31 32-31.1S369.7 448 352 448z" id="zoom-minus-color" fill="#84c1fa"></path>
          </svg>
        </div>
      </div>
      {jsx}
    </>
  );
}

export { PDFview };
