import { calendarConstants } from "./action-types";
import { calendarDataApi } from "../utils/api";
import { decrypt } from "../../Common/commondata/commonData";
import { message } from "antd";
/**
 * All Actions for contract Page
 */

/**
 * To update Navbar title action
 * @param {string}
 */

// get all calendar data
const getCalendarDetailsError = (error) => {
  return {
    type: calendarConstants.CALENDAR_DATA_FAILURE,
    calendarDataError: error,
    calendarDataLoading: false,
  };
};

const getCalendarDetailsSuccess = (response) => {
  return {
    type: calendarConstants.CALENDAR_DATA_SUCCESS,
    calendarData: response.data.info,
    calendarDataLoading: false,
  };
};

const getCalendarDetailsLoading = () => {
  return {
    type: calendarConstants.GET_CALENDAR_LOADING,
    calendarDataLoading: true,
    calendarDataError: null,
    calendarData: null,
  };
};

const getCalendarDetails = ({dataObject, timestamp, responseType}) => {
  return (dispatch, getState) => {
    // dispatch(getCalendarDetailsLoading());
    calendarDataApi
      .getCalendarDetailsRequest(dataObject, timestamp, responseType)
      .then((response) => {
        dispatch(getCalendarDetailsSuccess(response));
      })
      .catch((error) => {
        dispatch(getCalendarDetailsError(error));
      });
  };
};

// get all calendar data from the id
const getCreateOfferError = (error) => {
  return {
    type: calendarConstants.CALENDAR_GETDATA_FAILURE,
    calendarDataEncryptError: error,
    calendarDataEncryptLoading: false,
  };
};

const getCreateOfferSuccess = (originalText) => {
  return {
    type: calendarConstants.CALENDAR_GETDATA_SUCCESS,
    calendarDataEncrypt: originalText.urlData,
    calendarDataEncryptLoading: false,
  };
};

const getCalendarData = ({id, source, payloadTimestamp}) => {
  return (dispatch, getState) => {
    calendarDataApi
      .getCalendarDataRequest(id)
      .then((response) => {
        let date = new Date();
        let timestamp = date.getTime();
        let responseType = "YEAR";
        if (source === "TRANSACTION_CALENDAR") {
          responseType = "YEAR";
        } else if (source === "HOME_CALENDAR") {
          responseType = "YEAR";
          timestamp = payloadTimestamp;
        }
        let originalText = decrypt(response.data.info);
        // console.log("originalText in calendar", originalText);
        dispatch(getCreateOfferSuccess(originalText));
        dispatch(getCalendarDetails({dataObject: originalText.urlData, timestamp, responseType}));
        dispatch(getRemindersList(originalText.urlData, date.getFullYear()));
        dispatch(getScheduleList(originalText.urlData, date.getFullYear()));
      })
      .catch((error) => {
        dispatch(getCreateOfferError(error));
      });
  };
};

const resetCalendar = () => ({
  type: calendarConstants.RESET_CALENDAR,
});

const getRemindersListSuccess = (response) => {
  return {
    type: calendarConstants.GET_REMINDERS_LIST_SUCCESS,
    payload: response,
  };
};

const getRemindersListError = (error) => {
  return {
    type: calendarConstants.GET_REMINDERS_LIST_FAILURE,
    payload: error,
  };
};

const getRemindersList = (data, year) => {
  return (dispatch, getState) => {
    calendarDataApi
      .getRemindersListRequest(data, year)
      .then((response) => {
        const newData = response?.data?.info?.data;
        dispatch(getRemindersListSuccess(newData));
      })
      .catch((error) => {
        dispatch(getRemindersListError(error));
      });
  };
};

const getScheduleListSuccess = (response) => {
  return {
    type: calendarConstants.GET_SCHEDULE_LIST_SUCCESS,
    payload: response,
  };
};

const getScheduleListError = (error) => {
  return {
    type: calendarConstants.GET_SCHEDULE_LIST_FAILURE,
    payload: error,
  };
};

const getScheduleList = (data, year) => {
  return (dispatch, getState) => {
    calendarDataApi
      .getScheduleListRequest(data, year)
      .then((response) => {
        const newData = response?.data?.info?.data;
        dispatch(getScheduleListSuccess(newData));
      })
      .catch((error) => {
        dispatch(getScheduleListError(error));
      });
  };
};

const shareCalendarToClients = ({payloadData, userAuthKey}) => {
  return (dispatch, getState) => {
    dispatch(shareCalendarToClientsLoading());
    calendarDataApi.shareCalendarToClientsRequest({payloadData, userAuthKey})
    .then((response) => {
      dispatch(shareCalendarToClientsLoadingFalse());
      message.success(response?.data?.info?.message || "Calendar Send Successfully.");
    })
    .catch((error) => {
      dispatch(shareCalendarToClientsLoadingFalse());
      message.error(error?.response?.data?.message || 'We encountered some issue while sending the calendar please try again later.');
    });
  }
}

const shareCalendarToClientsLoading = () => ({
  type: calendarConstants.SHARE_CALENDAR_LOADING,
})

const shareCalendarToClientsLoadingFalse = () => ({
  type: calendarConstants.SHARE_CALENDAR_SUCCESS,
})

export const calendarHandle = {
  resetCalendar,
  getCalendarDetails,
  getCalendarData,
  getRemindersList,
  getScheduleList,
  shareCalendarToClients,
};
