import axios from "axios";
const apiURL = process.env.REACT_APP_BASE_URL;

const verifyUserOtp = (data) => {
  // let accessTokan = localStorage.getItem("auth");
  return axios.post(
    `${apiURL}/offerDocument/authentication`,
    {
      // ...data,
      OTP: data.OTP,
      OTPId: data.OTPId,
    },
    {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }
  );
};

export const clienAuthApi = {
  verifyUserOtp,
};
