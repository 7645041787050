import React, { useState } from "react";
import { Form, InputNumber, Input, Popconfirm } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { disabledTransactionText, formatPrice, loanTypeData } from "../../Common/commondata/commonData";
import { useSelector } from "react-redux";
const { TextArea } = Input;
function CustomInputField({ docId, save, keyName, previousValue, ele, placeholder, formatCol, acceptint, handleCancel, source, markedAsHidden}) {
  const { URLofferTableJSON, checkForDisable } = useSelector((state) => state?.offerTable);
  const [formattedValue, setFormattedValue] = useState(source === "Editable" ? ele?.data[keyName] : "");
  const [previousTextValue, setPreviousTextValue] = useState(source === "Editable" ? ele?.data[keyName] : "");
  const [showInput, setShowInput] = useState(false);
  const formatValueToUSD = (value) => {
    let formattedValue = formatPrice(value);
    if (formattedValue) {
      setFormattedValue(`$ ${formattedValue}`);
    }
  };
  return (
    <Form.Item
      style={{
        margin: 0,
      }}
      rules={[
        {
          required: true,
          message: `Value is required.`,
        },
      ]}
    >
      <Popconfirm title="Update ?" {...(checkForDisable?.disabled ? {onConfirm: disabledTransactionText} : {onConfirm: () => save(docId, keyName, ele)})}okText="Yes" cancelText="No" {...(checkForDisable?.disabled ? {} : {onCancel: () => {
       if (keyName === "Highest Escalated Price") {
       setFormattedValue(ele?.data[keyName]!=="undefined"?ele?.data[keyName]:"");
      } else {
        setPreviousTextValue(ele?.data[keyName]!=="undefined"?ele?.data[keyName]:"");
      }
      }})}>
        {formatCol === "text" && acceptint === true ? (
          source === "Editable" && !showInput ? (
            <>
             <text style={{color:markedAsHidden?"gray":"black",fontSize:"18px"}}>{formatPrice(ele?.data[keyName]) ? `$ ${formatPrice(ele.data[keyName])}` : "N/A"}</text> 
              <span style={{ marginLeft: "25px"}}>
                <EditOutlined
                  style={{
                    color: "hsl(211deg 77% 48%)",
                    pointerEvents:  (URLofferTableJSON?.urlData?.sellerOpenOfferTable || checkForDisable?.disabled) ? "none" : "auto",
                   fontSize:"16px"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowInput(!showInput);
                  }}
                />
              </span>{" "}
            </>
          ) : (
            <InputNumber
              onBlur={(e) => formatValueToUSD(e.target.value)}
              value={formattedValue}
              style={{
                width: "100%",
                pointerEvents:  (URLofferTableJSON?.urlData?.sellerOpenOfferTable || checkForDisable?.disabled) ? "none" : "auto",
              }}
              id={docId}
              placeholder={placeholder}
            />
          )
        ) : // ) : formatCol === "date" ? (
        //   <DatePicker
        //     style={{ width: "100%" }}
        //     id={docId}
        //     format={dateFormat}
        //   />
        // ) : formatCol === "time" ? (
        //   <TimePicker
        //     use12Hours
        //     format="h:mm:ss A"
        //     style={{ width: "100%" }}
        //     id={docId}
        //     placeholder={placeholder}
        //   />
        formatCol === "loanType" ? (
          <select
            id={docId}
            style={{
              width: "100%",
              fontSize: "15px",
              border: "0.2px solid #c1c1c1",
              background: "#fff",
              color: "#c1c1c1",
              height: "30px",
              padding: "0 6px",
              fontFamily: "inherit",
              textAlign: "left",
              fontWeight: "400",
              borderRadius: "2px",
              outline: "0",
              WebkitTransition: "all 0.3s linear",
              transition: "all 0.3s linear",
            }}
          >
            {loanTypeData &&
              loanTypeData.map((ele, index) => (
                <option key={index} disabled={ (URLofferTableJSON?.urlData?.sellerOpenOfferTable || checkForDisable?.disabled) ? true : false} value={ele}>
                  {ele}
                </option>
              ))}
          </select>
        ) : (
          <TextArea
            rows={4}
            id={docId}
            value={previousTextValue}
            onChange={(e) => setPreviousTextValue(e.target.value)}
            style={{
              pointerEvents:  (URLofferTableJSON?.urlData?.sellerOpenOfferTable || checkForDisable?.disabled) ? "none" : "auto",
            }}
            placeholder={placeholder}
          />
        )}
      </Popconfirm>
    </Form.Item>
  );
}
export { CustomInputField };
