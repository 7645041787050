import { useState } from "react";

import AgentDetailsModal from "./AgentDetailsModal";
import AvatarGroup from "./AvatarGroup";
import { Typography } from "antd";
import newSt from "../style/RtdWindow.module.css";
import "../style/rtdWindow.css";
const { Text } = Typography;

const BuyerSellerDiv = ({ allBuyerAgentData, allSellerAgentData, isSmallScreen, isMediumScreen }) => {
  const [userState, setUserState] = useState({
    showDetailModal: false,
    allAgentData: [],
    source: "",
  });

  const handleShowAgentDetail = (agents, source) => {
    setUserState({
      showDetailModal: true,
      allAgentData: agents,
      source,
    });
  };


  return (
    <>
      {/* Agent Details modal  */}
      {userState?.showDetailModal && <AgentDetailsModal userState={userState} setUserState={setUserState} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} />}

      <div className={newSt.buyerSellerBox}>
        <div className={newSt.sellerContainer}>
          <AvatarGroup allAgents={allSellerAgentData} source={"SELLER_AGENT"} handleShowAgentDetail={handleShowAgentDetail} />
          <div style={{ maxWidth: "130px" }}>
            <Text ellipsis style={{ fontWeight: "bold", color: "grey", visibility: allSellerAgentData?.length === 1 ? true : "hidden", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "18px" }}>
              {allSellerAgentData?.[0]?.fullName}
            </Text>
          </div>
          <div className={newSt.sellerDesk}>{`Seller Agent${allSellerAgentData?.length > 1 ? "s" : ""}`}</div>
        </div>

        <div className={newSt.buyerContainer}>
          <AvatarGroup allAgents={allBuyerAgentData} source={"BUYER_AGENT"} handleShowAgentDetail={handleShowAgentDetail} />
          <div style={{ maxWidth: "130px" }}>
            <Text ellipsis style={{ fontWeight: "bold", color: "grey", visibility: allBuyerAgentData?.length === 1 ? true : "hidden", fontSize: isSmallScreen ? "12px" : isMediumScreen ? "14px" : "18px" }}>
              {allBuyerAgentData?.[0]?.fullName}
            </Text>
          </div>
          <div className={newSt.buyerDesk}>{`Buyer Agent${allBuyerAgentData?.length > 1 ? "s" : ""}`}</div>
        </div>
      </div>
    </>
  );
};
export default BuyerSellerDiv;
