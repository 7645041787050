export const officeTypeConstants = {
  OFFICE_TYPE_LOADING: "OFFICE_TYPE_LOADING",
  OFFICE_TYPE_ERROR: "OFFICE_TYPE_ERROR",
  OFFICE_TYPE_DATA: "OFFICE_TYPE_DATA",

  PERSON_LOADING: "PERSON_LOADING",
  PERSON_ERROR: "PERSON_ERROR",
  PERSON_DATA: "PERSON_DATA",

  FIRM_UPDATE_LOADING: "FIRM_UPDATE_LOADING",
  FIRM_UPDATE_ERROR: "FIRM_UPDATE_ERROR",
  FIRM_UPDATE_DATA: "FIRM_UPDATE_DATA",

  FIRM_FILTER_STATUS_LOADING: "FIRM_FILTER_STATUS_LOADING",
  FIRM_FILTER_STATUS_ERROR: "FIRM_FILTER_STATUS_ERROR",
  FIRM_FILTER_STATUS_SUCESS: "FIRM_FILTER_STATUS_SUCESS",

  SET_ADMIN_ROLE_UPDATED: 'SET_ADMIN_ROLE_UPDATED',
};
