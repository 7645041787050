import { formatPhoneNumber } from "../../../Common/utils/universalFuntions";

export const addDynamicSellerBuyer = (clientType, clientArray, contractType) => {
    // Title order form
    if (contractType === 'TO') {
        if (clientType === "Sellers") {
            const sellerContainer = document.getElementById('dynamicSellerContainer');
            if (sellerContainer) {
                let count = 0;
                const sellerDetails = clientArray?.map((seller,i)=>{
                    count = i+1;
                    if (seller?.isCorp && seller?.signers) {
                        return seller?.signers?.map((signer,j) => {
                            count = count +j;
                            return `
                                <div class="mt titleCompanyAddressTable">
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="leftTable tb">Seller ${count}:</td>
                                            <td><input type="text" name="${signer?._id}_SellerFullName" placeholder="Seller FullName" value="${signer?.fullName ? signer?.fullName : ''} [${seller?.fullName ? seller?.fullName : ''}]"></td>
                                        </tr>
                                        <tr>
                                            <td>Phone W:</td>
                                            <td><input class="formatPhoneNumber" type="text" name="${signer?._id}_SellerOfficePhoneNumber" placeholder="Seller Office PhoneNumber" value="${signer?.officePhoneNumber ? formatPhoneNumber(signer?.officePhoneNumber) : ''}"></td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><input type="email" name="${signer?._id}_SellerEmail" placeholder="Seller Email" value="${signer?.email ? signer?.email : ''}"></td>
                                        </tr>
                                    </table>
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="rightTable">Cell:</td>
                                            <td><input class="formatPhoneNumber" type="text" name="${signer?._id}_SellerCellPhoneNo" placeholder="Seller Cell Phone No." value="${signer?.cellPhoneNumber ? formatPhoneNumber(signer?.cellPhoneNumber) : ''}"></td>
                                        </tr>
                                        <tr>
                                            <td>Phone H:</td>
                                            <td><input class="formatPhoneNumber" type="text" name="${signer?._id}_SellerPhoneNumber" placeholder="Seller PhoneNumber" value="${signer?.phoneNumber ? formatPhoneNumber(signer?.phoneNumber) : ''}"></td>
                                        </tr>
                                        <tr>
                                            <td>SSN:</td>
                                            <td><input type="text" name="${signer?._id}_SellerSSN" placeholder="Seller SSN Number" value="${signer?.SSN ? signer?.SSN : ''}"></td>
                                        </tr>
                                        <tr>
                                            <td>Fax:</td>
                                            <td><input class="formatPhoneNumber" type="text" name="${signer?._id}_SellerFaxNo" placeholder="Seller Fax Number" value="${signer?.fax ? formatPhoneNumber(signer?.fax) : ''}"></td>
                                        </tr>
                                    </table>
                                </div>
                            `
                        }).join('');
                    } else {
                        return `
                            <div class="mt titleCompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Seller ${count}:</td>
                                        <td><input type="text" name="${seller?._id}_SellerFullName" placeholder="Seller FullName" value="${seller?.fullName ? seller?.fullName : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Phone W:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${seller?._id}_SellerOfficePhoneNumber" placeholder="Seller Office PhoneNumber" value="${seller?.officePhoneNumber ? formatPhoneNumber(seller?.officePhoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="email" name="${seller?._id}_SellerEmail" placeholder="Seller Email" value="${seller?.email ? seller?.email : ''}"></td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Cell:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${seller?._id}_SellerCellPhoneNo" placeholder="Seller Cell Phone No." value="${seller?.cellPhoneNumber ? formatPhoneNumber(seller?.cellPhoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Phone H:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${seller?._id}_SellerPhoneNumber" placeholder="Seller PhoneNumber" value="${seller?.phoneNumber ? formatPhoneNumber(seller?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${seller?._id}_SellerSSN" placeholder="Seller SSN Number" value="${seller?.SSN ? seller?.SSN : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Fax:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${seller?._id}_SellerFaxNo" placeholder="Seller Fax Number" value="${seller?.fax ? formatPhoneNumber(seller?.fax) : ''}"></td>
                                    </tr>
                                </table>
                            </div>
                        `
                    }
                });
                sellerContainer.innerHTML = sellerDetails.join("\n");
            }
        }
        else if (clientType === "Buyers") {
            const buyerContainer = document.getElementById('dynamicBuyerContainer');
            if (buyerContainer) {
                let count = 0;
                const buyerDetails = clientArray?.map((buyer,i)=>{
                    count = i+1;
                    if (buyer?.isCorp && buyer?.signers) {
                        return buyer?.signers?.map((signer,j) => {
                            count = count+j;
                            return `
                            <div class="mt titleCompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Buyer ${count}:</td>
                                        <td><input type="text" name="${signer?._id}_BuyerFullName" placeholder="Buyer FullName" value="${signer?.fullName ? signer?.fullName : ''} [${buyer?.fullName ? buyer?.fullName : ''}]"></td>
                                    </tr>
                                    <tr>
                                        <td>Home Ph:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${signer?._id}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber" value="${signer?.phoneNumber ? formatPhoneNumber(signer?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${signer?._id}_BuyerOfficePhoneNo" placeholder="Buyer Office Ph. No." value="${signer?.officePhoneNumber ? formatPhoneNumber(signer?.officePhoneNumber) : ''}"></td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Cell Ph:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${signer?._id}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No." value="${signer?.cellPhoneNumber ? formatPhoneNumber(signer?.cellPhoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="email" name="${signer?._id}_BuyerEmail" placeholder="Buyer Email Address" value="${signer?.email ? signer?.email : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${signer?._id}_BuyerSSN" placeholder="Buyer SSN Number" value="${signer?.SSN ? signer?.SSN : ''}"></td>
                                    </tr>
                                </table>
                            </div>
                        `
                        }).join('');
                    } else {
                        return `
                            <div class="mt titleCompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Buyer ${i+1}:</td>
                                        <td><input type="text" name="${buyer?._id}_BuyerFullName" placeholder="Buyer FullName" value="${buyer?.fullName ? buyer?.fullName : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Home Ph:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${buyer?._id}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber" value="${buyer?.phoneNumber ? formatPhoneNumber(buyer?.phoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${buyer?._id}_BuyerOfficePhoneNo" placeholder="Buyer Office Ph. No." value="${buyer?.officePhoneNumber ? formatPhoneNumber(buyer?.officePhoneNumber) : ''}"></td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Cell Ph:</td>
                                        <td><input class="formatPhoneNumber" type="text" name="${buyer?._id}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No." value="${buyer?.cellPhoneNumber ? formatPhoneNumber(buyer?.cellPhoneNumber) : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="email" name="${buyer?._id}_BuyerEmail" placeholder="Buyer Email Address" value="${buyer?.email ? buyer?.email : ''}"></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${buyer?._id}_BuyerSSN" placeholder="Buyer SSN Number" value="${buyer?.SSN ? buyer?.SSN : ''}"></td>
                                    </tr>
                                </table>
                            </div>
                        `
                    }                   
                });
                buyerContainer.innerHTML = buyerDetails.join("\n");
            }
        }
        else if (clientType === 'BuyerAgents') {
            const buyerAgentsDivFromTO = document.getElementById('buyerAgentsContainer');
            if (clientArray && buyerAgentsDivFromTO) {
                buyerAgentsDivFromTO.innerHTML = clientArray?.map((record) => {
                    let agentId;
                    if (record?.personId?.length === 24) {
                        agentId = record?.personId;
                    } else if (record?.key) {
                        agentId = record?.key;
                    }
                    return `
                        <table class="tableWidth2">
                            <tr>
                                <td class="rightTable">Selling Co.:</td>
                                <td><input type="text" name="${agentId}_firmName_buying" value="${record?.firmName || ''}" placeholder="Selling Company Name"></td>
                            </tr>
                            <tr>
                                <td>Brokerage ID:</td>
                                <td><input type="text" name="${agentId}_firmLicense_buying" value="${record?.firmLicense || ''}" placeholder="Buyer Agent Brokerage ID/License No."></td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>
                                    <span class="grow-with-content">
                                        <textarea class="ta-like grow" name="${agentId}_address_buying" placeholder="Address">${record?.address || ''}</textarea>
                                    </span>
                                <!-- <input type="text" name="BuyerBrokerAddress1" placeholder="Address line 1"> -->
                                </td>
                            </tr>
                            <tr>
                                <td>Commission:</td>
                                <td><input type="text" name="${agentId}_commission_buying" placeholder="Buyer Agent Commission"></td>
                            </tr>
                            <tr>
                                <td>Paid By:</td>
                                <td><input type="text" name="${agentId}_commissionPaidBy_buying" placeholder="Commission Paid By"></td>
                            </tr>
                            <tr>
                                <td>Agent:</td>
                                <td><input type="text" name="${agentId}_fullName_buying" value="${record?.fullName || ''}" placeholder="Buyer Agent Name"></td>
                            </tr>
                            <tr>
                                <td>Agent ID:</td>
                                <td><input type="text" name="${agentId}_license_buying" value="${record?.license || ''}" placeholder="Buyer Agent ID/License No."></td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_phoneNumber_buying" value="${record?.phoneNumber || ''}" placeholder="Buyer Agent Phone No."></td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td><input type="email" name="${agentId}_email_buying" value="${record?.email || ''}" placeholder="Buyer Agent Email"></td>
                            </tr>
                            <tr>
                                <td>Cell Phone:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_cellPhoneNumber_buying" placeholder="Buyer Agent Cell Phone No."></td>
                            </tr>
                            <tr>
                                <td>Fax:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_faxNumber_buying" value="${record?.faxNumber || ''}" placeholder="Buyer Agent Fax No."></td>
                            </tr>
                            <tr>
                                <td>Other Request:</td>
                                <td><input type="text" name="${agentId}_otherRequest_buying" placeholder="Buyer Agent Other Request"></td>
                            </tr>
                        </table>
                    `
                }).join(`<div class="sectionTitle" style="margin: 5px 0;"></div>`)
            }
        }
        else if (clientType === 'SellerAgents') {
            const sellerAgentsDivFromTO = document.getElementById('sellerAgentsContainer');
            if (clientArray && sellerAgentsDivFromTO) {
                sellerAgentsDivFromTO.innerHTML = clientArray?.map((record) => {
                    let agentId;
                    if (record?.personId?.length === 24) {
                        agentId = record?.personId;
                    } else if (record?.key) {
                        agentId = record?.key
                    }
                    return `
                        <table class="tableWidth2">
                            <tr>
                                <td class="leftTable">Listing Co.:</td>
                                <td><input type="text" name="${agentId}_firmName_selling" value="${record?.firmName || ''}" placeholder="Listing Company Name"></td>
                            </tr>
                            <tr>
                                <td>Brokerage ID:</td>
                                <td><input type="text" name="${agentId}_firmLicense_selling" value="${record?.firmLicense || ''}" placeholder="Seller Agent Brokerage ID/License No."></td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>
                                    <span class="grow-with-content">
                                        <textarea class="ta-like grow" name="${agentId}_address_selling" placeholder="Address">${record?.address || ''}</textarea>
                                    </span>
                                <!-- <input type="text" name="SellerBrokerAddress1" placeholder="Address line 1"> -->
                                </td>
                            </tr>
                            <tr>
                                <td>Commission:</td>
                                <td><input type="text" name="${agentId}_commission_selling" placeholder="Seller Agent Commission"></td>
                            </tr>
                            <tr>
                                <td>Paid By:</td>
                                <td><input type="text" name="${agentId}_commissionPaidBy_selling" placeholder="Commission Paid By"></td>
                            </tr>
                            <tr>
                                <td>Agent:</td>
                                <td><input type="text" name="${agentId}_fullName_selling" value="${record?.fullName || ''}" placeholder="Seller Agent Name"></td>
                            </tr>
                            <tr>
                                <td>Agent ID:</td>
                                <td><input type="text" name="${agentId}_license_selling" value="${record?.license || ''}" placeholder="Seller Agent ID/License No."></td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_phoneNumber_selling" value="${record?.phoneNumber || ''}" placeholder="Seller Agent Phone No."></td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td><input type="email" name="${agentId}_email_selling" value="${record?.email || ''}" placeholder="Seller Agent Email"></td>
                            </tr>
                            <tr>
                                <td>Cell Phone:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_cellPhoneNumber_selling" placeholder="Seller Agent Cell Phone No."></td>
                            </tr>
                            <tr>
                                <td>Fax:</td>
                                <td><input class="formatPhoneNumber" type="text" name="${agentId}_faxNumber_selling" value="${record?.faxNumber || ''}" placeholder="Seller Agent Fax No."></td>
                            </tr>
                            <tr>
                                <td>Other Request:</td>
                                <td><input type="text" name="${agentId}_otherRequest_selling" placeholder="Seller Agent Other Request"></td>
                            </tr>
                        </table>
                    `
                }).join(`<div class="sectionTitle" style="margin: 5px 0;"></div>`)
            }
        }
    }
    
    // Transaction Report form
    else if (contractType === 'TR') {
        if (clientType === "Sellers") {
            const sellerContainer = document.getElementById('dynamicSellerContainer');
            if (sellerContainer) {
                let count = 0;
                const sellerDetails = clientArray?.map((seller,i)=>{
                    count = i+1;
                    if (seller?.isCorp && seller?.signers) {
                        return seller?.signers?.map((signer,j) => {
                            count = count +j;
                            return `
                                <div class="mt CompanyAddressTable">
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="leftTable tb">Seller ${count}:</td>
                                            <td><input type="text" name="${signer?._id}_SellerFullName"
                                                    placeholder="Seller FullName"
                                                    value="${signer?.fullName ? signer?.fullName : ''} [${seller?.fullName ? seller?.fullName : ''}]" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><input type="text" name="${signer?._id}_SellerEmail"
                                                    placeholder="Seller Email" value="${signer?.email ? signer?.email : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>SSN:</td>
                                            <td><input type="text" name="${signer?._id}_SellerSSN"
                                                    placeholder="Seller SSN Number"
                                                    value="${signer?.SSN ? signer?.SSN : ''}" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Address:</td>
                                            <td><input type="text" name="SellerAddress" placeholder="Seller Address" disabled></td>
                                        </tr>
                                    </table>
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="rightTable">Home Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?._id}_SellerPhoneNumber" placeholder="Seller PhoneNumber"
                                                    value="${signer?.phoneNumber ? formatPhoneNumber(signer?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Fax/Work:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${seller?._id}_SellerOfficePhoneNumber"
                                                    placeholder="Seller Office PhoneNumber"
                                                    value="${seller?.officePhoneNumber ? formatPhoneNumber(seller?.officePhoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${seller?._id}_SellerCellPhoneNo"
                                                    placeholder="Seller Cell Phone No."
                                                    value="${seller?.cellPhoneNumber ? formatPhoneNumber(seller?.cellPhoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                    </table>
                                </div>
                            `
                        }).join('');
                    } else {
                        return `
                            <div class="mt CompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Seller ${count}:</td>
                                        <td><input type="text" name="${seller?._id}_SellerFullName"
                                                placeholder="Seller FullName"
                                                value="${seller?.fullName ? seller?.fullName : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="text" name="${seller?._id}_SellerEmail"
                                                placeholder="Seller Email" value="${seller?.email ? seller?.email : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${seller?._id}_SellerSSN"
                                                placeholder="Seller SSN Number"
                                                value="${seller?.SSN ? seller?.SSN : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Address:</td>
                                        <td><input type="text" name="SellerAddress" placeholder="Seller Address" disabled></td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Home Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${seller?._id}_SellerPhoneNumber" placeholder="Seller PhoneNumber"
                                                value="${seller?.phoneNumber ? formatPhoneNumber(seller?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${seller?._id}_SellerOfficePhoneNumber"
                                                placeholder="Seller Office PhoneNumber"
                                                value="${seller?.officePhoneNumber ? formatPhoneNumber(seller?.officePhoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Mobile Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${seller?._id}_SellerCellPhoneNo"
                                                placeholder="Seller Cell Phone No."
                                                value="${seller?.cellPhoneNumber ? formatPhoneNumber(seller?.cellPhoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                </table>
                            </div>
                        `
                    }
                });
                sellerContainer.innerHTML = sellerDetails.join("\n");
            }
        }
        else if (clientType === "Buyers") {
            const buyerContainer = document.getElementById('dynamicBuyerContainer');
            if (buyerContainer) {
                let count = 0;
                const buyerDetails = clientArray?.map((buyer,i)=>{
                    count = i+1;
                    if (buyer?.isCorp && buyer?.signers) {
                        return buyer?.signers?.map((signer,j) => {
                            count = count+j
                            return `
                                <div class="mt CompanyAddressTable">
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="leftTable tb">Buyer ${count}:</td>
                                            <td><input type="text" name="${signer?._id}_BuyerFullName" placeholder="Buyer FullName"
                                                    value="${signer?.fullName ? signer?.fullName : ''} [${buyer?.fullName ? buyer?.fullName : ''}]" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td><input type="text" name="${signer?._id}_BuyerEmail"
                                                    placeholder="Buyer Email Address"
                                                    value="${signer?.email ? signer?.email : ''}" disabled></td>
                                        </tr>
                                        <tr>
                                            <td>SSN:</td>
                                            <td><input type="text" name="${signer?._id}_BuyerSSN"
                                                    placeholder="Buyer SSN Number" value="${signer?.SSN ? signer?.SSN : ''}"
                                                    disabled></td>
                                        </tr>
        
                                        <tr>
                                            <td>Address:</td>
                                            <td><input type="text" name="BuyerAddress" placeholder="Buyer Address" disabled>
                                            </td>
                                        </tr>
                                    </table>
                                    <table class="tableWidth2">
                                        <tr>
                                            <td class="rightTable">Home Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?._id}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber"
                                                    value="${signer?.phoneNumber ? formatPhoneNumber(signer?.phoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Fax/Work:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?._id}_BuyerOfficePhoneNo"
                                                    placeholder="Buyer Office Ph. No."
                                                    value="${signer?.officePhoneNumber ? formatPhoneNumber(signer?.officePhoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Ph.:</td>
                                            <td><input class="formatPhoneNumber" type="text"
                                                    name="${signer?._id}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No."
                                                    value="${signer?.cellPhoneNumber ? formatPhoneNumber(signer?.cellPhoneNumber) : ''}"
                                                    disabled></td>
                                        </tr>
        
                                    </table>
                                </div>
                            `
                        }).join('');
                    } else {
                        return `
                            <div class="mt CompanyAddressTable">
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="leftTable tb">Buyer ${count}:</td>
                                        <td><input type="text" name="${buyer?._id}_BuyerFullName" placeholder="Buyer FullName"
                                                value="${buyer?.fullName ? buyer?.fullName : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Email:</td>
                                        <td><input type="text" name="${buyer?._id}_BuyerEmail"
                                                placeholder="Buyer Email Address"
                                                value="${buyer?.email ? buyer?.email : ''}" disabled></td>
                                    </tr>
                                    <tr>
                                        <td>SSN:</td>
                                        <td><input type="text" name="${buyer?._id}_BuyerSSN"
                                                placeholder="Buyer SSN Number" value="${buyer?.SSN ? buyer?.SSN : ''}"
                                                disabled></td>
                                    </tr>

                                    <tr>
                                        <td>Address:</td>
                                        <td><input type="text" name="BuyerAddress" placeholder="Buyer Address" disabled>
                                        </td>
                                    </tr>
                                </table>
                                <table class="tableWidth2">
                                    <tr>
                                        <td class="rightTable">Home Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${buyer?._id}_BuyerPhoneNumber" placeholder="Buyer PhoneNumber"
                                                value="${buyer?.phoneNumber ? formatPhoneNumber(buyer?.phoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Fax/Work:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${buyer?._id}_BuyerOfficePhoneNo"
                                                placeholder="Buyer Office Ph. No."
                                                value="${buyer?.officePhoneNumber ? formatPhoneNumber(buyer?.officePhoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                    <tr>
                                        <td>Mobile Ph.:</td>
                                        <td><input class="formatPhoneNumber" type="text"
                                                name="${buyer?._id}_BuyerCellPhoneNo" placeholder="Buyer Cell Phone No."
                                                value="${buyer?.cellPhoneNumber ? formatPhoneNumber(buyer?.cellPhoneNumber) : ''}"
                                                disabled></td>
                                    </tr>
                                </table>
                            </div>
                        `
                    }
                });
                buyerContainer.innerHTML = buyerDetails.join("\n");
            }
        }
        else if (clientType === 'BuyerAgents') {
            const buyerAgentsDivFromTR = document.getElementById('buyerAgentsContainer');
            if (clientArray && buyerAgentsDivFromTR) {
                buyerAgentsDivFromTR.innerHTML = clientArray?.map((record) => {
                    let agentId;
                    if (record?.personId?.length === 24) {
                        agentId = record?.personId;
                    } else if (record?.key) {
                        agentId = record?.key;
                    }
                    return `
                        <table class="tableWidth2">
                            <tr>
                                <td class="rightTable">Brokerage Name:</td>
                                <td><input type="text" name="${agentId}_firmName" value="${record?.firmName || ''}"
                                        placeholder="Selling Company Name" disabled></td>
                            </tr>
                            <tr>
                                <td>Brokerage ID:</td>
                                <td><input type="text" name="${agentId}_firmLicense" value="${record?.firmLicense || ''}"
                                        placeholder="Buyer Agent Office ID/License No." disabled></td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>
                                    <span class="grow-with-content">
                                        <textarea class="ta-like grow" name="${agentId}_address" placeholder="Address" disabled>${record?.address || ''}</textarea>
                                    </span>
                                <!-- <input type="text" name="${agentId}_address" value="${record?.address || ''}" placeholder="Address" disabled> -->
                                </td>

                            </tr>
                            <tr>
                                <td>Agent:</td>
                                <td><input type="text" name="${agentId}_fullName" placeholder="Buyer Agent Name" value="${record?.fullName || ''}"
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Agent ID:</td>
                                <td><input type="text" name="${agentId}_license" value="${record?.license || ''}"
                                        placeholder="Buyer Agent ID/License No." disabled></td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td><input class="formatPhoneNumber" type="text"
                                        name="${agentId}_phoneNumber" placeholder="Buyer Agent Phone No." value="${record?.phoneNumber || ''}"
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Fax:</td>
                                <td><input class="formatPhoneNumber" type="text"
                                        name="${agentId}_faxNumber" value="${record?.faxNumber || ''}" placeholder="Buyer Agent Fax No."
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td><input type="text" name="${agentId}_email" value="${record?.email || ''}"
                                        placeholder="Buyer Agent Email" disabled></td>
                            </tr>
                        </table>
                    `
                }).join(`<div class="sectionTitle" style="margin: 5px 0;"></div>`)
            }
        }
        else if (clientType === 'SellerAgents') {
            const sellerAgentsDivFromTR = document.getElementById('sellerAgentsContainer');
            if (clientArray && sellerAgentsDivFromTR) {
                sellerAgentsDivFromTR.innerHTML = clientArray?.map((record) => {
                    return `
                        <table class="tableWidth2">
                            <tr>
                                <td class="leftTable">Brokerage Name:</td>
                                <td><input type="text" name="${record?.personId}_firmName" value="${record?.firmName || ''}"
                                        placeholder="Listing Company Name" disabled></td>
                            </tr>
                            <tr>
                                <td>Brokerage ID:</td>
                                <td><input type="text" name="${record?.personId}_firmLicense" value="${record?.firmLicense || ''}"
                                        placeholder="Seller Agent Office ID/License No." disabled></td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>
                                    <span class="grow-with-content">
                                        <textarea class="ta-like grow" name="${record?.personId}_address" placeholder="Address" disabled>${record?.address || ''}</textarea>
                                    </span>
                                <!-- <input type="text" name="${record?.personId}_address" placeholder="Address" value="${record?.address || ''}" disabled> -->
                                </td>
                            </tr>
                            <tr>
                                <td>Agent:</td>
                                <td><input type="text" name="${record?.personId}_fullName" value="${record?.fullName || ''}"
                                        placeholder="Seller Agent Name" disabled></td>
                            </tr>
                            <tr>
                                <td>Agent ID:</td>
                                <td><input type="text" name="${record?.personId}_license" value="${record?.license || ''}"
                                        placeholder="Seller Agent ID/License No." disabled></td>
                            </tr>
                            <tr>
                                <td>Phone:</td>
                                <td><input class="formatPhoneNumber" type="text"
                                        name="${record?.personId}_phoneNumber" value="${record?.phoneNumber || ''}" placeholder="Seller Agent Phone No."
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Fax:</td>
                                <td><input class="formatPhoneNumber" type="text"
                                        name="${record?.personId}_faxNumber" value="${record?.faxNumber || ''}" placeholder="Seller Agent faxNumber No."
                                        disabled></td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td><input type="text" name="${record?.personId}_email" value="${record?.email || ''}"
                                        placeholder="Seller Agent Email" disabled></td>
                            </tr>
                        </table>
                    `
                }).join(`<div class="sectionTitle" style="margin: 5px 0;"></div>`)
            }
        }
    }
}