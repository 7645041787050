export const ndaConstants = {
  POST_NDA_SUCCESS: "POST_NDA_SUCCESS",
  POST_NDA_FAILURE: "POST_NDA_FAILURE",

  GET_NDA_SUCCESS: "GET_NDA_SUCCESS",
  GET_NDA_FAILURE: "GET_NDA_FAILURE",

  EDIT_NDA_SUCCESS: "EDIT_NDA_SUCCESS",
  EDIT_NDA_FAILURE: "EDIT_NDA_FAILURE",

  DELETE_NDA_SUCCESS: "DELETE_NDA_SUCCESS",
  DELETE_NDA_FAILURE: "DELETE_NDA_FAILURE",

  NDA_JSX_SUCCESS: "NDA_JSX_SUCCESS",
  NDA_JSX_FAILURE: "NDA_JSX_FAILURE",

  IMAGE_UPLOAD_PDF_SUCCESS: "IMAGE_UPLOAD_PDF_SUCCESS",
  IMAGE_UPLOAD_PDF_FAILURE: "IMAGE_UPLOAD_PDF_FAILURE",

  SUBMIT_EMAIL_SUCCESS: "SUBMIT_EMAIL_SUCCESS",
  SUBMIT_EMAIL_FAILURE: "SUBMIT_EMAIL_FAILURE",

  PDF_UPLOAD_PDF_SUCCESS: "PDF_UPLOAD_PDF_SUCCESS",
  PDF_UPLOAD_PDF_FAILURE: "PDF_UPLOAD_PDF_FAILURE",
};
