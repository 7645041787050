export const appConstants = {
  UPDATE_APP_TITLE: "UPDATE_APP_TITLE",

  HELP_DATA_LOADING: "HELP_DATA_LOADING",
  HELP_DATA_SUCCESS: "HELP_DATA_SUCCESS",
  HELP_DATA_ERROR: "HELP_DATA_ERROR",

  HELP_GLOBAL_SEARCH_LOADING: "HELP_GLOBAL_SEARCH_LOADING",
  HELP_GLOBAL_SEARCH_SUCCESS: "HELP_GLOBAL_SEARCH_SUCCESS",
  HELP_GLOBAL_SEARCH_ERROR: "HELP_GLOBAL_SEARCH_ERROR",
};
