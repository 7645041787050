import { bugListingPageConstants } from "./action-types";
import { bugDataApi } from "../utils/api";
import { message } from "antd";

/**
 * All Actions for bug Listing Page
 */

// with access token
const bugListingError = (error) => {
  return {
    type: bugListingPageConstants.BUG_LISTING_PAGE_FAILURE,
    bugLlistingError: error,
   bugLlistLoading: true,
  };
};
const bugListingLoading = () => {
  return {
    type: bugListingPageConstants.BUG_LISTING_PAGE_LOADING,
  bugLlistLoading: true,
  };
};
const bugListingSuccess = (response) => {
  return {
    type: bugListingPageConstants.BUG_LISTING_PAGE_SUCCESS,
    bugLlistingData: response.data.info.data,
    totalRecords:response.data.info.totalRecords,
    bugLlistLoading: false,
  };
};

const getBugListingDataWith = (query) => {
  return (dispatch, getState) => {
    dispatch(bugListingLoading())
    bugDataApi
      .buglistingPageLoadRequest(query)
      .then((response) => {
        dispatch(bugListingSuccess(response));
      })
      .catch((error) => {
        dispatch(bugListingError(error));
      });
  };
};

//update status
const bugStatusError = (error) => {
  return {
    type: bugListingPageConstants.BUG_STATUS_FAILURE,
    bugStatusError: error,
    bugStatusLoading: true,
  };
};

const bugStatusSuccess = (response) => {
  return {
    type: bugListingPageConstants.BUG_STATUS__SUCCESS,
    bugStatusData: response.data.info,
    bugStatusLoading: false,
  };
};

const statusChange = (id, status) => {
  return (dispatch, getState) => {
    bugDataApi
      .bugStatusRequest(id, status)
      .then((response) => {
        dispatch(bugStatusSuccess(response));
        dispatch(getBugListingDataWith());
      })
      .catch((error) => {
        dispatch(bugStatusError(error));
      });
  };
};

// bugPostDelete
const bugPostDeleteError = (error) => {
 
  return {
    type: bugListingPageConstants.POST_BUG_DELETE_FAILURE,
    bugPostDeleteError: error,
    bugPostDeleteLoading: false,
  };
};

const bugPostDeleteSuccess = (response) => {
  return {
    type: bugListingPageConstants.POST_BUG_DELETE_SUCCESS,
    bugPostDeleteData: response.data.info,
    bugPostDeleteLoading: false,
  };
};

const bugPostDelete = (id) => {
  return (dispatch, getState) => {
       bugDataApi
      .bugPostDeleteRequest(id)
      .then((response) => {
        dispatch(bugPostDeleteSuccess(response));
        message.success("Deleted Sucessfully")
        dispatch(getBugListingDataWith());
        // dispatch(getBugListingDatayourBugs());
        // dispatch(sendNotification(response));
      })
      .catch((error) => {
       message.error(error.response.data.message);
        dispatch(bugPostDeleteError(error));
        
      });
  };
};

const resetListingData = () => ({
  type: bugListingPageConstants.RESET_LISTING_PAGE,
});
const clearImageRest = () => ({
  type: bugListingPageConstants.RESET_IMAGE_UPLOAD_PAGE,
});

const logOut = () => ({
  type: bugListingPageConstants.LOGOUT_PAGE,
});

// resetbug post data
const resetBugPostData = () => ({
  type: bugListingPageConstants.RESET_BUG,
});

// Upload Image
const uploadImageError = (error) => {
  return {
    type: bugListingPageConstants.UPLOAD_IMAGE_FAILURE,
    uploadImageError: error,
    uploadImageLoading: false,
  };
};

const uploadImageSuccess = (response) => {
  return {
    type: bugListingPageConstants.UPLOAD_IMAGE_SUCCESS,
    uploadImageData: response.data.info.docUrl
      ? response.data.info.docUrl
      : response.data.info.fileData.fileUrl,
    uploadImageLoading: false,
  };
};

const uploadImageforProfile = (file, origin) => {
  return (dispatch, getState) => {
    bugDataApi
      .uploadImageforProfileRequest(file, origin)
      .then((response) => {
        dispatch(uploadImageSuccess(response));
      })
      .catch((error) => {
        dispatch(uploadImageError(error));
      });
  };
};
// <--------------bug report comment--->
const bugReportCommentError = (error) => {
  return {
    type: bugListingPageConstants.BUG_REPORT_COMMENT_FAILURE,
    bugReportCommentError: error,
    bugReportCommentLoading: false,
  };
};
const bugeportCommentLoading = (response) => {
  return {
    type: bugListingPageConstants.BUG_REPORT_COMMENT_LOADING,
    bugReportCommentLoading:true,
  };
};
const bugeportCommentSuccess = (response) => {
  return {
    type: bugListingPageConstants.BUG_REPORT_COMMENT_SUCCESS,
    bugReportCommentData: response.data.info.data,
    bugReportCommentLoading: false,
  };
};

const getBugReportComment = (id) => {
  return (dispatch, getState) => {
    bugeportCommentLoading()
    bugDataApi
      .bugreportcomment(id)
      .then((response) => {
        dispatch(bugeportCommentSuccess(response));
      })
      .catch((error) => {
        dispatch(bugReportCommentError(error));
      });
  };
};
const addCommentSuccess = (response) => {
  return {
    type: bugListingPageConstants.BUG_COMMENT__SUCCESS,
    addCommentData: response.data.info,
    addCommentLoading: false,
  };
};
const addCommentError = (error) => {
  return {
    type: bugListingPageConstants.BUG_COMMENT_FAILURE,
    addCommentError: error,
    addCommentLoading: true,
  };
};
const addCommentOnBugs = (id, status) => {
  return (dispatch, getState) => {
    bugDataApi
      .bugCommentRequest(id, status)
      .then((response) => {
        dispatch(addCommentSuccess(response));
        dispatch(getBugReportComment(id))
      })
      .catch((error) => {
        dispatch(addCommentError(error));
      });
  };
};

export const bugReportHandle = {
  getBugListingDataWith,
  getBugReportComment,
  bugPostDelete,
  uploadImageforProfile,
  resetListingData,
  logOut,
  clearImageRest,
  resetBugPostData,
  statusChange,
  addCommentOnBugs
};
