import { cmsConstants } from "./action-types";
import { personDataApi } from "../utils/api";
import { message } from "antd";
/**
 * All Actions for contract Page
 */

/**
 * To update Navbar title action
 * @param {string}
 */

const personLoading = () => {
  return {
    type: cmsConstants.GET_PERSONS_LOADING,
  };
};
const personError = (error) => {
  return {
    type: cmsConstants.GET_PERSONS_FAILURE,
    personError: error,
    personLoading: false,
  };
};

const personSuccess = (response) => {
  return {
    type: cmsConstants.GET_PERSONS_SUCCESS,
    personData: response.data.info.data,
    totalItems: response.data.info.totalRecords,
    personLoading: false,
  };
};

const getPersonsData = (query,dataIndex) => {
  return (dispatch, getState) => {
    dispatch(personLoading());
    personDataApi
      .personDataRequest(query,dataIndex)
      .then((response) => {
        dispatch(personSuccess(response));
      })
      .catch((error) => {
        dispatch(personError(error));
      });
  };
};

const changeStatusError = (error) => {
  return {
    type: cmsConstants.USER_CHANGE_STATUS_FAILURE,
    changeStatusError: error,
    changeStatusLoading: true,
  };
};

const changeStatusSuccess = (response) => {
  return {
    type: cmsConstants.USER_CHANGE_STATUS_SUCCESS,
    changeStatusData: response.data.info,
    changeStatusLoading: false,
  };
};

const changeStatusData = (id, status) => {
  return (dispatch, getState) => {
    personDataApi
      .changeStatusRequest(id, status)
      .then((response) => {
        dispatch(changeStatusSuccess(response));
        dispatch(getPersonsData());
      })
      .catch((error) => {
        dispatch(changeStatusError(error));
      });
  };
};

const verifyEmailDataError = (error) => {
  return {
    type: cmsConstants.USER_EMAIL_VERIFY_FAILURE,
    verifyEmailError: error,
    verifyEmailLoading: false,
  };
};

const verifyEmailDataSuccess = (response) => {
  return {
    type: cmsConstants.USER_EMAIL_VERIFY_SUCCESS,
    verifyEmailData: response.data.info,
    verifyEmailLoading: false,
  };
};

const sendOrVerifyEmail = ({id, payload, currentPage}) => {
  return (dispatch, getState) => {
    personDataApi
      .sendOrVerifyEmailRequest({id, payload})
      .then((response) => {
        if (payload.isEmailVerified) dispatch(verifyEmailDataSuccess(response));
        if (payload.sendEmailVerificationLink) {
          message.success('Verification Email Sent Successfully!');
        } else if (payload.email) {
          message.success('Email Updated Successfully!');
        }
         let query={page:currentPage||1}
        dispatch(getPersonsData(query));
      })
      .catch((error) => {
        if (payload.isEmailVerified) dispatch(verifyEmailDataError(error));
        if (payload.sendEmailVerificationLink) {
          message.error(error?.response?.data?.message || "We encountered an issue while sending verification email. Please try after some time.");
        } else if(payload.email) {
          message.error(error?.response?.data?.message || "We encountered an issue while updating email. Please try after some time.");
        }
      });
  };
};
  //handle subcription methods
  const handleSubcriptionLoading = () => {
    return {
      type: cmsConstants.HANDLE_SUBCRIPTION_LOADING,
      subscriptionLoading:true,
    };
  };
  const handleSubcriptionError = (error) => {
    return {
      type: cmsConstants.HANDLE_SUBSCRIPTION_ERROR,
      subscriptionError: error,
      subscriptionLoading: false,
    };
  };
  const handleSubcriptionSuccess = (response) => {
    message.success(response?.data?.info?.message);
    return {
      type: cmsConstants.HANDLE_SUBSCRIPTION_SUCCESS,
      subcriptionData: response.data.info.data,
      subscriptionLoading: false,
    };
  };
  const handleSubcription = (data,personId) => {
    return (dispatch, getState) => {
      dispatch(handleSubcriptionLoading());
      personDataApi
        .handleSubcriptionRequest(data,personId)
        .then((response) => {
          dispatch(handleSubcriptionSuccess(response));
          dispatch(getPersonsData());
        })
        .catch((error) => {
          dispatch(handleSubcriptionError("error"));
          message.error("Permission Not Allowed!");
        });
    };
  };


  const deleteSubcriptionLoading = () => {
    return {
      type: cmsConstants.DELETE_SUBCRIPTION_LOADING,
      subscriptionLoading:true,
    };
  };
  const deleteSubcriptionError = (error) => {
    return {
      type: cmsConstants.DELETE_SUBSCRIPTION_ERROR,
      subscriptionError: error,
      subscriptionLoading: false,
    };
  };
  const deleteSubcriptionSuccess = (response) => {
    message.success(response?.data?.info?.message)
    return {
      type: cmsConstants.DELETE_SUBSCRIPTION_SUCCESS,
      subscriptionLoading: false,
    };
  };
  const deleteSubcription = (data) => {
    return (dispatch, getState) => {
      dispatch(deleteSubcriptionLoading());
      personDataApi
        .deleteSubcriptionRequest(data)
        .then((response) => {
          dispatch(deleteSubcriptionSuccess(response));
          dispatch(getPersonsData());
        })
        .catch((error) => {
          dispatch(deleteSubcriptionError("error"));
          message.error("Permission Not Allowed!");
        });
    };
  };

  const editSubcriptionLoading = () => {
    return {
      type: cmsConstants.EDIT_SUBCRIPTION_LOADING,
      subscriptionLoading:true,
    };
  };
  const editSubcriptionError = (error) => {
    return {
      type: cmsConstants.EDIT_SUBSCRIPTION_ERROR,
      subscriptionError: error,
      subscriptionLoading: false,
    };
  };
  const editSubcriptionSuccess = (response) => {
    message.success(response?.data?.info?.message);
    return {
      type: cmsConstants.EDIT_SUBSCRIPTION_SUCCESS,
      subscriptionLoading: false,
    };
  };
  const editSubcription = (data,planId) => {
    return (dispatch, getState) => {
      dispatch(editSubcriptionLoading());
      personDataApi
        .updateSubscriptionRequest(data,planId)
        .then((response) => {
          dispatch(editSubcriptionSuccess(response));
          dispatch(getPersonsData());
        })
        .catch((error) => {
          dispatch(editSubcriptionError("error"));
          message.error("Permission Not Allowed!");
        });
    };
  };


  const getAssociationDataLoading = () => {
    return {
      type:cmsConstants.ASSOCIATION_LOADING,
    }
  }

  const getAssociationDataError = (err) => {
    return {
      type:cmsConstants.ASSOCIATION_ERROR,
      associationDataError : err,
    }
  }

  const getAssociationDataSuccess = (data) => {
    return {
      type:cmsConstants.ASSOCIATION_SUCCESS,
      associationData : data,
    }
  }

const getAssociationData = (data) => {
  return (dispatch, getState) => {
    dispatch(getAssociationDataLoading())
    personDataApi.getAssociationData(data)
      .then((response) => {
        dispatch(getAssociationDataSuccess(response.data.info));
      })
      .catch((err) => dispatch(getAssociationDataError(err)))
  }
}

 const addAssociationLoading =()=>{
  return{
      type : cmsConstants.ADD_ASSOCIATION_LOADING ,
      mailLoading:true,
      mailError:false,
  }
};

const addAssociationError =()=>{
  return{
      type : cmsConstants.ADD_ASSOCIATION_ERROR,
      mailLoading:false,
      mailError:true,
  }
};

const addAssociationSuccess =(data,handlecloseSendModal)=>{
  message.success(data?.data?.info?.message);
  if(handlecloseSendModal){
    handlecloseSendModal();
  }
  return{
      type : cmsConstants.ADD_ASSOCIATION_SUCCESS,
      mailLoading:false,
      mailError:false,
  }
};

const addAssociation = (data,planCode,handlecloseSendModal) => {
  return (dispatch) => {
    dispatch(addAssociationLoading());
    personDataApi
      .addAssociationRequest(data,planCode)
      .then((response) => {
        dispatch(addAssociationSuccess(response,handlecloseSendModal));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(addAssociationError(error.response.data));
        }
      });
  };
};

const viewSignupLinkError =(err)=>{
  return{
      type : cmsConstants.VIEW_ASSOCIATION_ERROR,
      viewSignupLinkDataError:err
  }
};


const viewSignupLinkLoading =()=>{
  return{
      type : cmsConstants.VIEW_ASSOCIATION_LOADING ,
  }
};

const viewSignupLinkSuccess =(data)=>{
  return{
      type : cmsConstants.VIEW_ASSOCIATION_SUCCESS,
      viewSignupLinkData: data
  }
};


const getViewSignupLinkData = () => {
  return (dispatch) => {
    dispatch(viewSignupLinkLoading());
    personDataApi
      .viewSignupLinkDataRequest()
      .then((response) => {
        dispatch(viewSignupLinkSuccess(response?.data?.info));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(viewSignupLinkError(error.response.data));
        }
      });
  };
};

const handleToggleFunc = (data) => {
  return (dispatch, getState) => {
    dispatch(handleToggleLoading(true));
    personDataApi
      .handleToggleRequest(data)
      .then((response) => {
        message.success(response?.data?.info?.message);
        dispatch(handleToggleLoading(false));
      })
      .catch((error) => {
          const errorMessage = error?.response?.data?.message || "Error occured while updating association!";
          message.error(errorMessage);
          dispatch(handleToggleLoading(false));
          setTimeout(()=>{
            window.location.reload();
          }, 1000)
      });
  };
};

const handleToggleLoading = (value) => {
  return {
    type: cmsConstants.TOGGLE_LOADING,
    toggleLoading: value,
  };
};

const loginAnotherAccount = (data) => {
  return (dispatch) => {
    dispatch(handleToggleLoading(true));
    personDataApi
      .loginToDifferentAccount(data)
      .then((response) => {
        dispatch(handleToggleLoading(false));
        let fileUrl = process.env.REACT_APP_WEB_BASE_URL;
        window.open(`${fileUrl}?auth=${response.data.info.accessToken}`, "_blank").focus();
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue accessing the account. Please try again later!");
        dispatch(handleToggleLoading(false));
      });
  };
};

export const cmsDataHandle = {
  getPersonsData,
  changeStatusData,
  sendOrVerifyEmail,
  handleSubcription,
  getAssociationData,
  addAssociation,
  getViewSignupLinkData,
  handleToggleFunc,
  deleteSubcription,
  editSubcription,
  loginAnotherAccount
};
