import dayjs from "dayjs";
import { dateData, isPublicHoliday } from "../../Common/commondata/commonData";
import setDayOfWeek from "./helpers/setDayOfWeek";
let isPublicHolidaySet = new Set(isPublicHoliday.map((date) => dayjs(date).format("YYYY-MM-DD")));

const holidayCheck = (startDate, MECDate, dateData, index, iterator, currentUrlId, forMEC) => {
  const isHoliday = isPublicHolidaySet.has(dayjs(startDate).format("YYYY-MM-DD"));
  if (dayjs(startDate).day() === 0 || dayjs(startDate).day() === 6 || isHoliday) {
    const newDay = dayjs(startDate).add(1, "day").format("YYYY-MM-DD");
    return holidayCheck(newDay, MECDate, dateData, index, iterator + 1, currentUrlId, forMEC);
  } else {
    let objectFordateTableList={}
    if (!forMEC) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      objectFordateTableList = {
        type: 'date',
        value:formattedStartDate
      };
    }
    return {
      objectFordateTableList,
      MEC: startDate,
    };
  }
};
const autoFillDateLogic = (currentUrlId, source, specificData) => {
  let calculationDate;
  if (source === "Initial") {
    calculationDate = dayjs().add(1, "day").format("YYYY/MM/DD");
  } else {
    let OrignalContractDate = JSON.parse(localStorage.getItem(currentUrlId))?.OrignalContractDate;
    calculationDate = dayjs(OrignalContractDate).add(1, "day").format("YYYY/MM/DD");
  }
  const { MEC } = holidayCheck(calculationDate, null, null, null, 0, null, true);
  const MECDate = new Date(MEC.replace(/-/g, "/"));
  // const dateTable = {};
  const ClosingDate = JSON.parse(localStorage.getItem(currentUrlId))?.ClosingDate ||"";
  const localDataUpdates = {};
  for (let index = 0; index < dateData.length; index++) {
    if (dateData[index].type === "date") {
      const diff = dateData[index].differnce;
      if (diff) {
        const getDigits = diff.split("-");
        const getDateforevent = Math.ceil((Number(getDigits[0]) + Number(getDigits[1])) / 2);
        const startDate = dayjs(MECDate).add(diff.indexOf("-") > 0 ? getDateforevent : Number(diff), "day");
        const { objectFordateTableList } = features.holidayCheck(startDate, MECDate, dateData, index, 0, currentUrlId);
          localDataUpdates[dateData[index].keyName] =dateData[index].keyName==="PossessionDate"? typeof ClosingDate ==="object" ? ClosingDate : {type:"date", value:ClosingDate} : objectFordateTableList;
        if (source === "ToggleCheckbox") {
          let dateInputElement = document.querySelectorAll("input[name='" + dateData[index].keyName + "']");
          dateInputElement.forEach((el) => {
            el.value = objectFordateTableList?.value;
          });
          specificData[dateData[index].keyName] =dateData[index].keyName==="PossessionDate"? typeof ClosingDate ==="object" ? ClosingDate : {type:"date", value:ClosingDate} : objectFordateTableList;
          setDayOfWeek({
            name: dateData[index].keyName,
            value:dateData[index].keyName==="PossessionDate"? (ClosingDate?.value || ClosingDate) : objectFordateTableList?.value,
          });
        }
      }
    } else {
      localDataUpdates[dateData[index].keyName] = dateData[index].time;
      if (source === "ToggleCheckbox") {
        let dateInputElement = document.querySelectorAll("input[name='" + dateData[index].keyName + "']");
        dateInputElement.forEach((el) => {
          el.value = dateData[index].time;
        });
        specificData[dateData[index].keyName] = dateData[index].time;
      }
    
    }
  }

  const localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
  Object.assign(localData, localDataUpdates); // modify the existing object in-place and saves memory
  localStorage.setItem(currentUrlId, JSON.stringify(localData));
  const persistKey = JSON.parse(localStorage.getItem(currentUrlId)) || {};
  localStorage.setItem(currentUrlId, JSON.stringify(persistKey || {}));
};

export const features = {
  holidayCheck,
  autoFillDateLogic,
};
