import axios from "axios";
import dotEnv from "dotenv";
import {listingOrClientContractTypes } from "../../Common/commondata/commonData";
import { contractFooter,} from "../../Common/commondata/commonData";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;
const adminApiURL = process.env.REACT_APP_ADMIN_BASE_URL;


//validate contract
const validateFormRequest = (currentUrlId, accessTokan, contractId, rootDocData) => {
  const persistKey = JSON.parse(localStorage.getItem(currentUrlId)) || {};
  const { openFor, signatureInfo } = rootDocData || {};
  const buyerAgentIds = persistKey?.BuyerAgents?.filter((agent) => agent?.personId?.length === 24).map((agent) => agent.personId) || [];
  const sellerAgentIds = persistKey?.SellerAgents?.filter((agent) => agent?.personId?.length === 24).map((agent) => agent.personId) || [];
  const buyerIds = (openFor === "BUYER_AGENT" || openFor === "BUYER") ? persistKey.Buyers ||[]: [];
  const sellerIds = (openFor === "SELLER_AGENT" || openFor === 'SELLER') ? persistKey.Sellers ||[] : [];
  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
    data: {
      contractId: contractId,
      transactionData: persistKey,
      openFor: openFor,
      signatureInfo: signatureInfo,
      buyerIds: buyerIds,
      sellerIds: sellerIds,
      ...(openFor === "BUYER_AGENT" && {buyerAgentIds}),
      ...(openFor === "SELLER_AGENT" && {sellerAgentIds})
    },
  };
  // delete config.data.transactionData["RTDInitiator"];
  delete config.data.transactionData["contractId"];
  delete config.data.transactionData["redirectTo"];
  delete config.data.transactionData["documentId"];
  delete config.data.transactionData["openFor"];
  delete config.data.transactionData["offerId"];
  delete config.data.transactionData["docType"];
  // delete config.data.transactionData["contractType"]
  delete config.data.transactionData["key"];
  delete config.data.transactionData["templateId"];
  delete config.data.transactionData["onlyTemplateCreation"];
  delete config.data.transactionData["onlyTemplateview"];
  delete config.data.transactionData["templateOriginTemplateSection"];
  delete config.data.transactionData["templateFlag"];
  return axios(`${apiURL}/validatingDocument`, config);
};

// Auto save contract
const autoSaveContractRequest = (currentUrlId, accessToken, rootDocData, signatureBlock, valueObj, templateInfo = {}) => {
  let localData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
  delete localData["addClauseData"];
  delete localData["addDocumentData"];
  const url = rootDocData?.builtForSection === "TEMPLATE" || templateInfo?.createTemplate ? `${apiURL}/template` : `${apiURL}/offerDocument/autoSave`;
  const method = rootDocData?.builtForSection === "TEMPLATE" || templateInfo?.createTemplate ? "PUT" : "POST";
  let buildPayload = {};
  if (rootDocData?.builtForSection === "TEMPLATE") {
    buildPayload = {
      ...(currentUrlId && !rootDocData?.documentId ? { urlId: currentUrlId } : {}),
      ...(rootDocData?.documentId ? { documentId: rootDocData?.documentId } : {}),
      ...(rootDocData?.documentId ? { transactionData: valueObj || {} } : localData ? { transactionData: localData } : {}),
      ...(!rootDocData?.documentId &&
        rootDocData["templateName"] && {
          templateName: rootDocData["templateName"],
        }),
      ...(!rootDocData?.documentId &&
        rootDocData["templateDescription"] && {
          templateDescription: rootDocData["templateDescription"],
        }),
    };
  } else {
    //for sellerIds and buyerIds
    let spreadClients = {};
    const bothIdsPresent = !!(rootDocData?.offerId && rootDocData?.documentId);
    const documentIdPresent = !!rootDocData?.documentId;
    const allData = documentIdPresent ? (valueObj || {}) : localData;
    if (Array.isArray(allData?.Buyers)) {
      const buyerIds = allData.Buyers;
      spreadClients = { ...spreadClients, buyerIds };
    }
    if (Array.isArray(allData?.Sellers)) {
      const sellerIds = allData.Sellers;
      spreadClients = { ...spreadClients, sellerIds };
    }
    if (Array.isArray(allData?.BuyerAgents)) {
      const buyerAgentIds = allData?.BuyerAgents.filter((agent) => agent?.personId?.length === 24).map((agent) => agent.personId);
      spreadClients = { ...spreadClients, buyerAgentIds };
    }
    if (Array.isArray(allData?.SellerAgents)) {
      const sellerAgentIds = allData?.SellerAgents.filter((agent) => agent?.personId?.length === 24).map((agent) => agent.personId);
      spreadClients = { ...spreadClients, sellerAgentIds };
    }

    buildPayload = {
      ...(currentUrlId ? { urlId: currentUrlId } : {}),
      ...(templateInfo.createTemplate ? { transactionData: localData } : bothIdsPresent || (listingOrClientContractTypes.includes(rootDocData?.contractType) && documentIdPresent) ? { transactionData: valueObj || {} } : localData ? { transactionData: localData } : {}),
      ...(signatureBlock ? { signatureBlock } : {}),
      ...spreadClients,
      ...templateInfo,
    };
  }

  const config = { method, headers: { accept: "application/json", authorization: `bearer ${accessToken}` }, data: buildPayload };

  return axios(url, config);
};

const getAddClausesDataRequest = (authKey) => {
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
      authorization: `bearer ${authKey}`,
    },
  };
  return axios(`${apiURL}/docAndClause/CLAUSE`, config);
};

const getAddDocumentsDataRequest = (authKey) => {
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
      authorization: `bearer ${authKey}`,
    },
  };
  return axios(`${apiURL}/docAndClause/DOCUMENT`, config);
};

const resetSingatureApi = (documentId, roleType, accessToken) => {
  return axios.put(
    `${apiURL}/resetSignature/${documentId}`,
    {
      roleType,
    },
    {
      headers: {
        authorization: `bearer ${accessToken}`,
        accept: "application/json",
      },
    }
  );
};
const copyAsDraftOfferApi = (payloadData, accessToken) => {
  return axios.post(
    `${apiURL}/copyToDraft`,
    payloadData,
    {
      headers: {
        authorization: `bearer ${accessToken}`,
        accept: "application/json",
      },
    }
  );
};

const invalidateDocumentApi = (documentId, accessToken) => {
  return axios.put(
    `${apiURL}/invalidateDocument/${documentId}`,
    {
      invalidated: true,
    },
    {
      headers: {
        authorization: `bearer ${accessToken}`,
        accept: "application/json",
      },
    }
  );
};

const transactionLaneApi = (data, role, accessToken) => {
  return axios.get(`${apiURL}/RTD/${data}?role=${role}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const getClientDetailsApi = (Buyers=[], accessToken) => {
  const fetchPersonData = (id) => {
    return axios.get(`${apiURL}/personDetailedData/${id}`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    });
  };

  return Promise.all(
    Buyers.map((element) => {
      if (element.isCorp) {
        return Promise.all(
          element.signers?.map((el) =>
            el._id ? fetchPersonData(el._id) : Promise.resolve(null)
          )
        );
      } else {
        return element._id ? fetchPersonData(element._id) : Promise.resolve(null);
      }
    })
  );
};

const transactionReportApi = (offerId, accessToken) => {
  return axios.get(`${apiURL}/transactionReport/${offerId}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const downloadPdfApi = (accessToken, urlId,  rootDocData= {}) => {
  const {contractId } = rootDocData;
  let payloadData =
  {
    "url": "https://qa.admin.resure.realestate/contractDocument/65a10700a8c252e805a4b5c3",
  "options": {
      "addPageFooterToPdf": true,
      "addPageFooterToPdfOptions": {
          "includeHrAbove": true,
          "leftText": "",
          "includePageNumberRight": true,
          "includeTotalPagesRight": true,
          "includeResureAttribution": true
      },
      "margins": {
          "top": ".5in",
          "bottom": "1in",
          "left": ".5in",
          "right": ".5in"
      }
  }
}
  payloadData.url = `${adminApiURL}/contractDocument/${urlId}`;
  payloadData.options.addPageFooterToPdfOptions.leftText = contractId?._id ?contractFooter[contractId?._id]:contractFooter[contractId] || "";
  
  if (!payloadData.options.addPageFooterToPdfOptions.leftText) {
    delete payloadData.options.addPageFooterToPdfOptions.leftText;
  }
  return axios.post(`${apiURL}/download/fromurl`, { ...payloadData }, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    responseType: 'blob' 
  });
};

const deleteTitleCompanyData=(id,contractAuthKey)=>{
  return axios.delete(`${apiURL}/clearTitleOrderFormData?offerId=${id}`,{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${contractAuthKey}`,
  },
  })
}
const sendMultipleDocRequest = (data,contractAuthKey) => {

  return axios.post(`${apiURL}/sendMultipleDocs`,{...data},{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${contractAuthKey}`,
  },
  })
}

const templateDataRequest = ({ query = {}, accessToken = '' }) => {
  return axios.get(`${apiURL}/templates`, {
    params: { ...query },
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`, 
    },
  });
};

export const submitDataApi = {
  validateFormRequest,
  autoSaveContractRequest,
  getAddClausesDataRequest,
  getAddDocumentsDataRequest,
  transactionLaneApi,
  getClientDetailsApi,
  transactionReportApi,
  resetSingatureApi,
  copyAsDraftOfferApi,
  invalidateDocumentApi,
  downloadPdfApi,
  deleteTitleCompanyData,
  sendMultipleDocRequest,
  templateDataRequest
};
