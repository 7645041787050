import { Button, Form, Input, Modal, Row, Tooltip, message } from 'antd'
import React, { useState } from 'react'
import { contactSheetActions } from '../state/actions'
import { PlusCircleFilled, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { ClientList } from '../../CreateOffer/components/ClientList';
import styles from "../styles/SendContactSheet.module.css"
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import SubjectLine from '../../Common/components/SubjectLine';

const { confirm } = Modal;

export default function SendContactSheet({ offerId, accessToken, setModalState, modalState }) {
    const dispatch = useDispatch();
    const [openClientList, setOpenClientList] = useState(false);
    const [addedClients, setAddedClients] = useState([]);
    const [form] = Form.useForm();
    const [subjectForm] = Form.useForm();
    const { contactSheetData } = useSelector((state) => state.contactSheetReducer);
    const [emailContents, setEmailContents] = useState('');

    const handleEmailContentChange = (content) => {
        setEmailContents(content);
    };
    
    const closeClientListModal = () => {
        setOpenClientList(false);
    }

    const addClient = (selectedClient) => {
        setAddedClients((prev) => {
            if ([...prev].includes(selectedClient.email)) {
                message.error("This Email is already selected.")
                return [...prev]
            }
            if (addedClients?.length === 2) {
                message.error("You can add maximum 2 emails.");
                return [...prev]
            }
            message.success("Email Added Successfully.");
            return [...prev, selectedClient.email];
        });
    }

    const handleAdd = (values) => {
        setAddedClients((prev) => {
            if ([...prev].includes(values.email)) {
                message.error("This Email is already added.")
                return [...prev]
            }
            if (addedClients?.length === 2) {
                message.error("You can add maximum 2 emails.");
                return [...prev]
            }
            message.success("Email Added Successfully.");
            form.resetFields();
            return [...prev, values.email];
        });
    };

    const handleRemove = (email) => {
        setAddedClients((prev) => prev.filter((clientEmail) => clientEmail !== email));
        message.success("Email Removed Successfully.");
    };

    const handleSend = () => {
        let enteredMsg = document.getElementById("emailContents")?.value || emailContents;
        const subjectVal = subjectForm?.getFieldValue("subject");
        let payload = {
            offerId,
            propertyAddress: contactSheetData?.propertyAddress || "",
            emailArray: addedClients,
            ...(enteredMsg && { emailMessage: enteredMsg }),
            openFor: modalState?.openFor,
            ...(subjectVal?.length ? {subject: subjectVal} : {}),
        }

        let paramData = {
            contactSheetId: contactSheetData?._id || contactSheetData?.existingContactSheetId,
        }
        dispatch(contactSheetActions.sendContactSheetLink({ paramData, payload, accessToken }));
        setModalState((prev) => ({ ...prev, openSendEmailModal: false, openFor: "" }));
    }

    const formats = [
        'header', 'bold', 'italic', 'underline', 'strike', 'list', 'indent', 'color', 'align', 'background'
    ];

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }]
        ]
    };

    return (
        <>
            <Modal
                title={<div className={styles.modalTitle} style={{ textAlign: "center", color: "#178DFA" }}>SEND CONTACT SHEET TO</div>}
                width={700}
                closable
                visible={true}
                okText="Send"
                okButtonProps={{ disabled: (addedClients?.length <= 0) }}
                onCancel={() => {
                    if (form.getFieldValue('email') || addedClients?.length) {
                        confirm({
                            title: "Warning!",
                            content: "Are you sure want to cancel sending this Contact Sheet?",
                            onCancel: () => { },
                            onOk: () => { setModalState((prev) => ({ ...prev, openSendEmailModal: false, openFor: "" })) },
                            okText: "Yes",
                            cancelText: "No"
                        })
                    } else {
                        setModalState((prev) => ({ ...prev, openSendEmailModal: false, openFor: "" }));
                    }
                }}
                onOk={handleSend}
            >
                <div className={styles.outerContainer}>
                    <div className={styles.selectEmails}>
                        <div className={styles.emailAddingOptionsContainer}>
                            <div className={styles.manualAddEmailBox}>
                                <Form
                                    form={form}
                                    layout="inline"
                                    scrollToFirstError
                                    onFinish={handleAdd}
                                >
                                    <div className={styles.formInnerContainer}>
                                        <div className={styles.formLabel}>EMAIL: </div>
                                        <Form.Item
                                            name="email"
                                            label={""}
                                            // rules={[{ type: 'email', message: "Not a valid Email!" }]}
                                            style={{ width: "50%" }}
                                        >
                                            <Input allowClear size='small' type='email' placeholder='Please Enter Email Here' />
                                        </Form.Item>
                                        <Form.Item shouldUpdate>
                                            {() => (
                                                <Button
                                                    size='small'
                                                    type="primary"
                                                    htmlType="submit"
                                                    disabled={!form.getFieldValue('email')}
                                                >
                                                    Add
                                                </Button>
                                            )}
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                            <div className={styles.openClientListBtn}>
                                <Tooltip title="Add Email" placement="top">
                                    <PlusCircleFilled style={{ cursor: "pointer" }} onClick={() => { setOpenClientList(true) }} />
                                </Tooltip>
                            </div>
                        </div>
                        <div className={styles.showSelectedEmails}>
                            <ul>
                                {addedClients?.length > 0 && addedClients.map((email) => {
                                    return <li key={email}>
                                        <a href={`mailto: ${email}`}>{email}</a>
                                        <span style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => handleRemove(email)}>
                                            <Tooltip title={"Delete Email Id"} placement="right">
                                                <DeleteOutlined style={{ color: '#178DFA' }} />
                                            </Tooltip>
                                        </span>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>

                    <SubjectLine form={subjectForm} source="CONTACT_SHEET"/>

                    <div className={styles.emailContents}>
                        <div style={{ fontWeight: "bold", padding: "5px" }}>Please Enter your message</div>
                        <div className={styles.emailSpace}>
                            <ReactQuill
                                value={emailContents}
                                onChange={handleEmailContentChange}
                                placeholder='Enter Message...'
                                formats={formats}
                                modules={modules}
                                className={styles.reactQuillComp}
                            />
                            {/* <textarea id='emailContents' name="emailContents" placeholder='Enter Message'></textarea> */}
                        </div>
                    </div>
                </div>
            </Modal>

            {/* MODAL FOR CLIENT LIST */}
            <Modal
                // title={<div style={{ textAlign: "center", color: "#178DFA" }}>SELECT CLIENT EMAIL</div>}
                width={500}
                style={{ top: "10vh" }}
                closable
                visible={openClientList}
                onCancel={() => setOpenClientList(false)}
                footer={null}
            >
                <ClientList onAdd={addClient} accessTokan={accessToken} handleCancel={closeClientListModal} source="ContactSheet" addedClients={addedClients} />
            </Modal>
        </>
    )
}
