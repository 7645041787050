import React, { useRef, useState, useEffect } from "react";
import { ndaHandle } from "../state/actions";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  Tag,
  Table,
  Input,
  List,
  Popconfirm,
  Avatar,
  Typography,
  Button,
  Form,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Loading } from "../../Common";
const { Text } = Typography;

const NDATable = ({}) => {
  const dispatch = useDispatch();
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchColumn] = useState("");
  const { getNDAData, getNDALoading ,totalRecords} = useSelector((state) => state.nda);
  const [data, setData] = useState(getNDAData);
  const [disableBtn, setdisableBtn] = useState(true);
  const text = "Are you sure to delete this Document?";
  useEffect(() => {
    if (getNDAData) {
      let originData = [];
      for (let i = 0; i < getNDAData.length; i++) {
        originData.push({
          key: getNDAData[i]._id,
          disclosurePartyDate: getNDAData[i].disclosureParty
            ? getNDAData[i].disclosureParty["date"]
            : "",
          disclosurePartyName: getNDAData[i].disclosureParty
            ? getNDAData[i].disclosureParty["name"]
            : "",
          disclosurePartyNameOfCompany: getNDAData[i].disclosureParty
            ? getNDAData[i].disclosureParty["nameOfCompany"]
            : "",
          disclosurePartyNameOfPerson: getNDAData[i].disclosureParty
            ? getNDAData[i].disclosureParty["nameOfPerson"]
            : "",
          disclosurePartyTitle: getNDAData[i].disclosureParty
            ? getNDAData[i].disclosureParty["title"]
            : "",
          disclosurePartysignature: getNDAData[i].disclosureParty
            ? getNDAData[i].disclosureParty["signature"]
            : "",
          receivingPartyDate: getNDAData[i].receivingParty
            ? getNDAData[i].receivingParty["date"]
            : "",
          receivingPartyName: getNDAData[i].receivingParty
            ? getNDAData[i].receivingParty["name"]
            : "",
          receivingPartyNameOfCompany: getNDAData[i].receivingParty
            ? getNDAData[i].receivingParty["nameOfCompany"]
            : "",
          receivingPartyNameOfPerson: getNDAData[i].receivingParty
            ? getNDAData[i].receivingParty["nameOfPerson"]
            : "",
          receivingPartyTitle: getNDAData[i].receivingParty
            ? getNDAData[i].receivingParty["title"]
            : "",
          receivingPartysignature:getNDAData[i].receivingParty
            ? getNDAData[i].receivingParty["signature"]
            : "",
        });
      }
      setData(originData);
    }
  }, [getNDAData]);
  const [form] = Form.useForm();
  const [currentQuery,setCurrentQuery]=useState({})

  useEffect(() => {
    let query = {
      ...currentQuery,
      page: currentPage,
      limit: 20,
    };
    dispatch(ndaHandle.getNDAList(query));
  }, [dispatch]);
  // const [editingKey, setEditingKey] = useState("");
  let editingKey = "";

  const isEditing = (record) => record.key === editingKey;
  let handleDelete = (data, flag) => {
    dispatch(ndaHandle.deleteNDA(data, flag));
  };

  const searchInput = useRef();
  let handleSearch = (selectedKeys, confirm, dataIndex) => {
   confirm();
   setsearchText(selectedKeys[0]);
    let query = {
      [dataIndex]:selectedKeys[0],
      page: 1,
      limit: 20,
    };
    dispatch(ndaHandle.getNDAList(query))
    setsearchColumn(dataIndex);
    setCurrentQuery(query);
    setCurrentPage(1);
  };

  const handleReset = (clearFilters, setSelectedKeys) => {
    clearFilters();
    setSelectedKeys("");
    let query = {
      page: 1,
      limit: 20,
    };
    dispatch(ndaHandle.getNDAList(query))
    setsearchText("");
  };

  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput.current = node;
        }}
        placeholder={dataIndex}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {handleSearch(selectedKeys, confirm, dataIndex);setdisableBtn(false);}}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false);}}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {handleReset(clearFilters, setSelectedKeys);setdisableBtn(true)}}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
    render: (text) =>{

      const first=text.firstName?text.firstName:""
      const last=text.lastName?text.lastName:""

    return searchedColumn === dataIndex ?(
      <>
      {dataIndex=="nameSearch"?<div style={{display:"flex"}}><div >
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={first}
        />
      </div>
      <div style={{
        marginLeft:"5px"}}>
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={last}
        />
      </div>
     </div>
     :<Highlighter
      highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text }
     />}
    </>):(
        <>
        {dataIndex ==="nameSearch"?<><div style={{display:"flex"}}><div>{text.firstName ? text.firstName : "-"}</div>
       <div style={{marginLeft:"5px"}}>
         {text.lastName ? text.lastName : ""}
       </div></div></>:text
      }</>
      );
    },
  });

  let pdfDownload = (event) => {
    event && window.open(event);
  };
// <-------------pagination----------------->
const [currentPage,setCurrentPage]=useState(1)
  const columns = [
    {
      title: "Email",
      width: 100,
      key: "emailSearch",
      dataIndex: "emailSearch",
      ...getColumnSearchProps("emailSearch"),
      // render: (_, record) => {
      //   return <Text>{record.email}</Text>;
      // },
    },
    {
      title: "Name",
      width: 100,
      key: "nameSearch",
      dataIndex: "nameSearch",
      ...getColumnSearchProps("nameSearch"),
      // render: (_, record) => {
      //   return<><Text>{record.fullname}</Text>
      //   <text> </text>
      //   <Text>{record.lastName}</Text></>;
      // },
    },

    // {
    //   title: "Company Name",
    //   width: 200,
    //   key: "companyName",
    //   render: (_, record) => {
    //     return (
    //       <List
    //         dataSource={record.NDAData}
    //         renderItem={(item) => (
    //           <List.Item key={uuidv4()}>
    //             <Text>
    //               {item.receivingParty && item.receivingParty["nameOfCompany"]}
    //             </Text>
    //           </List.Item>
    //         )}
    //       />
    //     );
    //   },
    // },
    // {
    //   title: "Title",
    //   width: 100,
    //   key: "title",
    //   render: (_, record) => {
    //     return (
    //        <Text>{record.createdAt}</Text>
    //     );
    //   },
    // },
    {
      title: "Date",
      width: 100,
      key: "date",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      render: (_, record) => {
        return (
          <Text>{moment(record.createdAt).format("MMMM D, YYYY hh:mm A")}</Text>
        );
      },
    },
    {
      title: "PDF",
      width: 100,
      key: "email",
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              cursor: "pointer",
            }}
            onClick={() => pdfDownload(record.pdfUrl)}
          >
            <Avatar
              shape="square"
              size={64}
              src={record && record.thumbnailUrl && record.thumbnailUrl}
              icon={<FileTextOutlined />}
            />
            <Text style={{ color: "#5a5ad2" }}>{record.pdfFileName}</Text>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "",
      width: 50,
      filters: [
        { text: "COMPLETE", value: "COMPLETE" },
        { text: "PENDING", value: "PENDING" },
      ],
      onFilter: (value, record) => record.status === value,
      key: "x",
      render: (_, record) => (
        <Tag color={record.status === "COMPLETE" ? "green" : "red"}>
          {record.status}
        </Tag>
      ),
    },
    {
      title: "Delete",
      dataIndex: "",
      width: 50,
      key: "x",
      fixed: "right",
      render: (_, record) => (
        <Popconfirm
          placement="top"
          title={text}
          onConfirm={() => handleDelete(record._id, false)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Form form={form} component={false}>
     {getNDALoading?<Loading/>:
     <> <div style={{position:"absolute",top:"70px",zIndex:"111",left :'225px'}}>
     <Button disabled={disableBtn} onClick={()=>{dispatch(ndaHandle.getNDAList());setdisableBtn(true);setsearchText("");}} size="small" style={{marginLeft:"10px"}}>Reset Filters</Button>
     </div>
     <Table
        rowKey={(record) => record._id}
        bordered
        dataSource={getNDAData}
        columns={columns}
        scroll={{ y: "68vh" }}
        rowClassName="editable-row"
        pagination={{
        position: ["bottomCenter"],
        showSizeChanger: false,
        pageSize: 10,
        current: currentPage,
        total:totalRecords,
        onChange: (page) => {
              setCurrentPage(page);
            },
      }}
      />
      </>}
    </Form>
  );
};

export { NDATable };
