import React, { useRef } from "react";
import SignaturePad from "react-signature-canvas";
import "../styles/SignaturePad.css";
import { Button } from "antd";

function GetSignaturePad({ saveFunc }) {
  const signCanvas = useRef();
  let clear = () => signCanvas.current.clear();
  return (
    // <div className={"container"}>
    <div>
      {/* <div className={"sigContainer"}> */}
      <div>
        <SignaturePad
          canvasProps={{
            velocityFilterWeight: 0.4,
            dotSize: 2,
            throttle: 8,
            style: {
              height: "95%",
              width: "100%",
              border: "1px solid black",
            },
          }}
          ref={signCanvas}
        />
      </div>
      <Button size={"small"} onClick={clear}>
        Clear
      </Button>
      <Button size={"small"} onClick={() => saveFunc(signCanvas)}>
        Sign
      </Button>
    </div>
  );
}

export { GetSignaturePad };
