import axios from "axios";

const apiURL = process.env.REACT_APP_BASE_URL;

const getHelpData = (query,offerTableAuthKey) => {
    let accessTokan;
    if(query==='OFFER_TABLE'){
        accessTokan = offerTableAuthKey;
    }else{
         accessTokan = localStorage.getItem("auth");
    }

    // Build the URL based on the presence of the query
    let url = `${apiURL}/help`;
    if (query) {
      url += `?section=${query}&platForm=ADMIN`;
    }

    return axios.get(url, {
      params: { page: 1, limit: 10 },
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

  const getHelpSeachData = (paramName,query, offerTableAuthKey) => {
    let accessTokan;
    if(query==='OFFER_TABLE'){
      accessTokan = offerTableAuthKey;
    }else{
      accessTokan = localStorage.getItem("auth");
    }
    return axios.get(`${apiURL}/help?search=${query}&section=${paramName}&platForm=ADMIN`,{
        params:{page:1,limit:10},
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    })
  }

export const AppDataApi = {
    getHelpData,
    getHelpSeachData,
};
