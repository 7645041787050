import React from 'react'
import { HiArrowLeft } from "react-icons/hi2";
import newlogo from "../../Assets/Icons/newLogo.png";
import dotEnv from "dotenv";
dotEnv.config();
const marketingURL = process.env.REACT_APP_MARKETING_URL;
const NDAHeader = () => {    
    const handleBackButtonClick = () => {
        try {
          window.history?.back();
        } catch (e) {
          window.location.href = marketingURL;
        }
      };
      const handleHomeButtonClick = () => {
          window.location.href = marketingURL;
      };
  return (
    <nav
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "#124e8f",
      color: "#fff",
      position: "fixed",
      zIndex: 11,
      height: "70px",
      width: "-webkit-fill-available",
      padding: "0px 4rem",
    }}
  >
    <div style={{ color: "white", fontSize: "20px", display: "flex", gap: "20px", justifyContent: "center", alignItems: "center" }}>
      <HiArrowLeft
        onClick={handleBackButtonClick}
        style={{
          fontSize: "26px",
          cursor: "pointer",
        }}
      />
      <div style={{ cursor: "pointer" }} onClick={handleBackButtonClick}>
        Back
      </div>
    </div>
    <div onClick={handleHomeButtonClick}>
      <img style={{ width: "110px", height: "auto", cursor:"pointer" }} src={newlogo} alt="Logo" />
    </div>
  </nav>

  )
}

export default NDAHeader