import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Layout, Row, Card, Typography, Col, Button } from "antd";
import TempLogo from "../../Assets/Icons/Logo.png";
import styles from "../styles/Logout.module.css";
import { adminAction } from "../../Authenticate/state/actions";
const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

function LogoutWindow() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(adminAction.resetLogout());
  }, [dispatch]);

  return (
    <Layout style={{ height: "100vh" }}>
      <Header style={{ position: "fixed", zIndex: 1, width: "100%",backgroundColor:"#3788d8"}} className={styles.nav}>
        <Link to={"/admin"}>
          <div style={{ textAlign: "center", float: "left" }}>
            <img
              className={styles.logo}
              style={{ width: "52px", height: "auto" }}
              src={"https://resureprojectbucket.s3.amazonaws.com/dev/649be3538168791298e1a3f0/RESURE_RESERVED/18resure-logo-short-white.png"}
              alt="Logo"
            />
          </div>
        </Link>
      </Header>
      <Content style={{ padding: "0 50px", marginTop: 64, background: "#fff", alignItems:"center" }}>
        <div style={{ padding: 24, minHeight: 380, background: "#fff" }}>
          <Card style={{  width: "auto",margin:"auto",textAlign:"center",alignItems:"center" }}>
          <Col span={34} style={{padding:"10px",alignItems:"center",margin:"auto",}}>
              <div style={{display:"flex",flexDirection:"column"}} >
                <Text type="secondary" style={{fontSize:"22px",width:"100%",margin:'auto'}}>Thank you for using RE-Sure.</Text>
              </div>
              <div style={{width:"100%",padding:"10px",margin:"auto"}}>
                <Link to={"/admin"}>
                <Button type="primary" style={{ width:"auto",fontSize:"15px" }}>
                Log In
              </Button>
               </Link>
              </div>
            </Col>
          </Card>
        </div>
      </Content>
      <Footer style={{ textAlign: "center",backgroundColor:"#001529",color:"whitesmoke"  }}>© 2023 - Hana Software, Inc.</Footer>
    </Layout>
  );
}
export { LogoutWindow };
