import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const verificationRequest = (id, email, app) => {
  let accessTokan = localStorage.getItem("auth");
  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
    data: {
      userId: id,
      // timestamp: time,
      emailId: email,
      app,
    },
  };
  return axios(`${apiURL}/emailVerification`, config);
};

const emailVerificationWithTermsRequest = (token, {payload}) => {
  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      authorization: `bearer ${token}`,
    },
    data: {
     ...payload
    },
  };
  return axios(`${apiURL}/emailVerificationWithTerms`, config);
};

const approveEmailforClauseRequest = (id, source,fullName,userEmail) => {
    let accessTokan = localStorage.getItem("auth");
    const config = {
      method: "POST",
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
      data: {
        userId: id,
        source,
        fullName,
        userEmail
      },
    };
    return axios(`${apiURL}/confirm`, config);
  };

const getUsersDataRequest = ({ personId, key }) => {
  // console.log("key inside api", key)
  return axios.get(`${apiURL}/personDetailedData/${personId}`,
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${key}`,
      }
    }
  );
}

export const verificationApi = {
  verificationRequest,
  approveEmailforClauseRequest,
  getUsersDataRequest,
  emailVerificationWithTermsRequest
};
