import React from "react";

const styles = {
  iframe: {
    border: "none",
    padding: 0,
    margin: 0,
  },
};

const NewsLetterDesign = ({el}) => {
    if (el && el.pdfURLLink) {
      return (
        <div>
          <iframe
            src={el?.pdfURLLink}
            className={styles.iframe}
            width="100%"
            height="500px"
          ></iframe>
        </div>
      );
    } else if (el?.imageURLs && el?.imageURLs[0]) {
      return (
        <div style={{textAlign:"center"}}>
          <img src={el?.imageURLs} alt="newsletter image" height={'500px'} width={'90%'} />
        </div>
      );
    }


  return null;
};

export { NewsLetterDesign };
