import React, { useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Step3 from "../../DocumentSection/component/OfferClone/StepThree";
import Step1 from "../../DocumentSection/component/OfferClone/StepOne";
import Step2 from "../../DocumentSection/component/OfferClone/StepTwo";
import { Button, Modal, Steps } from "antd";
import { useHistory } from "react-router-dom";
import { documentAction } from "../../DocumentSection/state/actions";

const { Step } = Steps;

const OfferCloneModal = ({ selectedData, urlImportModalVisible, setUrlImportModalVisible }) => {
  const { offerTableData, offerTableAuthKey, offerTablePropertyId } = useSelector((state) => state.offerTable);

  const { urlImportData } = useSelector((state) => state.documentSection.urlImportReducer);

  const { urlImportErrorAccured } = useSelector((state) => state.documentSection.urlImportReducer, shallowEqual);

  const dispatch = useDispatch();
  const history = useHistory();

  const [importModalStep, setImportModalStep] = useState(0);
  const [importUrl, setImportUrl] = useState("");

  const urlValid = (importUrl) => {
    if (importUrl.includes("https://www.ctmecontracts.com/eContracts") || importUrl.includes("https://www.ctmecontracts.com/files/aspredir")) {
      return true;
    } else {
      return false;
    }
  };

  // Route for back button
  let prevRoute = history?.location?.pathname?.split("/");
  if (prevRoute[2] === "documents") {
    prevRoute = "/" + prevRoute[1];
  } else {
    prevRoute = "/" + prevRoute[1] + "/" + prevRoute[2];
  }

  const closeOfferCloneModal = () => {
    setUrlImportModalVisible(false);
    setImportModalStep(0);
    dispatch(documentAction.clearUrlData());
  };

  //   useEffect(() => {
  //     if (urlImportErrorAccured) {
  //       closeOfferCloneModal();
  //       dispatch(documentAction.urlImportErrorClear());
  //     }
  //   }, [urlImportErrorAccured]);

  const importUrlApiCall = (selectedRowspropertySearchParam, redirect) => {
    let payload = {
      importUrl: importUrl?.trim(),
      redirectTo: "webApp",
      tokenKey: offerTableAuthKey,
      propertyId: offerTablePropertyId,
      createOffer: true,
    };
    dispatch(documentAction.urlImport(payload, offerTableAuthKey, redirect));
  };

  const steps = [
    {
      title: "First",
      content: <Step1 importUrl={importUrl} setImportUrl={setImportUrl} />,
    },
    {
      title: "Second",
      content: <Step2 CurrentPdfImportData={urlImportData} />,
    },
    {
      title: "Third",
      content: <Step3 importUrlApiCall={importUrlApiCall} importMethod={urlImportModalVisible} urlImportData={urlImportData} selectedData={selectedData} />,
    },
  ];

  const getDataForImportUrl = (createOffer) => {
    dispatch(
      documentAction.urlImport(
        {
          importUrl: importUrl?.trim(),
          redirectTo: "webApp",
          tokenKey: offerTableAuthKey,
          createOffer,
        },
        offerTableAuthKey
      )
    );
  };

  const next = () => {
    if (importModalStep === 0) {
      getDataForImportUrl(false);
    }
    if (importModalStep === 1) {
    }
    setImportModalStep(importModalStep + 1);
  };

  const prev = () => {
    setImportModalStep(importModalStep - 1);
  };

  return (
    <>
      <Modal
        className="offer-clone"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "70%",
              margin: "auto",
              fontSize: "22px",
            }}
          >
            <div>
              <h3 style={{ color: " #3d82e3" }}>Offer Clone</h3>
            </div>
          </div>
        }
        visible={urlImportModalVisible}
        onOk={() => closeOfferCloneModal()}
        onCancel={() => closeOfferCloneModal()}
        footer={null}
        afterClose={() => closeOfferCloneModal()}
        width={600}
      >
        <>
          <h1
            style={{
              fontSize: "18px",
              marginTop: "-15px",
              marginBottom: "20px",
              textAlign: "center",
              color: "rgb(76,170,255)",
            }}
          >
            {history?.location?.state?.selectedData?.address}
          </h1>
          <Steps current={importModalStep}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          <div className="steps-content">{steps[importModalStep]?.content}</div>

          <div className="steps-action">
            {importModalStep > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
            {importModalStep === 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => closeOfferCloneModal()}
              >
                Cancel
              </Button>
            )}
            {importModalStep < steps.length - 1 && (
              <Button type="primary" onClick={() => next()} disabled={(importModalStep === 1 && !urlImportData?.createUrlDataResult?.hasOwnProperty("urlData")) || (importModalStep === 0 && !urlValid(importUrl))}>
                Next
              </Button>
            )}
          </div>
        </>
      </Modal>
    </>
  );
};

export { OfferCloneModal };
