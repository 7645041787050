import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const getClausesDataRequest = (tokan, id) => {
  let accessTokan =
    localStorage.getItem("auth") ||
    JSON.parse(localStorage.getItem("mobileToken"));
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/admin/clauseConstants`, config);
};

const createClausesRequest = (data) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.post(
    `${apiURL}/admin/clauseConstants`,
    { clausesArray: data },
    {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

export const clausesApi = {
  getClausesDataRequest,
  createClausesRequest,
};
