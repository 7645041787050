import React, { useRef } from "react";
import SignaturePad from "react-signature-canvas";
import "../styles/SignaturePad.css";
import { Button, Layout } from "antd";
const { Footer } = Layout;

function GetSignaturePad({ saveFunc }) {
  const signCanvas = useRef();
  let clear = () => signCanvas.current.clear();
  return (
    // <div className={"container"}>
    <div>
      {/* <div className={"sigContainer"}> */}
      <div style={{ marginTop: "10px" }}>
        <SignaturePad
          canvasProps={{
            velocityFilterWeight: 0.4,
            dotSize: 2,
            throttle: 8,
            style: {
              height: "150px",
              width: "100%",
              border: "1px solid #D9D9D9",
            },
          }}
          ref={signCanvas}
        />
      </div>
      <div style={{ textAlign: "end", marginTop: "30px" }}>
        <Button
          size={"middle"}
          onClick={clear}
          style={{ width: "100px", marginRight: "10px" }}
        >
          Clear
        </Button>
        <Button
          type="primary"
          size={"middle"}
          onClick={() => saveFunc(signCanvas, "sign")}
          style={{ width: "100px" }}
        >
          Sign
        </Button>
      </div>
    </div>
  );
}

export { GetSignaturePad };
