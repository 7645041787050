import { helpConstants } from "./action-types";
import {helpDataApi} from "../utils/api"
import { message } from "antd";

//Get Help Data
const postHelpDataLoading = (response) => {
    return {
        type: helpConstants.CREATE_HELP_TOPIC_DATA_LOADING,
        helpDataLoading: true,
    };
  }

  const postHelpDataSuccess = (response) => {
    return {
      type: helpConstants.CREATE_HELP_TOPIC_DATA_SUCCESS,
      helpData: response,
      helpDataLoading: false,
    };
  }
  const postHelpDataError = (error) => {
    return {
      type: helpConstants.CREATE_HELP_TOPIC_DATA_ERROR,
      helpDataError: error,
      helpDataLoading: false,
    };
  }
  const postHelpData = (data,page) => {
    return (dispatch, updateState) => {
      dispatch(postHelpDataLoading());
      helpDataApi.postHelpDataApi(data).then((response) =>{
        message.success(`Question ${response.data.info.message}!`);
      }).then((response) => {
          dispatch(postHelpDataSuccess(response?.data?.info));
        }).then((response) =>{dispatch(helpApiCall(null,page))})
        .catch((error) => {
          message.error("We encountered an issue while creating help data. Please try again later!");
          dispatch(postHelpDataError(error));
        });
    };
  }

//Help Global Search
const getHelpSearchLoading = (response) => {
    return {
        type: helpConstants.HELP_TOPIC_GLOBAL_SEARCH_LOADING,
        helpData: response,
        helpDataLoading: false,
    };
  }

  const getHelpSearchSuccess = (response) => {
    return {
      type: helpConstants.HELP_TOPIC_GLOBAL_SEARCH_SUCCESS,
      helpData: response,
      helpDataLoading: false,
    };
  }
  const getHelpSearchError = (error) => {
    return {
      type: helpConstants.HELP_TOPIC_GLOBAL_SEARCH_ERROR,
      helpDataError: error,
      helpDataLoading: false,
    };
  }
  const helpSearchApi = (query,page,limit,search) => {
    return (dispatch, updateState) => {
      dispatch(getHelpSearchLoading());
      helpDataApi.getHelpData(query,page,limit,search)
        .then((response) => {
          dispatch(getHelpSearchSuccess(response?.data?.info));
        })
        .catch((error) => {
          message.error("We encountered an issue while searching. Please try again later!");
          dispatch(getHelpSearchError(error));
        });
    };
  }

  //Get Help Data
  const getHelpLoading = (response) => {
    return {
        type: helpConstants.HELP_TOPIC_DATA_LOADING,
        helpData: response,
        helpDataLoading: true,
    };
  }

  const getHelpSuccess = (response) => {
    return {
      type: helpConstants.HELP_TOPIC_DATA_SUCCESS,
      helpData: response,
      helpDataLoading: false,
    };
  }
  const getHelpError = (error) => {
    return {
      type: helpConstants.HELP_TOPIC_DATA_ERROR,
      helpDataError: error,
      helpDataLoading: false,
    };
  }
  const helpApiCall = (query,page,limit,search) => {
    return (dispatch, updateState) => {
      dispatch(getHelpLoading());
      helpDataApi.getHelpData(query,page,limit,search)
        .then((response) => {
          dispatch(getHelpSuccess(response?.data?.info));
        })
        .catch((error) => {
          message.error("We encountered an issue while fetching help data. Please try again later!");
          dispatch(getHelpError(error));
        });
    };
  }

   //Edit Help Data
   const editHelpDataLoading = (response) => {
    return {
        type: helpConstants.DELETE_HELP_TOPIC_DATA_LOADING,
        helpData: response,
        helpDataLoading: true,
    };
  }

  const editHelpDataSuccess = (response) => {
    message.success('Question edited successfully!');
    return {
      type: helpConstants.DELETE_HELP_TOPIC_DATA_SUCCESS,
      helpData: response,
      helpDataLoading: false,
    };
  }
  const editHelpDataError = (error) => {
    return {
      type: helpConstants.DELETE_HELP_TOPIC_DATA_ERROR,
      helpDataError: error,
      helpDataLoading: false,
    };
  }
  const editHelpData = (data,id,page) => {
    return (dispatch, updateState) => {
      dispatch(editHelpDataLoading());
      helpDataApi.editHelpDataApi(data,id)
      .then(() => dispatch(helpApiCall(null,page)))
        .then((response) => {
          dispatch(editHelpDataSuccess(response?.data?.info));
        })
        .catch((error) => {
          message.error("We encountered an issue while editing help data. Please try again later!");
          dispatch(editHelpDataError(error));
        });
    };
  }

  //Delete Help Data
  const deleteHelpDataLoading = (response) => {
    return {
        type: helpConstants.DELETE_HELP_TOPIC_DATA_LOADING,
        helpData: response,
        helpDataLoading: true,
    };
  }

  const deleteHelpDataSuccess = (response) => {
    message.success('Question deleted successfully!')
    return {
      type: helpConstants.DELETE_HELP_TOPIC_DATA_SUCCESS,
      helpData: response,
      helpDataLoading: false,
    };
  }
  const deleteHelpDataError = (error) => {
    return {
      type: helpConstants.DELETE_HELP_TOPIC_DATA_ERROR,
      helpDataError: error,
      helpDataLoading: false,
    };
  }
  const deleteHelpData = (id) => {
    return (dispatch, updateState) => {
      dispatch(deleteHelpDataLoading());
      helpDataApi.deleteHelpDataApi(id)
      .then((data) =>dispatch(helpApiCall()))
        .then((response) => {
          dispatch(deleteHelpDataSuccess(response?.data?.info));
        })
        .catch((error) => {
          message.error("We encountered an issue while deleting help data. Please try again later!");
          dispatch(deleteHelpDataError(error));
        });
    };
  }

  //Upload image
  const uploadImageLoading = (error) => {
    return {
      type: helpConstants.UPLOAD_IMAGE_LOADING,
      uploadImageError: error,
      uploadImageLoading: true,
    };
  };

  const uploadImageSuccess = (response) => {
    // message.success("Image uploaded successfully !");
    return {
      type: helpConstants.UPLOAD_IMAGE_SUCCESS,
      uploadImageData: response,
      uploadImageLoading: false,
    };
  };

  const uploadImageError = (error) => {
    return {
      type: helpConstants.UPLOAD_IMAGE_ERROR,
      uploadImageError: error,
      uploadImageLoading: false,
    };
  };


const uploadAllImages = async (files,origin) => {
    let ImageUrls = [];
              try {
                  for (let i = 0; i < files.length; i++) {
                      let imageUrl = await helpDataApi.uploadImageRequest(files[i], origin);
                      let url = imageUrl?.data?.info?.fileData?.fileUrl
                      ImageUrls = [...ImageUrls, url];
                    }

                } catch (error) {
               }
                return ImageUrls;
    };

  const uploadImage = (files, origin) => {
    return (dispatch, updateState) => {
        dispatch(uploadImageLoading());
        uploadAllImages(files, origin)
          .then((response) => {
            dispatch(uploadImageSuccess(response));
          })
          .catch((error) => {
            message.error("We encountered an issue while uploading image. Please try again later!");
            dispatch(uploadImageError(error));
          });
      };
  };

//   reset store images

 const resetImageLoading = (error) => {
    return {
      type: helpConstants.RESET_IMAGE_LOADING,
      uploadImageError: error,
      uploadImageLoading: true,
    };
  };

  const resetImageSuccess = () => {
    return {
      type: helpConstants.RESET_IMAGE_SUCCESS,
      uploadImageData: [],
      uploadImageLoading: false,
    };
  };

  const resetImageError = (error) => {
    return {
      type: helpConstants.RESET_IMAGE_ERROR,
      uploadImageError: error,
      uploadImageLoading: false,
    };
  };

const handleResetImages = () => {
    return (dispatch, updateState) => {
        dispatch(resetImageLoading());
        dispatch(resetImageSuccess());

      };
  };

  //Get Roles
  const getRolesLoading = (response) => {
    return {
        type: helpConstants.GET_ROLES_LOADING,
        rolesData: response,
        rolesDataLoading: true,
    };
  }

  const getRolesSuccess = (response) => {
    return {
      type: helpConstants.GET_ROLES_SUCCESS,
      rolesData: response,
      rolesDataLoading: false,
    };
  }
  const getRolesError = (error) => {
    return {
      type: helpConstants.GET_ROLES_ERROR,
      rolesDataError: error,
      rolesDataLoading: false,
    };
  }
  const rolesApiCall = (query) => {
    return (dispatch, updateState) => {
      dispatch(getRolesLoading());
      helpDataApi.getRolesDataApi(query)
        .then((response) => {
          dispatch(getRolesSuccess(response?.data?.info?.data[0]?.helpArray?.roles));
        })
        .catch((error) => {
          message.error("We encountered an issue while fetching roles data. Please try again later!");
          dispatch(getRolesError(error));
        });
    };
  }

   //Get Sections
   const getSectionsLoading = (response) => {
    return {
        type: helpConstants.GET_SECTION_ERROR,
        sectionsData: response,
        sectionsDataLoading: true,
    };
  }

  const getSectionsSuccess = (response) => {
    return {
      type: helpConstants.GET_SECTION_SUCCESS,
      sectionsData: response,
      sectionsDataLoading: false,
    };
  }
  const getSectionsError = (error) => {
    return {
      type: helpConstants.GET_SECTION_ERROR,
      sectionsDataError: error,
      sectionsDataLoading: false,
    };
  }
  const sectionsApiCall = (query) => {
    return (dispatch, updateState) => {
      dispatch(getSectionsLoading());
      helpDataApi.getSectionsDataApi(query)
        .then((response) => {
          dispatch(getSectionsSuccess(response?.data?.info?.data[0]?.helpArray?.sections));
        })
        .catch((error) => {
          message.error("We encountered an issue while fetching sections data. Please try again later!");
          dispatch(getSectionsError(error));
        });
    };
  }

export const helpApis = {
  helpSearchApi,
  helpApiCall,
  postHelpData,
  editHelpData,
  deleteHelpData,
  uploadImage,
  handleResetImages,
  rolesApiCall,
  sectionsApiCall,
};
