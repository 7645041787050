import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import { Loading } from "../Common/components/Loading";
import { ndaHandle } from "./state/actions";
import { EditableTable } from "./components/EditableTable";
import styles from "./styles/ListingEdit.module.css";
import { NDATable } from "./components/NDATable";
import { Redirect } from "react-router-dom";
import { useState } from "react";
import { roleAccessFunc } from "../Common/commondata/commonData";
const { Content } = Layout;

function NDAlisting(props) {
  // useEffect(() => {
  //   if (props.networkConnection === false) {
  //     window.location.reload();
  //   }
  // }, [props]);
  const dispatch = useDispatch();
  const { getNDAData, getNDALoading ,totalRecords} = useSelector((state) => state.nda);
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );
  
  if (!roleAccessFunc(roleAccessed, "NDA")) {
    return <Redirect to="/error" />;
  }

  return (
    <Layout style={{ height: "100%", padding: "0px" }}>
      <Content
        style={{
          overflow: "auto",
          // background: "rgb(202 202 202)",
          padding: "1rem",
        }}
      >
        <div className={styles.site_layout_background}>
            <NDATable  />
        </div>
      </Content>
    </Layout>
  );
}

export { NDAlisting };
