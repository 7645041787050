import React, { useEffect } from "react";
import { Form, Input, Card, Button, Typography, Row } from "antd";
import { ndaHandle } from "./state/actions";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import { NDAFailed } from "./components/NDAFailed";
import { NDASuccessForm } from "./components/NDASuccessForm";
import NDAHeader from "./components/NDAHeader";
const { Content } = Layout;
const { Title } = Typography;

function NDAForm() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ndaHandle.getNDA_JSX());
  }, [dispatch]);

  const { submitEmailData, submitEmailError } = useSelector((state) => state.nda);

  let onFinish = (event) => {
    dispatch(ndaHandle.submitEmail(event.email, event.firstName, event?.lastName || ""));
  };

  if (submitEmailError) return <NDAFailed msg={"Submission Failed"} subTitle={"Already registered with this Email...!"} />;
  if (submitEmailData) return <NDASuccessForm title="Thank you!" subTitle="Please verify this NDA by checking your email (if not found, please check spam)" />;

  return (
    <Layout style={{ height: "100vh" }}>
      <NDAHeader />
      <Content style={{ padding: "0 50px", background: "#fff" }}>
        <Row justify="center" align="center" style={{ marginTop: "20vh", textAlign: "center" }}>
          <Title level={2}>HANA SOFTWARE, INC. NDA</Title>
        </Row>
        <Row justify="center" align="center" style={{ marginTop: "3rem" }}>
          <Card style={{ width: "400px" }}>
            <Form
              name="NDA"
              labelAlign="left"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={onFinish}
            >
              <Form.Item name="firstName" label="First Name" rules={[{ required: true, message: "Please Enter First Name!" }]}>
                <Input placeholder="Enter First Name" allowClear autoFocus />
              </Form.Item>
              <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: "Please Enter Last Name!" }]}>
                <Input placeholder="Enter Last Name" allowClear />
              </Form.Item>
              <Form.Item
                name="email"
                hasFeedback
                label="E-mail"
                rules={[
                  {
                    type: "email",
                    message: "Not a valid Email!",
                  },
                  {
                    required: true,
                    message: "Please Enter Email!",
                  },
                ]}
              >
                <Input placeholder="Enter Email" allowClear />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Row>
      </Content>
    </Layout>
  );
}

export { NDAForm };
