import React from "react";
import { Result, Button } from "antd";
import dotEnv from "dotenv";
import { useSelector } from "react-redux";
import {  detectWebView } from "../utils/universalFuntions";
dotEnv.config();
const apiURL = process.env.REACT_APP_WEB_BASE_URL;

function SuccessComponent() {
  const { rootDocData } = useSelector((state) => state.createOffer);
  let openFor, signable, builtForSection;
  const query = new URLSearchParams(window.location?.search);
  const isClient  = query?.get("client");
  if (rootDocData) {
    ({ openFor, signable, builtForSection } = rootDocData);
  }

  let redirectHome = async () => {
    if (builtForSection === "DOCUMENT") {
      await window?.ReactNativeWebView?.postMessage("DOCUMENT_SAVED");
    } else {
      await window?.ReactNativeWebView?.postMessage("WINDOW_CLOSED");
    }
  };
  const homeHandle = async () => {
    const isInWebView = detectWebView();
    if (builtForSection === "DOCUMENT") {
      if (openFor === "BUYER" || openFor === "SELLER" || openFor === "BUYER_WITHDRAW") {
        window.location = `${apiURL}/clientScreen`;
        return;
      }
    }
    if (isInWebView) {
      redirectHome();
    } else {
      if (openFor === "SELLER_AGENT" || openFor === "BUYER_AGENT") {
        window.location = apiURL;
      } else {
        window.location = `${apiURL}`;
      }
    }
  };
  return (
    <div>
      {" "}
      <Result
        status="success"
        title={signable && !isClient ? "Completed!" : "Thank you for using RE-Sure."}
        subTitle={builtForSection === "TEMPLATE" ? "Template Saved" : ""}
        extra={[
          signable && !isClient ? (
            <Button type="primary" onClick={homeHandle} key="console">
              Home
            </Button>
          ) : null,
        ]}
      />
    </div>
  );
}

export { SuccessComponent };
