import axios from "axios";
import dotEnv from "dotenv";
import moment from "moment";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const pdfUploadRequest = (token, event) => {
  let accessTokan = localStorage.getItem("auth");
  var formData = new FormData();
  formData.append("file", event.upload.file.originFileObj);
  formData.append("contractName", `${event.upload.file.name}`);
  let apikeyvalue = event.api_key
    ? event.api_key
    : "rj909485@gmail.com_fe35e451fb96e31f";
  formData.append("pdfcoKey", apikeyvalue);
  formData.append("documentaryId", event.documentries);
  formData.append("year", Number(moment(event.year).weekYear()));
  // categoryIds.forEach((item) => formData.append("categoryIds[]", item));
  // formData.append("docIds", event.docType);

  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
      authorization: `bearer ${accessTokan}`,
    },
    data: formData,
  };
  // return axios(`${apiURL}/common/uploadFile`, config);
  // return axios(`${apiURL}/pdfToJSXContractSave`, config);
  return axios(`${apiURL}/contract`, config);
};

const sendPreviewSubmitRequest = (
  id,
  password,
  htmlString,
  offerTableData,
  boldElements,
  originalJSX
) => {
  let accessTokan = localStorage.getItem("auth");
  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      authorization: `bearer ${accessTokan}`,
    },
    data: {
      // contractJSX: htmlString, // when we want to take the jsx from the developer tools
      contractJSX: originalJSX, // when taking from the pdfData.jsx
      password:password,
      // offerTableJson: offerTableData,
      // treeviewJSX: boldElements,
    },
  };
  // return axios(`${apiURL}/common/uploadFile`, config);
  return axios(`${apiURL}/contract/${id}`, config);
};

// get documentry listing
const documentryListingRequest = () => {
  let accessTokan = localStorage.getItem("auth");
  const config = {
    method: "GET",
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/contractDocumentaries`, config);
};

export const pdfUploadApi = {
  pdfUploadRequest,
  sendPreviewSubmitRequest,
  documentryListingRequest,
};
