import React, { useRef, useState } from "react";
import { Avatar, Button, Col, Dropdown, Empty, Form, Image, Input, Menu, Modal, Row, Select, Space, Spin, Table, Tooltip, Typography } from "antd";
import { PlusCircleFilled, FilterFilled, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { officeTypeHandle } from "../OfficeType/state/actions";
import { CreateFirms } from "./Components/CreateFirms";
import { firmDetailsHandle } from "./state/action";
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, ExclamationCircleOutlined, CloudUploadOutlined, CheckCircleFilled, SyncOutlined, MoreOutlined } from "@ant-design/icons";
import Createmodal from "./Components/Createmodal";
import Highlighter from "react-highlight-words";
import { generateInitialsFromObj } from "../Common/utils/universalFuntions";

const { Option } = Select;
var debounce

const FirmCreation = () => {
  const dispatch = useDispatch();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [addJoiningCodeModal, setAddJoiningCodeModal] = useState({
    openModal: false,
    openAdminModal:false,
    adminRecord:{}
  })
  const [showModal, setShowModal] = useState(false)
  const [selectedObject, setSelectedObject] = useState(null);
  const [formtag, setFormtag] = useState("")
  const [dataid, setDataid] = useState("")
  const [disableBtn, setdisableBtn] = useState(true);
  const [isBrokerageLogoRequired, setIsBrokerageLogoRequired] = useState(true);
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    unit: "",
    association: "",
    state: "",
    city: "",
    streetName: "",
    zipCode: "",
    streetNumber: "",
    logo: "",
    licenseNumber: ""
  });

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 9,
      },
      lg: {
        span: 8,
      }
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 15,
      },
      lg: {
        span: 16,
      }
    },
  };

  const { adminRole } = useSelector((state) => state.account);
  const { adminSearchLoading, adminSearchError, adminSearchData } = useSelector((state) => state.firmDetails);

  const editfunction = (record, name, id, allData) => {
    setSelectedObject(record)
    setFormtag(name)
    setShowModal(true)
    setDataid(id)
    setData({
      name: record?.name ? record?.name : "",
      email: record?.email ? record?.email : "",
      phoneNumber: record?.phoneNumber ? record?.phoneNumber : "",
      unit: record?.unit ? record?.unit : "",
      association: record?.association ? record?.association : "",
      state: record?.state ? record?.state : "",
      city: record?.city ? record?.city : "",
      streetName: record?.streetName ? record?.streetName : "",
      zipCode: record?.zipCode ? record?.zipCode : "",
      streetNumber: record?.streetNumber ? record?.streetNumber : "",
      logo: record?.logo ? record?.logo : "",
      licenseNumber: allData?.licenseNumber ? allData?.licenseNumber : "",
    })

    //  if (name === "brokerage") {
    //     let data = { officeTypes: "FRANCHISE" };
    //     dispatch(firmDetailsHandle.getAllFranchise(data));
    //   } else if (name === "office") {
    //     if (getFranchiseData.length === 0) {
    //       const payload = { officeTypes: "FRANCHISE" };
    //       dispatch(firmDetailsHandle.getAllFranchise(payload));
    //     }
    //     let data = { officeTypes: "BROKERAGE" };
    //     dispatch(firmDetailsHandle.getBrokrage(data));
    //   }



  }
  // <------------------searching functionality----------->
  const searchInput = useRef();
  let getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false) }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => { handleSearch(selectedKeys, confirm, dataIndex); setdisableBtn(false) }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys); setdisableBtn(true) }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>

        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),

    render: (text) =>

      searchedColumn === dataIndex ? (
        <>
          {dataIndex == "creator" ? <div><Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.fullName : ""}
          />
            <div style={{ fontSize: "11px", color: "#787474" }}>
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.email : ""}
              /></div></div>
            :
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.Address.toString() : ""}
            />
          }
        </>
      ) : (<>
        {dataIndex == "creator" ?
          <div>
            <div>{text.fullName ? text.fullName : "-"}</div>
            <div style={{ fontSize: "11px", color: "#787474" }}>
              {text.email ? `${text.email}` : ""}
            </div>
          </div> :
          text}
      </>
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    searchRef.current = true;
    confirm();
    setsearchText(selectedKeys[0]);
    let query = {}
    if (dataIndex == "creator") {
      query = {
        ...currentQuery,
        nameEmailSearch: selectedKeys[0],
        page: 1,
      };
    } else if (dataIndex === 'franchise') {
      query.type = dataIndex.toUpperCase();
      query.search = selectedKeys[0];
    } else if (dataIndex === 'brokerage') {
      query.type = dataIndex.toUpperCase();
      query.search = selectedKeys[0]
    } else if (dataIndex === 'office') {
      // query.type = dataIndex.toUpperCase();
      query.search = selectedKeys[0]
    } else {
      query = {
        // officeTypes:dataIndex.toUpperCase(),
        ...currentQuery,
        search: selectedKeys[0],
        page: 1,
      };
    }
    dispatch(officeTypeHandle.getOfficeTypes("createFirm", query));
    setsearchedColumn(dataIndex);

    setCurrentQuery(query);
    setCurrentPage(1);
  };

  // <------------------filtering functionality------------------->
  const [filterbyOffice, setFilterbyoffice] = useState("")
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [currentQuery, setCurrentQuery] = useState({})
  const searchRef = useRef(false);
  let getoffice = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Firm Type"
            onChange={(role, event) => {
              setFilterbyoffice(role)
            }}
            options={[
              {
                value: "FRANCHISE",
                label: "FRANCHISE",
              },
              {
                value: "BROKERAGE",
                label: "BROKERAGE",
              },
              {
                value: "OFFICE",
                label: "OFFICE",
              },
            ]}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => { handleFilterChange(filterbyOffice); setdisableBtn(false) }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => { handleReset(clearFilters, setSelectedKeys); setdisableBtn(true) }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890FF" : undefined }} />
    ),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#FFC069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const [isVerify, setisVerify] = useState("")
  let getstatus = (dataIndex) => ({

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: "10px" }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Status"
            onChange={(role, event) => {
              setisVerify(role)
            }}
            options={[
              {
                value: "true",
                label: "Verified",
              },
              {
                value: "false",
                label: "Not Verified",
              },
            ]}
          />
        </div>
        <Space>
          <Button
            type="primary"
            onClick={() => { handleFilterChangestatus(isVerify); setdisableBtn(false) }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, setSelectedKeys)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890FF" : undefined }} />
    ),

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#FFC069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        (
          <div>
            {text ? (
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <CheckCircleOutlined
                  style={{ color: "#4bd33a", fontSize: "18px" }}
                />
                Verified
              </div>
            ) : (
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <CloseCircleOutlined
                  style={{ color: "#e73939", fontSize: "18px" }}
                />
                Not Verified
              </div>
            )}
          </div>
        )
      ),
  });
  const handleFilterChangestatus = (value) => {
    let query = {
      ...currentQuery,
      isVerified: value
    };
    dispatch(officeTypeHandle.getOfficeTypes("createFirm", query));
    setCurrentQuery(query)
    setCurrentPage(1);
  }
  const handleFilterChange = (value) => {
    let query = {
      ...currentQuery,
      firmType: value
    };

    dispatch(officeTypeHandle.getOfficeTypes("createFirm", query));
    setCurrentQuery(query)

    setCurrentPage(1);
  }
  const handleReset = (clearFilters, setSelectedKeys) => {
    clearFilters();
    setSelectedKeys("");
    setCurrentQuery({})
    let query = {
      page: 1,
    };
    dispatch(officeTypeHandle.getOfficeTypes("createFirm", query));
    setsearchText("");
    searchRef.current = false;
  };

  const handleResetFilters = () => {
    setCurrentQuery({})
    let query = {
      page: 1,
    };
    dispatch(officeTypeHandle.getOfficeTypes("createFirm", query));
    setsearchText("");
    searchRef.current = false;
  };

  const ActionMenu = ({ el, record, handleCodeStatus, handleDeleteCode }) => {
    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={() => handleCodeStatus(el, record)}>
          {el?.status === "ACTIVE" ? "Mark Inactive" : "Mark Active"}
        </Menu.Item>
        <Menu.Item key="2" onClick={() => handleDeleteCode(el, record)}>
          Delete code
        </Menu.Item>
      </Menu>
    );
  
    return (
      <Dropdown overlay={menu} trigger={['click','hover']}>
        <MoreOutlined style={{fontSize:"18px"}}/>
      </Dropdown>
    );
  };

  const AdminMenu = ({ el, record }) => {
    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={() => handleRemoveAdmin(el, record)}>
          Remove
        </Menu.Item>
      </Menu>
    );
  
    return (
      <Dropdown overlay={menu} trigger={['click','hover']}>
        <MoreOutlined style={{fontSize:"18px"}}/>
      </Dropdown>
    );
  };

  const getAllAdminsToAssign = (value) => {
    if(debounce){
      clearTimeout(debounce)
    }
    dispatch(firmDetailsHandle.getAdminSearchLoading(false));
    if(value.fullName.length < 3){
      dispatch(firmDetailsHandle.getAdminSearchData([]));
      return
    }
    else{
      dispatch(firmDetailsHandle.getAdminSearchLoading(true));
    }
    debounce = setTimeout(()=>{
      if(value.fullName.length < 3){
        return
      }
      dispatch(firmDetailsHandle.getAdminSearch(value))
    },1500)
  }

  const handleCodeStatus = (item,record) => {
    let urlData = {
      code:item?.code,
      ...(item?.status === "ACTIVE" ? {makeCodeInActive: true} : {makeCodeInActive:false})
    }
    dispatch(firmDetailsHandle.editfranchisedetails(urlData,record?.id))
  }

  const handleDeleteCode = (item,record) => {
    let urlData = {
      deleteCode:item?.code
    }
    Modal.confirm({
      title:"Are you sure you want to delete this code?",
      okText:"Yes",
      cancelText:"No",
      onOk(){
        dispatch(firmDetailsHandle.editfranchisedetails(urlData,record?.id))
      },
      onCancel() {}
    })
  }

  const handleJoinBrokerage = (record) => {
    let urlData = {
      addJoiningCode: true,
    }
    dispatch(firmDetailsHandle.editfranchisedetails(urlData,record?.id))
    handleJoinBrokerageModalCancel()
  }

  const handleJoinBrokerageModalCancel = () => {
    setAddJoiningCodeModal({...addJoiningCodeModal,openModal:false});
    joinBrokerageForm.resetFields()
  }

  const handleAddAdmin = (item) => {
    setAddJoiningCodeModal({...addJoiningCodeModal,openAdminModal:true,adminRecord:item})
  }

  const handleCancelAdminModal = () => {
    setAddJoiningCodeModal({...addJoiningCodeModal,openAdminModal:false,adminRecord:{}})
    addAdminForm.resetFields();
  }

  const handleAdminForm = (values) => {
    let adminArray = addJoiningCodeModal?.adminRecord?.admins?.map((el) => el?._id);
    let urlData = {
      ...(values?.admins?.length ? {admins:[...adminArray,...values?.admins]} : {})
    }
    dispatch(firmDetailsHandle.editfranchisedetails(urlData,addJoiningCodeModal?.adminRecord?.id))
    handleCancelAdminModal();
  }

  const handleRemoveAdmin = (item,record) => {
    let adminArray = record?.admins?.filter((ele) => ele?._id !== item?._id)?.map((el) => el?._id);
    let urlData = {
      admins:[...adminArray]
    }
    Modal.confirm({
      title:"Are you sure you want to remove this admin?",
      okText:"Yes",
      cancelText:"No",
      onOk(){
        dispatch(firmDetailsHandle.editfranchisedetails(urlData,record?.id))
      },
      onCancel() {}
    })
  }

  const [form] = Form.useForm();
  const [joinBrokerageForm] = Form.useForm();
  const [addAdminForm] = Form.useForm();
  const firmCreationColoums = [
    {
      title: 'FIRM CREATED',
      dataIndex: 'name',
      key: 'name',
      width: "10%",
      // ...getoffice()
    },
    {
      title: 'ADMINS',
      dataIndex: 'creator',
      key: 'creator',
      width: '25%',
      // ...getColumnSearchProps("creator"),
      render: (text, record) => <div>
        {record?.admins?.map((el, i) => {
          return <div style={{ display: "flex", gap: "5px", width: "90%" }}>
            <div>
            <Avatar size={40} src={el?.profileImg?.original ? el?.profileImg?.original : ""}>{generateInitialsFromObj(el)}</Avatar>
            </div>
            <div style={{ width: "80%",display:"flex",flexDirection:"column",padding:"3px" }}>
              <span>{el?.fullName}</span>
              <span>{el?.email}</span>
            </div>
            {adminRole?.includes("SUPER_ADMIN") && <div>
              <AdminMenu el={el} record={record} />
            </div>}
          </div>
        }) || '-'}
        {adminRole?.includes("SUPER_ADMIN") && <Button onClick={() => handleAddAdmin(record)}>Add Admin</Button>}
      </div>
    },
    {
      title: 'BROKERAGE CODE',
      dataIndex: 'codes',
      key: 'codes',
      width: '23%',
      // ...getstatus("isVerified")
      render: (text, record) => (
        <div>
          {record?.codes?.map((el, i) => {
            return <div style={{ display: "flex", gap: "5px",padding:"3px", marginBottom: "5px",border:`2px solid ${el?.status === "ACTIVE" ? "green" : "red"}` }}>
              <div style={{ display:"flex",flexDirection:"column" }}>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                Code:&emsp; <Typography.Text copyable style={{fontWeight:"bold"}}>{el?.code}</Typography.Text>
                <div>
                <ActionMenu
                    el={el}
                    record={record}
                    handleCodeStatus={handleCodeStatus}
                    handleDeleteCode={handleDeleteCode}
                  />
                  </div>
                </div>
                <div>
                  {el?.status === "ACTIVE" ? (
                    <div
                      style={{ display: "flex", gap: "5px", alignItems: "center" }}
                    >
                      Status: <span style={{fontWeight:"bold"}}>Active</span>
                      <CheckCircleOutlined
                        style={{ color: "#4bd33a", fontSize: "18px" }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ display: "flex", gap: "5px", alignItems: "center" }}
                    >
                      Status: <span style={{fontWeight:"bold"}}>Inactive</span>
                      <CloseCircleOutlined
                        style={{ color: "#e73939", fontSize: "18px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          }) || '-'}
          {record?.name === "BROKERAGE" && <Button onClick={() => handleJoinBrokerage(record)}>Add Code</Button>}
        </div>
      )
      ,
    },
    {
      title: 'BROKERAGE',
      dataIndex: ['brokerage', 'name'],
      key: 'brokerage',
      width: '20%',
      ...getColumnSearchProps("brokerage"),
      sorter: (a, b) => a?.brokerage?.name?.localeCompare(b?.brokerage?.name),
      sortDirections: ['ascend', 'descend'],
      render: (text, record) => (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {record?.brokerage?.name && record?.brokerage?.officeLogo ? <Image src={record?.brokerage?.officeLogo} alt="" width={"30px"} /> : ""}
              {searchedColumn === 'brokerage' ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={record?.brokerage?.name ? record?.brokerage?.name : "-"}
                />
              ) : (
                record?.brokerage?.name || '-'
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span style={{ padding: "10px" }}>
                {record?.name === "BROKERAGE" && (
                  <Tooltip title={"Send request to access FJGG Docs and Clauses"} trigger={'hover'}>
                    <CloudUploadOutlined onClick={() => showFjggModal(record?.id)} style={{ cursor: "pointer" }} />
                  </Tooltip>
                )}
              </span>
              {record?.name === "BROKERAGE" && (
                record?.updateInProgress ? (
                  <Tooltip title={"Updated changes under verification"} color={"#085191"}>
                    <ExclamationCircleOutlined style={{ cursor: "pointer", color: "orange" }} />
                  </Tooltip>
                ) : (
                  <EditOutlined onClick={() => editfunction(record?.brokerage, "brokerage", record?.id, record)} />
                )
              )}
            </div>
          </div>
          <div style={{ fontSize: "11px", color: "#787474" }}>
            {searchedColumn === 'brokerage' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={record?.licenseNumber ? `License No: ${record?.licenseNumber}` : ""}
              />
            ) : (
              <span>{record?.licenseNumber ? `License No: ${record?.licenseNumber}` : "-"}</span>
            )}
          </div>
          {record?.brokerage?.address && !record?.brokerage?.address?.includes("undefined") && <div style={{ fontSize: "11px", color: "#787474" }}>
            {searchedColumn === 'brokerage' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={record?.brokerage?.address ? `Address: ${record?.brokerage?.address}` : ""}
              />
            ) : (
              record?.brokerage?.address || '-'
            )}
          </div>}
        </div>
      ),
    },
    {
      title: 'FRANCHISE',
      dataIndex: 'franchise',
      key: 'franchise',
      width: '20%',
      ...getColumnSearchProps("franchise"),
      sorter: (a, b) => a?.franchise?.name?.localeCompare(b?.franchise?.name),
      sortDirections: ['ascend', 'descend'],
      render: (text, record) => (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {record?.franchise?.officeLogo ? <Image src={record?.franchise?.officeLogo} alt="" width={"30px"} /> : ""}
              {searchedColumn === 'franchise' ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[searchText]}
                  autoEscape
                  textToHighlight={record?.franchise?.name ? record?.franchise?.name : "-"}
                />
              ) : (
                record?.franchise?.name || '-'
              )}
            </div>
            <div>{record?.name === "FRANCHISE" ? record?.updateInProgress ? <Tooltip title={"Updated changes under verification"} color={"#085191"}><ExclamationCircleOutlined style={{ cursor: "pointer", color: "orange" }} /></Tooltip> :
              <EditOutlined onClick={() => editfunction(record?.franchise, "franchise", record?.id)} /> : ""}</div>
          </div>
          {(record?.franchise?.address && !record?.franchise?.address?.includes("undefined")) && <div style={{ fontSize: "11px", color: "#787474" }}>
            {searchedColumn === 'franchise' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={record?.franchise?.address ? `Address: ${record?.franchise?.address}` : ""}
              />
            ) : (
              record?.franchise?.address || '-'
            )}
          </div>}

        </div>
      ),
    },
    // {
    //   title: 'OFFICE',
    //   dataIndex: 'office',
    //   key: 'office',
    //   width: '20%',
    //   ...getColumnSearchProps("office"),
    //   render: (text, record) => (
    //     <div>
    //       <div style={{ display: "flex", justifyContent: "space-between" }}>
    //         <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
    //           {searchedColumn === 'office' ? (
    //             <Highlighter
    //               highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
    //               searchWords={[searchText]}
    //               autoEscape
    //               textToHighlight={record.office?.name ? record.office?.name : "-"}
    //             />
    //           ) : (
    //             record.office?.name || '-'
    //           )}
    //         </div>
    //         <div>
    //           {record?.name === "OFFICE" && (
    //             record?.updateInProgress ? (
    //               <Tooltip title={"Updated changes under verification"} color={"#085191"}>
    //                 <ExclamationCircleOutlined style={{ cursor: "pointer", color: "orange" }} />
    //               </Tooltip>
    //             ) : (
    //               <EditOutlined onClick={() => editfunction(record?.office, "office", record?.id)} />
    //             )
    //           )}
    //         </div>
    //       </div>
    //       <div style={{ fontSize: "11px", color: "#787474" }}>
    //         {searchedColumn === 'office' ? (
    //           <Highlighter
    //             highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
    //             searchWords={[searchText]}
    //             autoEscape
    //             textToHighlight={record.office?.address ? `Address: ${record.office?.address}` : ""}
    //           />
    //         ) : (
    //           record.office?.address || '-'
    //         )}
    //       </div>
    //     </div>
    //   ),
    // },
  ]




  useEffect(() => {
    dispatch(officeTypeHandle.getOfficeTypes("createFirm"));
  }, []);

  const { officeLoading, officeError, officeData, totalRecords } = useSelector(
    (state) => state.officeType
  );

  const { createFranchiseData } = useSelector((state) => state.firmDetails);
  const [brokrageAddress, setBrokrageAddress] = useState("")
  const [officeAddress, setOfficeAddress] = useState("")
  const handleCancelModal = () => {
    dispatch(firmDetailsHandle.resetImageUrl());
    form.resetFields();
    setBrokrageAddress("")
    setOfficeAddress("")
    setShowCreateModal(false)
    // setChooseModal(false)
    dispatch(firmDetailsHandle.getAdminSearchData([]));

  };
  const handleCancelModal2 = () => {
    dispatch(firmDetailsHandle.resetImageUrl());
    setData({
      name: "",
      email: "",
      phoneNumber: "",
      unit: "",
      association: "",
      state: "",
      city: "",
      streetName: "",
      zipCode: "",
      streetNumber: "",
      officeLogo: "",
    })
    setShowModal(false);
  };


  // <-----creation modal function------------->
  const [currentPage, setCurrentPage] = useState(1);
  const [isFjggModal, setisFjggModal] = useState({ vissible: false, id: "" });
  const { FJGGAcessData, FJGGAcessDataLoading, SenMailforClauseLoading } = useSelector((state) => state.firmDetails);
  const handleSendMail = () => {
    dispatch(firmDetailsHandle.sendMailForClauses(setisFjggModal, isFjggModal))
    setisFjggModal({ visible: false });
  };

  const showFjggModal = (id) => {
    setisFjggModal({ visible: true, id });
    dispatch(firmDetailsHandle.getFJGGAccess(id));
  }

  useEffect(() => {
    if(addJoiningCodeModal?.openAdminModal){
      dispatch(firmDetailsHandle.resetAdminSearchData());
      addAdminForm.resetFields()
    }
  },[addJoiningCodeModal])

  return (
    <div>
      <Modal
        title={<span style={{ display: "flex", justifyContent: "center", color: "grey" }}>
          REQUEST TO ACCESS FJGG DOCS AND CLAUSES
        </span>}
        footer={false}
        visible={isFjggModal.visible}
        onCancel={() => setisFjggModal({ visible: false })}
      >
        {
          FJGGAcessDataLoading ? <div
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}><Spin /></div> :
            FJGGAcessData?.isEmailRequestSent ? (
              <div style={{ height: "300px" }}>
                {FJGGAcessData?.isApproved ? (
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", maxWidth: "350px", margin: "auto" }}>
                    <CheckCircleFilled style={{ fontSize: '4em', color: "green" }} />
                    <Typography.Text style={{ fontSize: "1.4em", fontWeight: "bold", color: "grey" }}>
                      Congrats!
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "1.4em", fontWeight: "bold", color: "grey", textAlign: "center" }}>
                      Your request to access FJGG documents and clauses have been approved.
                    </Typography.Text>
                  </div>
                ) : (
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "100%",
                    margin: "auto",
                    padding: "20px",
                    borderRadius: "10px",
                  }}>
                    <SyncOutlined style={{ fontSize: '4em', color: "#555", marginBottom: "20px" }} spin />
                    <Typography.Text style={{ fontSize: "1.4em", fontWeight: "bold", color: "#555", textAlign: "center", marginBottom: "10px" }}>
                      Your request to access FJGG Docs and Clauses has been successfully submitted and is now under process.
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: "1.4em", fontWeight: "bold", color: "#555", textAlign: "center" }}>
                      Your existing data will be updated automatically once they approve the request.
                    </Typography.Text>
                  </div>

                )}
              </div>
            ) : (
              <>
                {SenMailforClauseLoading ? (
                  <div style={{
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <Spin />
                  </div>
                ) : (
                  <div style={{ textAlign: "center", height: "300px", display: "flex", flexDirection: "column", gap: '20px', justifyContent: "center", alignItems: "center", marginTop: "-20px", marginBottom: "10px" }}>
                    <Typography.Text style={{ fontSize: "20px", color: "grey", fontWeight: "bold" }}>
                      Send request to access FJGG Docs and Clauses.
                    </Typography.Text>
                    <Button type="primary" style={{ width: "150px" }} onClick={handleSendMail}>
                      Send email
                    </Button>
                  </div>
                )}
              </>
            )
        }

      </Modal>
      <Modal
        visible={showCreateModal}
        style={{ top: 20 }}
        footer={false}
        onCancel={handleCancelModal}
        onOk={() => { }}
      >
        <CreateFirms setShowCreateModal={setShowCreateModal}
          form={form} isBrokerageLogoRequired={isBrokerageLogoRequired} setIsBrokerageLogoRequired={setIsBrokerageLogoRequired}
          brokrageAddress={brokrageAddress} setBrokrageAddress={setBrokrageAddress}
          officeAddress={officeAddress}
          setOfficeAddress={setOfficeAddress}
        />
      </Modal>
      <Modal
        width={"580px"}
        padding={"20px"}
        // bodyStyle={{ maxHeight: '400px', overflow: 'auto' }}
        visible={showModal}
        style={{ top: 20, }}
        footer={false}
        onCancel={handleCancelModal2}
        onOk={() => { }}
      >
        <Createmodal officeData={officeData} setShowModal={setShowModal} setData={setData} data={data}
          formtag={formtag} dataid={dataid} />
      </Modal>

      <Modal visible={addJoiningCodeModal.openModal} title={<div style={{ textAlign: "center", color: "#178DFA", fontWeight: "bold", fontSize: "20px" }}>Add Brokerage Code</div>} onCancel={handleJoinBrokerageModalCancel} footer={false}>
        <Form form={joinBrokerageForm} onFinish={handleJoinBrokerage} {...formItemLayout}>
          <Form.Item label="Brokerage Code" name="brokerageCode" required>
            <Input placeholder="Enter Brokerage Code..."></Input>
          </Form.Item>
          <Row justify={"end"} gutter={0} style={{ width: "100%" }}>
            <Col>
              <Button onClick={handleJoinBrokerageModalCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }}>Add</Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal visible={addJoiningCodeModal.openAdminModal} title={<div style={{ textAlign: "center", color: "#178DFA", fontWeight: "bold", fontSize: "20px" }}>Add Admin</div>} onCancel={handleCancelAdminModal} footer={false}>
      <Form form={addAdminForm} onFinish={handleAdminForm} {...formItemLayout}>
       <Form.Item
                  label="Assign an admin"
                  name="admins"
                >
                  <Select
                    showSearch
                    mode="multiple"
                    value={""}
                    placeholder={`Assign Admin ${addJoiningCodeModal?.adminRecord?.name === "BROKERAGE" ? "Brokerage" : "Franchise"}`}
                    // style={props.style}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={(e)=>{
                      getAllAdminsToAssign({tag: addJoiningCodeModal?.adminRecord?.name, fullName: e})
                      }}
                    // onChange={()=>console.log("changing")}
                    notFoundContent={null}
                  >
                    {
                      adminSearchLoading ? <Option disabled><div style={{textAlign: "center"}}><Spin size="small"/></div></Option>
                      : adminSearchError ? "...error" : <>
                        {
                          adminSearchData?.map((element)=>{
                            return <Option value={element._id}><div>
                                { element.fullName } - {element.adminRole?.includes("ADMIN_FRANCHISE") ? "ADMIN FRANCHISE" : element.adminRole?.includes("ADMIN_BROKERAGE") ? "ADMIN BROKERAGE" : ""}
                              </div><div style={{fontSize: "10px", color: "#858585", marginTop: "-8px"}}>{element.email}</div></Option>
                          })
                        }
                      </>
                    }
                  </Select>
                </Form.Item>
                <Row justify={"end"} gutter={0} style={{ width: "100%" }}>
                  <Col>
                    <Button onClick={handleCancelAdminModal}>Cancel</Button>
                    <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }}>Add</Button>
                  </Col>
                </Row>
              </Form>
      </Modal>

      {officeLoading || SenMailforClauseLoading ? (
        <div
          style={{
            display: "flex",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : officeError ? (
        <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "5px 30px 10px 10px",
            }}
          >
            <div>
              <Button disabled={disableBtn} onClick={() => { handleResetFilters(); setdisableBtn(true) }} size="small" style={{ marginLeft: "10px" }}>Reset Filters</Button>
            </div>
            <div>
              <h1 style={{fontSize: "large"}}>Lists of Created Firms</h1>
            </div>
            <Tooltip title={"Create Firms"} placement="left" color={"#085191"}>
              <PlusCircleFilled
                style={{
                  fontSize: "30px",
                  fill: "#085191",
                  color: "#085191",
                }}
                onClick={() => {
                  setShowCreateModal(true)
                  setIsBrokerageLogoRequired(true)
                }}
              />
            </Tooltip>
          </div>
          <Table
            columns={firmCreationColoums}
            scroll={{ y: "62vh" }}
            dataSource={officeData}
            bordered
            pagination={{
              position: ["bottomCenter"],
              showSizeChanger: false,
              pageSize: officeData?.length,
              current: currentPage,
              total: totalRecords,
              onChange: (page) => {
                if (!searchRef.current) {
                  dispatch(officeTypeHandle.getOfficeTypes("createFirm", {
                    page: page,
                  }));
                }
                setCurrentPage(page);
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default FirmCreation;
