import { Modal, Select, Spin, Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { officeTypeHandle } from "./state/actions";
import Created from "./components/Created";
import { useState } from "react";
// import Accessed from "./components/Accessed";
import UpdatedFirmsVerify from "./components/Updated";

const { Option } = Select;

export default function OfficeType() {
  const [toggle, setToggle] = useState('create');
  const dispatch = useDispatch();
 
 const handleToggle = (value) => {
    setToggle(value);
  };

  return (
    <div >
    <div style={{ padding: "10px", height: `100%`, }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "38%",
          marginBottom: "15px",
        }}
      >
        <Select style={{ width: 120 }} value={toggle} onChange={(value)=>handleToggle(value)}>
          <Option value="create">Created</Option>
          <Option value="update">Updated</Option>
        </Select>
        <div style={{ fontSize: "20px", fontWeight: 600 }}>
          {(toggle == "create") ? "Verify Created Firms" : "Verify Updated Firms"}
        </div>
      </div>
      {toggle == "update" ? 
        <UpdatedFirmsVerify />: <Created />}
        </div>
        </div>
  )
      }
       