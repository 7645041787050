import { Select, Image, message, Spin } from 'antd';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closingActions } from '../state/actions';
import { Loading } from '../../Common/components/Loading';

const CompanyTitle = ({isMobile}) => {
  const { Option } = Select;
  const dispatch = useDispatch()
  const { openTitleCompanyModal, selectOffice, companyModalStep, titleOffice, titleCloser, titleRep } = useSelector((state) => state.transactionReport);
  const { contractAuthKey } = useSelector((state) => state.createOffer);
  const { getTitleCompanysData, getTitleCompanyLoading } = useSelector(
    (state) => state.transactionReport
  )
  const handleSelectedValues = (value) => {
    dispatch(closingActions.titleCompanyModalState({
      selectOffice: value,
    }));
    dispatch(closingActions.getTitleOffice(value._id, contractAuthKey))
    dispatch(closingActions.titleCompanyModalState({
      companyModalStep: (companyModalStep < 3) ? (companyModalStep + 1) : 3,
    }));
  }
  return (
    <div style={{ width: "100%", margin: "auto", textAlign: "center" }}>

      {/* <div><text style={{fontSize:"18px",color:"green",fontWeight:"bold"}}>Please make your selection : </text><text style={{fontSize:"16px",color:"gray"}} >
    Click on <text style={{fontSize:"16px",color:"gray",fontWeight:"bold"}}>TITLE COMPANY LOGO</text> to order title</text></div> */}
    {getTitleCompanyLoading ? (
         <Spin style={{textAlign:"center",width:"100%"}}/>
        ) :
     
      <div style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: "10px",
      }}>
        {getTitleCompanysData && getTitleCompanysData.map((element, index) => {
          return (
            <div
              style={{
                height: isMobile?'60px':"120px",
                cursor: "pointer"

              }}
              onClick={() => handleSelectedValues(element)}
            >
              <img
                src={element.officeLogo}
                style={{
                  width: "auto",
                  height: "100%",
                  objectFit: "contain",
                  maxWidth: "100%"
                }}
                alt=""
              />
            </div>
          );
        })}
      </div>}

      {/* <Select
    mode="single"
    showArrow
    placeholder="Select Company Details"
    onChange={handleSelectedValues}
    // tagRender={tagRender}
    style={{ width: "400px" ,margin:"auto"}}
  >
  
    {getTitleCompanyLoading?<Loading/>: getTitleCompanysData &&
      getTitleCompanysData.map((element, index) => {
        return (
          <Option key={index} value={element._id} >
           <div style={{display:"flex",justifyContent:"space-around",alignItems:"center"}}><h3>{element.name}</h3> 
            <Image src={element.officeLogo} width={"60px"} height={"30px"}/>
            </div>
          </Option>
        );
      })}
  </Select> */}
    </div>
  )
}
export { CompanyTitle }