import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();
const apiURL = process.env.REACT_APP_BASE_URL;
const getLogsDataRequest = (queryData) => {
    // console.log("queryData", queryData);
    if (!queryData.limit) queryData.limit = 5;
    if (!queryData.page) queryData.page = 1;
    // Construct the URL with the provided query parameters
    const url = `${apiURL}/createdLogs`;
    // Make the request with the constructed URL and headers
    return axios.get(url, {
        params: queryData, // Pass the query parameters here
        headers: {
            accept: "application/json",
            authorization: `bearer ${localStorage.getItem("auth")}`,
        },
    });
};
export const logsDataApi = {
    getLogsDataRequest,
}