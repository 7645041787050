/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Modal, Row, Tooltip, Spin } from "antd";
import { FileZipOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { IoIosCreate } from "react-icons/io";
import { MdDriveFolderUpload, MdOutlineUploadFile } from "react-icons/md";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { transactionLaneDataHandle } from "../state/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import styles from '../style/DoraDocument.module.css';
import { useMediaQuery } from "react-responsive";
import DoraDocLegendModal from "./DoraDocLegendModal";
import { getCoAgentsIds } from "../../Common/utils/universalFuntions";
import { contractDataHandle } from "../../CreateOffer/state/actions";
import axios from "axios";
import { removePersonObject } from "../../Common/utils/universalFuntions";

const StyledTree = styled.div`
  line-height: 1.5;
  width: 100%
`;
const StyledFile = styled.div`
  padding-left: px;
  display: flex;
  align-items: center;
  span {
    margin-left: 0px;
  }
`;


const File = ({
  doraDocItem,
  selectedData,
  getTransactionLaneData,
  insideFolder,
  userAuthKey,
  isSmallScreen,
  isMediumScreen
}) => {
 let dispatch = useDispatch();
  const { URLofferTableJSON, isAccpetedOfferPresent, checkForDisable, currentSellerAgentData, } = useSelector((state) => state.offerTable);
  const { propertyDocs } = useSelector((state) => state.createOffer, shallowEqual);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const history = useHistory();
 
  const urlShortHand = (ele) => {
   let buildData = {
      builtForId: currentSellerAgentData?._id,
      builtForSection: "DOCUMENT",
      signable: true,
      openFor: "SELLER_AGENT",
      contractId: ele.contractType === "SPD" ? "64f97e26494d69c486381500" : ele.contractId,
      contractType: ele.contractType,
      offerId: selectedData?.offerId,
      propertyId: URLofferTableJSON?.propertyId,
      token: userAuthKey,
      RTDInitiator: "SELLER",
      buyerAgentIds: getCoAgentsIds(selectedData?.buyerAgentIds),
      sellerAgentIds: getCoAgentsIds(selectedData?.sellerAgentIds),
      urlData: {
        ...selectedData?.transactionData,
        Sellers: removePersonObject(selectedData?.sellerIds),
         ...(ele?.prefillValues || {}),
      },
    };
    dispatch(transactionLaneDataHandle.doraDocsUrlShorthandApiCalling(buildData, userAuthKey, history));
  };

  const handleCreateDoraDoc = () => {
    setIsModalVisible(true);
  }

  let docInRtd = false;
  getTransactionLaneData?.forEach((el) => {
    if (el.contractType === doraDocItem.contractType) {
      docInRtd = true;
    }
  });
const {doraDocsUrlShorthandLoading} = useSelector(
    (state) => state.rtdTransactionLane
);
  const disbleCOCondition = URLofferTableJSON?.urlData?.sellerOpenOfferTable || isAccpetedOfferPresent  || selectedData?.data?.offerStatus === "TERMINATED" || checkForDisable?.disabled;
  const notshowToolTip= URLofferTableJSON?.urlData?.sellerOpenOfferTable || selectedData?.data?.offerStatus === "TERMINATED" || checkForDisable?.disabled;
return (
  <StyledFile>
    <Tooltip title={isAccpetedOfferPresent && !notshowToolTip ? <span style={{ fontSize: isSmallScreen ? 10 : isMediumScreen ? 12 : 14 }}>Accepted Offer Exists: Cannot Create This Document</span> : ""}>
      <span
        style={{
          fontSize: isSmallScreen ? "14px" : isMediumScreen ? "16px" : "18px",
          fontWeight: 500,
          color: disbleCOCondition ? "#b0b0b0" : docInRtd ? "rgb(110 153 214)" : "#8C8C8C",
          width: "100%",
          marginLeft: insideFolder ? "21px" : "auto",
          paddingLeft: 0,
          cursor: notshowToolTip ? "not-allowed" : isAccpetedOfferPresent ? "context-menu" : "pointer",
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        {doraDocItem.name && (
          <FileZipOutlined
            className={disbleCOCondition || notshowToolTip ? "" : styles.fileContainer}
            style={{
              fontSize: isSmallScreen ? "14px" : isMediumScreen ? "16px" : "20px",
              marginRight: "5px",
              flexShrink: 0,
            }}
          />
        )}

        <span
          className={disbleCOCondition || notshowToolTip ? "" : styles.fileContainer}
          onClick={() => ( disbleCOCondition || notshowToolTip  ? {} : handleCreateDoraDoc())}
          style={{
            wordBreak: "break-word",
            display: "inline-block",
          }}
        >
          {doraDocItem.name}
        </span>
      </span>
    </Tooltip>

    <Modal visible={isModalVisible} onCancel={handleCancel} footer={[]} style={{ height: "300px" }}>
      {doraDocsUrlShorthandLoading ? (
        <div style={{ width: "100px", height: "260px", display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", textAlign: "center" }}>
          {" "}
          <Spin />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            gap: "5px",
            width: window.screen.width > 850 ? "51%" : "80%",
            margin: "auto",
            paddingLeft: "30px",
            fontSize: isSmallScreen ? "14px" : "20px",
          }}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "#2976be",
              cursor: "pointer",
            }}
            onClick={() => urlShortHand(doraDocItem)}
          >
            <IoIosCreate size={25} /> Create New
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "darkgray",
              cursor: "pointer",
              pointerEvents: "none",
            }}
          >
            <MdOutlineUploadFile size={25} /> Upload Image
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "darkgray",
              cursor: "pointer",
              pointerEvents: "none",
            }}
          >
            <MdDriveFolderUpload size={25} /> Upload Document
          </p>
        </div>
      )}
    </Modal>
  </StyledFile>
);
};

const Tree = ({ children }) => {
  return <StyledTree>{children}</StyledTree>;
};

Tree.File = File;

export default function DoraDocument({ selectedData, seller, userAuthKey }) {

  const isSmallScreen = useMediaQuery({ maxWidth: 550 });
  const isMediumScreen = useMediaQuery({ minWidth: 551, maxWidth: 850 });
  const dispatch = useDispatch();
  const { getDoraDocumentsData, getTransactionLaneData, getDoraDocumentsLoading } = useSelector((state) => state.rtdTransactionLane);
  const [showLegendModal, setShowLegendModal] = useState(false);
  const [doraDocsData, setDoraDocsData] = useState([]);


  useEffect(() => {
    if (Array.isArray(getTransactionLaneData)  &&  Array.isArray(getDoraDocumentsData)) {
      const foundMH = getTransactionLaneData.some((el) => el.contractType === "CBS_MH");
      const newData = foundMH ? getDoraDocumentsData.filter((el) => el.contractType === "MH_CO") : getDoraDocumentsData.filter((el) => el.contractType === "CO");
      setDoraDocsData(newData);
    }
  }, [getTransactionLaneData, getDoraDocumentsData]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    let unmounted = {
      value: false,
    };
    let params = {
      ...(selectedData?.thirdpartyId ? { thirdpartyIdDoc: selectedData?.thirdpartyId } : {}),
      ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
      ...(selectedData?.contractType === "ERTS" || selectedData?.ertsId ? { ertsId: selectedData?.contractType === "ERTS" ? selectedData?.documentId : selectedData?.ertsId } : {}),
      openFor: "SELLER_AGENT",
      personId: selectedData?.sellerAgentId?._id,
    };
    if (userAuthKey && selectedData?.sellerAgentId?._id ) {
      dispatch(contractDataHandle.getPropertyDocsFun({ params, unmounted, source, contractAuthKey:userAuthKey }));
    }
    return () => {
      unmounted.value = true;
      source.cancel();
    };
  }, [userAuthKey, selectedData?.sellerAgentId?._id]);

  return (
    <>
      {showLegendModal && <DoraDocLegendModal showLegendModal={showLegendModal} setShowLegendModal={setShowLegendModal} />}
      {getDoraDocumentsLoading ? ( 
        <Loading />
      ) : (
        <Row className={styles.DoraDocumentContainer}>
          <Col style={{ width: "100%" }}>
            <div className={styles.doraDocHeading}>
              <h3>Documents</h3>
              <div>
                <Tooltip title="Legend" placement="right">
                  <InfoCircleOutlined
                    onClick={() => setShowLegendModal(true)}
                    style={{
                      fontSize: isSmallScreen ? "18px" : isMediumScreen ? "20px" : "25px",
                      cursor: "pointer",
                      fontWeight: "bold",
                      color: "hsl(209deg 72% 51%)",
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
              overflowY: "auto",
              height: "67vh",
              paddingTop: "10px",
              width: "100%",
            }}
          >
            <Tree>
              {doraDocsData?.map((item) => (
                <Tree.File key={item._id} doraDocItem={item} selectedData={selectedData} seller={seller} getTransactionLaneData={getTransactionLaneData} insideFolder={false} userAuthKey={userAuthKey} isSmallScreen={isSmallScreen} isMediumScreen={isMediumScreen} />
              ))}
            </Tree>
          </Col>
        </Row>
      )}
    </>
  );
}
