import { Button, Form, Modal, message,Image, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { TitleCompanyDetails } from "../../TransactionReport/components/TitleCompanyDetails";
import { closingActions } from '../../TransactionReport/state/actions';
import { useMediaQuery } from 'react-responsive';
import Dragger from 'antd/lib/upload/Dragger';
import {InboxOutlined,LoadingOutlined, LeftOutlined, RightOutlined, MoreOutlined, DeleteTwoTone, ExclamationCircleOutlined, LinkOutlined } from "@ant-design/icons";

function TitleOfficeModal() {
    const dispatch = useDispatch();
    const [fileText, setFileText] = useState({ name: null, size: null });
    const [loading, setLoading] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [disabledSaveButton, setSisabledSaveButton] = useState(false);
    const [upload, setUpload] = useState(null);
    const Text = Typography;
    const [form] = Form.useForm();
    const { confirm } = Modal;
    const isMobile = useMediaQuery({ maxWidth: 500 });
    const { openTitleCompanyModal,titleOfficeLogoModal, selectOffice, companyModalStep, titleOffice, titleCloser, titleRep } = useSelector((state) => state.transactionReport);
    const { currentUrlId, contractAuthKey, rootDocData,offerId } = useSelector((state) => state.createOffer);
    const titleCompanyModalOnCancel = () => {
        dispatch(closingActions.titleCompanyModalState({
            openTitleCompanyModal: false,
            selectOffice: null,
            titleOffice: null,
            titleCloser: null,
            titleRep: null,
            companyModalStep: 0,
        }));
    }
    const handleCancel = () => {
        dispatch(closingActions.titleCompanyModalState({
            titleOfficeLogoModal: false,
        }));
    }
    const onFinish = (values) => {
        let file = values?.upload?.file ? values?.upload?.file : {}
        let payload = {
            file: file,
            offerId: offerId,
            titleOfficeId: titleOffice || "",
            isFileUpload: true
        }
        dispatch(closingActions.addTitleOffice(payload, contractAuthKey, rootDocData))
        handleCancel()
    };

    function beforeUpload(file) {
        let supportedFormats = file?.type;

        if (supportedFormats.includes("image")) {
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                message.error("You can only upload JPG/PNG file!");
            }
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error("Image must smaller than 5MB!");
            }
            return isJpgOrPng && isLt5M;
        }

        if (supportedFormats.includes("pdf")) {
            const isPdf = file.type === "application/pdf";
            if (!isPdf) {
                message.error("You can only upload PDF file!");
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error("File must be smaller than 2MB!");
            }
            return isPdf && isLt2M;
        }
    }

    function bytesToSize(bytes) {
        let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    }

    let handleCustome = (event) => {
        // Setting name to show the name of image
        setFileText({ name: event.file.name, size: bytesToSize(event.file.size) });
        setLoading(true);
        setSpinning(true);
        // Preview the image before uploading to our server
        const file = event.file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");
            setPreviewUrl(base64String);
            setSpinning(false);
        };
    };
    useEffect(() => {
        let handlePasteEvent = (event) => {
            setLoading(true);
            setSpinning(true);
            let count = 0;
            var items = (event.clipboardData || event.originalEvent.clipboardData)
                .items;
            for (let index in items) {
                var item = items[index];
                if (item.kind === "file" && count === 0) {
                    var blob = item.getAsFile();
                    var reader = new FileReader();
                    const ide = Math.floor(10000 + Math.random() * 90000);
                    const imageName = "pastedImage" + ide;
                    var updatedBlob = new File([blob], imageName, { type: blob.type });
                    let file = { ...updatedBlob, originFileObj: updatedBlob };
                    let uploadData = { upload: { file: file } };
                    let checkImage = beforeUpload(updatedBlob);
                    if (checkImage) {
                        setSisabledSaveButton(false);
                        setUpload(uploadData);
                        reader.onload = () => {
                            const base64String = reader.result
                                .replace("data:", "")
                                .replace(/^.+,/, "");
                            setPreviewUrl(base64String);
                            setSpinning(false);
                        };
                    } else {
                        setSisabledSaveButton(true);
                    }
                    setSpinning(false);
                    reader.readAsDataURL(blob);
                    count = +count + 1;
                }
            }
        };
        let getModal = document;
        getModal.addEventListener("paste", handlePasteEvent);
        return () => {
            getModal.removeEventListener("paste", handlePasteEvent);
        };
    }, []);

    const HandlePasteImage = () => {
        let file = upload?.upload?.file ? upload?.upload?.file : {}
        let payload = {
            file: file,
            offerId: offerId,
            titleOfficeId: titleOffice || "",
            isFileUpload: true
        }
        dispatch(closingActions.addTitleOffice(payload, contractAuthKey, rootDocData))
        handleCancel()
    };

    return (

        <>
            <Modal
                width={"700px"}
                height={isMobile ? "80vh" : "auto"}
                visible={openTitleCompanyModal}
                onCancel={titleCompanyModalOnCancel}
                title={
                    <span style={{ display: "flex", justifyContent: "center", fontSize: "22px", color: "grey" }}>
                        {selectOffice ? (
                            <div style={{ display: "flex", alignItems: "center", height: "30px" }}>
                                <div style={{ alignItems: "center", height: selectOffice?.name === "Land Title" ? "50px" : "30px" }}>
                                    <img
                                        src={selectOffice?.officeLogo}
                                        style={{
                                            width: "auto",
                                            height: "100%",
                                            objectFit: "contain",
                                            maxWidth: "100%"
                                        }}
                                        alt=""
                                    />
                                </div>
                                <text style={{ fontSize: "22px", fontWeight: "bold", paddingLeft: "10px", textDecoration: "underline" }}>{selectOffice?.name}</text>{" "}

                            </div>
                        ) : (
                            <text style={{ fontWeight: "bold", fontSize: isMobile ? "small" : "22px" }}>ADD TITLE COMPANY</text>
                        )}
                    </span>
                }
                footer={false}
                style={{ position:'fixed',overflow:isMobile ?"auto":"hidden",top: isMobile ? '10vh':'5vh',right:10,left:10 }}
            >
                <TitleCompanyDetails isMobile={isMobile}/>
            </Modal>

            <Modal style={{ minHeight: "400px" }}
                title={<span style={{ display: "flex", justifyContent: "center", color: "grey" }}>UPLOAD IMAGE</span>}
                visible={titleOfficeLogoModal}
                onCancel={handleCancel}
                maskClosable={true}
                footer={false}>
                <Form onFinish={onFinish} autoComplete="off" id="formName" form={form}>
                    <div style={{ display: loading ? "none" : "flex" }}>
                        <Form.Item
                            name="upload"
                            // label="Upload File"
                            rules={[{ required: true, message: "Please select file!" }]}
                        >
                            <Dragger
                                accept="image/png, image/jpeg, image/gif"
                                showUploadList={false}
                                customRequest={handleCustome}
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                </p>
                                <p className="ant-upload-hint">
                                    Support for a single Image upload. Strictly prohibit from
                                    uploading company data or other band files
                                </p>
                            </Dragger>
                        </Form.Item>
                    </div>

                    <Form.Item>

                        <div
                            style={{
                                width: "10%",
                                margin: "auto",
                                display: spinning ? "flex" : "none",
                            }}
                        >
                            <LoadingOutlined
                                style={{
                                    fontSize: 64,
                                }}
                                spin
                            />
                        </div>
                        <div
                            style={{
                                display: previewUrl ? "flex" : "none",
                                width: "40%",
                                margin: "auto",
                            }}
                        >
                            {previewUrl && <Image src={`data:image/png;base64,${previewUrl}`} />}
                        </div>
                    </Form.Item>

                    {fileText.name && (
                        <Form.Item >
                            <div style={{ textAlign: "center" }}>
                                <Text type="secondary"><strong>File Name:</strong> {fileText.name}</Text>
                                <br />
                                <Text type="secondary"><strong>Size:</strong> {fileText.size}</Text>
                            </div>
                        </Form.Item>
                    )}
                    <Form.Item
                        style={{ marginTop: "-10px" }}
                        wrapperCol={{ offset: 10, span: 14 }}
                    >
                        <Button
                            disabled={disabledSaveButton}
                            type="primary"
                            htmlType="submit"
                            style={{ marginRight: "15px", marginLeft: "-20px" }}
                            onClick={() => {
                                if (upload) {
                                    HandlePasteImage();
                                }
                            }}>
                            Save
                        </Button>
                        <Button type="primary" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
 
        </>
    )
}

export default TitleOfficeModal