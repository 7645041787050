import axios from "axios";
import dotEnv from "dotenv";
// import fetch from 'node-fetch';
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// with access token
const buglistingPageLoadRequest = (query) => {
  // let params={
  //   idTitleSearch:query?.bugidtitle,
  //   nameEmailSearch:query?.createdBy,
  //   contentSearch:query?.content,
  //   filterByStatus:query?.filterbystatus,
  //   page:1,
  //   limit:30,
  // }
  let URLlink = `${apiURL}/bugReports`;
    
  let accessTokan = localStorage.getItem("auth");
  const config = {
    method: "GET",
    params:{
      ...query
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(URLlink, config);
};

// bug status
const bugStatusRequest = (id, status) => {
  let accessTokan = localStorage.getItem("auth");
  const config = {
    method: "PUT",
    data: {
      status: status,
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/bugReport/${id}`, config);
};

// Profile Image upload
const uploadImageforProfileRequest = (data, origin) => {
  let accessTokan = localStorage.getItem("auth");
  if (origin === "base64") {
    return axios.post(
      `${apiURL}/common/uploadDocsBase64`,
      new URLSearchParams({
        fileBase64: data,
        fileType: "IMAGE",
        folderOf: "PROPERTY_DOCS_IMAGE",
      }),
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }


  var params = new FormData();
  params.append("fileBase64", data);
  params.append("folderOf", "BUGS");

  return axios.post(`${apiURL}/common/uploadFile`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const bugPostDeleteRequest = (id) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.delete(`${apiURL}/bugReport/${id}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const bugreportcomment = (id) => {
  
  let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/bugReport/${id}/comments`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const bugCommentRequest = (id, data) => {
  let accessTokan = localStorage.getItem("auth");
  const config = {
    method: "POST",
    data: {
      comment: data,
    },
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  };
  return axios(`${apiURL}/bugReport/${id}/comment`, config);
};
export const bugDataApi = {
  buglistingPageLoadRequest,
  uploadImageforProfileRequest,
  bugStatusRequest,
  bugPostDeleteRequest,
  bugreportcomment,
  bugCommentRequest
};
