import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { contractStoreHandle } from '../../ContractStore/state/actions';
import ClauseData from './ClauseData';
import DocsData from './DocsData';
function AddDocClause({ setOpenClauseModal, currentUrlId, contractAuthKey, values }) {
    const [addClauseData, setAddClauseData] = useState([]);
    const [existingValues, setExistingValues] = useState([]);
    const [isValeChanged, setIsValeChanged] = useState(false);
    const dispatch = useDispatch();
    
    useEffect(() => {
        let localData = JSON.parse(localStorage.getItem(currentUrlId));
        if ((localData?.addClauseData || localData?.addDocumentData)) {
            if (values?.value === 'Add Clauses') {
                setAddClauseData(localData.addClauseData);
            } else if (values?.value === 'Add Docs') {
                setAddClauseData(localData.addDocumentData);
            }
        }
        let getExistingValues = [];
        let targetTextArea = document.querySelector(`textarea[name='${values?.refName}']`);
        let targetInputBox = document.querySelector(`input[name='${values?.refName}']`);
        if (targetTextArea) {
          getExistingValues = targetTextArea?.parentNode?.dataset?.replicatedValue?.split('\n') || [];
          getExistingValues = getExistingValues.map((el) => {
            // removing the extra numbers and dots in the array if present
            // const match = el.match(/^\d+\. (.+?)\.?$/);
            const match = el.match(/^\d+\. (.+?\.?)$/);
            return match ? match[1] : el;
          });
        }
        else if (targetInputBox) {
          getExistingValues = targetInputBox?.value.split(', ') || [];
        }
        setExistingValues(getExistingValues.filter(el=>el?.length > 0));
    }, [currentUrlId]);

    let handleOk = () => {
        setOpenClauseModal(false);
        let targetTextArea = document.querySelector(`textarea[name='${values?.refName}']`);
        let targetInputBox = document.querySelector(`input[name='${values?.refName}']`);
        let valueObj = {};
        if (targetTextArea) {
            // let selectedClausesList = selectedClauses.map((el, i) => el?.name).join('.\n').trim()+'.';
            let selectedClausesListForParent = existingValues.map((el)=>el).join("\n").trim();
            let selectedClausesList = existingValues.map((el,i)=>`${i+1}. ${el}`).join("\n").trim();
            targetTextArea.value = selectedClausesList;
            if (targetTextArea.parentNode?.classList?.contains("grow-with-content")) {
                targetTextArea.parentNode.dataset.replicatedValue = selectedClausesListForParent;
            }
            valueObj[values?.refName] = selectedClausesList; // for autosave
            const currentUrlIdData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
            currentUrlIdData[values?.refName] = selectedClausesList;
            localStorage.setItem(currentUrlId, JSON.stringify(currentUrlIdData));

        } else if (targetInputBox) {
            // let selectedDocsList = selectedClauses.map((el, i) => el?.name).join(', ').trim();
            let selectedDocsList = existingValues.map((el, i) => el).join(', ').trim();
            targetInputBox.value = selectedDocsList;
            if (targetInputBox.parentNode?.classList?.contains("grow-with-input")) {
                targetInputBox.parentNode.dataset.replicatedValue = targetInputBox.value;
            }
            valueObj[values?.refName] = selectedDocsList;
            const currentUrlIdData = JSON.parse(localStorage.getItem(currentUrlId)) || {};
            currentUrlIdData[values?.refName] = selectedDocsList;
            localStorage.setItem(currentUrlId, JSON.stringify(currentUrlIdData));
        }
        setTimeout(()=>{
            let checkValue = false;
            for(let e in valueObj) {
                if (valueObj[e]) {
                    checkValue = true
                    break;
                }
            }
            if (checkValue) { // If there is no value then don't call API
                dispatch(contractStoreHandle.autoSaveContract({currentUrlId, contractAuthKey, valueObj}));
            } else if (!checkValue && isValeChanged) { // But if value cleared then also there's no value > call the API
                dispatch(contractStoreHandle.autoSaveContract({currentUrlId, contractAuthKey,  valueObj}));
            }
        },2000)

    };
    const handleSelectChange = (selectedValues) => {
        setIsValeChanged(true);
        // setSelectedClauses(selectedValues.map(value => addClauseData.find(el => el.name === value)));
        setExistingValues(selectedValues && selectedValues);
    };

    let handleCancel = () => {
        setOpenClauseModal(false);
    };

    return (
        <>
            {values?.value === 'Add Clauses' ?
                <ClauseData values={values} handleSelectChange={handleSelectChange} existingValues={existingValues} addClauseData={addClauseData} handleCancel={handleCancel} handleOk={handleOk} />
                :
                <DocsData values={values} handleSelectChange={handleSelectChange} existingValues={existingValues} addClauseData={addClauseData} handleCancel={handleCancel} handleOk={handleOk} />
            }
        </>
    );
}

export default AddDocClause
