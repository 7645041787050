import { documentConstants } from "../action-types";

const initialState = {
  urlImportData: {},
  CurrentPdfImportData: {},
  urlImportError: null,
  urlImportErrorAccured: false,
  pdfImportError: false,
};

const urlImportReducer = (state = initialState, action) => {
  switch (action.type) {
    case documentConstants.URL_IMPORT_SUCCESS:
      return {
        ...state,
        urlImportData: action.payload,
        CurrentPdfImportData: action.payload,
        urlImportError: false ,
        pdfImportError: false,
      };
    case documentConstants.URL_IMPORT_ERROR:
      return {
        ...state,
        urlImportData: {},
        CurrentPdfImportData: {},
        urlImportError: action.payload,
        urlImportErrorAccured: true,
      };
    case documentConstants.PDF_IMPORT_ERROR:
      return {
        ...state,
        pdfImportError: true,
      };
      case documentConstants.SET_PDF_IMPORT_STATE:
        return {
          ...state,
          CurrentPdfImportData:{...state.CurrentPdfImportData,  ...action.payload},
        };
    case documentConstants.URL_IMPORT_CLEAR_SUCCESS:
      return {
        ...state,
        ...initialState
      };

    case documentConstants.URL_IMPORT_ERROR_CLEAR:
      return {
        ...state,
        urlImportErrorAccured: false,
      };

    default:
      return state;
  }
};
export { urlImportReducer };
