import { ndaConstants } from "./action-types";

const initialState = {
  ndaData: null,
  ndaloading: true,
  ndaErrorData: null,
  totalRecords:0,

  getNDAData: null,
  getNDALoading: true,
  getNDAError: null,

  uploadData: null,
  imageloading: true,
  uploadError: null,

  getNDA_jsx: null,
  getNDA_jsx_validation: [],
  getNDA_JSXLoading: true,
  getNDA_JSX_Error: null,

  submitEmailData: null,
  submitEmailLoading: true,
  submitEmailError: null,

  pdfUploadData: null,
  pdfLoading: true,
  pdfUploadError: null,
  pdfUploadthumbnail: null,
};

const nda = (state = initialState, action) => {
  switch (action.type) {
    // POST NDA data from the webview LINK
    case ndaConstants.POST_NDA_SUCCESS:
      return {
        ...state,
        ndaData: action.ndaData,
        ndaloading: action.ndaloading,
      };
    case ndaConstants.POST_NDA_FAILURE:
      return {
        ...state,
        ndaErrorData: action.ndaErrorData,
        ndaloading: action.ndaloading,
      };

    // upload Signature
    case ndaConstants.IMAGE_UPLOAD_PDF_SUCCESS:
      return {
        ...state,
        uploadData: action.uploadData,
        imageloading: action.imageloading,
      };
    case ndaConstants.IMAGE_UPLOAD_PDF_FAILURE:
      return {
        ...state,
        uploadError: action.uploadError,
        imageloading: action.imageloading,
      };
    // Get NDA Data
    case ndaConstants.GET_NDA_SUCCESS:
      const datainfo=[]
      action.getNDAData.forEach((element) => {
        datainfo.push({
          nameSearch:
           {
            firstName: element.firstName,
            lastName: element.lastName
          },
          emailSearch:element.email,
          pdfUrl:element.pdfUrl,
          pdfFileName:element.pdfFileName,
          createdAt:element.createdAt,
          imageUrls:element.imageUrls,
          status:element.status,
          _id:element._id
        });
      });
      return {
        ...state,
        getNDAData: datainfo,
        totalRecords:action.totalRecords,
        getNDALoading: action.getNDALoading,
      };
    case ndaConstants.GET_NDA_FAILURE:
      return {
        ...state,
        getNDAError: action.getNDAError,
        getNDALoading: action.getNDALoading,
      };
    // Get NDA JSX
    case ndaConstants.NDA_JSX_SUCCESS:
      return {
        ...state,
        getNDA_jsx: action.getNDA_jsx,
        getNDA_jsx_validation: action.getNDA_jsx_validation,
        getNDA_JSXLoading: action.getNDA_JSXLoading,
      };
    case ndaConstants.NDA_JSX_FAILURE:
      return {
        ...state,
        getNDA_JSX_Error: action.getNDA_JSX_Error,
        getNDA_JSXLoading: action.getNDA_JSXLoading,
      };
    // submit email
    case ndaConstants.SUBMIT_EMAIL_SUCCESS:
      return {
        ...state,
        submitEmailData: action.submitEmailData,
        submitEmailLoading: action.submitEmailLoading,
      };
    case ndaConstants.SUBMIT_EMAIL_FAILURE:
      return {
        ...state,
        submitEmailError: action.submitEmailError,
        submitEmailLoading: action.submitEmailLoading,
      };
    // PDF upload
    case ndaConstants.PDF_UPLOAD_PDF_SUCCESS:
      return {
        ...state,
        pdfUploadData: action.pdfUploadData,
        pdfUploadthumbnail: action.pdfUploadthumbnail,
        pdfLoading: action.pdfLoading,
      };
    case ndaConstants.PDF_UPLOAD_PDF_FAILURE:
      return {
        ...state,
        pdfUploadError: action.pdfUploadError,
        pdfLoading: action.pdfLoading,
      };
    default:
      return state;
  }
};
export { nda };
