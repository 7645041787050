import { suggestionConstants } from "./action-types";
import { suggessionDataApi } from "../utils/api";

/**
 * All Actions for Admin
 */

/**
 * To update Admin state
 * @param {string} auth
 */
const createSuggessionError = (error) => {
  return {
    type: suggestionConstants.CREATE_SUGGESSION_FAILURE,
    createSuggessionError: error,
    createSuggessionLoading: false,
  };
};

const createSuggessionSuccess = (response) => {
  return {
    type: suggestionConstants.CREATE_SUGGESSION_SUCCESS,
    createSuggessionData: response.data.info,
    createSuggessionLoading: false,
  };
};

const createSuggession = (data) => {
  return (dispatch, getState) => {
    suggessionDataApi
      .createSuggessionRequest(data)
      .then((response) => {
        dispatch(createSuggessionSuccess(response));
        dispatch(getSuggesionList());
      })
      .catch((error) => {
        dispatch(createSuggessionError(error));
      });
  };
};

const getSuggesionError = (error) => {
  return {
    type: suggestionConstants.GET_SUGGESION_FAILURE,
    getSuggesionError: error,
    getSuggesionLoading: true,
  };
};

const getSuggesionSuccess = (response) => {
  return {
    type: suggestionConstants.GET_SUGGESION_SUCCESS,
    getSuggesionData: response.data.info["suggestionsArray"],
    getSuggesionLoading: false,
  };
};

const getSuggesionList = () => {
  return (dispatch, getState) => {
    suggessionDataApi
      .getSuggesionRequest()
      .then((response) => {
        dispatch(getSuggesionSuccess(response));
      })
      .catch((error) => {
        dispatch(getSuggesionError(error));
      });
  };
};

// const editCategoryError = (error) => {
//   return {
//     type: listingEditConstants.EDIT_CATEGORY_FAILURE,
//     editCategoryError: error,
//     editCategoryLoading: true,
//   };
// };

// const editCategorySuccess = (response) => {
//   return {
//     type: listingEditConstants.EDIT_CATEGORY_SUCCESS,
//     editCategoryData: response.data.info,
//     editCategoryLoading: false,
//   };
// };

// const editCategory = (data, id) => {
//   return (dispatch, getState) => {
//     categoryDataApi
//       .editCategoryRequest(data, id)
//       .then((response) => {
//         dispatch(editCategorySuccess(response));
//         dispatch(getCategoryList());
//       })
//       .catch((error) => {
//         dispatch(editCategoryError(error));
//       });
//   };
// };

// const deleteCategoryError = (error) => {
//   return {
//     type: listingEditConstants.DELETE_CATEGORY_FAILURE,
//     deleteCategoryError: error,
//     deleteCategoryLoading: true,
//   };
// };

// const deleteCategorySuccess = (response) => {
//   return {
//     type: listingEditConstants.DELETE_CATEGORY_SUCCESS,
//     deleteCategoryData: response.data.info,
//     deleteCategoryLoading: false,
//   };
// };

// const deleteCategory = (id) => {
//   return (dispatch, getState) => {
//     categoryDataApi
//       .deleteCategoryRequest(id)
//       .then((response) => {
//         dispatch(deleteCategorySuccess(response));
//         dispatch(getCategoryList());
//       })
//       .catch((error) => {
//         dispatch(deleteCategoryError(error));
//       });
//   };
// };

export const suggessionAction = {
  createSuggession,
  getSuggesionList,
  // getCategoryList,
  // editCategory,
  // deleteCategory,
};
