export const pdfUploadConstants = {
  UPLOAD_PDF_SUCCESS: "UPLOAD_PDF_SUCCESS",
  UPLOAD_PDF_FAILURE: "UPLOAD_PDF_FAILURE",

  SEND_PREVIEW_SUCCESS: "SEND_PREVIEW_SUCCESS",
  SEND_PREVIEW_FAILURE: "SEND_PREVIEW_FAILURE",
  DOCUMENTRY_LIST_SUCCESS: "DOCUMENTRY_LIST_SUCCESS",
  DOCUMENTRY_LIST_FAILURE: "DOCUMENTRY_LIST_FAILURE",

  RESET_PDF_UPLOAD: "RESET_PDF_UPLOAD",
};
