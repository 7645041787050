
export const getCombinedAgentsData = ({ getIndividualTransactionData = {}, agentData = [], agentList = [], agentType="" }) => {
  const transactionData = getIndividualTransactionData?.newTransactionData || {};
  const agentMap = agentData.reduce((acc, agent) => {
    if (agent?.profileImg?.original) {
      acc[String(agent._id)] = agent.profileImg.original;
    }
    return acc;
  }, {});

  const updatedAgents =
    transactionData[agentType]?.map((el) => {
      let profileImg;
      if (typeof el.personId === "string" && el.personId.length === 24) {
        profileImg = agentMap[el.personId];
      }
      return {
        ...el,
        ...(profileImg ? { profileImg } : {}),
      };
    }) || [];
  agentList.push(...updatedAgents);
  
};
