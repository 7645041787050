import { Col, Form, Input, Modal, Row, Select } from 'antd';
import React from 'react'
import { useDispatch } from 'react-redux';
import { contactSheetActions } from '../state/actions';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import styles from "../styles/ContactSheet.module.css";
import PhoneInput from 'react-phone-input-2';

const { TextArea } = Input;
const { Option } = Select;

function AddOrUpdateContact({ offerId, accessToken, setModalState, modalState }) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const screens = useBreakpoint();

    const handleSubmit = () => {
        form.validateFields().then(values => {
            if (modalState?.source === "ADD") {
                values = {
                    ...values,
                    type: values?.type || "",
                    email: values?.email || "",
                    fullName: values?.fullName || "",
                    mobilePhoneNumber: values?.mobilePhoneNumber || "",
                    officePhoneNumber: values?.officePhoneNumber || "",
                    fax: values?.fax || "",
                    notes: values?.notes || "",
                    contactTo: modalState?.openFor || "BUYER_AGENT",
                }
            }

            let queryData = {
                ...(modalState?.source === "UPDATE" ? { key: modalState?.key } : {}),
                openFor: modalState?.openFor,
                contactSheetId: modalState?.contactSheetId,
                type: modalState?.source,
            }
            dispatch(contactSheetActions.addOrUpdateContact({ offerId, queryData, payload: values, accessToken, openFor: modalState?.openFor }));
            handleClose();
        }).catch(info => {
            // console.log('Validate Failed:', info);
        });
    };

    const handleClose = () => {
        setModalState((prev) => ({
            ...prev,
            openAddContactModal: false,
            openIndividualFieldUpdateModal: false,
            openSendEmailModal: false,
            source: "",
            key: "",
            selectedKey: "",
            contactSheetId: "",
            ref: {},
            openFor: ""
        }))
    }

    const typeOption = [
        {label: "Other", value: 'OTHER'},
        {label: "Transaction Coordinator", value: 'TRANSACTION COORDINATOR'},
        {label: "Buyer", value: 'BUYER'},
        {label: "Seller", value: 'SELLER'},
        {label: "Co-Buyer Agent", value: 'CO-BUYER AGENT'},
        {label: "Co-Seller Agent", value: 'CO-SELLER AGENT'},
        {label: "Lender", value: 'LENDER'},
        {label: "Title Rep", value: 'TITLE REP'},
        {label: "Title Closer", value: 'TITLE CLOSER'},
        {label: "Stager", value: 'STAGER'},
        {label: "Photographer", value: 'PHOTOGRAPHER'},
        {label: "Property Inspector", value: 'PROPERTY INSPECTOR'},
    ]

    return (
        <>
            <Modal
                visible={modalState?.openAddContactModal}
                width={800}
                closable
                title={<div style={{ textAlign: "center", color: "#178DFA" }}>{modalState?.source === "UPDATE" ? "EDIT" : modalState?.source === "ADD" ? "ADD NEW" : ""} CONTACT</div>}
                okText={modalState?.source === "UPDATE" ? "Update" : modalState?.source === "ADD" ? "Add" : ""}
                onOk={handleSubmit}
                onCancel={handleClose}
                style={{
                    maxHeight: "400px",
                    top: "10vh",
                }}
            >
                <div className={styles.formContainer}>
                    <Form initialValues={{ ...modalState?.selectedKey }} form={form} layout="vertical" style={{ padding: 10 }} scrollToFirstError>
                        <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                            <Col span={screens.xs ? 24 : 11}>
                                <Form.Item name={`type`} label="Type" rules={modalState?.source === "ADD" ? [{ required: true, message: 'Please select a type' }] : ""}>
                                    <Select placeholder="Select a type" allowClear disabled={modalState?.selectedKey?.type === 'TITLE COMPANY'}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {typeOption?.map((option) => <Option value={option?.value}>{option?.label}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={screens.xs ? 24 : 11}>
                                {modalState?.selectedKey?.type === 'TITLE COMPANY' ?
                                    <Form.Item name={`titleCompanyName`} label="Company">
                                        <Input type='text' allowClear placeholder='Enter Company Name' />
                                    </Form.Item> :
                                    <Form.Item name={`fullName`} label="Name" rules={modalState?.source === "ADD" ? [{ required: true, message: 'Please enter the name' }] : ""}>
                                        <Input type='text' allowClear placeholder='Enter Full name' />
                                    </Form.Item>
                                }
                            </Col>
                        </Row>
                        <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                            <Col span={screens.xs ? 24 : 11}>
                                {
                                    modalState?.selectedKey?.type === 'TITLE COMPANY' ?
                                        <Form.Item name={`titleOfficeName`} label="Title Office">
                                            <Input type='text' allowClear placeholder='Enter Title Office Name' />
                                        </Form.Item> :
                                        <Form.Item name={`email`} label="Email" rules={[{ type: 'email', message: "Not a valid Email!" }]}>
                                            <Input type='email' placeholder='Enter Email' allowClear />
                                        </Form.Item>
                                }
                            </Col>
                            <Col span={screens.xs ? 24 : 11}>
                                {
                                    modalState?.selectedKey?.type === 'TITLE COMPANY' ?
                                        <Form.Item name={`titleOfficeEmail`} label="Title Office Email" rules={[{ type: 'email', message: "Not a valid Email!" }]}>
                                            <Input type='email' placeholder='Enter Email' allowClear />
                                        </Form.Item> :
                                        <Form.Item name={`mobilePhoneNumber`} label="Mobile Number">
                                            <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Enter Mobile Number" className="phone-input" />
                                        </Form.Item>
                                }
                            </Col>
                        </Row>
                        <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                            <Col span={screens.xs ? 24 : 11}>
                                {
                                    modalState?.selectedKey?.type === 'TITLE COMPANY' ?
                                        <Form.Item name={`titleOfficePhoneNumber`} label="Title Office Phone">
                                            <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Enter Phone Number" className="phone-input" />
                                        </Form.Item> :
                                        <Form.Item name={`officePhoneNumber`} label="Office Number">
                                            <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Enter Office Phone Number" className="phone-input" />
                                        </Form.Item>
                                }
                            </Col>
                            <Col span={screens.xs ? 24 : 11}>
                                {
                                    modalState?.selectedKey?.type === 'TITLE COMPANY' ?
                                        <Form.Item name={`titleOfficeFax`} label="Title Office Fax">
                                            <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Enter Title Office Fax" className="phone-input" />
                                        </Form.Item> :
                                        <Form.Item name={`fax`} label="Fax">
                                            <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Enter Fax Number" className="phone-input" />
                                        </Form.Item>
                                }
                            </Col>
                        </Row>
                        {
                            modalState?.selectedKey?.type === 'TITLE COMPANY' ?
                                <>
                                    <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                                        <Col span={screens.xs ? 24 : 24}>
                                            <Form.Item name={`titleOfficeAddress`} label="Title Office Address">
                                                <TextArea type='text' placeholder='Enter Address' allowClear />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                                        <Col span={screens.xs ? 24 : 11}>
                                            <Form.Item name={`salesRepName`} label="Sales Rep">
                                                <Input type='text' allowClear placeholder='Enter Sales Rep Name' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={screens.xs ? 24 : 11}>
                                            <Form.Item name={`salesRepEmail`} label="Sales Rep Email">
                                                <Input type='email' placeholder='Enter Email' allowClear />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                                        <Col span={screens.xs ? 24 : 11}>
                                            <Form.Item name={`salesRepPhoneNumber`} label="Sales Rep Phone">
                                                <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Sales Rep Ph. No." className="phone-input" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={screens.xs ? 24 : 11}>
                                            <Form.Item name={`titleCloserName`} label="Title Closer">
                                                <Input type='text' placeholder='Enter Title Closer Name' allowClear />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                                        <Col span={screens.xs ? 24 : 11}>
                                            <Form.Item name={`titleCloserEmail`} label="Title Closer Email">
                                                <Input type='email' placeholder='Enter Email' allowClear />
                                            </Form.Item>
                                        </Col>
                                        <Col span={screens.xs ? 24 : 11}>
                                            <Form.Item name={`titleCloserPhone`} label="Title Closer Phone">
                                                <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Title Closer Ph. No." className="phone-input" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>

                                : ""
                        }
                        <Row justify="space-between" gutter={0} style={{ width: "100%" }}>
                            <Col span={screens.xs ? 24 : 24}>
                                <Form.Item name={`notes`} label="Notes">
                                    <TextArea type='text' placeholder='Add Notes' allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>

            {/* Individual field update Modal */}
            <Modal
                visible={modalState?.openIndividualFieldUpdateModal}
                width={500}
                closable
                title={<div style={{ textAlign: "center", color: "#178DFA", textTransform: "uppercase" }}>UPDATE {modalState?.ref?.label}</div>}
                okText={modalState?.source === "UPDATE" ? "Update" : modalState?.source === "ADD" ? "Add" : ""}
                onOk={handleSubmit}
                onCancel={handleClose}
                bodyStyle={{padding: '5px'}}
                okButtonProps={{
                    disabled: modalState?.source === "UPDATE" && (modalState?.ref?.updatingKey === 'type' && ['TITLE COMPANY', 'BUYER AGENT', 'SELLER AGENT'].includes(modalState?.selectedKey?.type) || ['BUYER AGENT', 'SELLER AGENT'].includes(modalState?.selectedKey?.type) && modalState?.ref?.updatingKey === 'email')
                }}
            >
                <Form initialValues={{ ...modalState?.selectedKey }} form={form} layout="horizontal" style={{ padding: 10 }} scrollToFirstError>
                        <Row justify="space-between" gutter={0} style={{ width: "100%"}}>
                            <Col span={screens.xs ? 24 : 24}>
                            <Form.Item name={modalState?.ref?.updatingKey} label={modalState?.ref?.label} style={{ fontWeight: "bold", color: '#124e8f' }} rules={["email", "titleOfficeEmail", "salesRepEmail", "titleCloserEmail"].includes(modalState?.ref?.updatingKey) ? [{ type: "email", message: 'Not a valid email!' }] : ""}>
                                {['mobilePhoneNumber', 'titleCloserPhone', 'salesRepPhoneNumber', 'fax',
                                    'titleOfficeFax', 'officePhoneNumber', 'titleOfficePhoneNumber'].includes(modalState?.ref?.updatingKey) ?
                                    <PhoneInput allowClear inputStyle={{ width: "100%" }} inputClass="phoneInput" country={"us"} onlyCountries={["us"]} maxLength="10" international={false} disableCountryCode={true} placeholder="Enter here" className="phone-input" />
                                    :
                                    (modalState?.ref?.updatingKey === 'notes' || modalState?.ref?.updatingKey === 'titleOfficeAddress') ? 
                                    <TextArea type='text' placeholder='Enter Here..' allowClear />
                                    :
                                    modalState?.ref?.updatingKey === 'type' ? 
                                    <Select placeholder="Select a type" allowClear disabled={['TITLE COMPANY', 'BUYER AGENT', 'SELLER AGENT'].includes(modalState?.selectedKey?.type)}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {typeOption?.map((option) => <Option value={option?.value}>{option?.label}</Option>)}
                                    </Select>
                                    :
                                    <Input type='text' allowClear placeholder='Enter Here..' disabled={['BUYER AGENT', 'SELLER AGENT'].includes(modalState?.selectedKey?.type) && modalState?.ref?.updatingKey === 'email'} />
                                }
                            </Form.Item>
                            </Col>
                        </Row>
                </Form>
            </Modal>
        </>
    )
}

export default AddOrUpdateContact
