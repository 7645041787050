const weekdays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const setDayOfWeek = (input) => {
  let inputName = input.name;
  let inputValue = input.value;

  // console.log(inputName, "has been changed. updating", `${inputName}_DAY`);

  document.querySelectorAll(`[data-setdayofweek="${inputName}_DAY"]`).forEach((element) => {
    element.innerText = weekdays[new Date(inputValue).getUTCDay()] || "";
  });
};

export default setDayOfWeek;
