export const listingEditConstants = {
  CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",
  CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",

  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAILURE: "GET_CATEGORY_FAILURE",

  EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
  EDIT_CATEGORY_FAILURE: "EDIT_CATEGORY_FAILURE",

  DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",

  GET_SUBCATEGORY_FAILURE: "GET_SUBCATEGORY_FAILURE",
  GET_SUBCATEGORY_SUCCESS: "GET_SUBCATEGORY_SUCCESS",

  GET_DOCKTYPE_FAILURE: "GET_DOCKTYPE_FAILURE",
  GET_DOCKTYPE_SUCCESS: "GET_DOCKTYPE_SUCCESS",

  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
};
