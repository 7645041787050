import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import { Loading } from "../Common/components/Loading";
import styles from "./styles/BugListingAdmin.module.css";
import { bugReportHandle } from "./state/actions";
import { BUGTable } from "./components/BUGTable";
import { Redirect } from "react-router-dom";
import { roleAccessFunc } from "../Common/commondata/commonData";
// import { NDATable } from "./components/NDATable";
const { Content } = Layout;

function BugReportAdmin() {
  const dispatch = useDispatch();
  const { logInData, bugPostData, bugLlistingData ,bugLlistLoading,bugReportCommentData} = useSelector(
    (state) => state.bugReport
  );
  const { adminRole, roleAccessed } = useSelector(
    (state) => state.account,
    shallowEqual
  );



  if (!roleAccessFunc(roleAccessed, "BUGS")) {
    return <Redirect to="/error" />;
  }
  return (
    <Layout
    style={{ height: "88vh", padding: "0px", }}
    >
      <Content
        style={{
          overflow: "auto",
        //   padding: "1rem",
        }}
      >
        <div>
          {/* className={styles.site_layout_background} */}
            <BUGTable list={bugLlistingData} />

        </div>
      </Content>
    </Layout>
  );
}

export { BugReportAdmin };
