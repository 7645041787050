import { logsDataApi } from "../utils/api";
import { logsConstants } from "./action-types";

const getAgentCreatedLogsDataSuccess = (response) => {
    return {
        type: logsConstants.GET_AGENT_CREATED_LOGS_SUCCESS,
        getAgentCreatedLogs: response.data.info.data,
        totalRecords: response.data.info.totalRecords,
        getLogsLoading: false,
    }
}

const getLogsDataError = (error) => {
    return {
        type: logsConstants.GET_LOGS_FAILURE,
        getLogsDataError: error,
        getLogsLoading: false,
    }
}

const getLogsLoading = ()=>{
    return{
        type: logsConstants.GET_LOGS_LOADING,
        getLogsLoading: true,
    }
}

const getAgentCreatedLogsData = (queryData, page, limit) => {

    return (dispatch, getState) => {
        dispatch(getLogsLoading())
        logsDataApi
            .getLogsDataRequest(queryData, page, limit)
            .then((response) => {
                dispatch(getAgentCreatedLogsDataSuccess(response))
            })
            .catch((error) => {
                dispatch(getLogsDataError(error));
            });

    }
}

export const logsDataHandle = {
    getAgentCreatedLogsData,
    getAgentCreatedLogsDataSuccess,
    getLogsDataError,
}
