import React from "react";
import { Result, Button } from "antd";
// import { CloseCircleOutlined } from "@ant-design/icons";
// import { Link } from "react-router-dom";

// const { Paragraph, Text } = Typography;

function NDAFailed({ msg, subTitle }) {
  return (
    <div>
      <Result
        status="error"
        // title="Submission Failed"
        title={msg}
        // subTitle="Please check and modify the following information before resubmitting."
        subTitle={subTitle}
        extra={[
          <Button
            onClick={() => window.history.back()}
            type="primary"
            key="console"
          >
            Try again
          </Button>,
        ]}
      >
        {/* <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              The content you submitted has the following error:
            </Text>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
            account has been frozen. <a>Thaw immediately &gt;</a>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
            account is not yet eligible to apply. <a>Apply Unlock &gt;</a>
          </Paragraph>
        </div> */}
      </Result>
    </div>
  );
}

export { NDAFailed };
