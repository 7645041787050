import { Button, Steps, message } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { CompanyTitle } from './CompanyTitle';
import { TitleOffice } from './TitleOffice';
import { closingActions } from '../state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { TitleRepsCloser } from './TitleRepsCloser';
import { RiNumbersFill } from 'react-icons/ri';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { contractDataHandle } from '../../CreateOffer/state/actions';
import { contractStoreHandle } from '../../ContractStore/state/actions';
import { useMediaQuery } from 'react-responsive';
import {  ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { titleOrderFormatAddress, formatAddress } from '../../Common/utils/ExtraFunctions';

const { Step } = Steps;
const TitleCompanyDetails = () => {
  const urlId = useParams()?.id;
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const location = useLocation()
  const { currentUrlId, contractAuthKey, rootDocData } = useSelector((state) => state.createOffer);
  const {openTitleCompanyModal, selectOffice, companyModalStep, titleOffice, titleCloser, titleRep,titleRepId,titleCloserId,titleOfficeAddress,titleOfficePhoneNumber,titleOfficeFaxNumber,titleRepEmail,titleCloserEmail,titleOfficeName} = useSelector((state) => state.transactionReport);
  const dispatch = useDispatch();
  const {confirm}=Modal;
  const { offerId } = useSelector(
    (state) => state.createOffer
  );
  


const prev = () => {
  dispatch(closingActions.titleCompanyModalState({
    companyModalStep: (companyModalStep > 0) ? (companyModalStep - 1) : 0,
  }));
  if(companyModalStep===1) {
    dispatch(closingActions.titleCompanyModalState({
      selectOffice: null,
      titleOffice: null,
      titleCloser: null,
      titleRep: null,
    }));
  } else if (companyModalStep === 2) {
      dispatch(closingActions.titleCompanyModalState({
        titleCloser: null,
        titleRep: null,
      }));
    }
  };

  const next = () => {
    dispatch(closingActions.titleCompanyModalState({
      companyModalStep: (companyModalStep < 2) ? (companyModalStep + 1) : 2,
    }));
  };
  const handleSave = () => {
    let payload;
    let formatedAddress = titleOrderFormatAddress(titleOfficeAddress)
    if (titleRep && titleCloser) {
      payload = {
        TitleOfficeId: titleOffice,
        SalesRep: titleRep,
        SalesClosingRep: titleCloser,
        SalesRepId: titleRepId,
        SalesClosingRepId: titleCloserId,
        TitleOfficeAddress: formatedAddress,
        TitleOfficePhoneNo: titleOfficePhoneNumber,
        TitleOfficeFaxNo: titleOfficeFaxNumber,
        TitleOfficeName: titleOfficeName,
        TitleCompanyName: selectOffice?.name,
        TitleCompanyLogo: selectOffice?.officeLogo,
        TitleOfficeEmail: titleRepEmail
      }
    }
    else if (titleRep) {
      payload = {
        TitleOfficeId: titleOffice,
        SalesRep: titleRep,
        SalesRepId:titleRepId,
        TitleOfficeAddress: formatedAddress,
        TitleOfficePhoneNo: titleOfficePhoneNumber,
        TitleOfficeFaxNo: titleOfficeFaxNumber,
        TitleOfficeName: titleOfficeName,
        TitleCompanyName: selectOffice?.name,
        TitleCompanyLogo: selectOffice?.officeLogo,
        TitleOfficeEmail: titleRepEmail
      }
    }
    else if (titleCloser) {
      payload = {
        TitleOfficeId: titleOffice,
        SalesClosingRep: titleCloser,
        SalesClosingRepId:titleCloserId,
        TitleOfficeAddress: formatedAddress,
        TitleOfficePhoneNo: titleOfficePhoneNumber,
        TitleOfficeFaxNo: titleOfficeFaxNumber,
        TitleOfficeName: titleOfficeName,
        TitleCompanyName: selectOffice?.name,
        TitleCompanyLogo: selectOffice?.officeLogo,
        TitleOfficeEmail: titleCloserEmail
      }
    }
    else {
      payload = {
        offerId: offerId,
        titleOfficeId: titleOffice
      }
    }
    dispatch(closingActions.addTitleOffice(payload, contractAuthKey, rootDocData))

    dispatch(closingActions.titleCompanyModalState({
      selectOffice: null,
      titleOffice: null,
      titleCloser: null,
      titleRep: null,
      companyModalStep: 0,
      openTitleCompanyModal: false,
    }));
    dispatch(contractDataHandle.getCreateOfferData(urlId, location.pathname));

  }

  const closeCompanyModal = () => {
    dispatch(closingActions.titleCompanyModalState({
      openTitleCompanyModal: false,
    }));
  }

  const handeldeleteTitleCompanydata = () => {
    confirm({
      title: `Are you sure, you want to remove saved title Company ?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const selectedOfferId = rootDocData?.offerId || null;
        dispatch(contractStoreHandle.deleteTitleCompanyData(selectedOfferId, contractAuthKey));
        dispatch(closingActions.titleCompanyModalState({ openTitleCompanyModal: false }));
      },
      onCancel() {},
    });
  };
  
  
  const steps = [
    {
      title: "First",
      content: (
        <CompanyTitle
        isMobile={isMobile}
          // setSelectoffice={setSelectoffice}
          // selectoffice={selectoffice}
          // setCompanyModalStep={setCompanyModalStep}
          // companyModalStep={companyModalStep}

          // selectedRows={selectedRows}
          // setSelectedRowKeys={setSelectedRowKeys}
          // fieldEmpty={fieldEmpty}
          // setFieldIsEmpty={setFieldIsEmpty}
        />
      ),
    },
    {
      title: "Second",
      content: (
        <TitleOffice
        isMobile={isMobile}
          // setTitleoffice={setTitleoffice}
          // titleoffice={titleoffice}
          // selectoffice={selectoffice}
        />
      ),
    },
    {
      title: "Third",
      content: (
        <TitleRepsCloser
        isMobile={isMobile}
          // titleCloser={titleCloser}
          // setTitleCloser={setTitleCloser}
          // titleoffice={titleoffice}
          // setTitleRep={setTitleRep}
          // titleRep={titleRep} 
          />
      )
    },
  ];
  return (
    <div >
      <Steps current={companyModalStep}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>

      <div className="steps-content" style={{ marginTop: "20px", minHeight:isMobile?"200px":"420px",height: "auto", }}>
        {steps[companyModalStep]?.content}
      </div>

      <div className="steps-action" style={{ marginTop: "25px" }}>
        {companyModalStep > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {companyModalStep === 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={closeCompanyModal}
          >
            Cancel
          </Button>
        )}
        {companyModalStep < steps.length - 1 && (
          <Button
            type="primary"
            disabled={
              !(selectOffice) ? true : !(titleOffice) && (companyModalStep) === 1 ? true : false
            }
            style={{
              margin: "0 8px",
            }}
            onClick={() => next()}
          >
            Next
          </Button>
        )}
        {(companyModalStep === 1 || (companyModalStep) === 2) && (
          <Button
            type="primary"
            onClick={handleSave}
            disabled={
              !(titleOffice) && (companyModalStep) === 1 ? true : ((!(titleRep) && (companyModalStep) === 2) && (!(titleCloser) && (companyModalStep) === 2)) ? true : false
            }
          >
            Save
          </Button>
        )}
        {companyModalStep === 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={handeldeleteTitleCompanydata}
          >
            Clear
          </Button>
        )}
      </div>
    </div>
  )
}

export { TitleCompanyDetails }

