import React from 'react'
import { CloseOutlined, PrinterOutlined } from '@ant-design/icons';
import logo from "../../Assets/Icons/newLogo.png";
import styles from "../styles/CommonNavbar.module.css"
import { detectWebView } from '../../Common/utils/universalFuntions';
import { useMediaQuery } from 'react-responsive';
import dotEnv from "dotenv";
import {message } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { handleClose } from '../../Common/commonFunctions/handleClose';
dotEnv.config();
const apiURL = process.env.REACT_APP_WEB_BASE_URL;

function CommonNavbar({ source, readOnly, decryptedId, tabId, clientId }) {
    const isSmallScreen = useMediaQuery({ maxWidth: 550 });
    const history = useHistory();

    const handleRedirect = async () => {
        localStorage.removeItem(decryptedId);
        if (!readOnly) {
            let redirectHome = async () => {
                await window?.ReactNativeWebView?.postMessage("REDIRECT_HOME");
            };
            const isInWebView = detectWebView();
            if (isInWebView) {
                redirectHome();
            } else {
                window.location.href = `${apiURL}`;
            }
            return;
        } else {
            history.push(`/success?client=true`);
        }
    }

    const handlePrint = () => {
        const isInWebView = detectWebView();
        if (isInWebView) {
            message.warn("Please use our website to print the Contact Sheet.");
        } else {
            window.print();
        }
    }

    return (
        <div className={`${styles.topNavBar} ${styles.noPrint}`}>
            <div className={styles.logoContainer}>
                <img src={logo} alt="Re-Sure Logo" onClick={handleRedirect} />
            </div>

            <div className={styles.toolsContainer}>
                <div className={styles.dummyDiv}></div>
                <div className={styles.printBtnContainer}>
                    <div className={styles.printBtn} onClick={handlePrint}>
                        <PrinterOutlined
                            style={{
                                fontSize: isSmallScreen ? 12 : 16
                            }} />
                        <span>Print</span>
                    </div>
                </div>
                <div className={styles.closeBtnContainer}>
                    <div className={styles.closeBtn} onClick={()=>handleClose({source, urlId: decryptedId, readOnly, history, tabId, clientId})}>
                        <CloseOutlined
                            style={{
                                fontSize: isSmallScreen ? 12 : 16
                            }} />
                        <span>Close</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommonNavbar;
