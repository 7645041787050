import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { contractStoreHandle } from '../../ContractStore/state/actions';
import { SearchOutlined } from "@ant-design/icons";
import { Empty, Modal, Select, Spin } from 'antd';
const { Option } = Select;

function ClauseData({ values, handleSelectChange, existingValues, addClauseData, handleOk, handleCancel }) {
    const { addClausesData, addClausesError, addClausesLoading } = useSelector((state) => state.contractStore);
    const { rootDocData } = useSelector((state) => state.createOffer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(contractStoreHandle.getAddClausesData(rootDocData.token));
    }, [])

    return (
        <Modal
            title={!addClausesData?.length && !addClausesLoading && !addClausesError ? `Looks like you don't have any Clauses in your account!` : values?.value?.toUpperCase()}
            visible={true}
            onOk={handleOk}
            mask={true}
            closable={false}
            onCancel={handleCancel}
        >
            {addClausesLoading ? <div style={{textAlign: 'center'}}><Spin /></div> :
                addClausesError ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/> :
                    !addClausesData?.length ?
                        (<div>
                            Please go to "Tools", then click on "Docs/Clause". On the upper right hand corner click on the "+" button.
                            {/* <span>You can also refer below tutorial links.</span> */}
                        </div>) :
                        <>
                            <Select
                                allowClear
                                style={{ width: "93%" }}
                                placeholder={values?.refName ? `Please select ${values.refName}` : "Please select"}
                                mode='multiple'
                                onChange={handleSelectChange}
                                value={existingValues && existingValues}
                            >
                                {addClauseData.map((el, i) => <Option style={{ zIndex: 12 }} key={el.name + i} value={el.name}>{el.name}</Option>)}
                            </Select>
                            <SearchOutlined
                                style={{
                                    position: 'absolute',
                                    fontSize: "18px",
                                    cursor: 'pointer',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    right: '20px',
                                }}
                            />
                        </>
            }
        </Modal>
    )
}

export default ClauseData
