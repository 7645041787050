const removeSection = (toRemoveElement) => {
  document
    .querySelectorAll(
      `[data-remove="${toRemoveElement.dataset?.removeTarget}"]`
    )
    .forEach((element, i) => {
      if (toRemoveElement.checked) {
        element.classList.add("removed");
        element.classList.add("no-print");

        if (i === 0) {
          document
            .querySelectorAll(
              `[data-show-removed="${toRemoveElement.dataset?.removeTarget}"]`
            )
            .forEach((ele) => {
              ele.innerText = " - Deleted";
            });
        }
      } else {
        element.classList.remove("removed");
        element.classList.remove("no-print");

        if (i === 0) {
          document
            .querySelectorAll(
              `[data-show-removed="${toRemoveElement.dataset?.removeTarget}"]`
            )
            .forEach((ele) => {
              ele.innerText = "";
            });
        }
      }
    });

  // handle enable/disable inputs
  let dirtyFields = {};
  document.querySelectorAll(`[data-remove="${toRemoveElement.dataset.removeTarget}"] input, [data-remove="${toRemoveElement.dataset.removeTarget}"] textarea`)?.forEach((element) => {
    if (toRemoveElement.checked && !toRemoveElement.hasAttribute("data-remove-table31")) {
      if (element.type === "checkbox") {
        element.checked = false;
      } else {
        element.value = null;
      }
      element.setAttribute("disabled", true);
      dirtyFields[element.name] = null;
    } else {
      element.removeAttribute("disabled");
    }
  });
  if (toRemoveElement.hasAttribute("data-remove-table31")) {
    dirtyFields["Table31SectionRemoved"] = true;
  }
  return dirtyFields;
};

export default removeSection;
