export const listingPageConstants = {
  LISTING_PAGE_SUCCESS: "LISTING_PAGE_SUCCESS",
  LISTING_PAGE_FAILURE: "LISTING_PAGE_FAILURE",

  EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
  EDIT_CATEGORY_FAILURE: "EDIT_CATEGORY_FAILURE",

  RESET_LISTING_PAGE: "RESET_LISTING_PAGE",

  DELETE_CONTRACT_SUCCESS: "DELETE_CONTRACT_SUCCESS",
  DELETE_CONTRACT_FAILURE: "DELETE_CONTRACT_FAILURE",
};
