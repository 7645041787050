import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const createSuggessionRequest = (data) => {
  let accessTokan = localStorage.getItem("auth");
  return axios.post(
    `${apiURL}/admin/suggestionConstants`,
    // { suggestionsArray: data.suggestionsArray },
    { suggestionsArray: data },
    {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const getSuggesionRequest = () => {
  let accessTokan = localStorage.getItem("auth");
  return axios.get(`${apiURL}/admin/suggestionConstants`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// const editCategoryRequest = (data, id) => {
//   let accessTokan = localStorage.getItem("auth");
//   const params = new URLSearchParams();
//   params.append("name", data.name);
//   return axios.put(`${apiURL}/category/${id}`, params, {
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//       accept: "application/json",
//       authorization: `bearer ${accessTokan}`,
//     },
//   });
// };

// const deleteCategoryRequest = (id) => {
//   let accessTokan = localStorage.getItem("auth");
//   return axios.delete(`${apiURL}/category/${id}`, {
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//       accept: "application/json",
//       authorization: `bearer ${accessTokan}`,
//     },
//   });
// };

export const suggessionDataApi = {
  createSuggessionRequest,
  getSuggesionRequest,
  // editCategoryRequest,
  // deleteCategoryRequest,
};
