import { formatAndParse } from "../../../Common/utils/universalFuntions";
const accounting = require("accounting");

const calculatePriceTable = (table={}, source) => {
  let { PurchasePrice, EarnestMoney, NewLoan, AssumptionBalance, PrivateFinancing, SellerFinancing } = table;
  let CashAtClosing, TotalLeftColumn, TotalRightColumn;
  TotalLeftColumn = PurchasePrice;
  TotalRightColumn = PurchasePrice;

  if (source === "ChecboxUnchecked") {
    EarnestMoney = formatAndParse((PurchasePrice * 1) / 100);
    NewLoan = formatAndParse((PurchasePrice * 95) / 100);
    CashAtClosing = formatAndParse((PurchasePrice * 4) / 100);
    AssumptionBalance = 0.0;
    PrivateFinancing = 0.0;
    SellerFinancing = 0.0;
  } else {
    CashAtClosing = PurchasePrice - (EarnestMoney + NewLoan + AssumptionBalance + PrivateFinancing + SellerFinancing);
  }
  return {
    PurchasePrice: accounting.formatNumber(PurchasePrice, 2),
    EarnestMoney: accounting.formatNumber(EarnestMoney, 2),
    NewLoan: accounting.formatNumber(NewLoan, 2),
    AssumptionBalance: accounting.formatNumber(AssumptionBalance, 2),
    PrivateFinancing: accounting.formatNumber(PrivateFinancing, 2),
    SellerFinancing: accounting.formatNumber(SellerFinancing, 2),
    CashAtClosing: accounting.formatNumber(CashAtClosing, 2),
    TotalLeftColumn: accounting.formatNumber(TotalLeftColumn, 2),
    TotalRightColumn: accounting.formatNumber(TotalRightColumn, 2),
  };
};

export default calculatePriceTable;
