/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Spin, Row, Col, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import CobuyerCosellerList from "../../../CreateOffer/components/CobuyerCosellerList";
import { documentAction } from "../../state/actions";
// import moment from "moment";
import { handleBlurEvent, handleInputEvent } from "../../../CreateOffer/features/FormLogic/AllForms";
import styles from "../../styles/offerImport.module.css";
import "../../styles/offerImport.css";
import { isAtleastOneClientPresent } from "../../../OfferTable/utils/helper";
import ModifyDatesModal from "../../../CreateOffer/components/ModifyDatesModal";
import { BsCurrencyDollar } from "react-icons/bs";
import SetInitialValues from "../../../CreateOffer/features/SetInitialValues";
import { handleShowPicker } from "../../../Common/utils/universalFuntions";
const Step2 = ({ urlImportData, CurrentPdfImportData }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState({
    editClientModalVisible: false,
    clientType: "Buyers",
    event: {},
    showDateFieldModal: false,
  });

  const { URLofferTableJSON } = useSelector((state) => state.offerTable);

  const clickHandler = (event) => {
    if (event.target.classList.contains("relativeDateInput")) {
      setModalState((prevState) => ({ ...prevState, showDateFieldModal: true, event }));
    }
  };

  useEffect(() => {
    if (urlImportData && Object.keys(urlImportData)?.length) {
      document.querySelectorAll("input,textarea")?.forEach((element) => {
        if (element.name !== "no-event" && element.disabled === false) {
          if (element.type === "date") {
            element.addEventListener("click", handleShowPicker);
          }
          element.addEventListener("input", handleInput);
          element.addEventListener("blur", handleBlur);
        }
      });
      document.querySelectorAll(".relativeDateInput")?.forEach((element) => {
        element.addEventListener("click", clickHandler);
      });
    }
    // Cleanup function to remove the event listeners
    return () => {
      document.querySelectorAll("input,textarea")?.forEach((element) => {
        if (element.type === "date") {
          element.removeEventListener("click", handleShowPicker);
        }
        element.removeEventListener("input", handleInput);
        element.removeEventListener("blur", handleBlur);
      });
    };
  }, [urlImportData]);

  useEffect(() => {
    if (CurrentPdfImportData && Object.keys(CurrentPdfImportData)?.length && URLofferTableJSON?._id) {
      SetInitialValues({ currentUrlId: URLofferTableJSON?._id, rootDocData: {}, CurrentPdfImportData, source: "OFFER_IMPORT" });
    }
  }, [CurrentPdfImportData]);

  const handleInput = (event) => {
    handleInputEvent({ event, currentUrlId: URLofferTableJSON?._id, handleInputSave, CurrentPdfImportData });
  };

  const handleInputSave = (valueObj) => {
    if (Object.keys(valueObj)?.length) {
      dispatch(documentAction.setpdfImportState(valueObj));
    }
  };

  const handleBlur = (event) => {
    handleBlurEvent({ event, currentUrlId: URLofferTableJSON?._id, handleInputSave });
  };

  const handleCancel = (e) => {
    if (!isAtleastOneClientPresent(CurrentPdfImportData?.Buyers)) {
      message.error("Please select at least one buyer.");
    } else {
      setModalState((prevState) => ({ ...prevState, editClientModalVisible: false }));
    }
  };
  const handleEditClientclick = (clientType) => {
    setModalState((prevState) => ({ ...prevState, editClientModalVisible: true, clientType }));
  };
  let rootDocData = {
    openFor: "SELLER_AGENT",
  };
  return (
    <>
      {modalState?.editClientModalVisible && (
        <Modal visible={modalState?.editClientModalVisible} width={"600px"} title={<span style={{ display: "flex", justifyContent: "center", fontSize: "22px", color: "grey" }}>{modalState?.clientType === "Buyers" ? "EDIT BUYERS" : "EDIT SELLERS"}</span>} onCancel={handleCancel} footer={false}>
          <CobuyerCosellerList clientType={modalState?.clientType} sourceType={modalState?.clientType === "Buyers" ? "MANUAL_ADD_CLIENTS" : "NORMAL"} currentUrlId={URLofferTableJSON?._id} rootDocData={rootDocData} />
        </Modal>
      )}

      {modalState?.showDateFieldModal && <ModifyDatesModal modalState={modalState} setModalState={setModalState} currentUrlId={URLofferTableJSON?._id} handleInputSave={handleInputSave} source="OFFER_IMPORT" />}
      {!Object.keys(urlImportData)?.length ? (
        <div style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="OIformWrapper" style={{ maxHeight: isMobile ? "300px" : "400px", overflow: "auto", overflowX: "hidden", marginBottom: "30px", paddingRight: "15px" }}>
            <Row gutter={[16, 16]} className={styles.formRow} style={{ marginTop: "25px" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Buyers
                </div>
                <span data-buyers-list style={{ color: "blue", marginRight: "10px" }}></span>
                <span style={{ fontSize: isMobile ? "10px" : "15px", color: "#1890ff", cursor: "pointer" }} onClick={() => handleEditClientclick("Buyers")}>
                  <EditOutlined />
                </span>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Property Address
                </div>
                <div className={styles.inputWrapper}>
                  <input name="FullAddress" placeholder="Enter Property Address" style={{ width: "100%" }} required />
                  <span className={styles.validationMessage}>Please Enter Property Address!</span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Buyer Agent
                </div>
                <div className={styles.inputWrapper}>
                  <input name="BuyerBrokerName" placeholder="Enter Buyer Agent Name" style={{ width: "100%" }} required />
                  <span className={styles.validationMessage}>Please Enter Buyer Agent Name!</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Buyer Agent Email
                </div>
                <div className={styles.inputWrapper}>
                  <input name="BuyerBrokerEmailAddress" placeholder="Enter Buyer Agent Email" style={{ width: "100%" }} type="email" required />
                  <span className={styles.validationMessage}>Please enter only one valid email address.</span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Purchase Price
                </div>
                <div className={styles.inputWrapper}>
                  <BsCurrencyDollar className={styles.currencyIcon} />
                  <input name="PurchasePrice" placeholder="Enter Purchase Price" className="currency" required />
                  <span className={styles.validationMessage}>Please Enter Purchase Price!</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Earnest Money
                </div>
                <div className={styles.inputWrapper}>
                  <BsCurrencyDollar className={styles.currencyIcon} />
                  <input name="EarnestMoney" placeholder="Enter Earnest Money" className="currency" required />
                  <span className={styles.validationMessage}>Please Enter Earnest Money!</span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>New Loan</div>
                <div className={styles.inputWrapper}>
                  <BsCurrencyDollar className={styles.currencyIcon} />
                  <input name="NewLoan" placeholder="Enter New Loan" className="currency" />
                  <span className={styles.validationMessage}>Please Enter New Loan!</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Cash At Closing
                </div>
                <div className={styles.inputWrapper}>
                  <BsCurrencyDollar className={styles.currencyIcon} />
                  <input name="CashAtClosing" placeholder="Enter Cash At Closing" className="currency" required />
                  <span className={styles.validationMessage}>Please Enter Cash At Closing!</span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Concessions</div>
                <div className={styles.inputWrapper}>
                  <BsCurrencyDollar className={styles.currencyIcon} />
                  <input name="SellerConcession" placeholder="Enter Concessions Price" className="currency" />
                  <span className={styles.validationMessage}>Please Enter Concessions Price!</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Possession Penalty Per Day</div>
                <div className={styles.inputWrapper}>
                  <BsCurrencyDollar className={styles.currencyIcon} />
                  <input name="PosessionSellerPenaltyPerDay" placeholder="Enter Possession Penalty Per Day" className="currency" />
                  <span className={styles.validationMessage}>Please Enter Possession Penalty Per Day!</span>
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Possession Date
                </div>
                <input className="relativeDateInput requiredDateInput" name="PossessionDate" type="text" style={{ width: "100%" }} placeholder="Enter Possession Date" required readOnly />
                <span className={styles.validationMessage}>Please Enter Possession Date!</span>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Closing Date
                </div>
                  <input className="relativeDateInput requiredDateInput closing-date-input"  type="text" name="ClosingDate" style={{ width: "100%" }} placeholder="Enter Closing Date" required  />
                  <span className={styles.validationMessage}>Please Enter Closing Date!</span>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Acceptance Date
                </div>
                <input className="relativeDateInput requiredDateInput" type="text" name="AcceptanceDeadlineDate" style={{ width: "100%" }} placeholder="Enter Acceptance Date" required readOnly />
                <span className={styles.validationMessage}>Please Enter Acceptance Date!</span>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Acceptance Time</div>
                <input className="relativeDateInput relativeTimeInput" type="text" name="AcceptanceDeadlineTime" style={{ width: "100%" }} placeholder="Enter Acceptance Time" readOnly/>
                <span className={styles.validationMessage}>Please Enter Acceptance Time!</span>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Inspection Objection Deadline</div>
                <input className="relativeDateInput" name="InspectionObjectionDeadline" type="text" style={{ width: "100%" }} placeholder="Inspection Objection Deadline" readOnly />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Inspection Termination Deadline</div>
                <input className="relativeDateInput" name="InspectionTerminationDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Inspection Termination Deadline" readOnly />
              </Col>
            </Row>

            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Loan Termination Deadline</div>
                <input className="relativeDateInput" name="NewLoanTermsDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Loan Termination Deadline" readOnly />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Appraisal Deadline</div>
                <input className="relativeDateInput" name="AppraisalDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Appraisal Deadline" readOnly />
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Property Insurance Termination Deadline</div>
                <input className="relativeDateInput" name="PropertyInsuranceTerminationDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Property Insurance Termination Deadline" readOnly />
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Due Diligence Documents Delivery Deadline</div>
                <input className="relativeDateInput" name="DueDiligenceDocumentsDeliveryDeadline" type="text" style={{ width: "100%" }} placeholder="Due Diligence Documents Delivery Deadline" readOnly />
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Loan Type</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="Conventional" />
                    &nbsp; <span>Conventional</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="FHA" />
                    &nbsp; <span>FHA</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="VA" />
                    &nbsp; <span>VA</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="Bond" />
                    &nbsp; <span>Bond</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="LoanLimitations__Other" data-linked-value="LoanLimitations__Other" />
                    &nbsp; <span>Other</span>
                  </label>
                  <span className={styles.otherInputWrapper}>
                    <input type="text" style={{ width: "100%" }} name="LoanLimitations__Other" data-linked-value="LoanLimitations" />
                  </span>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Closing Services Fee Paid By</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="ClosingServicesFeePaidBy" name="ClosingServicesFeePaidBy" value="Buyer" />
                    &nbsp;<span>Buyer</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="ClosingServicesFeePaidBy" name="ClosingServicesFeePaidBy" value="Seller" />
                    &nbsp;<span>Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="ClosingServicesFeePaidBy" name="ClosingServicesFeePaidBy" value="One-Half by Buyer and One-Half by Seller" />
                    &nbsp;<span>One-Half by Buyer and One-Half by Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="ClosingServicesFeePaidBy" name="ClosingServicesFeePaidBy" data-linked-value="ClosingServicesFeePaidBy__Other" />
                    &nbsp;<span>Other</span>
                  </label>

                  <span className={styles.otherInputWrapper}>
                    <input type="text" name="ClosingServicesFeePaidBy__Other" data-linked-value="ClosingServicesFeePaidBy" style={{ width: "100%" }} />
                  </span>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Status Letter Fee Paid By</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationStatusLetterFeePaidBy" name="AssociationStatusLetterFeePaidBy" value="Buyer" />
                    &nbsp;<span>Buyer</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationStatusLetterFeePaidBy" name="AssociationStatusLetterFeePaidBy" value="Seller" />
                    &nbsp;<span>Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationStatusLetterFeePaidBy" name="AssociationStatusLetterFeePaidBy" value="One-Half by Buyer and One-Half by Seller" />
                    &nbsp;<span>One-Half by Buyer and One-Half by Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationStatusLetterFeePaidBy" name="AssociationStatusLetterFeePaidBy" value="N/A" />
                    &nbsp;<span>N/A</span>
                  </label>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Record Change Fee Paid By</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationRecordChangeFeePaidBy" name="AssociationRecordChangeFeePaidBy" value="Buyer" />
                    &nbsp;<span>Buyer</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationRecordChangeFeePaidBy" name="AssociationRecordChangeFeePaidBy" value="Seller" />
                    &nbsp;<span>Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationRecordChangeFeePaidBy" name="AssociationRecordChangeFeePaidBy" value="One-Half by Buyer and One-Half by Seller" />
                    &nbsp;<span>One-Half by Buyer and One-Half by Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationRecordChangeFeePaidBy" name="AssociationRecordChangeFeePaidBy" value="N/A" />
                    &nbsp;<span>N/A</span>
                  </label>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Water Transfer Fee Paid By</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="WaterTransferFeesPaidBy" name="WaterTransferFeesPaidBy" value="Buyer" />
                    &nbsp;<span>Buyer</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="WaterTransferFeesPaidBy" name="WaterTransferFeesPaidBy" value="Seller" />
                    &nbsp;<span>Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="WaterTransferFeesPaidBy" name="WaterTransferFeesPaidBy" value="One-Half by Buyer and One-Half by Seller" />
                    &nbsp;<span>One-Half by Buyer and One-Half by Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="WaterTransferFeesPaidBy" name="WaterTransferFeesPaidBy" value="N/A" />
                    &nbsp;<span>N/A</span>
                  </label>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Additional Prov</div>
                <span className="grow-with-content">
                  <textarea name="AdditionalProvisions"></textarea>
                </span>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Other Inclusions</div>
                <span className="grow-with-content">
                  <textarea name="OtherInclusions"></textarea>
                </span>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Exclusions</div>
                <span className="grow-with-content">
                  <textarea name="Exclusions"></textarea>
                </span>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default Step2;
