import { message } from "antd";
import { firmDetailsApi } from "../utils/api";
import { firmConstants } from "./action-types";
import { officeTypeHandle } from "../../OfficeType/state/actions";

// create a License
const createFranchiseLoading = () => {
  return {
    type: firmConstants.CREATE_FRANCHISE_DETAILS_LOADING,
  };
};
const createFranchiseError = (error) => {
  return {
    type: firmConstants.CREATE_FRANCHISE_DETAILS_FAILURE,
    createFranchiseError: error,
  };
};

const createFranchiseSuccess = (response) => {
  return {
    type: firmConstants.CREATE_FRANCHISE_DETAILS_SUCCESS,
    createFranchiseData: response.data.info,
  };
};

const createFranchise = (payload) => (dispatch) => {
  dispatch(createFranchiseLoading());
  return firmDetailsApi
    .createFranchiseRequest(payload)
    .then((response) => {
      dispatch(createFranchiseSuccess(response));
      message.success("Created Successfully!", 2);
      dispatch(officeTypeHandle.getOfficeTypes('verifyCreatedFirm'));
    })
    .catch((error) => {
      message.error(error?.response?.data?.message);
      dispatch(createFranchiseError(error));
    });
};

const getFranchiseLoading = (error) => {
  return {
    type: firmConstants.GET_FRANCHISE_DETAILS_LOADING,
  };
};
// getting all the License
const getFranchiseError = (error) => {
  return {
    type: firmConstants.GET_FRANCHISE_DETAILS_FAILURE,
    getLicenseError: error,
  };
};

const getFranchiseSuccess = (response) => {
  return {
    type: firmConstants.GET_FRANCHISE_DETAILS_SUCCESS,
    getFranchiseData: response?.data?.info?.data,
  };
};

const getAllFranchise = (data) => (dispatch) => {
  dispatch(getFranchiseLoading());
  firmDetailsApi
    .getFranchiseRequest(data)
    .then((response) => {
      dispatch(getFranchiseSuccess(response));
    })
    .catch((error) => {
      dispatch(getFranchiseError(error));
    });
};

const getBrokrageLoading = () => {
  return {
    type: firmConstants.GET_BROKRAGE_DETAILS_LOADING,
  };
};
const getBrokrageSuccess = (response) => {
  return {
    type: firmConstants.GET_BROKRAGE_DETAILS_SUCCESS,
    getBrokrageData: response.data.info,
  };
};
const getBrokrageError = (error) => {
  return {
    type: firmConstants.GET_BROKRAGE_DETAILS_SUCCESS,
    getBrokrageError: error,
  };
};
const getBrokrage = (data) => (dispatch) => {
  dispatch(getBrokrageLoading());
  return firmDetailsApi
    .getFranchiseRequest(data)
    .then((response) => {
      dispatch(getBrokrageSuccess(response));
    })
    .catch((error) => {
      dispatch(getBrokrageError(error));
    });
};

// edit franchise details

const editfranchiseLoading = (error) => {
  return {
    type: firmConstants.EDIT_FRANCHISE_DETAILS_LOADING
  };
};
// getting all the License
const editfranchiseError = (error) => {
  return {
    type: firmConstants.EDIT_FRANCHISE_DETAILS_FAILURE,

  };
};

const editfranchiseSuccess = (response) => {
  return {
    type: firmConstants.EDIT_FRANCHISE_DETAILS_SUCCESS,
    editfranchiseData: response.data.info,
  };
};
const editfranchisedetails = (payload,dataid) => (dispatch) => {
  dispatch(editfranchiseLoading());
  return firmDetailsApi
    .editFranchiseRequest(payload,dataid)
    .then((response) => {
      dispatch(editfranchiseSuccess(response));
      message.success(response?.data?.info?.message);
      dispatch(officeTypeHandle.getOfficeTypes("createFirm"))

    })
    .catch((error) => {

      dispatch(editfranchiseError(error));
    });

};

const editBrokeragedetails = (payload) => (dispatch) => {
  dispatch(editfranchiseLoading());
  return firmDetailsApi
    .editBrokerageRequest(payload)
    .then((response) => {
      dispatch(editfranchiseSuccess(response));
      message.success(response?.data?.info?.message);
      dispatch(officeTypeHandle.getAllPersons({page:1,limit:20}))
    })
    .catch((error) => {
      dispatch(editfranchiseError(error));
    });
};


// Post image Url
const resetImageUrl = () => {
  return {
    type: firmConstants.RESET_IMAGE_URL,
  };
};

// Post image Url
const postImageError = (error) => {
  return {
    type: firmConstants.POST_IMAGE_FAILURE,
    postImageError: error,
    postImageLoading: true,
  };
};

const postImageSuccess = (response) => {
  return {
    type: firmConstants.POST_IMAGE_SUCCESS,
    postImageUrl: response.data.info.fileData.fileUrl,
    postImageLoading: false,
  };
};
const postImageLoading = (response) => {
  return {
    type: firmConstants.POST_IMAGE_LOADING,
    postImageLoading: true,
  };
};

const postImage = (data) => {
  return (dispatch, getState) => {
    dispatch(postImageLoading());
    firmDetailsApi
      .postImageRequest(data)
      .then((response) => {
        dispatch(postImageSuccess(response));
      })
      .catch((error) => {
        dispatch(postImageError(error));
      });
  };
};

const getAdminSearchLoading = ( value ) => {
  return {
    type: firmConstants.SEARCH_ADMIN_LOADING,
    data: value
  }
}

const getAdminSearchError = ( response ) => {
  return {
    type: firmConstants.SEARCH_ADMIN_ERROR
  }
}

const getAdminSearchData = (response) => {
  return {
    type: firmConstants.SEARCH_ADMIN_DATA,
    data: response,
  };
};

const resetAdminSearchData = () => {
  return {
    type: firmConstants.RESET_SEARCH_ADMIN_DATA
  };
};

const getAdminSearch = (data) => {
  return (dispatch, getState) => {
    firmDetailsApi
      .getAdminSearchRequest(data)
      .then((response) => {
        dispatch(getAdminSearchData(response.data.info));
      })
      .catch((error) => {
        dispatch(getAdminSearchError(error));
      });
  };
};

// Send mail for clauses
const sendMailForClausesLoading = () => {
    return {
      type: firmConstants.SEND_MAIL_FOR_CLAUSES_LOADING,
    };
  };
  const sendMailForClausesError = (error) => {
    return {
      type: firmConstants.SEND_MAIL_FOR_CLAUSES_FAILURE,
    };
  };

  const sendMailForClausesSuccess = (response,setisFjggModal) => {
    message.success(response?.data?.info);
    setisFjggModal({visvible:false,id:""});
    return {
      type: firmConstants.SEND_MAIL_FOR_CLAUSES_SUCCESS,
      addClausesData: response.data.info,
    };
  };

  const sendMailForClauses = (setisFjggModal,isFjggModal) => {
    return (dispatch, getState) => {
      dispatch(sendMailForClausesLoading());
      firmDetailsApi
        .sendMailForClausesRequest(isFjggModal.id)
        .then((response) => {
          dispatch(sendMailForClausesSuccess(response,setisFjggModal));
        })
        .catch((error) => {
          dispatch(sendMailForClausesError(error));
        });
    };
  };

  // get access for FJGG Doc & clauses
const getFJGGAccessLoading = () => {
    return {
      type: firmConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_LAODING,
    };
  };
  const getFJGGAccessError = (error) => {
    return {
      type: firmConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_ERROR,
    };
  };

  const getFJGGAccessSuccess = (response) => {
    return {
      type: firmConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_SUCCESS,
      FJGGAccessData: response?.data?.info?.[0],
    };
  };

  const getFJGGAccess = (id) => {
    return (dispatch, getState) => {
      dispatch(getFJGGAccessLoading());
      firmDetailsApi
        .getFJGGAccessRequest(id)
        .then((response) => {
          dispatch(getFJGGAccessSuccess(response));
        })
        .catch((error) => {
          dispatch(getFJGGAccessError(error));
        });
    };
  };

const getCompanyError = (error) => {
  return {
    type: firmConstants.GET_COMPANY_DETAILS_FAILURE,
    getCompnayError: error,
  };
};

const getCompanySuccess = (response) => {
  return {
    type: firmConstants.GET_COMPANY_DETAILS_SUCCESS,
    getCompanyData: response?.data?.info,
  };
};

const getCompanyLoading = (error) => {
  return {
    type: firmConstants.GET_COMPANY_DETAILS_LOADING,
  };
};

const getAllCompany = (query) => (dispatch) => {
  dispatch(getCompanyLoading());
  firmDetailsApi
    .getCompanyRequest(query)
    .then((response) => {
      dispatch(getCompanySuccess(response));
    })
    .catch((error) => {
      dispatch(getCompanyError(error));
    });
};

const getAllOfficeError = (error) => {
  return {
    type: firmConstants.GET_OFFICE_DETAILS_FAILURE,
    getCompnayError: error,
  };
};

const getAllOfficeSuccess = (response) => {
  return {
    type: firmConstants.GET_OFFICE_DETAILS_SUCCESS,
    getOfficeData: response?.data?.info,
  };
};

const getAllOfficeLoading = (error) => {
  return {
    type: firmConstants.GET_OFFICE_DETAILS_LOADING,
  };
};

const getAllOffice = (query) => (dispatch) => {
  dispatch(getAllOfficeLoading());
  firmDetailsApi
    .getAllOfficeRequest(query)
    .then((response) => {
      dispatch(getAllOfficeSuccess(response));
    })
    .catch((error) => {
      dispatch(getAllOfficeError(error));
    });
};

export const firmDetailsHandle = {
  createFranchise,
  getBrokrage,
  getAllFranchise,
  postImage,
  resetImageUrl,
  editfranchisedetails,
  getAdminSearch,
  getAdminSearchLoading,
  getAdminSearchData,
  sendMailForClauses,
  getFJGGAccess,
  getAllCompany,
  getAllOffice,
  editBrokeragedetails,
  resetAdminSearchData,
};
