import { Button, Form, Image, Input, Modal, Select, Spin, Tag, Upload, message } from 'antd';
import { Option } from 'antd/lib/mentions';
import React from 'react'
import { useState } from 'react';
import { BiImageAdd } from 'react-icons/bi';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { firmDetailsHandle } from '../../FirmCreation/state/action';
import { TitleCompanyHandle } from '../state/action';
const CreateTitleCompanyForm = ({form,tag,setShowCreateModal}) => {
      const {getdata} = useSelector((state)=>state.titleCompanyDetails)
   const { getUserDetailsData } = useSelector(
      (state) => state.account
    );
    const {  getCompanyData, getCompanyLoading, getCompanyError,getOfficeData, getOfficeLoading, getOfficeError } = useSelector(
      (state) => state.firmDetails
    );
    const { postImageLoading, postImageUrl } = useSelector((state) => state.firmDetails);
    const dispatch = useDispatch()

    const [showCustomError, setShowCustomError] = useState({
      status: false,
      message: "",
    });
    const [formData, setFormData] = useState({
      name: "",
      officeLogo: "",
      association: "",
      streetNumber: "",
      streetName: "",
      unit: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      email: "",
    });
    const [visible, setVisible] = useState(false);
    const [fileData, setFileData] = useState("");
    const [previewUrl, setPreviewUrl] = useState(null);

    const handleFinish = (values) => {
      if (tag === "COMPANY") {
        if (!postImageUrl) {
          message.error("Please Enter the Logo");
          setShowCustomError({ status: true, message: "Please Enter the Logo" });
          return;
        }
        let data = {
          ...values,
          officeLogo: postImageUrl,
          "titleType": "TITLE_COMPANY",
        };
        for (let key in data) {
          if (!data[key]) {
            delete data[key];
          }
        }
        dispatch(TitleCompanyHandle.createTitleCompany(data));
      } else if (tag === "OFFICE") {
        let data = { ...values, "titleType": "TITLE_OFFICE" };
        for (let key in data) {
          if (!data[key]) {
            delete data[key];
          }
        }
        dispatch(TitleCompanyHandle.createTitleCompany(data));
      }else if(tag === "SALESREP" || tag === "TITLECLOSERS") {
        let data = { ...values,verify:tag};
        for (let key in data) {
          if (!data[key]) {
            delete data[key];
          }
        }
        TitleCompanyHandle.updateClosuresSalesRep(data);
      }
      dispatch(firmDetailsHandle.resetImageUrl());
      form.resetFields();
      setShowCreateModal(false);
      dispatch(firmDetailsHandle.resetImageUrl());
    }

    let handleCustome = (event) => {
      setVisible(true);
      setFileData(event.file);
      setVisible(true);
  
      // Preview the image before uploading to our server
      const file = event.file;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        setPreviewUrl(base64String);
      };
    };

    function beforeUpload(file) {
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    }

    const handleImageUpload = () => {
      dispatch(firmDetailsHandle.postImage(fileData));
      setVisible(false);
    };

    const handleCancel = () => {
      setVisible(false);
    };
   
    return (
      <>
        <Modal visible={visible} onCancel={handleCancel} footer={[]}>
          {/* Show only when a new picture is uploaded */}
          <div
            style={{
              display: previewUrl ? "block" : "none",
              width: "50%",
              margin: "auto",
              textAlign: "center",
            }}
          >
            {previewUrl && (
              <Image
                src={`data:image/png;base64,${previewUrl}`}
                width="250px"
                height="200px"
              />
            )}
            <div
              style={{ marginTop: "30px", textAlign: "center" }}
              wrapperCol={{ offset: 10, span: 14 }}
            >
              <Button
                type="primary"
                onClick={handleImageUpload}
                style={{ marginRight: "15px", marginLeft: "20px" }}
              >
                Save
              </Button>
              <Button type="primary" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
        {postImageLoading ? (
          <div
            style={{
              display: "flex",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div style={{ top: 20, height: "400px", overflowY: "auto" }}>
            <Form
              form={form}
              colon={false}
              autoComplete="off"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 15 }}
              labelAlign="left"
              onFinish={handleFinish}
            >
              <div>
                {tag === 'SALESREP' || tag === "TITLECLOSERS" ? (
                  <>
                  <Form.Item
                    label="Select Title Office"
                    name="parent"
                    rules={[
                      {
                        required: true,
                        message: "Please Select TitleOffice Name!",
                      },
                    ]}
                  >
                    <Select defaultValue={"Select Title Office"}>
                      {getOfficeData?.map((el) => {
                        return (
                          <Option key={el._id} value={el._id}>
                            {el.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                    <Form.Item
                      label={<span style={{ marginLeft: '11px' }}>Name</span>}
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Name!',
                        },
                      ]}
                    >
                      <Input placeholder="Enter Name" allowClear />
                    </Form.Item>
    
                    <Form.Item
                      label={<span style={{ marginLeft: '11px' }}>Email</span>}
                      name="email"
                      hasFeedback
                      rules={[
                        {
                          type: 'email',
                          message: 'Not a valid Email!',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Email"
                        style={{
                          flex: 1,
                        }}
                        allowClear
                      />
                    </Form.Item>
    
                    <Form.Item
                      label={
                        <span style={{ marginLeft: '11px' }}>Phone Number</span>
                      }
                      name="phoneNumber"
                      hasFeedback
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value?.length) {
                              if (value?.length === 10) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('Please Enter 10 digit Phone Number!')
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <PhoneInput
                        inputStyle={{ width: '100%' }}
                        inputClass="phoneInput"
                        country={'us'}
                        onlyCountries={['us']}
                        maxLength="10"
                        international={false}
                        disableCountryCode={true}
                        placeholder="Enter Phone Number"
                        className="phone-input"
                        style={{ width: '286px !important' }}
                      />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Form.Item>
                  </>
                ) : (
                  <>
                    {tag === 'COMPANY' ? (
                      <>
                        <Form.Item
                          label="Company Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Company Name!',
                            },
                          ]}
                        >
                          <Input
                            name={'name'}
                            value={formData.name}
                            placeholder="Enter Name"
                            allowClear
                          />
                        </Form.Item>
                        <Form.Item
                          label={
                            <div
                              style={{
                                position: 'relative',
                                display: 'flex',
                              }}
                            >
                              <span style={{ marginLeft: '10px' }}>Company Logo</span>
                            </div>
                          }
                          name="companyLogo"
                        >
                          <>
                            <Input
                              disabled={postImageUrl}
                              style={{
                                marginLeft: '4px',
                                width: '102%',
                              }}
                              placeholder={'Enter Logo Url'}
                              allowClear
                            />
                            {postImageUrl && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '0',
                                  left: 10,
                                  zIndex: '30',
                                }}
                              >
                                <Image
                                  width={50}
                                  height={'31px'}
                                  src={postImageUrl}
                                />
                              </div>
                            )}
                            <div
                              style={{
                                position: 'absolute',
                                top: '0',
                                right: 4,
                                zIndex: '30',
                                height: '20px',
                                cursor: 'pointer',
                              }}
                            >
                              <Upload
                                showUploadList={false}
                                customRequest={handleCustome}
                                beforeUpload={beforeUpload}
                                style={{ fontSize: '20px' }}
                                size={10}
                              >
                                <span style={{ fontSize: '20px', height: '10px' }}>
                                  <BiImageAdd
                                    style={{
                                      fontSize: '34px',
                                      fill: '#085191',
                                      color: '#085191',
                                    }}
                                  />
                                </span>
                              </Upload>
                            </div>
                          </>
                        </Form.Item>
                      </>
                    ) : (
                      ''
                    )}
                    {tag === 'OFFICE' ? (
                      <>
                        <Form.Item
                          label="Select Title Company"
                          name="parent"
                          rules={[
                            {
                              required: true,
                              message: 'Please Enter Company Name!',
                            },
                          ]}
                        >
                          <Select defaultValue={'Select Company'}>
                            {getCompanyData?.map((el) => {
                              return (
                                <Option key={el._id} value={el._id}>
                                  {el.name}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </>
                    ) : (
                      ''
                    )}
                    {tag === 'OFFICE' ? (
                      <Form.Item
                        label="Office Name"
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: 'Please Enter Office Name!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter Office Name" allowClear />
                      </Form.Item>
                    ) : (
                      ''
                    )}
    
                    {getUserDetailsData?.titleAdminRole === 'SUPER_ADMIN' ? (
                      <Form.Item
                        label="Assign an admin"
                        name="admin"
                        rules={[
                          {
                            message:
                              'Please Assign admin whose title is being created',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          value={''}
                          placeholder={'Assign Admin'}
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          notFoundContent={null}
                        ></Select>
                      </Form.Item>
                    ) : (
                      ''
                    )}
    
                    <Form.Item
                      label="Street Number"
                      name="streetNumber"
                      rules={[
                        { required: true, message: 'Please Enter Street Number! ' },
                      ]}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Input
                        placeholder="Enter Street Number"
                        style={{
                          flex: 1,
                        }}
                        allowClear
                      />
                    </Form.Item>
    
                    {/* Street Name */}
                    <Form.Item
                      label="Street Name"
                      name="streetName"
                      rules={[
                        { required: true, message: 'Please Enter Street Name! ' },
                      ]}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Input
                        placeholder="Enter Street Name"
                        style={{
                          flex: 1,
                        }}
                        allowClear
                      />
                    </Form.Item>
    
                    <Form.Item
                      label={<span style={{ marginLeft: '11px' }}>Unit</span>}
                      name="unit"
                    >
                      <Input
                        placeholder="Enter Unit"
                        style={{
                          flex: 1,
                        }}
                        allowClear
                      />
                    </Form.Item>
    
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[
                        { required: true, message: 'Please Enter City!' },
                      ]}
                    >
                      <Input
                        placeholder="Enter City"
                        style={{
                          flex: 1,
                        }}
                        allowClear
                      />
                    </Form.Item>
    
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        { required: true, message: 'Please Enter State!' },
                      ]}
                    >
                      <Input
                        placeholder="Enter State"
                        style={{
                          flex: 1,
                        }}
                        allowClear
                      />
                    </Form.Item>
    
                    <Form.Item
                      label="Zip Code"
                      name="zipCode"
                      hasFeedback
                      rules={[
                        { required: true, message: 'Please Enter Zip Code!' },
                        { whitespace: true },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value?.length) {
                              if (value?.length > 4 && value.length < 7) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('Please Enter valid Zip Code!')
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input
                        type="number"
                        maxLength={'6'}
                        style={{
                          flex: 1,
                        }}
                        placeholder="Enter Zip Code"
                        allowClear
                      />
                    </Form.Item>
    
                    <Form.Item
                      label={<span style={{ marginLeft: '11px' }}>Email</span>}
                      name="email"
                      hasFeedback
                      rules={[
                        {
                          type: 'email',
                          message: 'Not a valid Email!',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Email"
                        style={{
                          flex: 1,
                        }}
                        allowClear
                      />
                    </Form.Item>
    
                    <Form.Item
                      label={
                        <span style={{ marginLeft: '11px' }}>Phone Number</span>
                      }
                      name="phoneNumber"
                      hasFeedback
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value?.length) {
                              if (value?.length === 10) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('Please Enter 10 digit Phone Number!')
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <PhoneInput
                        inputStyle={{ width: '100%' }}
                        inputClass="phoneInput"
                        country={'us'}
                        onlyCountries={['us']}
                        maxLength="10"
                        international={false}
                        disableCountryCode={true}
                        placeholder="Enter Phone Number"
                        className="phone-input"
                        style={{ width: '286px !important' }}
                      />
                    </Form.Item>
    
                    <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    </Form.Item>
                  </>
                )}
              </div>
            </Form>
          </div>
        )}
      </>
    );
    
  };

export {CreateTitleCompanyForm}