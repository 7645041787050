import { listingEditConstants } from "./action-types";
import { categoryDataApi } from "../utils/api";

/**
 * All Actions for Category
 */

/**
 * To update Category state
 * @param {string} auth
 */

const createCategoryError = (error) => {
  return {
    type: listingEditConstants.CREATE_CATEGORY_FAILURE,
    createCategoryError: error,
    createCategoryLoading: true,
  };
};

const createCategorySuccess = (response) => {
  return {
    type: listingEditConstants.CREATE_CATEGORY_SUCCESS,
    createCategoryData: response.data.info,
    createCategoryLoading: false,
  };
};

const createCategory = (data, categoryType) => {
  return (dispatch, getState) => {
    categoryDataApi
      .createCategoryRequest(data, categoryType)
      .then((response) => {
        dispatch(getCategoryList());
        dispatch(createCategorySuccess(response));
      })
      .catch((error) => {
        dispatch(createCategoryError(error));
      });
  };
};

//Get category
const getCategoryError = (error) => {
  return {
    type: listingEditConstants.GET_CATEGORY_FAILURE,
    getCategoryError: error,
    getCategoryLoading: true,
  };
};

const getCategorySuccess = (response) => {
  return {
    type: listingEditConstants.GET_CATEGORY_SUCCESS,
    getCategoryData: response.data.info,
    getCategoryLoading: false,
  };
};

const getCategoryList = () => {
  return (dispatch, getState) => {
    categoryDataApi
      .getCategoryRequest()
      .then((response) => {
        dispatch(getCategorySuccess(response));
      })
      .catch((error) => {
        dispatch(getCategoryError(error));
      });
  };
};

//Get Sub category
const getSubCategoryError = (error) => {
  return {
    type: listingEditConstants.GET_SUBCATEGORY_FAILURE,
    getSubCategoryError: error,
    getSubCategoryLoading: false,
  };
};

const getSubCategorySuccess = (response) => {
  return {
    type: listingEditConstants.GET_SUBCATEGORY_SUCCESS,
    getSubCategoryData: response.data.info,
    getSubCategoryLoading: false,
  };
};

const getSubCategory = (data) => {
  return (dispatch, getState) => {
    categoryDataApi
      .getSubCategoryRequest(data)
      .then((response) => {
        dispatch(getSubCategorySuccess(response));
      })
      .catch((error) => {
        dispatch(getSubCategoryError(error));
      });
  };
};

//Get Dock type
const getDockTypeError = (error) => {
  return {
    type: listingEditConstants.GET_DOCKTYPE_FAILURE,
    getDockTypeError: error,
    getDockTypeLoading: false,
  };
};

const getDockTypeSuccess = (response) => {
  return {
    type: listingEditConstants.GET_DOCKTYPE_SUCCESS,
    getDockTypeData: response.data.info,
    getDockTypeLoading: false,
  };
};

const getDockType = (data) => {
  return (dispatch, getState) => {
    categoryDataApi
      .getDockTypeRequest(data)
      .then((response) => {
        // dispatch(getCategoryList());
        dispatch(getDockTypeSuccess(response));
      })
      .catch((error) => {
        dispatch(getDockTypeError(error));
      });
  };
};

// Edit Category
const editCategoryError = (error) => {
  return {
    type: listingEditConstants.EDIT_CATEGORY_FAILURE,
    editCategoryError: error,
    editCategoryLoading: true,
  };
};

const editCategorySuccess = (response) => {
  return {
    type: listingEditConstants.EDIT_CATEGORY_SUCCESS,
    editCategoryData: response.data.info,
    editCategoryLoading: false,
  };
};

const editCategory = (data, id) => {
  return (dispatch, getState) => {
    categoryDataApi
      .editCategoryRequest(data, id)
      .then((response) => {
        dispatch(editCategorySuccess(response));
        dispatch(getCategoryList());
      })
      .catch((error) => {
        dispatch(editCategoryError(error));
      });
  };
};

const deleteCategoryError = (error) => {
  return {
    type: listingEditConstants.DELETE_CATEGORY_FAILURE,
    deleteCategoryError: error,
    deleteCategoryLoading: true,
  };
};

const deleteCategorySuccess = (response) => {
  return {
    type: listingEditConstants.DELETE_CATEGORY_SUCCESS,
    deleteCategoryData: response.data.info,
    deleteCategoryLoading: false,
  };
};

const deleteCategory = (id) => {
  return (dispatch, getState) => {
    categoryDataApi
      .deleteCategoryRequest(id)
      .then((response) => {
        dispatch(deleteCategorySuccess(response));
        dispatch(getCategoryList());
      })
      .catch((error) => {
        dispatch(deleteCategoryError(error));
      });
  };
};

// Upload Image
const uploadImageError = (error) => {
  return {
    type: listingEditConstants.UPLOAD_IMAGE_FAILURE,
    uploadImageError: error,
    uploadImageLoading: true,
  };
};

const uploadImageSuccess = (response) => {
  return {
    type: listingEditConstants.UPLOAD_IMAGE_SUCCESS,
    uploadImageData: response.data.info,
    uploadImageLoading: false,
  };
};

const handleImageUploadUrl = (data, id, name) => {
  return (dispatch, getState) => {
    categoryDataApi
      .UpdateImageUrlRequest(data.data.info.fileData.fileUrl, id, name)
      .then((response) => {
        dispatch(uploadImageSuccess(response));
        dispatch(getCategoryList());
      })
      .catch((error) => {
        dispatch(uploadImageError(error));
      });
  };
};

const uploadImage = (file, id, name) => {
  return (dispatch, getState) => {
    categoryDataApi
      .uploadImageRequest(file)
      .then((response) => {
        dispatch(handleImageUploadUrl(response, id, name));
      })
      .catch((error) => {
        dispatch(uploadImageError(error));
      });
  };
};

export const listingEditAction = {
  getCategoryList,
  getSubCategory,
  getDockType,
  createCategory,
  editCategory,
  deleteCategory,
  uploadImage,
};
